import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Collapse, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import ClickToCopy from "components/ClickToCopy";
import { Button } from "components/buttons/";

import NotifyContext from "contexts/NotifyContext";

import { ltiResetConsumerKey } from "features/account/lticonsumer.actions";

import { URL } from "config/";

function MoodleIntegrationsList() {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const [showIntegerations, setShowIntegrations] = useState(false); // toggles diplay of consumer intagrations <Collaps>
	const [resettingIntegration, setResettingIntegration] = useState({}); // stores loading state of exiting consumers

	const {
		entity: { ltiOrganizationConsumers = {} },
	} = useSelector((state) => state.account);

	/** if ltiOrganizationConsumers changes, open showIntegratons, re-initialize resettingIntegrations */
	useEffect(() => {
		// console.log(
		// 	"useEffect called - ltiOrganizationConsumers changed - ",
		// 	Object.keys(ltiOrganizationConsumers).length
		// );
		if (0 < Object.keys(ltiOrganizationConsumers).length) {
			setShowIntegrations(true);
			setResettingIntegration({ ...ltiOrganizationConsumers });
		}
	}, [ltiOrganizationConsumers]);

	// Return empty <> if no existing consumers
	if (Object.keys(ltiOrganizationConsumers).length < 1)
		return <React.Fragment />;

	/** Resets secret key for given consumerID */
	const onResetSecretKeyClick = async (ltiConsumerID) => {
		setResettingIntegration({
			...resettingIntegration,
			[ltiConsumerID]: { loading: true },
		});
		const res = await dispatch(ltiResetConsumerKey({ ltiConsumerID }));
		if (res.error) notify.error(`Error updating Consumer ID ${ltiConsumerID}`);
		else notify.success("Consumer Secret Key successfully reset!");
		setResettingIntegration({
			...resettingIntegration,
			[ltiConsumerID]: { loading: false },
		});
	};

	const renderConsumerKeyValue = (title, name, value) => {
		return (
			<Row className="mt-1" style={{ display: "flex" }}>
				<Col>{title}</Col>
				<Col>
					<ClickToCopy id={name} text={value} />
				</Col>
			</Row>
		);
	};

	const ltiConsumersList = [];
	Object.keys(ltiOrganizationConsumers).forEach((ltiConsumerID) => {
		const { ltiConsumerKey, ltiConsumerName, ltiConsumerSecret } =
			ltiOrganizationConsumers[ltiConsumerID];

		ltiConsumersList.push(
			<Row
				key={ltiConsumerID}
				className="mb-3 pb-3"
				style={{ borderBottom: "1px solid" }}
			>
				<Col xl={12}>
					<Row>
						<Col>
							<h6>
								{undefined === ltiConsumerName
									? "This integration has not been used."
									: `ConsumerID Desciption: ${ltiConsumerName}`}
							</h6>
						</Col>
					</Row>
					{renderConsumerKeyValue("Consumer ID", "ltiConsumerID", ltiConsumerID)}
					{ltiConsumerKey
						? renderConsumerKeyValue("Consumer key", "ltiConsumerKey", ltiConsumerKey)
						: null}
					{renderConsumerKeyValue("Tool URL", "toolURL", URL.MOODLE_TOOL)}
					{renderConsumerKeyValue(
						"Secret key",
						"ltiConsumerSecret",
						ltiConsumerSecret
					)}
					<div
						className="col-12 container"
						style={{ marginTop: ".8rem", textAlign: "right" }}
					>
						<Button
							onClick={() => onResetSecretKeyClick(ltiConsumerID)}
							variant="outline-success"
							disabled={resettingIntegration[ltiConsumerID]?.loading}
							loading={resettingIntegration[ltiConsumerID]?.loading}
						>
							Reset Secret Key
						</Button>
					</div>
				</Col>
			</Row>
		);
	});

	const padding = "pt-2";
	return (
		<React.Fragment>
			<tr>
				<td />
				<td
					className={`cursor-pointer text-start ${padding}`}
					colSpan={2}
					onClick={() => setShowIntegrations(!showIntegerations)}
				>
					<h5>
						Exisiting Moodle Intgrations{" "}
						{showIntegerations ? (
							<FontAwesomeIcon icon={solid("angle-up")} />
						) : (
							<FontAwesomeIcon icon={solid("angle-down")} />
						)}
					</h5>
				</td>
			</tr>
			<Collapse in={showIntegerations} className="mb-0">
				<tr className="hello">
					<td className={padding} />
					<td className={padding} colSpan={2}>
						{ltiConsumersList}
					</td>
				</tr>
			</Collapse>
		</React.Fragment>
	);
}

export default MoodleIntegrationsList;
