import React, { useEffect, useState } from "react";

import useAppSelector from "hooks/useAppSelector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Dropdown, Form } from "react-bootstrap";

// import createValidation from "yup/lib/util/createValidation";

const AnalysisFilters = (props) => {
	const { currentProjectDashboards } = props;
	const { currentProjectMarkers } = props;

	const {
		entities: dashboardEntities,
		error: dashboardError,
		loading: dashboardLoading,
		currentEntityID: currentDashboardID,
	} = useAppSelector((state) => state.dashboards);

	const {
		entities: dashboardTags,
		error: tagError,
		loading: tagLoading,
		currentEntityID: currentTagID,
	} = useAppSelector((state) => state.dashboardTags);

	const {
		entities: markerEntities,
		error: markerError,
		loading: markerLoading,
		currentEntityID: currentMarkerID,
	} = useAppSelector((state) => state.userMarkers);

	//DASHBOARDS/TAGS

	const [dashboardTagsDropdown, setDashboardTagsDropdown] = useState();

	const generateTagList = (tagIDs) => {
		if (tagIDs === undefined || tagIDs.length < 1) {
			return null;
		}
		let tagButtonArray = [];
		for (let i = 0; i < tagIDs.length; i++) {
			if (dashboardTags[tagIDs[i]]) {
				tagButtonArray.push(dashboardTags[tagIDs[i]]);
			}
		}
		return tagButtonArray.map((tag) => {
			return <Dropdown.Item key={tag.dashboardTagID}>{tag.label}</Dropdown.Item>;
		});
	};

	useEffect(() => {
		if (undefined !== currentProjectDashboards) {
			setDashboardTagsDropdown(
				currentProjectDashboards.map((dashboard) => {
					return (
						<React.Fragment key={dashboard.dashboardID}>
							<Dropdown.Header>
								<i>{dashboard.title}</i>
							</Dropdown.Header>
							{generateTagList(dashboard.dashboardTags)}
						</React.Fragment>
					);
				})
			);
		}
	}, [currentProjectDashboards]);

	//MARKERS/OPTIONS

	const [markersDropdown, setMarkersDropdown] = useState();

	const generateOptionList = (optionList) => {
		let optionsArray = [];
		for (const [key, value] of Object.entries(optionList)) {
			optionsArray.push(value);
		}
		return optionsArray.map((option) => {
			return <Dropdown.Item key={option}>{option}</Dropdown.Item>;
		});
	};

	useEffect(() => {
		if (undefined !== currentProjectMarkers) {
			setMarkersDropdown(
				currentProjectMarkers.map((marker) => {
					return (
						<React.Fragment key={marker.markerID}>
							<Dropdown.Header>
								<i>{marker.name}</i>
							</Dropdown.Header>
							{generateOptionList(marker.options)}
						</React.Fragment>
					);
				})
			);
		}
	}, [currentProjectDashboards]);

	return (
		<div id="icons" className="d-flex justify-content-start w-100 p-2">
			<Dropdown className="me-1" id="visibilityDropdown">
				<Dropdown.Toggle>
					<FontAwesomeIcon icon={regular("eye")} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Header>
						<i>Analysis Visibility</i>
					</Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Item eventKey="collaborators">
						Collaborators <FontAwesomeIcon icon={regular("eye")} />
					</Dropdown.Item>
					<Dropdown.Item eventKey="private">
						Private <FontAwesomeIcon icon={regular("eye-slash")} />
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			<Dropdown className="me-1" id="tagFilterDropdown">
				<Dropdown.Toggle>
					<FontAwesomeIcon icon={regular("bullseye")} />
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ maxHeight: "500px", overflow: "auto" }}>
					<Dropdown.Header>
						<i>Filter by tag</i>
					</Dropdown.Header>
					<Dropdown.Divider />
					{dashboardTagsDropdown}
				</Dropdown.Menu>
			</Dropdown>

			<Dropdown className="me-1" id="markerFilterDropdown">
				<Dropdown.Toggle>
					<FontAwesomeIcon icon={regular("bookmark")} />
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ maxHeight: "500px", overflow: "auto" }}>
					<Dropdown.Header>
						<i>Filter by marker</i>
					</Dropdown.Header>
					<Dropdown.Divider />
					{markersDropdown}
				</Dropdown.Menu>
			</Dropdown>

			<Dropdown className="me-1" id="collaboratorFilterDropdown">
				<Dropdown.Toggle>
					<FontAwesomeIcon icon={regular("users")} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Header>
						<i>Filter by collaborator</i>
					</Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Item eventKey="owner">You (owner)</Dropdown.Item>
					<Dropdown.Item eventKey="showAll">Show All</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			<Dropdown className="me-1" id="sortByTimeCode">
				<Dropdown.Toggle>
					<FontAwesomeIcon icon={regular("arrow-down-wide-short")} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Header>
						<i>Sort by...</i>
					</Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Item eventKey="timeCode">
						Time code <FontAwesomeIcon icon={solid("arrow-down")} />
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			<Form.Control
				type="text"
				// onChange={handleSearchOnChange}
				style={{ maxWidth: "150px" }}
			/>
		</div>
	);
};

export default AnalysisFilters;
