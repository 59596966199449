export const NavButton = ({ className, icon, onClick }) => (
	<div className="nav-item">
		<div
			className={`nav-link nav-icon cursor-pointer ${className}`}
			onClick={onClick}
		>
			{icon}
		</div>
	</div>
);
