/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import { createSlice } from "@reduxjs/toolkit";
import {
	fetchOrganizations,
	updateOrganizations,
} from "./organizations.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entity: {},
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
	// currentRequestId: undefined, //a unique string ID value automatically generated by the thunk callback, identifying this request sequence
	// error: null,
};

const organizationsSlice = createSlice({
	name: "organizations",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// setCurrentEntityID: (state, action) => {
		// 	// state.currentEntityID = action.payload; // payload is itemID
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		/** fetchOrganizations */
		[fetchOrganizations.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchOrganizations.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload contains organizations object
		},
		[fetchOrganizations.rejected]: (state, action) => {
			state.status = "failed";
			// state.error = action.error;
		},

		/** updateOrganizations */
		[updateOrganizations.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateOrganizations.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload is updated project object
		},
		[updateOrganizations.rejected]: (state, action) => {
			state.status = "failed";
			// if (action.payload) {
			// 	state.error = action.payload.errorMessage;
			// } else {
			// 	state.error = action.error;
			// }
		},
	},
});

// Action creators are generated for each case reducer function
// export const { setCurrentEntityID } = organizationsSlice.actions;
export default organizationsSlice.reducer;
