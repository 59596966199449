/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import { createSlice } from "@reduxjs/toolkit";
import {
	fetchImportedZoomRecordings,
	fetchZoomRecordings,
	importZoomRecording,
} from "./zoom.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: null, //{},
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
	message: undefined,
};

const zoomSlice = createSlice({
	name: "zoom",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// setCurrentEntityID: (state, action) => {
		// 	// state.currentEntityID = action.payload; // payload is itemID
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		zoomReset: () => initialState,
		zoomClearRecordings: (state) => {
			state.entities = {};
		},
		zoomMessage: (state, action) => {
			state.message = action.payload;
		},

		/** fetchZoomRecordings */
		[fetchZoomRecordings.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchZoomRecordings.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// do nothing with payload. payload is handled by projects.
			// zoom store only deals with listings from zoom cloud api
		},
		[fetchZoomRecordings.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** fetchImportedZoomRecordings */
		[fetchImportedZoomRecordings.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchImportedZoomRecordings.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entities = action.payload; // updated state.entities object
		},
		[fetchImportedZoomRecordings.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** importZoomRecording */
		[importZoomRecording.pending]: (state, action) => {
			state.status = "pending";
		},
		[importZoomRecording.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// state.entities = action.payload; // updated state.entities object
		},
		[importZoomRecording.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// Action creators are generated for each case reducer function
// export const {} = zoomSlice.actions;
export default zoomSlice.reducer;
