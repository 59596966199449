import "./icons.scss";

import { FaIcon } from "./FaIcon.js";
import { THEME_COLORS } from "constants/";

// export const AngleLeftIcon = (props) => <FaIcon icon="angleLeft" {...props} />;
// export const AngleRightIcon = (props) => (
// 	<FaIcon icon="angleRight" {...props} />
// );
// export const ArrowDownIcon = (props) => <FaIcon icon="arrowDown" {...props} />;

// export const ArrowUpRightFromSquareIcon = (props) => (
// 	<FaIcon icon="arrowUpRightFromSquare" {...props} />
// );

export const AudioIcon = (props) => <FaIcon icon="music" {...props} />;
// export const BellIcon = (props) => <FaIcon icon="bell" {...props} />;
// export const BookmarkIcon = (props) => <FaIcon icon="bookmark" {...props} />;

export const BullhornIcon = (props) => <FaIcon icon="bullhorn" {...props} />;

export const ButtonLoadingIcon = (props) => (
	<FaIcon icon="circle-notch" className="fa-spin ms-2" />
);

export const CircleNotchSpinningIcon = (props) => (
	<FaIcon
		{...props}
		icon="circle-notch"
		className={`fa-spin ${props.className}`}
	/>
);

export const CheckIcon = (props) => (
	<FaIcon weight="fas" icon="check" {...props} />
);

export const CreateIcon = (props) => <PlusIcon {...props} />;

export const CreateFolderIcon = (props) => (
	<FaIcon icon="folder-plus" {...props} />
);

export const CreditCardIcon = (props) => (
	<FaIcon icon="credit-card" {...props} />
);

export const FileCopyIcon = (props) => <FaIcon icon="copy" {...props} />;

export const EllipsesIcon = (props) => <FaIcon icon="ellipsis" {...props} />;

export const GearIcon = (props) => <FaIcon icon="gear" {...props} />;

export const GripDotsVIcon = (props) => (
	<FaIcon
		icon="grip-dots-vertical"
		// weight="fad"
		size="lg"
		className="ps-0 pe-2 icon-gripper"
		color={THEME_COLORS.muted}
		{...props}
	/>
);

export const HomeIcon = (props) => <FaIcon icon="home-blank" {...props} />;

export const LockIcon = (props) => <FaIcon icon="lock" {...props} />;

export const LockOpenIcon = (props) => <FaIcon icon="lock-open" {...props} />;

export const MagnifyingGlassIcon = (props) => (
	<FaIcon icon="magnifying-glass" {...props} />
);

export const OpenIcon = (props) => (
	<FaIcon icon="up-right-from-square" {...props} />
);

export const PlusIcon = (props) => <FaIcon icon="plus" {...props} />;
export const RedoIcon = (props) => <FaIcon icon="redo" {...props} />;

export const RocketLaunchIcon = (props) => (
	<FaIcon icon="rocket-launch" {...props} />
);

export const ShieldCheckIcon = (props) => (
	<FaIcon icon="shield-check" {...props} />
);

export const ShieldBlankIcon = (props) => (
	<FaIcon icon="shield-blank" {...props} />
);

export const SquareIcon = (props) => <FaIcon icon="square" {...props} />;
export const SquareCheckIcon = (props) => (
	<FaIcon icon="square-check" {...props} />
);

export const SortIcon = (props) => (
	<FaIcon icon="arrow-down-wide-short" {...props} />
);
export const SortAZIcon = (props) => (
	<FaIcon icon="arrow-down-a-z" {...props} />
);
export const SortZAIcon = (props) => <FaIcon icon="arrow-up-a-z" {...props} />;

export const Sort19Icon = (props) => (
	<FaIcon icon="arrow-down-1-9" {...props} />
);
export const Sort91Icon = (props) => <FaIcon icon="arrow-up-1-9" {...props} />;

export const StarIcon = (props) => (
	<FaIcon
		icon="star"
		weight="fas"
		className="my-auto"
		color={THEME_COLORS.plum}
		{...props}
	/>
);

export const TrashCanIcon = (props) => (
	<FaIcon icon="trash-can" variant="danger" {...props} />
);

export const DownloadIcon = (props) => (
	<FaIcon icon="cloud-arrow-down" {...props} />
);

export const UploadIcon = (props) => (
	<FaIcon icon="cloud-arrow-up" {...props} />
);

export const VideoIcon = (props) => <FaIcon icon="video" {...props} />;

export const ZoomVideoIcon = (props) => <VideoIcon {...props} />;

// export const CircleIcon = (props) => <FaIcon icon="circle" {...props} />;
// export const CircleCheckIcon = (props) => (
// 	<FaIcon icon="circleCheck" {...props} />
// );

// export const CirclePlayIcon = (props) => (
// 	<FaIcon icon="circlePlay" {...props} />
// );

// export const ChartLineUpIcon = (props) => (
// 	<FaIcon icon="chartLineUp" {...props} />
// );

// export const EyeIconIcon = (props) => <FaIcon icon="eye" {...props} />;
// export const EyeSlashIcon = (props) => <FaIcon icon="eyeSlash" {...props} />;
// export const FileLinesIcon = (props) => <FaIcon icon="FileLines" {...props} />;

// export const LayerGroupIcon = (props) => (
// 	<FaIcon icon="layerGroup" {...props} />
// );

// export const PaperclipIcon = (props) => <FaIcon icon="Paperclip" {...props} />;

// export const SpinnerIcon = (props) => <FaIcon icon="spinner" {...props} />;
// export const ThumbsUpIcon = (props) => <FaIcon icon="thumbsUp" {...props} />;
// export const TrashCanIcon = (props) => <FaIcon icon="trashCan" {...props} />;
// export const UserIcon = (props) => <FaIcon icon="user" {...props} />;
