import { createSlice } from "@reduxjs/toolkit";

import {
	createUserMarker,
	deleteUserMarker,
	fetchUserMarkers,
	updateUserMarker,
} from "features/markers/markers.actions";

/**
 * Redux Learning NOTES
 * =====================\
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: null,
	currentEntityID: undefined,
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
};

const userMarkersSlice = createSlice({
	name: "markers",
	initialState,

	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		setCurrentEntityID: (state, action) => {
			state.currentEntityID = action.payload; // payload is markerID
		},
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,

		/** fetchUserMarkers */
		[fetchUserMarkers.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchUserMarkers.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = action.payload; // payload contains object of user markers
		},
		[fetchUserMarkers.rejected]: (state, action) => {
			state.status = "idle";
			state.error = action.error;
		},

		/** createUserMarker */
		[createUserMarker.pending]: (state, action) => {
			state.status = "pending";
		},
		[createUserMarker.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = {
				...state.entities,
				...action.payload, // payload is markerObject {"abcID": {markerID: "abcID", ...}}
			};
			state.currentEntityID = Object.keys(action.payload)[0];
		},
		[createUserMarker.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** deleteUserMarker */
		[deleteUserMarker.pending]: (state, action) => {
			state.status = "pending";
		},
		[deleteUserMarker.fulfilled]: (state, action) => {
			state.status = "idle";
			delete state.entities[action.payload]; // payload is markerID
			state.currentEntityID = undefined;
		},
		[deleteUserMarker.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** updateUserMarker */
		[updateUserMarker.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateUserMarker.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities[action.payload.markerID] = action.payload; // payload is updated marker object
		},
		[updateUserMarker.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentEntityID } = userMarkersSlice.actions;
export default userMarkersSlice.reducer;
