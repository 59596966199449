/**
 * Thunks for members
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH, INVITE_STATUS } from "constants/";
import { removeProject } from "features/projects/projects.slice";

/** fetch organization members from API */
export const fetchItemAnalysis = createAsyncThunk(
	"itemAnalysis/fetch",
	async (itemAnalysisID, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(
				`${APIPATH.itemAnalysisGet}${itemAnalysisID}`
			);
			return {
				...getState().itemAnalyses.entities,
				[itemAnalysisID]: {
					...res,
				},
			};
		} catch (err) {
			// console.log("fetchItemAnalysis FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates itemAnalysis */
export const updateItemAnalysis = createAsyncThunk(
	"itemAnalysis/update",
	async (itemAnalysis, { dispatch, getState, requestId, rejectWithValue }) => {
		console.log("updateItemAnalysis to - ", itemAnalysis);
		// return;
		try {
			await apigateway.post(APIPATH.collaborationInvite, itemAnalysis);
			return {
				...getState().itemAnalyses.entities[itemAnalysis.itemAnalysisID],
				...itemAnalysis,
			};
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const inviteCollaboration = createAsyncThunk(
	"itemAnalysis/collaborate/invite",
	async (
		{ itemAnalysisID, emails, permission = "ANALYZE" },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		console.log(`inviteCollaborators to ${itemAnalysisID} - `, emails);
		try {
			const res = await apigateway.post(APIPATH.collaborationInvite, {
				itemAnalysisIDs: [itemAnalysisID],
				emails,
				permission,
				suppressNotificationEmail: false,
			});
			console.log("inviteCollaboration res: ", res);

			//todo: if res.failures: {
			// 	"NOTIFICATION": [],
			// 	"EXCEEDED": [],
			// 	"OTHER": []
			// }

			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			await dispatch({
				type: "account/rehydrateCollaborators",
				payload: [...res.collaborators],
			});
			await dispatch(fetchItemAnalysis(itemAnalysisID));
			return;
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const leaveCollaboration = createAsyncThunk(
	"itemAnalysis/collaborate/leave",
	async (
		{ itemID, itemAnalysisID = false },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			// Share
			if (itemAnalysisID === false)
				var res = await apigateway.get(
					`${APIPATH.collaborationLeave}itemID=${itemID}`
				);
			// Collaboration
			else
				var res = await apigateway.get(
					`${APIPATH.collaborationLeave}itemAnalysisID=${itemAnalysisID}`
				);

			dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});

			dispatch(removeProject(itemID));
			return itemAnalysisID;
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const revokeCollaboration = createAsyncThunk(
	"itemAnalysis/collaborate/revoke",
	async (
		{ itemAnalysisID, userID },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			await apigateway.get(
				`${APIPATH.collaborationRevoke}${userID}&itemAnalysisID=${itemAnalysisID}`
			);
			const itemAnalysis = { ...getState().itemAnalyses.entities[itemAnalysisID] };
			var tmpCollaborators = {
				...itemAnalysis.collaborators.users,
			};
			delete tmpCollaborators[userID];
			return {
				...itemAnalysis,
				collaborators: {
					...itemAnalysis.collaborators,
					users: { ...tmpCollaborators },
				},
			};
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const exportAnalysis = createAsyncThunk(
	"itemAnalysis/export",
	async (itemAnalysisID, { rejectWithValue }) => {
		try {
			await apigateway.get(`${APIPATH.itemAnalysisExport}${itemAnalysisID}`);
			return;
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
