import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AnalysisLayout from "layouts/Analysis";
import AppLayout from "layouts/App";
import AuthLayout from "layouts/Auth";

// Guards
import AuthGuard from "components/auth/AuthGuard";
import AuthRedirect from "components/auth/AuthRedirect";
import Redirect from "components/auth/Redirect";

// Pages
import AnalysisPage from "pages/analysis/index";
import DashboardsPage from "pages/dashboards/index";
import MarkersPage from "pages/markers/index";
import ProjectsPage from "pages/projects/index";
import Account from "pages/account/";
import UpgradePage from "pages/upgrade/index";

import TestPage from "pages/test/index";

// Auth
import Page404 from "pages/auth/Page404";
import SignIn from "pages/auth/SignIn";
import SignUp from "pages/auth/SignUp";
import ForgotPassword from "pages/auth/ForgotPassword";
import Verify from "pages/auth/Verify";

import { ROUTES } from "constants/";

const routes = [
	/** UNAUTHERD WITH REDIRECT FOR AUTHED - SignUp, SignIn */
	{
		path: "/",
		element: (
			<AuthRedirect>
				<AuthLayout />
			</AuthRedirect>
		),
		children: [
			{
				path: ROUTES.SIGNIN,
				element: <SignIn />,
			},
			{
				path: ROUTES.SIGNUP,
				element: <SignUp />,
			},
			{
				path: ROUTES.FORGOT,
				element: <ForgotPassword />,
			},
			{
				path: ROUTES.VERIFY,
				element: <Verify />,
			},
		],
	},

	// {
	// 	path: "/",
	// 	element: <AuthLayout />,
	// 	children: [
	// 		{
	// 			path: ROUTES["404"],
	// 			element: <Page404 />,
	// 		},
	// 	],
	// },

	/** AUTHED APP LAYOUT - Projects, dashbaords, markers */
	{
		path: "/",
		element: (
			<AuthGuard>
				<Redirect>
					<AppLayout />
				</Redirect>
			</AuthGuard>
		),
		children: [
			{
				path: ROUTES.DASHBOARDS,
				element: <DashboardsPage />,
			},
			{
				path: ROUTES.MARKERS,
				element: <MarkersPage />,
			},
			{
				path: ROUTES.PROJECTS,
				element: <ProjectsPage zoom={false} />,
				children: [
					{
						path: ROUTES.PROJECTS_RT,
					},
				],
			},
			{
				path: ROUTES.ZOOM,
				element: <ProjectsPage zoom={true} />,
				children: [
					{
						path: ROUTES.ZOOM_RT,
					},
				],
			},
			{
				path: ROUTES.UPGRADE,
				element: <UpgradePage />,
			},
			{
				path: ROUTES.ACCOUNT,
				element: <Account />,
				children: [
					{
						path: ROUTES.PROFILE,
						element: <Account />,
					},
					{
						path: ROUTES.ACCOUNT,
						element: <Account />,
					},
					{
						path: ROUTES.PREFERENCES,
						element: <Account />,
					},
					{
						path: ROUTES.BILLING,
						element: <Account />,
					},
					{
						path: ROUTES.REPORTS,
						element: <Account />,
					},
				],
			},
		],
	},

	/** AUTHED - ANALYSIS LAYOUT */
	{
		path: "/",
		element: (
			<AuthGuard>
				<AnalysisLayout />
			</AuthGuard>
		),
		children: [
			{
				path: ROUTES.ANALYSIS,
				element: <AnalysisPage />,
				children: [
					{
						path: ROUTES.ANALYSIS_RT,
					},
				],
			},
		],
	},

	/** 404 **/
	{
		path: "*",
		element: <AuthLayout />,
		children: [
			{
				path: "*",
				element: <Page404 />,
			},
		],
	},
	// {
	// 	path: "/test",
	// 	element: <AppLayout />,
	// 	children: [
	// 		{
	// 			path: "slidepanes/Allotment",
	// 			element: <TestPage package="allotment" />,
	// 		},
	// 		{
	// 			path: "slidepanes/ReactReflex",
	// 			element: <TestPage package="reactReflex" />,
	// 		},
	// 	],
	// },
];

export default routes;
