import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlignLeft,
	faAngleLeft,
	faAngleRight,
	faArrowDown,
	faArrowDownAZ,
	faArrowDown19,
	faArrowUpAZ,
	faArrowUp19,
	faArrowDownArrowUp,
	faArrowDownWideShort,
	faArrowUpRightFromSquare,
	faBell,
	faBookmark,
	faBullhorn,
	faBullseyePointer,
	faChartLineUp,
	faCircleCheck,
	faCircleNotch,
	faCirclePlay,
	// faCirclePlus,
	faCloudArrowDown,
	faCloudArrowUp,
	faCopy,
	faCreditCard,
	faEllipsis,
	faEye,
	faEyeSlash,
	faFileLines,
	faFilePen,
	faFolder,
	faFolderClosed,
	faFolderPlus,
	faGear,
	faGripDotsVertical,
	faHouseBlank,
	faLayerGroup,
	faLock,
	faLockOpen,
	faMagnifyingGlass,
	faMoon,
	faMusic,
	faPaperclip,
	faPersonWalkingArrowRight,
	faPlus,
	faRedo,
	faRocketLaunch,
	faShareFromSquare,
	faShieldCheck,
	faShieldBlank,
	faSpinner,
	faSquare,
	faSquareCheck,
	faSunBright,
	// faBrightness,
	faThumbsUp,
	faToggleOn,
	faTrashCan,
	faUpRightFromSquare,
	faUsers,
	faVideo,
} from "@fortawesome/pro-light-svg-icons";

import {
	faBars,
	faBrightness,
	faCheck,
	faCircle,
	// faCirclePlus,
	// faGripDotsVertical,
	faStar,
} from "@fortawesome/pro-solid-svg-icons";

export const icons = [
	faAlignLeft,
	// faAngleLeft,
	// faAngleRight,
	// faArrowDown,
	faArrowDownAZ,
	faArrowDown19,
	faArrowUpAZ,
	faArrowUp19,
	// faArrowDownArrowUp,
	faArrowDownWideShort,
	// faArrowUpRightFromSquare,
	// faBars,
	// faBell,
	faBookmark,
	// faBullhorn,
	faBullseyePointer,
	// faChartLineUp,
	faCheck,
	faCircle,
	// faCircleCheck,
	faCircleNotch,
	// faCirclePlay,
	// faCirclePlus,
	faCloudArrowDown,
	faCloudArrowUp,
	faCopy,
	// faCreditCard,
	faEllipsis,
	// faEye,
	// faEyeSlash,
	// faFileLines,
	faFilePen,
	faFolder,
	// faFolderClosed,
	faFolderPlus,
	// faFolder,
	faGear,
	faGripDotsVertical,
	// faLayerGroup,
	faHouseBlank,
	faLock,
	faLockOpen,
	faMagnifyingGlass,
	faMoon,
	faMusic,
	faPaperclip,
	faPersonWalkingArrowRight,
	faPlus,
	faRedo,
	faRocketLaunch,
	faShareFromSquare,
	faShieldCheck,
	faShieldBlank,
	// faBrightness,
	faSpinner,
	faSquare,
	faSquareCheck,
	faStar,
	faSunBright,
	// faThumbsUp,
	// faToggleOn,
	faTrashCan,
	faUpRightFromSquare,
	faUsers,
	faVideo,
];

export default function faInitialize() {
	library.add(...icons);
}
