import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const LoadingLips = () => {
	return (
		<div className="ellipsis-loader">
			<FontAwesomeIcon icon={solid("circle")} className="ms-1" />
			<FontAwesomeIcon icon={solid("circle")} className="ms-1" />
			<FontAwesomeIcon icon={solid("circle")} className="ms-1" />
		</div>
	);
};
