import React, { useContext, useState } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import NotifyContext from "contexts/NotifyContext";

import { Dropdown, ListGroup, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, light } from "@fortawesome/fontawesome-svg-core/import.macro";

import { inviteMember, revokeMember } from "features/members/members.actions";

import { INVITE_STATUS } from "constants";

const MemberDropdown = ({ member }) => {
	const dispatch = useAppDispatch();
	const notify = useContext(NotifyContext);

	const [loading, setLoading] = useState(false);

	const activate =
		member.status === INVITE_STATUS.LEFT ||
		member.status === INVITE_STATUS.DECLINED ||
		member.status === INVITE_STATUS.REVOKED
			? true
			: false;

	/** Revokes/Invites membership access for members & invitees */
	const toggleMembershipActivate = async (activate = true) => {
		setLoading(true);

		let activationFunction = activate ? inviteMember : revokeMember;

		const res = await dispatch(activationFunction(member));
		if (res.error) {
			const message = res.payload.message || "Something went wrong";
			notify.error(message);
		}
		setLoading(false);
	};

	return (
		<Dropdown className="me-2 nav-item" align="end" autoClose="outside">
			<Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
				<div className="position-relative">
					<FontAwesomeIcon icon={regular("ellipsis")} />
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu drop="end" className="dropdown-menu">
				<Dropdown.Item
					onClick={() => {
						toggleMembershipActivate(activate);
					}}
				>
					{loading ? (
						<FontAwesomeIcon
							disabled
							icon={activate ? light("user-plus") : light("user-minus")}
							className={
								`align-middle me-2 fa-beat-fade text-` +
								(activate ? `success` : `danger`)
							}
							style={{ opacity: 0.1 }}
						/>
					) : (
						<FontAwesomeIcon
							icon={activate ? light("user-plus") : light("user-minus")}
							className={`align-middle me-2 text-` + (activate ? `success` : `danger`)}
						/>
					)}
					{activate ? "Activate Membership" : "Deactivate Membership"}
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default MemberDropdown;
