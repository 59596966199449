import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

import { Col, Container, Row } from "react-bootstrap";

import DashboardsLists from "./DashboardsLists";
import SelectedDashboard from "./SelectedDashboard";
import TagInfo from "./TagInfo";
import TagsBank from "./TagsBank";

const DashboardsPage = () => {
	const { currentEntityID: currentDashboardID } = useSelector(
		(state) => state.dashboards
	);
	const { currentEntityID: currentDashboardTagID } = useSelector(
		(state) => state.dashboardTags
	); //Bringing in state from global store

	return (
		<React.Fragment>
			<Helmet title="Dashboards" />
			<Container fluid className="p-0 h-100">
				<Row className="g-0 h-100">
					<Col lg={3} xl={2} className="border-end">
						<DashboardsLists className="h-100" />
					</Col>
					<Col lg={5} xl={7}>
						<Row className="pb-4 mx-0 overflow-auto" style={{ minHeight: "100px" }}>
							<Col className="h-100">
								{/* <h4 className="border-bottom py-2 ">Hello</h4> */}

								{currentDashboardID ? (
									<SelectedDashboard />
								) : (
									<h2 className="my-4 text-muted" style={{ textAlign: "center" }}>
										{"Select a dashboard".toLocaleUpperCase()}
									</h2>
								)}
							</Col>
						</Row>
						<Row className="pt-1  mx-0 overflow-auto">
							<Col className="h-100">
								<TagsBank />
							</Col>
						</Row>
					</Col>
					<Col lg={4} xl={3} className="border-start">
						{undefined !== currentDashboardTagID ? <TagInfo /> : null}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default DashboardsPage;
