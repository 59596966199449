import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { ListGroupItem } from "react-bootstrap";
import { GripDotsVIcon, StarIcon } from "components/icons";

export const DashboardsListItem = ({
	active = false,
	dashboardID,
	index,
	onDashboardsReorderDrag,
	onDashboardsReorderDrop,
	onClick,
	title,
}) => {
	const ref = useRef(null);

	const [{ isDragging }, drag] = useDrag({
		type: "dashboard",
		item: () => {
			return { dashboardID, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [{ handlerId }, drop] = useDrop({
		accept: "dashboard",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			// console.log("Hover item - ", item);
			// console.log("Monitor - ", monitor);
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			onDashboardsReorderDrag(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		drop(item, monitor) {
			// console.log("Dropped item - ", item);
			// console.log("Monitor - ", monitor);
			onDashboardsReorderDrop(item);
		},
	});

	const opacity = isDragging ? 0 : 1;

	drag(drop(ref));

	return (
		<ListGroupItem
			action
			active={active}
			className="ps-1 py-2 d-flex justify-content-between align-items-start dashboards-list-item"
			data-handler-id={handlerId}
			ref={ref}
			onClick={onClick}
			style={{ opacity }}
		>
			<div className="me-auto">
				<GripDotsVIcon />
				{title}
			</div>
			<StarIcon />
		</ListGroupItem>
	);
};
