/** Defines listeners for redux store */
import { createListenerMiddleware } from "@reduxjs/toolkit";

import { bootIntercom, updateIntercom } from "utils/";

// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerMiddleware.startListening({
	predicate: (action, currentState, previousState) => {
		// console.log("predicate heard with action: ", action);
		// console.log("predicate heard");
		// console.log("window.Intercom: ", window.Intercom);

		switch (action.type) {
			case "user/fetch/fulfilled":
			case "user/update/fulfilled":
				/** any changes to user require listening too*/
				if (currentState.user.entity !== previousState.user.entity) return true;
				break;

			case "account/rehydrate":
			case "account/fetch/fulfilled":
			case "account/update/fulfilled": {
				const currentAccount = currentState.account.entity;
				const prevAccount = previousState.account.entity;
				/** changes to planDate, usageDate and organizations require listening too  */
				if (
					currentAccount.planData !== prevAccount.planData ||
					currentAccount.usageData !== prevAccount.usageData ||
					currentAccount.organizations !== prevAccount.organizations
				) {
					return true;
				}
				break;
			}
			default:
				return false;
		}
	},
	effect: async (action, { getState, dispatch }) => {
		const { account, user } = getState();
		// console.log("listenerMiddleware - user ", user);
		// console.log("listenerMiddleware - account ", account);

		/** USER */
		if (action.type.startsWith("user/")) {
			// console.log("listenerMiddleware - user effects happening...  ", user.entity);
			// update Intercom
			bootIntercom({ ...user.entity });
		}

		/** aACCOUNT */
		if (action.type.startsWith("account/")) {
			// console.log(
			// 	"listenerMiddleware - account effects happening... ",
			// 	account.entity
			// );

			// update Intercom
			updateIntercom({ account: { ...account.entity } });
			// setUpgraded
			dispatch({ type: "setUpgradeMe", payload: account.entity.planType });
		}
	},
});
