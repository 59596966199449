import { DashboardsIcon, MarkersIcon } from "components/icons";
import { ROUTES } from "constants/";

const analysisToolsSection = [
	{
		href: ROUTES.DASHBOARDS,
		icon: DashboardsIcon,
		title: "Dashboards",
	},
	{
		href: ROUTES.MARKERS,
		icon: MarkersIcon,
		title: "Markers",
	},
];

const navItems = [
	{
		title: "Analysis Tools",
		pages: analysisToolsSection,
	},
];

export default navItems;
