import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faInitialize from "utils/fontawesome";

import { THEME_COLORS } from "constants/theme";

faInitialize();

export const FaIcon = ({
	className = "",
	color,
	icon,
	flip,
	fixedWidth = false,
	onClick = () => {},
	rotation = 0,
	listItem = false,
	size = "lg",
	style = "",
	variant,
	weight = "fal",
	menu = false,
}) => {
	// console.log("icon - ", icon);
	// console.log("weight - ", weight);
	// console.log("color - ", color);
	// console.log("variant - ", variant);
	// console.log("menu - ", menu);
	// console.log("className - ", className);
	return (
		<FontAwesomeIcon
			className={className ? className : menu ? `me-2` : `mx-1`}
			color={color || THEME_COLORS[variant]}
			icon={[weight, icon]}
			onClick={onClick}
			size={size}
			style={style}
			rotation={rotation}
			flip={flip}
			fixedWidth={menu ? true : fixedWidth}
			listItem={listItem}
		/>
	);
};
