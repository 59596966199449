import React from "react";
import { Helmet } from "react-helmet-async";

import ItemAnalysis from "features/analysis/ItemAnalysis";

const AnalysisPage = () => (
	<React.Fragment>
		<Helmet title="Analysis" />
		<ItemAnalysis />
	</React.Fragment>
);

export default AnalysisPage;
