import { v4 as uuidV4 } from "uuid";
import React, { useState, useEffect, useContext } from "react";

import {
	Form,
	FloatingLabel,
	ListGroup,
	ListGroupItem,
	Modal,
} from "react-bootstrap";
import { Button } from "components/buttons";
import { ColorDropdown } from "components/ColorDropdown";
import { MarkerOptions } from "components/MarkerOptions";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

const createTypeProps = {
	Tag: {
		name: "label",
		title: "Tag label",
		maxLength: 16,
		formData: {
			color: "",
		},
	},
	Dashboard: {
		name: "title",
		title: "Dashboard title",
		maxLength: 34,
		formData: {
			defaultChecked: true,
		},
	},
	Marker: {
		name: "name",
		title: "Marker name",
		maxLength: 22,
		formData: {
			defaultChecked: true,
			options: false,
		},
	},
};

export const CreateModal = ({
	body,
	cancelBtnAction,
	cancelBtnClasses,
	cancelBtnTitle,
	confirmBtnAction,
	confirmBtnClasses,
	confirmBtnTitle,
	createType,
	title,
}) => {
	createType = {
		...createTypeProps[createType],
		type: createType,
	};

	const { closeModal, showModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const [loadingOnConfirm, setLoadingOnConfirm] = useState(false);
	const [loadingOnCancel, setLoadingOnCancel] = useState(false);

	const [formData, setFormData] = useState(createType.formData);
	// const [newMarkerOptions, setNewMarkerOptions] = useState({});
	// const [newMarkerOptionsList, setNewMarkerOptionsList] = useState([]);

	// /** Markers only useEffect on newMarkerOptions changing */
	// useEffect(() => {
	// 	setNewMarkerOptionsList(
	// 		Object.keys(newMarkerOptions).map((key) => {
	// 			return (
	// 				<li key={key} className="list-group-item border-0">
	// 					<Form.Control
	// 						type="text"
	// 						defaultValue={newMarkerOptions[key]}
	// 						maxLength="22"
	// 						className="form-control border-0 border-bottom w-25 d-inline"
	// 					/>
	// 					<Button
	// 						className="btn btn-outline-secondary d-inline border-0"
	// 						onClick={() => handleEditOptions("remove", key)}
	// 					>
	// 						X
	// 					</Button>
	// 				</li>
	// 			);
	// 		})
	// 	);
	// 	setFormData({
	// 		...formData,
	// 		options: newMarkerOptions,
	// 	});
	// }, [newMarkerOptions]);

	// useEffect on unmount
	useEffect(() => {
		return () => {
			createType = null; //needed? ensures creating a tag after creating a marker does not mix the two
		};
	});

	/** Cancel creaton */
	const onCancelClick = (e) => {
		if (cancelBtnAction) {
			setLoadingOnCancel(true);
			cancelBtnAction(e);
		}
		closeModal();
	};

	/** Go ahead and create */
	const onCreateClick = (e) => {
		e.preventDefault();
		if (
			"" === formData[createType.name] ||
			undefined === formData[createType.name]
		) {
			notify.error(`${createType.title} required.`);
			return;
		}
		switch (createType.type) {
			case "Tag":
				if ("" === formData.color) formData.color = "primary";
				break;
			case "Marker":
				if (false === formData.options) {
					notify.error(`Marker option is required.`);
					return;
				}
				break;
			default:
				break;
		}
		setLoadingOnConfirm(true);
		confirmBtnAction(formData);
	};

	/**  Generic Change Handler */
	const onChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]:
				"checkbox" === e.target.type ? e.target.checked : e.target.value,
		});
	};

	/** Tags: Color change handler */
	const onColorChange = (color) => {
		setFormData({
			...formData,
			color,
		});
	};

	/** Tags: Color change handler */
	const onOptionsChange = (options) => {
		setFormData({
			...formData,
			options: {
				...options,
			},
		});
	};

	const renderDefaultCheckBox = () => {
		return (
			<Form.Check
				type="checkbox"
				name="defaultChecked"
				id="checkbox" //rename to defaultCheckbox?
				defaultChecked
				label="Add to all new projects"
				onChange={onChange}
				value={true}
			/>
		);
	};

	return (
		<Modal
			show={showModal}
			onShow={() => {
				document.getElementById("createModalTitle").focus();
			}}
			onHide={onCancelClick}
		>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label={createType.title} className="mb-3">
					<Form.Control
						id="createModalTitle"
						type="text"
						name={createType.name}
						placeholder={createType.title}
						maxLength={createType.maxLength}
						onChange={onChange}
						onKeyDown={(e) =>
							e.code === "Enter" || e.code === "NumpadEnter" ? onCreateClick(e) : null
						}
						required
					/>
				</FloatingLabel>
				{"Tag" === createType.type && <ColorDropdown onChange={onColorChange} />}
				{"Tag" !== createType.type && renderDefaultCheckBox()}
				{"Marker" === createType.type && (
					<MarkerOptions onChange={onOptionsChange} />
				)}
				{/* {"Marker" === createType.type && renderMarkerOptionsList()} */}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="muted"
					className={cancelBtnClasses}
					onClick={onCancelClick}
					loading={loadingOnCancel}
					disabled={loadingOnCancel}
				>
					{cancelBtnTitle || "Cancel"}
				</Button>
				<Button
					autoFocus
					variant="success"
					className={confirmBtnClasses}
					onClick={onCreateClick}
					loading={loadingOnConfirm}
					disabled={loadingOnConfirm}
				>
					{confirmBtnTitle}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
