import AuthCardWrapper from "./AuthCardWrapper";
import { Navigate } from "react-router-dom";
import Verify from "./VerifyForm";

import useAuth from "hooks/useAuth";
import { ROUTES } from "constants/";

const ValidatePage = () => {
	const { user } = useAuth();
	if (undefined === user.username) {
		return <Navigate to={ROUTES.SIGNIN} replace={true} />;
	}
	return (
		<AuthCardWrapper helmet="Verify" title="You're almost there!">
			<Verify />
		</AuthCardWrapper>
	);
};

export default ValidatePage;
