import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "hooks/useAuth";

import { ROUTES } from "constants/";

// For routes that should only be access by unautheorized users
function AuthRedirect({ children }) {
	const { isAuthenticated, isInitialized } = useAuth();

	if (isInitialized && isAuthenticated) {
		return <Navigate to={ROUTES.HOME} />;
	}

	return <React.Fragment>{children}</React.Fragment>;
}

export default AuthRedirect;
