import React from "react";

import { Dropdown as BSDropdown } from "react-bootstrap";

export const Dropdown = ({ autoClose = "true", children, heading, icon }) => (
	<BSDropdown autoClose={autoClose} className="nav-item">
		<BSDropdown.Toggle as="a" className="nav-link nav-icon" flip="true">
			{icon}
		</BSDropdown.Toggle>
		<BSDropdown.Menu>
			{heading ? (
				<BSDropdown.Header>
					<h5 className="mb-0">{heading}</h5>
				</BSDropdown.Header>
			) : null}

			{children}
		</BSDropdown.Menu>
	</BSDropdown>
);
