import React, { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

import { MoodleLogo } from "components/images";
import ChatNow from "components/ChatNow";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { ltiAddConsumer } from "features/account/lticonsumer.actions";

import { ROUTES } from "constants";

function MoodleIntegration() {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const { closeModal, openModal } = useModalContext();

	const {
		entity: { isOrganization },
	} = useSelector((state) => state.account);

	const onAddLtiIntegrationClick = () => {
		const title = "Integrate with Moodle";

		if (!isOrganization) {
			return openModal(MODAL_TYPES.INFO, {
				title,
				size: "",
				body: (
					<React.Fragment>
						<p>
							Dreamaker.io's and Moodle integration and plugin allows Moodle
							administrators and teachers to create Dreamaker.io video and audio
							assignments directly from with in Moodle.
						</p>
						<p>
							With the Moodle integration, <b>teachers</b> can{" "}
						</p>
						<ul>
							<li>Create Dreamaker.io assignments inside Moodle</li>
							<li>Grade assignments inside Dreamaker.io</li>
							<li>View assignment submission reports from within Dreamaker.io</li>
						</ul>

						<p>
							With the Moodle integration, <b>students</b> can{" "}
						</p>
						<ul>
							<li>Access Moodle assignments from within Dreamaker.io</li>
							<li>Complete and submit Moodle assignments from inside Dreamaker.io</li>
							<li>
								Collaborate on video assignments with peers and get direct supervisor
								feedback.
							</li>
						</ul>
						<p>
							<ChatNow>Contact us now to enable this integration.</ChatNow>
						</p>
					</React.Fragment>
				),
			});
		}

		// if not Connect, assume Revoke
		return openModal(MODAL_TYPES.CONFIRMATION, {
			title,
			// confirmBtnClasses: "",
			confirmBtnTitle: "Add Moodle",
			confirmBtnAction: async () => {
				const ltiConsumerID = document
					.getElementById("addLtiConsumerID")
					.value.trim();
				if ("" === ltiConsumerID) {
					document.getElementById("ltiConsumerIDFeedback").style.display = "block";
					return Promise.reject(false);
				}

				const res = await dispatch(ltiAddConsumer(ltiConsumerID));
				if (!res.error) {
					notify.success("Moodle consumer ID successfully added!");
					closeModal();
				} else {
					const message =
						res.payload.message || res.error.message || "Something went wrong";
					notify.error(message);
					return Promise.reject(false);
				}
			},
			body: (
				<React.Fragment>
					<p>
						{`Enter your Moodle consumer ID in the space provided. `}
						{`This is your Moodle domain name, without any `}
						<code>/</code>
						{` and `}
						<code>www</code>
					</p>
					<p>
						{`Example  `}
						<code>moodledomain.edu</code>
						{` or  `}
						<code>moodle.mydomain.edu</code>
					</p>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Moodle Consumer ID</Form.Label>
							<Form.Control
								id="addLtiConsumerID"
								type="text"
								name="consumerID"
								placeholder="moodledomain.edu"
								maxLength={255}
								//onChange={handleInputChange}
								required
							/>
							<Form.Control.Feedback id="ltiConsumerIDFeedback" type="invalid">
								Required
							</Form.Control.Feedback>
						</Form.Group>
					</Form>
				</React.Fragment>
			),
		});
	};

	const padding = "pt-4";

	return (
		<tr>
			<td className={padding}>
				<MoodleLogo />
			</td>
			<td className={padding}>
				Connet your Dreamaker.io and Moodle accounts to create, submit and grade
				assignments.
			</td>
			<td className={padding}>
				<Button
					type="submit"
					variant="primary"
					onClick={(e) => {
						onAddLtiIntegrationClick();
					}}
				>
					Connect
				</Button>
			</td>
		</tr>
	);
}

export default MoodleIntegration;
