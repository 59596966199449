import React from "react";
import { CheckIcon } from "components/icons";
import DropdownItem from "./DropdownItem";

export const CheckMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<CheckIcon menu="true" variant="success" />}
		title={props.title}
	/>
);
