/**
 * Contains all actions for this feature
 */
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/**
 * Fetches all current user's dashboardTags
 */
export const fetchUserDashboards = createAsyncThunk(
	"dashboards/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.post(APIPATH.dashboardsGet);
			return res.dashboards;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Creates a user dashboard
 * dashboard paramer shape:
 * {
 * 		"note": "Dashboard for working with Zebras",
 * 		"isTemplate": true,
 * 		"hasComment": true,
 * 		"hasAnnotate": true,
 * 		"title": "Indivudual",
 * 		"dashboardTagsItems": [
 * 			{
 * 				"metadata": {},
 * 				"color": "default | waring | danger | success",
 * 				"label": "ZComment",
 * 				"type": "COMMENT" | "BUTTON"
 * 			},
 * 		]
 * 	},
 */
export const createUserDashboard = createAsyncThunk(
	"dashboards/create",
	async (dashboard, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const dashboardID = uuidV4();
			let res = await apigateway.post(APIPATH.dashboardCreate, {
				dashboardID,
				dashboardTags: [],
				dashboardTagsItems: [],
				...dashboard,
			});
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			delete res.usageData;
			return { ...res };
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Deletes user's dashboard with dashboardID
 */
export const deleteUserDashboard = createAsyncThunk(
	"dashboards/delete",
	async (
		{ dashboardID, deleteUserDashboardTags = false },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			const res = await apigateway.delete(
				`${APIPATH.dashboardDelete}${dashboardID}&deleteUserDashboardTags=${deleteUserDashboardTags}`
			);
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			return dashboardID;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Updates dashboard with dashboardID to dashboard
 */
export const updateUserDashboard = createAsyncThunk(
	"dashboards/update",
	// dashboard an object with dashboardID and any fields requiring updates
	async (dashboard, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			await apigateway.post(APIPATH.dashboardUpdate, {
				...dashboard,
			});
			return { ...dashboard };
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
