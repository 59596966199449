import React from "react";
import { Card, Col, ListGroup } from "react-bootstrap";

import PlanItem from "./PlanItem";
import StripeCheckoutButton from "components/stripe/StripeCheckoutButton";

import { PLANID, THEME_COLORS } from "constants/";

const colorScheme = ["primary", "success", "muted"];

const PlanCard = ({
	idx,
	billingPeriod,
	plan,
	userBilling,
	userPlan,
	selectedPeriodIsCurrentPeriod,
	selectedPeriodIsUpcomingPeriod,
	hasUpcomingInvoice,
}) => {
	const horizontalRule = (thickness, variant = THEME_COLORS.muted) => {
		return (
			<hr
				className={`text-${variant}`}
				style={{ opacity: 1, height: thickness }}
			></hr>
		);
	};

	const renderCostText = (plan, variant) => {
		if ("MONTHLY" === billingPeriod) {
			return (
				<Card.Text>
					<span className={`text-${variant}`} style={{ fontSize: "2.5em" }}>
						${plan.costMonthly}
					</span>{" "}
					/Mth
				</Card.Text>
			);
		} else {
			return (
				<Card.Text>
					<span className={`text-${variant}`} style={{ fontSize: "2.5em" }}>
						${plan.costYearly / 12}
					</span>{" "}
					/Mth, ${plan.costYearly} billed annually
				</Card.Text>
			);
		}
	};

	const renderEssentialFeatures = (plan, variant) => {
		return (
			<ListGroup>
				<PlanItem feature={plan.maxProjects} variant={variant} />
				<PlanItem
					feature={plan.maxStorage / 1000 + " GB Storage"}
					variant={variant}
				/>
				<PlanItem
					feature={plan.maxDashboards + " Analysis Dashboards"}
					variant={variant}
				/>
				{plan.maxCollaborators > 0 ? (
					<PlanItem
						feature={plan.maxCollaborators + " Collaborators"}
						variant={variant}
					/>
				) : null}
			</ListGroup>
		);
	};

	const renderAdvancedFeatures = (plan, variant) => {
		return (
			<ListGroup>
				{plan.additionalLimits.notes === true ? (
					<PlanItem feature="Project Notes" variant={variant} />
				) : null}
				{plan.additionalLimits.export === true ? (
					<PlanItem feature="Export Analysis" variant={variant} />
				) : null}
				{plan.additionalLimits.confidential === true ? (
					<PlanItem feature="Privacy Guard" variant={variant} />
				) : null}
				{plan.additionalLimits.chat === true ? (
					<PlanItem feature="Chat Support" variant={variant} />
				) : (
					<PlanItem feature={plan.additionalLimits.chat} variant={variant} />
				)}
				{plan.additionalLimits.locked === true ? (
					<PlanItem feature="Project Lock" variant={variant} />
				) : null}
			</ListGroup>
		);
	};

	const renderUpgradeButton = (plan, variant) => {
		/***** BUTTON DEFAULTS FOR NEW, NOT-YET-PAYING CUSTOMERS****/
		let buttonTitle = "Select Plan";
		let disabled = false;

		/** NOT PAYING CUSTOMER - IE - DONT HAVE CARD DETAILS **/
		/** UPGRADE TO ANY PLAN */
		/* Note: userBilling = {} if is no card details on stripe or no associated stripe customer
		 */
		if (undefined === userBilling?.card) {
			/** DO NOTHING */
		}

		/** PREVIOUSLY PAYING CUSTOMERS **/
		/**
		 * CUSTOMERS WITH CARD DETAILS AND CANCELLED TO FREE PLAN WITH STRIPE & BILLING CARD
		 * Note: we also need to check if the account is on the free plan, if it is, we forward them to the payment page.
		 * Note: Previously checked hasStripe & made sure has a card, but no longer needed
		 */
		if (PLANID.FREE[userPlan.planID]) {
			/** DO NOTHING */
		}

		const upcomingInvoice = userBilling?.upcomingInvoice || {};

		/***** NONE BUTTON DEFAULTS - CURRENTLY PAYING CUSTOMERS *****/
		/**
		 * CUSTOMERS WITH CARD DETAILS AND... BUTTON PLAN === CURRENT PLAN
		 * Note: Button is disabled IF selectedPeriodIsCurrentPeriod or selectedPeriodIsUpcomingPeriod
		 * Note: Doing a title check hear because planID's change
		 */
		if (plan.title === userPlan.title) {
			// CURRENT CUSTOMER
			if (hasUpcomingInvoice) {
				// BUTTON PLAN/PERIOD == CURRENT && UPCOMING'
				if (
					selectedPeriodIsCurrentPeriod ||
					(userPlan.planID === upcomingInvoice.plan &&
						selectedPeriodIsUpcomingPeriod)
				) {
					buttonTitle = "Your Current Plan";
					disabled = true;

					// PLAN HAS CHANGED but BUTTON PLAN is CURRENT
				} else if (selectedPeriodIsCurrentPeriod) {
					buttonTitle = "Your Current Plan";
					disabled = true;
				}

				// CANCELLATION COMING...
			} else if (selectedPeriodIsCurrentPeriod) {
				buttonTitle = "Your Current Plan";
				disabled = true;
			}
		}
		// CUSTOMERS WITH CARD DETAILS AND PLAN HAS CHANGED - BUTTON PLAN !== CURRENT PLAN && BUTTON PLAN IS UPCOMING
		else if (
			hasUpcomingInvoice &&
			selectedPeriodIsUpcomingPeriod &&
			plan.planID === upcomingInvoice.plan
		) {
			buttonTitle = "Your Upcoming Plan";
			disabled = true;
		}

		return (
			<div className="py-2" style={{ textAlign: "center" }}>
				<StripeCheckoutButton
					className="px-5"
					variant={variant}
					disabled={disabled}
					buttonTitle={buttonTitle}
					newPlan={plan}
					newPaymentPeriod={billingPeriod}
				/>
			</div>
		);
	};

	return (
		<Col key={idx}>
			<Card>
				<Card.Body>
					<Card.Title>
						<h3>{plan.title}</h3>
						{horizontalRule(5, colorScheme[idx])}
					</Card.Title>
					<Card.Subtitle>{plan.description}</Card.Subtitle>
					{plan.costMonthly === 0 ? (
						<Card.Text>
							<span style={{ fontSize: "2.5em" }}>Free</span>
						</Card.Text>
					) : (
						renderCostText(plan, colorScheme[idx])
					)}
					{idx < 2 ? (
						renderUpgradeButton(plan, colorScheme[idx])
					) : (
						<div style={{ height: "30px" }} />
					)}

					{horizontalRule(2)}
					<br />
					<span style={{ color: colorScheme[idx] }}>Essential Features</span>
					{renderEssentialFeatures(plan, colorScheme[idx])}
					{plan.costMonthly === 0 ? null : (
						<div>
							{horizontalRule(2)}
							<span style={{ color: colorScheme[idx] }}>Advanced Features</span>
							{renderAdvancedFeatures(plan, colorScheme[idx])}
						</div>
					)}
					{horizontalRule(2)}
					{idx < 2 ? renderUpgradeButton(plan, colorScheme[idx]) : null}
				</Card.Body>
			</Card>
		</Col>
	);
};

export default PlanCard;
