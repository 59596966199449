import "./dashboards.scss";
import _ from "lodash";
import React, { useCallback, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders";

import NotifyContext from "contexts/NotifyContext";

import {
	fetchUserDashboards,
	updateUserDashboard,
} from "features/dashboards/dashboards.actions";
import { setCurrentDashboardID } from "features/dashboards/dashboards.slice";

import DashboardCreateButton from "./DashboardCreateButton";
import { DashboardsListItem } from "./DashboardsListItem";

const DashboardsLists = () => {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);

	const { currentEntityID: currentDashboardID, entities: dashboards } =
		useSelector((state) => state.dashboards); //Bringing in state from global store

	const [loading, setLoading] = useState(true);
	const [defaultDashboards, setDefaultDashboards] = useState([]);
	const [otherDashboards, setOtherDashboards] = useState([]);
	const [maxDefaultSortOrder, setMaxDefaultSortOrder] = useState(0);

	// console.log("dashboards: ", dashboards);
	// console.log("defaultDashboards: ", defaultDashboards);
	// console.log("otherDashboards: ", otherDashboards);
	// console.log("maxDefaultSortOrder: ", maxDefaultSortOrder);

	useEffect(() => {
		async function fetchData() {
			await dispatch(fetchUserDashboards());
			setLoading(false);
		}
		null === dashboards ? fetchData() : setLoading(false);
	}, []);

	// useEffect on [entities.dashboards] changing: sets defaultDashboards, otherDashboards and maxDefaultSortOrder
	useEffect(() => {
		// console.log("Running setDashboardsArrays useEffect");

		const orderedDashboards = _.orderBy(
			dashboards,
			["defaultSortOrder", "dateUpdated"],
			["asc", "desc"]
		);
		let tmpDefaultDashboards = [];
		let tmpOtherDashboards = [];
		let maxDefaultSortOrder = 0;

		var cnt = 0,
			len = orderedDashboards.length;

		while (cnt < len) {
			// console.log("dashboardID: ", dashboardID);
			const dashboard = orderedDashboards[cnt];
			// console.log("dashboard: ", dashboard);
			if (true === dashboard.default) {
				if (maxDefaultSortOrder < dashboard.defaultSortOrder)
					maxDefaultSortOrder = dashboard.defaultSortOrder;
				tmpDefaultDashboards.push(dashboard);
			} else {
				tmpOtherDashboards.push(dashboard);
			}
			cnt++;
		}
		// tmpOtherDashboards.sort((a, b) => a.title.localeCompare(b.title));
		setOtherDashboards(tmpOtherDashboards);
		setDefaultDashboards(tmpDefaultDashboards);
		setMaxDefaultSortOrder(maxDefaultSortOrder);
	}, [dashboards]);

	const onDashboardsReorderDrag = useCallback((dragIndex, hoverIndex) => {
		console.log("onDashboardsReorderDrag() ");
		console.log("dragIndex ", dragIndex);
		console.log("hoverIndex ", hoverIndex);
		console.log("");
		setDefaultDashboards((prevDashboards) =>
			update(prevDashboards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevDashboards[dragIndex]],
				],
			})
		);
	}, []);

	const onDashboardsReorderDrop = useCallback(({ dashboardID, index }) => {
		const newDefaultSortOrder = index + 1;
		updateDashboard(dashboardID, newDefaultSortOrder);

		const currentDashboard = dashboards[dashboardID];

		console.log("defaultSortOrder - ", currentDashboard.defaultSortOrder);
		console.log("newDefaultSortOrder - ", newDefaultSortOrder);
		console.log("moved dashboard - ", currentDashboard);

		var cnt = newDefaultSortOrder;
		if (newDefaultSortOrder < currentDashboard.defaultSortOrder) {
			while (cnt < defaultDashboards.length) {
				const dashboard = defaultDashboards[cnt];
				if (dashboardID === dashboard.dashboardID) {
					continue;
				}
				updateDashboard(dashboard.dashboardID, dashboard.defaultSortOrder + 1);
				console.log("change dashboard - ", dashboard);
				console.log("... new deafultSortOrder - ", dashboard.defaultSortOrder + 1);
				cnt++;
			}
		} else {
			while (cnt > 0) {
				cnt--;
				const dashboard = defaultDashboards[cnt];
				if (dashboardID === dashboard.dashboardID) {
					continue;
				}
				updateDashboard(dashboard.dashboardID, dashboard.defaultSortOrder - 1);
				console.log("change dashboard - ", dashboard);
				console.log("... new deafultSortOrder - ", dashboard.defaultSortOrder - 1);
			}
		}
	});

	const updateDashboard = (dashboardID, defaultSortOrder) => {
		const res = dispatch(
			updateUserDashboard({
				dashboardID,
				defaultSortOrder,
			})
		);
		if (res.error) {
			console.log("updateUserDashboard Error: ", res.error);
			notify.error(res.error);
		}
	};

	const defaultDashboardsList = defaultDashboards.map(
		({ dashboardID, title }, index) => {
			return (
				<DashboardsListItem
					key={dashboardID}
					active={dashboardID === currentDashboardID}
					dashboardID={dashboardID}
					index={index}
					onDashboardsReorderDrag={onDashboardsReorderDrag}
					onDashboardsReorderDrop={onDashboardsReorderDrop}
					onClick={() => dispatch(setCurrentDashboardID(dashboardID))}
					title={title}
				/>
			);
		}
	);

	const otherDashboardsList = otherDashboards.map(({ dashboardID, title }) => (
		<ListGroupItem
			action
			active={dashboardID === currentDashboardID}
			className="ps-1 py-2 d-flex justify-content-between align-items-start dashboards-list-item"
			key={dashboardID}
			onClick={() => dispatch(setCurrentDashboardID(dashboardID))}
		>
			<div className="ms-3 me-auto">{title}</div>
		</ListGroupItem>
	));

	return (
		<>
			<DashboardCreateButton />
			<div className="d-flex-column">
				<h4 className="ps-1 me-2 py-2 border-bottom">Default Dashboards</h4>
				<div className="dashboards-list p-2 dashboards-list-container">
					{loading ? (
						<LoadingSpinner />
					) : 0 >= defaultDashboardsList.length ? (
						<p>No default dashboards</p>
					) : (
						<DndProvider backend={HTML5Backend}>
							<ListGroup variant="flush">{defaultDashboardsList}</ListGroup>
						</DndProvider>
					)}
				</div>
				<h4 className="ps-1 me-2 pt-4 pb-2 border-bottom ">Other Dashboards</h4>
				<div className="p-2 dashboards-list-container">
					{loading ? (
						<LoadingSpinner />
					) : 0 >= otherDashboardsList.length ? (
						<p>No other dashboards</p>
					) : (
						<ListGroup variant="flush">{otherDashboardsList}</ListGroup>
					)}
				</div>
			</div>
		</>
	);
};

export default DashboardsLists;
