import "pages/dashboards/dashboards.scss";
import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/buttons";
import { CreateIcon } from "components/icons";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { createUserMarker } from "features/markers/markers.actions";

const MarkerCreateButton = () => {
	const dispatch = useDispatch();
	const { openModal, closeModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { entities: markers } = useSelector((state) => state.markers); //Bringing in state from global store

	const [maxDefaultSortOrder, setMaxDefaultSortOrder] = useState(0);

	// console.log("markers: ", markers);
	// console.log("maxDefaultSortOrder: ", maxDefaultSortOrder);

	// useEffect on [entities.markers] changing: sets defaultMarkers, otherMarkers and maxDefaultSortOrder
	useEffect(() => {
		const orderedMarkers = _.orderBy(markers, ["sortOrder"], ["desc"]);
		let maxDefaultSortOrder = 0;

		var cnt = 0,
			len = orderedMarkers.length;

		while (cnt < len) {
			const marker = orderedMarkers[cnt];
			if (true === marker.default) {
				if (maxDefaultSortOrder < marker.sortOrder)
					maxDefaultSortOrder = marker.sortOrder;
			}
			cnt++;
		}
		setMaxDefaultSortOrder(++maxDefaultSortOrder);
	}, [markers]);

	const onCreateClick = () => {
		openModal(MODAL_TYPES.CREATE, {
			title: "Create New Marker",
			confirmBtnAction: createMarker,
			confirmBtnTitle: "Create Marker",
			createType: "Marker",
		});
	};

	const createMarker = async ({ defaultChecked, note = "", options, name }) => {
		let newMarker = {
			name,
			note: note,
			default: defaultChecked,
			options,
		};

		// only required if defaultChecked selected
		if (defaultChecked) {
			newMarker.sortOrder = maxDefaultSortOrder;
			setMaxDefaultSortOrder(maxDefaultSortOrder + 1);
		}

		const res = await dispatch(createUserMarker(newMarker));
		if (res.error) {
			notify.error(res.error);
			if (defaultChecked) {
				setMaxDefaultSortOrder(maxDefaultSortOrder - 1);
			}
			return;
		}
		notify.success("Marker successfully created!");
		closeModal();
	};

	return (
		// <div className="border-bottom py-2 d-flex flex-row-reverse">
		<div className="d-grid gap-2 me-2 pb-3">
			<Button variant="success" onClick={() => onCreateClick()} className="">
				<CreateIcon className="me-2" size="lg" />
				Create New Marker
			</Button>
		</div>
	);
};

export default MarkerCreateButton;
