/**
 * Thunks for user's zoom integration
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** Authorize user;s zoom account from API */
export const zoomAuthorization = createAsyncThunk(
	"user/zoomAuthourization",
	async (folderID = 0, { getState, dispatch, requestId, rejectWithValue }) => {
		try {
			var res = await apigateway.get(
				`${APIPATH.zoomInitiateAuthorization}${folderID}`
			);
			if ("OAuthAlreadyAuthorizedException" === res.code) {
				console.log("Zoom Already Authed");
				return {
					zoom: {
						isAuthorized: true,
					},
				};
			} else {
				res.Location && (window.location = res.Location);
				return Promise.resolve(res);
			}
		} catch (err) {
			console.log("zoomAuth FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Revokes zoom authorization and deleted global state user.data.oauth.zoom
 * if already revoked, returns success regardless. IE - can be called a number of times without failure.
 * Zoom Revoke URL: https://marketplace.zoom.us/user/installed
 *
 * TODO: check this is needed and implement @throws {200} OAuthNotAuthorizedException - User is not authorized by zoom
 */
export const zoomDeauthorization = createAsyncThunk(
	"user/zoomDeauthourization",
	async (unused, { getState, dispatch, requestId, rejectWithValue }) => {
		try {
			await apigateway.get(APIPATH.zoomRevokeAuthorization);
			return; // payload deliberately empty
		} catch (err) {
			console.log("zoomDeauth FAILED - ", err);
			if (!err.response) {
				// throw err;
				return rejectWithValue(err);
			}
			return rejectWithValue(err.response.data);
		}
	}
);
