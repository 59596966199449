import AuthCardWrapper from "./AuthCardWrapper";
import ForgotPassword from "./ForgotPasswordForm";

const ForgotPasswordPage = () => (
	<AuthCardWrapper
		helmet="Reset Password"
		title="Reset Password"
		subTitle="Enter your email to reset your password."
	>
		<ForgotPassword />
	</AuthCardWrapper>
);

export default ForgotPasswordPage;
