import React, { useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Card, Col, Form, Placeholder, Row, Table } from "react-bootstrap";
import { PreferencesLoading } from "./PreferencesLoading";

import { SubmitButton } from "components/buttons/";
import { PreferenceInput } from "./PreferenceInput";

import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

import NotifyContext from "contexts/NotifyContext";
import { updateUser } from "features/user/user.actions";

const cardHeader = (
	<Card.Header>
		<Card.Title className="mb-0">Media Player Preferences</Card.Title>
	</Card.Header>
);

function MediaPlayerPrefs() {
	const dispatch = useAppDispatch();
	const notify = useContext(NotifyContext);

	const {
		entity: { preferences },
	} = useAppSelector((state) => state.user);

	if (undefined === preferences)
		return <PreferencesLoading>{cardHeader}</PreferencesLoading>;

	const { scanFwd, scanBwd } = preferences;

	let initialValues = {
		scanFwd,
		scanBwd,
		submit: false,
	};

	let numValid = Yup.number().min(0).max(1000).optional();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				scanFwd: numValid,
				scanBwd: numValid,
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				const res = await dispatch(
					updateUser({ preferences: { ...preferences, ...values } })
				);
				if (!res.error) notify.success("Successfully updated!");
				else {
					const message = res.error.message || "Something went wrong";
					notify.error(message);
					setStatus({ success: false });
					setErrors({ submit: message });
					setSubmitting(false);
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Card>
						{cardHeader}
						<Card.Body>
							<Table responsive>
								<tbody>
									{PreferenceInput(
										"Replay",
										"scanBwd",
										values.scanBwd,
										"The number seconds to replay when using the Replay button",
										handleBlur,
										handleChange
									)}
									{PreferenceInput(
										"Forward play",
										"scanFwd",
										values.scanFwd,
										"The number seconds to scan forward when using the Forward Play button",
										handleBlur,
										handleChange
									)}
								</tbody>
							</Table>
							<SubmitButton title="Save Changes" disabled={isSubmitting} />
						</Card.Body>
					</Card>
				</Form>
			)}
		</Formik>
	);
}

export default MediaPlayerPrefs;
