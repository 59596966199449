import React from "react";

import { ListGroupItem } from "react-bootstrap";
import { CheckIcon } from "components/icons";

const PlanItem = ({ feature, variant }) => {
	return (
		<ListGroupItem className="border-0 p-2">
			<CheckIcon color={variant} className={`me-2 text-${variant}`} />
			{feature}
		</ListGroupItem>
	);
};

export default PlanItem;
