import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Col, Row, Form, Placeholder } from "react-bootstrap";

import useAuth from "hooks/useAuth";
import NotifyContext from "contexts/NotifyContext";

import { SubmitButton } from "components/buttons/";

export function Security() {
	const dispatch = useDispatch();
	const { resetPassword } = useAuth();
	const notify = useContext(NotifyContext);

	const {
		entity: { firstName },
	} = useSelector((state) => state.user);

	let initialValues = {
		submit: false,
	};

	if (undefined === firstName) return <Loadholder />;

	initialValues = {
		newPassword: "",
		oldPassword: "",
		confirmPassword: "",
		submit: false,
	};

	const passwordValidationScheme = Yup.string()
		.min(8, "Must be at least 8 characters")
		.max(255)
		.required("Required");

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				newPassword: passwordValidationScheme,
				oldPassword: passwordValidationScheme,
				confirmPassword: Yup.string()
					.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
					.required("Required"),
			})}
			onSubmit={async (
				values,
				{ resetForm, setErrors, setStatus, setSubmitting }
			) => {
				await resetPassword(values)
					.then((res) => {
						notify.success("Password successfully updated!");
						resetForm();
					})
					.catch((error) => {
						const message = error.message || "Something went wrong";
						notify.error(message);
						setStatus({ success: false });
						setErrors({ submit: message });
						setSubmitting(false);
					});
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Row className="ps-2 pe-2">
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>Old Password</Form.Label>
								<Form.Control
									type="password"
									name="oldPassword"
									placeholder="Old Password"
									value={values.oldPassword}
									isInvalid={Boolean(touched.oldPassword && errors.oldPassword)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.oldPassword && (
									<Form.Control.Feedback type="invalid">
										{errors.oldPassword}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row className="ps-2 pe-2">
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									name="newPassword"
									placeholder="New Password"
									value={values.newPassword}
									isInvalid={Boolean(touched.newPassword && errors.newPassword)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.newPassword && (
									<Form.Control.Feedback type="invalid">
										{errors.newPassword}
									</Form.Control.Feedback>
								)}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									name="confirmPassword"
									placeholder="Confirm New Password"
									value={values.confirmPassword}
									isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.confirmPassword && (
									<Form.Control.Feedback type="invalid">
										{errors.confirmPassword}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
					</Row>
					<SubmitButton title="Update Password" disabled={isSubmitting} />
				</Form>
			)}
		</Formik>
	);
}

const Loadholder = () => {
	const col = (
		<Col md={6}>
			<Placeholder className="w-100 placeholder-lg" />
		</Col>
	);
	const row = (
		<Row className="m-2">
			{col}
			{col}
		</Row>
	);
	return (
		<Placeholder animation="glow">
			<Row>
				<Col md="8">
					{row}
					{row}
				</Col>
				<Col md="4">{col}</Col>
			</Row>
		</Placeholder>
	);
};

// export  Profile;
