import React from "react";
import { Card, ListGroup } from "react-bootstrap";

import useAppSelector from "hooks/useAppSelector";

const PageTabNavigation = () => {
	const { isOrganization } = useAppSelector((state) => state.account.entity);

	return (
		<Card>
			<ListGroup variant="flush">
				<ListGroup.Item action href="#profile">
					Profile
				</ListGroup.Item>
				<ListGroup.Item action href="#account">
					Plan & Usage
				</ListGroup.Item>
				<ListGroup.Item action href="#preferences">
					Preferences
				</ListGroup.Item>
				<ListGroup.Item action href="#integrations">
					Integrations
				</ListGroup.Item>
				{isOrganization ? (
					<React.Fragment>
						<ListGroup.Item action href="#members">
							Members
						</ListGroup.Item>
						<ListGroup.Item action href="#reports">
							Reports
						</ListGroup.Item>
					</React.Fragment>
				) : null}
				<ListGroup.Item action href="#billing">
					Billing
				</ListGroup.Item>
				<ListGroup.Item action href="#security">
					Security
				</ListGroup.Item>
			</ListGroup>
		</Card>
	);
};

export default PageTabNavigation;
