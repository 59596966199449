/**
 * Thunks for members
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { createProject, deleteItem } from "features/projects/projects.actions";
import { zoomAuthorization } from "features/user/oauth.actions";

import { APIPATH, PLANID, PROGRESS_STATUS, UNLIMITED } from "constants/";

/** fetch recordings listfrom Zoom */
export const fetchZoomRecordings = createAsyncThunk(
	"zoom/fetchRecordings",
	async (unused, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(`${APIPATH.zoomFetchRecordings}`);
			await _has200Exception(res.code, dispatch);
			// console.log("zoom/fetchRecording res - ", res);
			// console.log("zoom/fetchRecording message - ", message);
			// console.log("");
			return res;
		} catch (err) {
			console.log("err - ", err);

			if (!err.response) {
				throw err;
			}

			// console.log("code - ", err.response.data.code);
			// console.log("err.response - ", err.response);
			if ("OAuthAccessTokenError" === err.response.data?.code) {
				await dispatch({
					type: "zoomMessage",
					payload: "Zoom not authorized... Redirecting to Zoom...",
				});
				dispatch(zoomAuthorization());
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** fetch IMPORTED recordings  */
export const fetchImportedZoomRecordings = createAsyncThunk(
	"zoom/fetchImportedRecordings",
	async (
		{ oauthUserID = false, pendingDelete = false },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		if (false === oauthUserID)
			return Promise.reject("Authorized Zoom user not found.");

		try {
			const res = await apigateway.get(
				APIPATH.zoomFetchImportedItems +
					(oauthUserID ? `?oauthUserID=${oauthUserID}` : "") +
					(pendingDelete ? `?pendingDelete=${pendingDelete}` : "")
			);

			console.log("zoom/fetchImportedRecordings res - ", res);

			return res;
		} catch (err) {
			console.log("err - ", err);

			if (!err.response) {
				throw err;
			}

			// console.log("code - ", err.response.data.code);
			// console.log("err.response - ", err.response);

			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** fetch organization members from API */
export const importZoomRecording = createAsyncThunk(
	"zoom/importRecording",
	async (
		{ file, folderID, importChat },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			const {
				download_url,
				file_type,
				file_size,
				chatDownloadUrl,
				chatRecordingOffsetSeconds,
				transcriptDownloadUrl,
				status,
				topic,
				vttDownloadUrl,
			} = file;

			if ("completed" === status)
				rejectWithValue("File uplcoud to Zoom incomplete.");

			const { usageData, planData, dateCreated } = getState().account.entity;

			// CHECK USAGE LIMITS
			let errorMsg = null;

			// Check if free plan - limit to 1 analysis projects if signup after 12AM GMT 24 October(1508803200000)
			if (
				UNLIMITED.value !== planData.maxStorage &&
				PLANID.FREE[planData.planID] &&
				dateCreated > 1508803200000 &&
				1 <= usageData.videoCount + usageData.audioCount
			)
				errorMsg = "Project limit reached. Upgrade to import further media.";

			// Check usageData.storageLimit (We're giving them an allowance of 2 MBs)
			// file_size in bytes
			if (usageData.storageCount + file_size / 1000000 > planData.maxStorage + 2)
				errorMsg = "Storage limit reached. Upgrade to import further media.";

			// Throw error if present
			if (errorMsg) {
				return Promise.reject(errorMsg);
			}

			// INITIALIZE VARIABLES
			const itemID = uuidV4(),
				s3FileName = new Date().getTime().toString();
			let thumbnailURL = `urlforaudiothumbs`,
				fileType = `AUDIO`;
			if ("MP4" === file_type) {
				file_size >= 40000000 // Thumbnails retrieved at 60 sec intervals in live - 13 second video was 2.8MB; file_size is bytes
					? (thumbnailURL = `${s3FileName}-thumbnail-00002.jpg`)
					: (thumbnailURL = `${s3FileName}-thumbnail-00001.jpg`);
				fileType = "VIDEO";
			}

			// INITIALIZE NEW DMIO ITEM DETAILS
			let newItem = {
				itemID,
				mediaSource: "zoom",
				folderID,
				mediaType: fileType,
				title: topic,
				thumbnailURL,
				mediaURL: `${s3FileName}.m3u8`,
				transcodeProgress: PROGRESS_STATUS.UPLOADSTARTED,
			};

			let importChatDetails = importChat
				? { chatDestination: "BOTH", chatDownloadUrl, chatRecordingOffsetSeconds }
				: {};

			// CREATE ITEM & IF SUCCESSFUL, TRIGGER IMPORT, ELSE DELETE ITEM AND SHOW ERROR

			await dispatch(createProject({ ...newItem })); // createItem() rehydrates account

			const res = await apigateway.post(APIPATH.zoomImportRecording, {
				downloadUrl: download_url,
				itemID,
				...importChatDetails,
				vttDownloadUrl,
				transcriptDownloadUrl,
			});

			// Has an auth error? reject
			_has200Exception(res.code, dispatch).catch(() => {
				dispatch(deleteItem(itemID));
				return Promise.reject(res);
			});

			return res;
		} catch (err) {
			console.log("err - ", err);

			if (!err.response) {
				throw err;
			}

			// console.log("code - ", err.response.data.code);
			// console.log("err.response - ", err.response);
			if ("OAuthAccessTokenError" === err.response.data?.code) {
				await dispatch({
					type: "zoomMessage",
					payload: "Zoom not authorized... Redirecting to Zoom...",
				});
				dispatch(zoomAuthorization());
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Checks of for exceptionCode, dispatching actions based on exception code
 * @param {String} exceptionCode Exception error code (usually derived from `resp.code`)
 * @param {Function|Boolean} [dispatch=false] React dispatch function or false (default it false)
 * @param {Object[]} [dispatchActions=[]] Array of actions to dispatch only taken if dispatch !== false
 * @param {String} dispatchActions[].type Type of action to take
 * @param {String} dispatchActions[].payload Payload of the action
 * @returns {Boolean|String} {String} Error message if has exceptionCode is fatal, otherwise {Boolean} false
 *
 * NOTE: Exception meanings
 * OAuthAccountNotSupported: User authorized but Zoo Basic plan, needs a Zoom Pro plan
 * OAuthNotAuthorizedException: User is not authorized by zoom
 * OAuthAlreadyAuthorizedException: user already authorized
 */
const _has200Exception = async (
	exceptionCode,
	dispatch = false,
	dispatchActions = []
) => {
	// No excpetionCode === no error > return
	if (undefined === exceptionCode) return false;

	switch (exceptionCode) {
		case "OAuthAccountNotSupported":
			await dispatch({ type: "zoomClearRecordings" });
			await dispatch({
				type: "zoomMessage",
				payload:
					"Use this feature by enabling Zoom cloud recordings in your Zoom account.",
			});
			//https://support.zoom.us/hc/en-us/articles/203741855-Cloud-Recording
			// dispatch({
			// 	type: ERROR_ADD,
			// 	payload:
			// 		"Use this feature by enabling Zoom cloud recordings in your Zoom account.",
			// 	// payload: <span>Dreamaker.io imports your Zoom recordings from the Zoom cloud. Unfortunately, your Zoom account does not allow for Zoom cloud recordings.{' '}
			// 	// 	<a href='https://support.zoom.us/hc/en-us/articles/203741855-Cloud-recording' target='_blank'>Click here for further info</a>.</span>
			// });
			return Promise.reject();

		case "OAuthAlreadyAuthorizedException": {
			return Promise.reject();
		}
		case "OAuthNotAuthorizedException":
			await dispatch({ type: "zoomReset" });
			await dispatch({
				type: "zoomMessage",
				payload: "Zoom account not authorized... Redirecting to zoom.",
			});
			await dispatch(zoomAuthorization());
			return Promise.reject();

		default:
			return Promise.resolve();
	}
};
