import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { AuthSubmitButton } from "components/buttons/";

import useAuth from "hooks/useAuth";
import { ROUTES } from "constants/";

function Validate() {
	const navigate = useNavigate();

	const { resendVerificationCode, user, verifySignUp } = useAuth();
	const [successfulMessage, setSuccessfulMessage] = useState(
		<span>Check your email for a verification from Dreamaker.io.</span>
	);

	return (
		<Formik
			initialValues={{
				verificationCode: "",
				submit: false,
			}}
			validationSchema={Yup.object().shape({
				verificationCode: Yup.string()
					.max(255)
					.required("Oops, verification code required."),
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				try {
					await verifySignUp(values.verificationCode);
					navigate(ROUTES.SIGNIN);
				} catch (error) {
					setStatus({ success: false });
					setErrors({ submit: error.message || "Something went wrong" });
					setSubmitting(false);
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<div
						className={`text-center mt-3 ${successfulMessage ? "" : "text-danger"}`}
						style={{ minHheight: "2em" }}
					>
						{errors.submit ? errors.submit : successfulMessage}
					</div>
					<Form.Group className="mb-3 mt-4">
						<Form.Control
							type="text"
							name="verificationCode"
							placeholder="Verification Code"
							value={values.verificationCode}
							isInvalid={Boolean(touched.verificationCode && errors.verificationCode)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.verificationCode && (
							<Form.Control.Feedback type="invalid">
								{errors.name}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<div className="text-center mt-3">
						<AuthSubmitButton title="Verify" disabled={isSubmitting} />
						<p>
							Code not arrive?{" "}
							<a
								href=""
								onClick={(e) => {
									e.preventDefault();
									setSuccessfulMessage(
										<span>
											The verification code has been re-sent to your{" "}
											<span className="text-warning">{user.email}</span> address. Be sure
											to check your spam and junk mail folders.
										</span>
									);
									resendVerificationCode(user.username);
								}}
							>
								Resend code
							</a>
						</p>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default Validate;
