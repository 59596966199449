import "./projects.scss";

import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { Dropdown as BSDropdown } from "react-bootstrap";
import { Dropdown } from "components/dropdowns/";
import { EllipsesIcon } from "components/icons";

import {
	CheckMenuBtn,
	CollaborateMenuBtn,
	DashboardsMenuBtn,
	MarkersMenuBtn,
	ProjectCopyMenuBtn,
	ProjectDeleteMenuBtn,
	ProjectExportMenuBtn,
	CollaborationLeaveMenuBtn,
	ProjectLockMenuBtn,
	ProjectOpenMenuBtn,
	ProjectConfidentialMenuBtn,
	ProjectRenameMenuBtn,
	ProjectSendMenuBtn,
} from "components/dropdowns/";

import {
	deleteItem,
	copyProject,
	sendProject,
} from "features/projects/projects.actions";
import {
	exportAnalysis,
	inviteCollaboration,
	leaveCollaboration,
} from "features/itemAnalyses/itemAnalyses.actions";

import { ownerNameStr } from "utils/";
import { INVITE_TYPES, ROUTES } from "constants/";

/**
 * TODO:
 * 	: implement plan limits: lock, confidential, export analyses
 */
const ProjectSettingsDropdown = ({
	isFolder,
	isOwner,
	item,
	onBooleanClick,
	onOpenClick,
	onRenameClick,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { closeModal, openModal } = useModalContext();

	const { locked, confidential } = item;

	const [isCopying, setIsCopying] = useState(false);
	const [isExporting, setIsExporting] = useState(false);

	// console.log("==ThumbCard DD==");
	// console.log(`${item.title} - `, item.itemID);
	// console.log("isEditingTitle - ", isEditingTitle);
	// console.log("isOwner - ", isOwner);
	// console.log("userID - ", userID);
	// console.log("ownerID - ", item.ownerID);
	// console.log("isOwner - ", isOwner);
	// console.log("isFolder - ", isFolder);
	// console.log("");

	const onCollaborateClick = () => {
		return openModal(MODAL_TYPES.INVITE, {
			confirmBtnAction: async ({ itemAnalysisID, emails, inviteMessage }) => {
				const res = await dispatch(
					inviteCollaboration({ itemAnalysisID, emails, inviteMessage })
				);
				if (res.error) {
					notify.error(res.error);
					console.log("failed -", res.err);
					return Promise.reject();
				}
				notify.success(`${emails.length} successfuly invited to collaborate.`);
				return Promise.resolve();
			},
			inviteType: INVITE_TYPES.COLLABORATE,
			itemAnalysisID: item.itemAnalysisIDs[0],
		});
	};

	const onCopyClick = async () => {
		setIsCopying(true);
		const res = await dispatch(copyProject(item.itemID));
		if (!res.error) {
			notify.success("Item susseccfully deleted!");
			setIsCopying(false);
		} else {
			notify.error(res.error);
			setIsCopying(false);
			return Promise.reject(false);
		}
	};

	const onLeaveCollaborationClick = () => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: (
				<>
					Are you sure you want to leave {ownerNameStr(item.name)}'s collaborative
					project <i>{item.title}</i> ?
					<br />
					<br />
					Note that any analysis and replies you added to the project will not be
					deleted.
				</>
			),
			confirmBtnAction: async ({ itemAnalysisID, itemID }) => {
				const res = await dispatch(leaveCollaboration({ itemAnalysisID, itemID }));
				if (!res.error) {
					// notify.success("Item susseccfully deleted!");
					closeModal();
				} else {
					notify.error(res.error);
					return Promise.reject(false);
				}
			},
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Leave Collaboration",
			title: "Leave collaborative project",
			data: {
				itemAnalysisID: item.itemAnalysisIDs[0],
				itemID: item.itemID,
			},
		});
	};

	const onSendClick = () => {
		return openModal(MODAL_TYPES.INVITE, {
			confirmBtnAction: async ({ itemAnalysisID, itemID, emails }) => {
				const res = await dispatch(sendProject({ itemAnalysisID, itemID, emails }));
				if (res.error) {
					notify.error(res.error);
					console.log("failed -", res.err);
					return Promise.reject();
				}
				notify.success(`Media successfully sent to ${emails.length} people.`);
				return Promise.resolve();
			},
			inviteType: INVITE_TYPES.SEND,
			itemAnalysisID: item.itemAnalysisIDs[0],
			itemID: item.itemID,
		});
	};

	const onDeleteClick = () => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: (
				<>
					Are you sure you want to delete <i>{item.title}</i> ? All analysis,
					conversations and attached files will also be deleted.
					<br />
					<br />
					Deleting projects cannot undone.
				</>
			),
			confirmBtnAction: async ({ itemID }) => {
				const res = await dispatch(deleteItem({ itemID }));
				if (!res.error) {
					notify.success("Item susseccfully deleted!");
					closeModal();
				} else {
					notify.error(res.error);
					return Promise.reject(false);
				}
			},
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Delete Item",
			title: "Delete " + item.title,
			data: { itemID: item.itemID },
		});
	};

	const onExportClick = async (e) => {
		setIsExporting(true);

		// If item marked as confidential, confirm export.
		let exportConfirmed = true;
		if (confidential)
			// eslint-disable-next-line no-restricted-globals
			exportConfirmed = confirm(
				`PRIVACY GUARD ALERT\nThis project has been marked as private and confidential.\n\nAre you sure you want to export the project analysis?\n`
			);

		if (!exportConfirmed) {
			setIsExporting(false);
			return;
		}

		const res = await dispatch(exportAnalysis(item.itemAnalysisIDs[0]));
		if (!res.error) {
			const data = "text/csv;charset=utf-8," + encodeURIComponent(res);

			// create an <a />
			let link = document.createElement("a");
			link.href = "data:" + data;
			link.download = `${item.title}.csv`;
			link.click();
			setIsExporting(false);
		} else {
			setIsExporting(false);
			notify.error(res.error);
		}
	};

	const onUpdateDashboardsClick = () => {
		return openModal(MODAL_TYPES.UPDATE_DASHBOARDS, {
			title: item.title,
			itemAnalysisID: item.itemAnalysisIDs[0],
		});
	};

	const onUpdateMarkersClick = () => {
		return openModal(MODAL_TYPES.UPDATE_MARKERS, {
			title: item.title,
			itemAnalysisID: item.itemAnalysisIDs[0],
		});
	};

	const renderOwnerMenus = () => {
		// debugger;
		if (!isOwner) return <React.Fragment />;

		return (
			<>
				{!isFolder ? (
					<>
						<CollaborateMenuBtn onClick={() => onCollaborateClick()} />
						<ProjectSendMenuBtn onClick={() => onSendClick()} />
						<ProjectExportMenuBtn onClick={() => onExportClick()} />
						<BSDropdown.Divider />
						<DashboardsMenuBtn
							disabled={item.locked}
							onClick={() => onUpdateDashboardsClick()}
						/>
						<MarkersMenuBtn
							disabled={item.locked}
							onClick={() => onUpdateMarkersClick()}
						/>
						<BSDropdown.Divider />
					</>
				) : null}

				{locked ? (
					<CheckMenuBtn title="Locked" onClick={() => onBooleanClick("locked")} />
				) : (
					<ProjectLockMenuBtn onClick={() => onBooleanClick("locked")} />
				)}

				{confidential ? (
					<CheckMenuBtn
						title="Confidential"
						onClick={() => onBooleanClick("confidential")}
					/>
				) : (
					<ProjectConfidentialMenuBtn
						onClick={() => onBooleanClick("confidential")}
					/>
				)}
				<BSDropdown.Divider />
				<ProjectDeleteMenuBtn
					disabled={item.locked}
					onClick={() => onDeleteClick(item.itemID)}
				/>
			</>
		);
	};

	return (
		<Dropdown icon={<EllipsesIcon />}>
			<ProjectOpenMenuBtn
				onClick={() => {
					// openItem(item.itemID, item.mediaType);
					onOpenClick();
				}}
			/>
			{isOwner && <ProjectRenameMenuBtn onClick={() => onRenameClick()} />}
			{isOwner && !isFolder && (
				<ProjectCopyMenuBtn onClick={() => onCopyClick()} />
			)}
			<BSDropdown.Divider />
			{renderOwnerMenus()}
			{!isOwner && (
				<CollaborationLeaveMenuBtn onClick={() => onLeaveCollaborationClick()} />
			)}
		</Dropdown>
	);
};

export default ProjectSettingsDropdown;
