import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row, Container, Tab } from "react-bootstrap";

import PageTabNavigation from "./PageTabNavigation";
import PageTabPanes from "./PageTabPanes";

import { capitalize } from "utils/";

const Account = () => {
	const location = useLocation();
	const [key, setKey] = useState(location.hash || "#account");

	/**
	 * useEffect on location.hash change.
	 * Important, when already on an account page and navigating to another account page WITHOUT USING Tab.Content - eg Profile link on user nav dropdown
	 */
	useEffect(() => {
		setKey(location.hash);
	}, [location.hash]);

	return (
		<React.Fragment>
			<Helmet title={capitalize(key.replace("#", ""))} />
			<Container fluid className="p-0">
				<Tab.Container
					id="account-tabs"
					defaultActiveKey="#account"
					activeKey={key}
					onSelect={(k) => setKey(k)}
				>
					<Row>
						<Col md={3} xl={2}>
							<PageTabNavigation />
						</Col>
						<Col md={9} xl={10}>
							<PageTabPanes />
						</Col>
					</Row>
				</Tab.Container>
			</Container>
		</React.Fragment>
	);
};

export default Account;
