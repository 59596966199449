import "./modal.scss";

import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { Button, Form, Modal, Table } from "react-bootstrap";
// import { Button } from "components/buttons/";
import { RedoIcon, SquareIcon, SquareCheckIcon } from "components/icons";
import { LoadingSpinner } from "components/loaders";
import ImportZoomDropdown from "./ImportZoomModalDropdown";
import {
	fetchZoomRecordings,
	importZoomRecording,
} from "features/zoom/zoom.actions";

import { capitalize, formatLocalDate, formatStorage } from "utils/";

export const ImportZoomModal = ({ folderID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { closeModal, showModal } = useModalContext();

	const { entities: recordings, message } = useSelector((state) => state.zoom);

	const [loading, setLoading] = useState(false);
	const [importChat, setImportChat] = useState(true);
	const [sort, setSort] = useState({
		key: "start_time",
		order: "desc",
	});

	// console.log("==ImportZoomModal==");
	// console.log("folderID - ", folderID);
	// console.log("recordings - ", recordings);
	// console.log("loading - ", loading);
	// console.log("message - ", message);

	const fetchData = async () => {
		setLoading(true);
		await dispatch(fetchZoomRecordings(folderID));
		setLoading(false);
	};

	useEffect(() => {
		if (null === recordings) fetchData();
	}, []);

	// unmount useEffect
	useEffect(() => {
		// return () => {
		// 	// put unmount code here
		// };
	});

	const toggleImportChat = () => {
		setImportChat(!importChat);
	};

	/**
	 * Imports Recording
	 * User messages returned via global props.messages
	 */
	const importRecording = async (file) => {
		// console.log("importRecording(file) - file: ", file);
		const res = await dispatch(
			importZoomRecording({ file, folderID, importChat })
		);
		if (res.error) {
			notify.error(res.error);
		}
	};

	/** Sorts members table by key */
	const onHeaderClick = (key) => {
		setSort({ key, order: "asc" === sort.order ? "desc" : "asc" });
	};

	/** Renders <thead /> row */
	const renderTheadRow = () => {
		/** Return <th /> header with sort */
		const getTh = (key, heading) => {
			return (
				<th
					className={
						`sorting ` +
						(key === sort.key
							? `sorting_` + sort.order
							: `sorting_` + sort.order + `_disabled`)
					}
					onClick={() => onHeaderClick(key)}
				>
					{heading || capitalize(key)}
				</th>
			);
		};

		return (
			<tr>
				{getTh("topic")}
				{getTh("id")}
				{getTh("start_time", "Start Time")}
				{getTh("total_size", "Total Size")}
				<th className="text-center">Import</th>
			</tr>
		);
	};

	const renderRecordings = () => {
		const meetings = recordings?.meetings; // || undefined;

		let recordingsList = null;
		if (undefined !== meetings && 0 < meetings.length) {
			let orderedRecordings = meetings;
			// if (sort.key == '') orderedRecordings = meetings;
			// else orderedRecordings = _.orderBy(meetings, [sort.key, 'topic'], [sort.order]);
			orderedRecordings = _.orderBy(meetings, [sort.key, "topic"], [sort.order]);

			recordingsList = orderedRecordings.map((meeting, i) => {
				const { topic, id, start_time, total_size, recording_files } = meeting;
				const processing = "processing" === recording_files[0].status;
				return (
					<tr key={i}>
						<td>{topic}</td>
						<td>{id}</td>
						<td>
							{new Date(start_time)
								.toLocaleString()
								.replace(/(\:[0-9][0-9])(?!.*\:[0-9][0-9])/, "")}
						</td>
						<td>
							{processing ? <i>Processing</i> : formatStorage(total_size, "BYTE")}
						</td>
						<td align="center">
							{"completed" === recording_files[0].status ? (
								<ImportZoomDropdown
									disabled={false}
									files={recording_files}
									importRecording={importRecording}
									topic={topic}
									start_time={start_time}
								/>
							) : null}
						</td>
					</tr>
				);
			});
		}

		return recordingsList;
	};

	return (
		<Modal show={showModal} onHide={() => closeModal()} size="lg">
			<Modal.Header>
				<Modal.Title className="d-flex justify-content-between">
					Import your Zoom cloud recordings
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ maxHeight: "25rem", overflow: "scroll" }}>
				{loading ? (
					<div className="text-center">
						<LoadingSpinner />
					</div>
				) : message ? (
					<div>{message}</div>
				) : (
					<>
						<div>
							<Button
								className="ps-0"
								variant="link"
								onClick={() => fetchData()}
								disabled={loading}
							>
								<RedoIcon />
								Refresh List
							</Button>
							{"|"}
							<Button
								variant="link"
								onClick={() => toggleImportChat()}
								disabled={loading}
							>
								{importChat ? <SquareCheckIcon /> : <SquareIcon />}
								Import Chat
							</Button>
						</div>
						<Table className="mb-0" responsive hover>
							<thead>{renderTheadRow()}</thead>
							<tbody>{renderRecordings()}</tbody>
						</Table>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" className="mx-2" onClick={() => closeModal()}>
					Done
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
