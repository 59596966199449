import { v4 as uuidV4 } from "uuid";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import {
	deleteUserMarker,
	updateUserMarker,
} from "features/markers/markers.actions";

import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { MarkerOptions } from "components/MarkerOptions";

const SelectedMarker = () => {
	const dispatch = useDispatch();
	const { closeModal, openModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { entities: markers, currentEntityID: currentMarkerID } = useSelector(
		(state) => state.markers
	);

	const [currentMarkerName, setCurrentMarkerName] = useState("");

	//Fires when a user switches markers
	useEffect(() => {
		// console.log("Running setRenamedMarkerTitle useEffect");
		if (markers[currentMarkerID] !== undefined) {
			setCurrentMarkerName(markers[currentMarkerID].name);
		}
	}, [currentMarkerID]);

	const updateMarker = async (marker) => {
		const res = await dispatch(updateUserMarker(marker));
		if (res.error) {
			notify.error(res.error);
		}
	};

	const onOptionsChange = (options) => {
		updateMarker({
			markerID: currentMarkerID,
			options: { ...options },
		});
	};

	const renameMarker = (marker) => {
		if (currentMarkerName !== markers[currentMarkerID].name) {
			updateMarker({
				markerID: marker.markerID,
				name: currentMarkerName,
			});
		}
	};

	// Toggle default
	const onDefaultChange = (marker) => {
		updateMarker({
			markerID: marker.markerID,
			default: !marker.default,
			defaultSortOrder: marker.default ? 0 : 0,
		});
	};

	// Delete marker
	const onDeleteClick = (markerID) => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: "Are you sure you want to delete this Marker?",
			confirmBtnAction: async (markerID) => {
				const res = await dispatch(deleteUserMarker(markerID));
				if (!res.error) {
					notify.success("Marker susseccfully deleted!");
					closeModal();
				} else {
					notify.error(res.error);
					return Promise.reject(false);
				}
			},
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Delete Marker",
			title: "Delete Marker: " + markers[currentMarkerID].name,
			data: { markerID },
		});
	};

	return (
		<>
			<Row className="px-3">
				<Col className="d-flex justify-content-between">
					<Form.Group>
						<FloatingLabel label="Title" className="mb-3">
							<Form.Control
								id="selectedMarkerTitle"
								type="text"
								maxLength="34"
								placeholder="Title"
								style={{ width: "400px" }}
								value={currentMarkerName}
								text-overflow="ellipsis"
								onChange={(e) => setCurrentMarkerName(e.target.value)}
								onBlur={() => renameMarker(markers[currentMarkerID])}
								onKeyDown={(e) =>
									e.code === "Enter" || e.code === "NumpadEnter"
										? renameMarker(markers[currentMarkerID])
										: null
								}
							/>
						</FloatingLabel>
					</Form.Group>
					<Button
						variant="outline-danger"
						style={{ height: "fit-content" }}
						onClick={() => onDeleteClick(currentMarkerID)}
					>
						Delete Marker
					</Button>
				</Col>
			</Row>
			<Row className="px-3">
				<Col>
					<Form.Check
						style={{ display: "inline-block" }}
						checked={markers[currentMarkerID].default}
						onChange={() => onDefaultChange(markers[currentMarkerID])}
						label="Add to all new projects"
					/>
				</Col>
			</Row>
			<Row id="markerUpdateDelete">
				<Col id="emptySpacerColumn">
					<MarkerOptions
						options={markers[currentMarkerID].options}
						onChange={onOptionsChange}
					/>
				</Col>
			</Row>
		</>
	);
};

export default SelectedMarker;
