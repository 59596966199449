import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import MediaPlayerPrefs from "./preferences/MediaPlayerPrefs";
import AnalysisPrefs from "./preferences/AnalysisPrefs";

export function Preferences() {
	return (
		<React.Fragment>
			<Container fluid className="p-0">
				<Row>
					<Col xl="6">
						<AnalysisPrefs />
					</Col>
					<Col xl="6">
						<MediaPlayerPrefs />
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}
