import {
	configureStore,
	compose,
	getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { listenerMiddleware } from "./store.listeners";
import logger from "redux-logger";

import appReducer from "./_/app.slice";
import accountReducer from "./account/account.slice";
import billingReducer from "./billing/billing.slice";
import eventTagsReducer from "./analysis/eventTags.slice";
import itemAnalysesReducer from "./itemAnalyses/itemAnalyses.slice";
import membersReducer from "./members/members.slice";
import organizationsReducer from "./organizations/organizations.slice";
import projectsReducer from "./projects/projects.slice";
import dashboardsReducer from "./dashboards/dashboards.slice";
import dashboardTagsReducer from "./dashboardTags/dashboardTags.slice";
import uploadsReducer from "./uploader/uploader.slice";
import userMarkersReducer from "./markers/markers.slice";
import userReducer from "./user/user.slice";
import zoomReducer from "./zoom/zoom.slice";

var composeEnhancers = compose;
var additionalMiddleWares = [];

if ("development" === process.env.NODE_ENV) {
	// Redux Logger
	const { createLogger } = require("redux-logger");
	var reduxLogger = createLogger({
		collapsed: (getState, action, logEntry) => !logEntry.error,
		predicate: (getState, action) =>
			action.type !== "SET_CURRENTTIME" &&
			action.type !== "SET_PLAYER" &&
			!/uploader\/updateProgress\//.test(action.type), // actionType does not start with uploader/updateProgress
	});
	additionalMiddleWares.push(reduxLogger);

	// Redux DevTools
	if (
		typeof window === "object" &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	) {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			actionsBlacklist: ["SET_CURRENTTIME", "SET_PLAYER", "UPLOAD_PROGRESS"],
		});
	}
	// Other
	// Rollbar.configure({ enabled: false });
}

const store = configureStore({
	reducer: {
		_: appReducer,
		account: accountReducer,
		billing: billingReducer,
		eventTags: eventTagsReducer,
		members: membersReducer,
		organizations: organizationsReducer,
		user: userReducer,
		dashboards: dashboardsReducer,
		dashboardTags: dashboardTagsReducer,
		markers: userMarkersReducer,
		projects: projectsReducer,
		itemAnalyses: itemAnalysesReducer,
		uploader: uploadsReducer,
		zoom: zoomReducer,
	},

	devTools: "development" === process.env.NODE_ENV,

	// Add the listener middleware to the store.
	// NOTE: Since this can receive actions with functions inside,
	// it should go before the serializability check middleware
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(listenerMiddleware.middleware)
			.concat(additionalMiddleWares),

	// enhancers: composeEnhancers,
});

export default store;
