import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { PageLoader } from "components/loaders/";
import { ROUTES } from "constants/";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
	const { isAuthenticated, isInitialized } = useAuth();
	const location = useLocation();

	// APP NOT INITITALIZED - show loader
	if (!isInitialized) {
		return <PageLoader />;
	}

	// APP INITILAIZED, USER NOT AUTHENTICATED - push to signin
	else if (isInitialized && !isAuthenticated) {
		// pick up redirect parameter if present
		let to =
			"/" !== location.pathname
				? ROUTES.SIGNIN +
				  "?redirect=" +
				  location.pathname +
				  location.search +
				  location.hash
				: ROUTES.SIGNIN + location.search + location.hash;
		return <Navigate to={to} />;
	}

	// APP INITITLAIZED, USER AUTHENTICATED
	return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
