/**
 * Default app configuration settings
 */
import { Auth } from "aws-amplify";

// const dmio_conf = process.env.REACT_APP_DMIO_CONF;
const identityPoolId = `REACT_APP_AMPLIFY_AUTH_identityPoolId`,
	region = `REACT_APP_AMPLIFY_AUTH_region`,
	userPoolId = `REACT_APP_AMPLIFY_AUTH_userPoolId`,
	userPoolWebClientId = `REACT_APP_AMPLIFY_AUTH_userPoolWebClientId`,
	endpoint = `REACT_APP_AMPLIFY_API_endpoint`,
	apiVersion = `REACT_APP_AMPLIFY_API_VERSION`;

// console.log("dmio_conf: ", dmio_conf);
// console.log("apiVersion: ", apiVersion);
// console.log("process.env: ", process.env);

console.log(
	`Dmio App v${process.env.VERSION} ${
		"" !== process.env[apiVersion] ? `with API ${process.env[apiVersion]}` : ""
	}`
);

// const test = async () => {
// 	const res = await Auth.currentCredentials();
// 	console.log("1 - ", Auth);
// 	console.log("2 - ", Auth.currentCredentials());
// 	console.log("3 - ", res);
// };

// test();

/**
 * NOTE: See https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
 */
export const amplify = {
	Auth: {
		identityPoolId: process.env[identityPoolId],
		mandatorySignIn: true,
		region: process.env[region],
		userPoolId: process.env[userPoolId],
		userPoolWebClientId: process.env[userPoolWebClientId],
	},
	API: {
		endpoints: [
			{
				name: "APIGateway",
				endpoint: `${process.env[endpoint]}${
					"" !== process.env[apiVersion] ? process.env[apiVersion] + "/" : ""
				}`,
				custom_header: async () => {
					return {
						/** The Access Token contains scopes and groups and is used to grant access to authorized resources. */
						AccessToken: `${(await Auth.currentSession())
							.getAccessToken()
							.getJwtToken()}`,
						/** The ID Token contains claims about the identity of the authenticated user such as name, email, and phone_number. It could have custom claims as well */
						Authorization: `${(await Auth.currentSession())
							.getIdToken()
							.getJwtToken()}`,
					};
				},
			},
			// {
			// 	name: "CloudFrontApi",
			// 	endpoint: "https://api.devpaola.dreamaker.io/",
			// },
		],
	},
	// MAX_ATTACHMENT_SIZE: 5000000,
	s3: {
		REGION: process.env[region],
		BUCKET: "notes-app-uploads",
	},
	// apiGateway: {
	// 	REGION: "us-east-1",
	// 	URL: "https://5by75p4gn3.execute-api.us-east-1.amazonaws.com/prod"
	// },
	// social: {
	// 	FB: "2132652943414873"
	// }
};

/**
 * Variable names here are specific to needs of Cognito
 */
export const cognitoConfig = {
	IdentityPoolId: amplify.Auth.identityPoolId,
	Region: amplify.Auth.region,
	UserPoolId: amplify.Auth.userPoolId,
	ClientId: amplify.Auth.userPoolWebClientId,
	Paranoia: 7,
};

export const BUCKET = {
	FILEIN: process.env.REACT_APP_BUCKET_FILEIN,
	TRANSIN: process.env.REACT_APP_BUCKET_TRANSIN,
	TRANSOUT: process.env.REACT_APP_BUCKET_TRANSOUT,
};

export const URL = {
	MOODLE_TOOL: process.env.REACT_APP_URL_MOODLE_TOOL,
	TRANSOUT: process.env.REACT_APP_URL_TRANSOUT,
	TRANSOUT_SIGNED: process.env.REACT_APP_URL__TRANSOUT_SIGNED,
};
export const LOCAL_STORAGE_PREFIX = `dmio.${cognitoConfig.ClientId}`;
export const DMIO_RELEASE = process.env.REACT_APP_VERSION;

// export const API_URL = awsConfig.ApiHost
// export const PROVIDER = `cognito-idp.${awsConfig.Region}.amazonaws.com/${awsConfig.UserPoolId}`
// export const WEBAPP_URL = awsConfig.WebappUrl
// export const BANDWIDTH_TEST = awsConfig.BandwidthTest

// // FIle uploading URLS
// export const FILEIN_BCT = awsConfig.FileinBct

// // Transcoding URLS
// export const TRANSIN_BCT = awsConfig.TransinBct
// export const TRANSOUT_BCT = awsConfig.TransoutBct

// // Profile URLS
// export const LOOKIN_BCT = awsConfig.LookinBct
// export const LOOKOUT_BCT = awsConfig.LookoutBct
// export const LOOKOUT_URL = awsConfig.LookoutUrl

// // LTI URLS
// export const URL_MOODLE_TOOL = awsConfig.MoodleToolUrl

// // 3RD Party
// export const INTERCOM_APPID = process.env.INTERCOM_ID

// // Stripe Payment API
// export const STRIPEAPIKEY = awsConfig.StripeAPIKey
