import { createContext, useCallback, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { Auth } from "aws-amplify";

import { useModalContext } from "contexts/ModalContext";

import { fetchUser } from "features/user/user.actions";
import { fetchAccount } from "features/account/account.actions";

import {
	// AuthenticationDetails,
	// CognitoUser,
	// CognitoUserAttribute,
	CognitoUserPool,
} from "amazon-cognito-identity-js";

import { cognitoConfig } from "../config";
const UserPool = new CognitoUserPool({
	...cognitoConfig,
	// UserPoolId: cognitoConfig.userPoolId || "",
	// ClientId: cognitoConfig.clientId || "",
});

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
	isAuthenticated: false, // user if cognito authenticated
	isInitialized: false, // app is initialized (isAuthenticated may be true or false)
	user: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case INITIALIZE: {
			const { isAuthenticated, user } = action.payload;
			return {
				...state,
				isAuthenticated,
				isInitialized: true,
				user,
			};
		}
		case SIGN_OUT: {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}
		default:
			return state;
	}
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState); //dispatch actions local to this context (ie, file)
	const appDispatch = useDispatch(); // dispatch actions that lie outside of this context
	const { closeModal } = useModalContext();

	const getUserAttributes = useCallback(
		(currentUser) =>
			new Promise((resolve, reject) => {
				currentUser.getUserAttributes((err, attributes) => {
					if (err) {
						reject(err);
					} else {
						const results = {};

						attributes?.forEach((attribute) => {
							results[attribute.Name] = attribute.Value;
						});
						resolve(results);
					}
				});
			}),
		[]
	);

	/** Get & Initialize cognito user */
	const getSession = useCallback(async () => {
		console.log("AuthContext getSession() called");
		const user = UserPool.getCurrentUser();
		if (user) {
			await user.getSession(async (err, session) => {
				if (err) {
					console.log("AuthContext getSession() user.getSession() failed");
					Promise.reject(err);
				} else {
					const attributes = await getUserAttributes(user);
					const acessToken = session?.getAccessToken().getJwtToken();
					const authToken = session?.getIdToken().getJwtToken();
					dispatch({
						type: INITIALIZE,
						payload: { isAuthenticated: true, user: attributes },
					});
					Promise.resolve({
						user,
						session,
						headers: { Authorization: authToken, AccessToken: acessToken },
					});
				}
			});
		} else {
			console.log("AuthContext getSession() UserPool.getCurrentUser failed");
			dispatch({
				type: INITIALIZE,
				payload: {
					isAuthenticated: false,
					user: null,
				},
			});
		}
	}, [getUserAttributes]);

	/** Initalize App */
	const initialize = useCallback(async () => {
		console.log("AuthContext initialize() called");
		try {
			await getSession();
			appDispatch(fetchUser());
			appDispatch(fetchAccount());
		} catch {
			dispatch({
				type: INITIALIZE,
				payload: {
					isAuthenticated: false,
					user: null,
				},
			});
		}
	}, [getSession]);

	/**
	 * useEffect for initialize
	 */
	useEffect(() => {
		initialize();
	}, [initialize]);

	const signIn = useCallback(async (email, password) => {
		try {
			const user = await Auth.signIn(email, password);
			await getSession();
			appDispatch(fetchUser());
			appDispatch(fetchAccount());
			dispatch({
				type: INITIALIZE,
				payload: { isAuthenticated: true, user: { ...user } },
			});
		} catch (error) {
			return Promise.reject(error);
		}
	});

	/**
	 * Signs out user
	 * @param {Boolean} global If true, gloablly signs out user - IE, from all devices.
	 */
	const signOut = async (global = false) => {
		try {
			await Auth.signOut({ global });
		} catch (error) {
			return Promise.reject(error);
		} finally {
			dispatch({ type: SIGN_OUT });
			appDispatch({ type: "reset" });
			closeModal();
		}
	};

	const signUp = async ({ email, name, password, emailsUnsubscribed }) => {
		try {
			const { user } = await Auth.signUp({
				username: uuidV4(),
				password,
				attributes: {
					email: email.toLowerCase(),
					name: name,
					"custom:emailsUnsubscribed": emailsUnsubscribed || false,
				},
			});
			dispatch({
				type: INITIALIZE,
				payload: { isAuthenticated: false, user: { email, ...user } },
			});
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const verifySignUp = async (code) => {
		try {
			await Auth.confirmSignUp(state.user.username, code);
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const resendVerificationCode = async (email) => {
		try {
			await Auth.resendSignUp(email);
		} catch (error) {
			return Promise.reject(error);
		}
	};

	/**
	 * Resets password of a logged in user
	 * @param {{String, String}} oldPassword is current password, newPassword is what you would like to change it to
	 * @returns
	 */
	const resetPassword = async ({ oldPassword, newPassword }) => {
		var user;
		try {
			user = await Auth.currentAuthenticatedUser();
		} catch (error) {
			if (!user) signOut();
			return;
		}

		try {
			await Auth.changePassword(user, oldPassword, newPassword);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	};

	/**
	 * Send verification code to email on request to reset password
	 * @param {Strong} email
	 * @returns
	 */
	const forgotPassword = async (email) => {
		// Send confirmation code to user's email
		return Auth.forgotPassword(email)
			.then((res) => {
				Promise.resolve();
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};

	/**
	 * Resets password of a user not currently logged in
	 * @param {{String, String}} oldPassword is current password, newPassword is what you would like to change it to
	 * @returns
	 */
	const forgotPasswordSubmit = async ({ verificationCode, email, password }) => {
		// Collect confirmation code and new password, then
		return Auth.forgotPasswordSubmit(email, verificationCode, password)
			.then((data) => Promise.resolve())
			.catch((error) => {
				return Promise.reject(error);
			});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: "cognito",
				user: {
					// email: state?.user?.email || "",
					role: "user",
					...state.user,
				},
				forgotPassword,
				forgotPasswordSubmit,
				signIn,
				signUp,
				signOut,
				resendVerificationCode,
				resetPassword,
				verifySignUp,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
