import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { Container, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders";

import { setCurrentEntityID } from "features/projects/projects.slice";
import { uploadMediaItem } from "features/uploader/uploader.actions";

import {
	createFolder,
	fetchFolder,
	fetchFolderChildren,
} from "features/projects/projects.actions";

import ProjectsHeader from "./ProjectsHeader";
import ThumbCard from "./ThumbCard";
import ThumbLoadingCard from "./ThumbLoadingCard";
import ThumbUploadingCard from "./ThumbUploadingCard";
import ProjectUploadImporter from "./ProjectUploadImporter";

import { PLANID, PROGRESS_STATUS } from "constants/";

const Projects = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { openModal } = useModalContext();
	const [searchParams] = useSearchParams();

	const { folderID = "0" } = useParams(); // folder/itemID of current folder contents being viewed
	const view = searchParams.get("view");
	// console.log("view - ", view);

	const { exportStatus } = useSelector((state) => state.itemAnalyses);
	const { planType } = useSelector((state) => state.account.entity);
	const { userID } = useSelector((state) => state.user.entity);

	const {
		entities: projects,
		createStatus,
		currentEntityID, // indicates currently selected entity, whether folder or item/project (NOT of the current folder contents being viewed)
	} = useSelector((state) => state.projects);

	const [filter, setFilter] = useState();
	const [folderItems, setFolderItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState({
		by: "dateCreated",
		order: "desc",
	});

	// console.log("== Projects ==");
	// console.log("folderID - ", folderID);
	// console.log("projects - ", projects);
	// console.log("currentEntityID - ", currentEntityID);
	// console.log("folderItems - ", folderItems);
	// console.log("sort - ", sort);
	// console.log("filter - ", filter);
	// console.log("");

	const fetchData = async () => {
		// console.log("fetchData for ", folderID);
		setLoading(true);
		await dispatch(fetchFolder(folderID));
		await dispatch(fetchFolderChildren(folderID));
		await setFilter({ ...filter, folderID });
		// only worked putting this one here...
		// if ("zoom" === view) {
		// 	setFilter({ folderID, mediaSource: "zoom" });
		// }
		setLoading(false);
	};

	// useEffect(() => {
	// 	// console.log("view useEffect - ", view);
	// 	const setView = async () => {
	// 		await setFilter({ folderID, mediaSource: "zoom" });
	// 	};
	// 	if ("zoom" === view) {
	// 		console.log("view useEffect - ", view);
	// 		setView();
	// 	}
	// 	console.log("filter - ", filter);
	// }, [view]);

	/**
	 * useEffect on params changing
	 * Note: Params is loaded after onMount - hence including folderID here will initiate fetch on first load too
	 */
	useEffect(() => {
		// console.log(
		// 	"useEffect [folderID] - ",
		// 	projects?.[folderID]?.folderItemsResult
		// );
		// console.log("filter - ", filter);
		if (
			null === projects ||
			undefined === projects[folderID] ||
			undefined === projects[folderID].folderItemsResult
		)
			fetchData();
		setFilter({ ...filter, folderID });
	}, [folderID]);

	// useEffect on filter and sort change
	useEffect(() => {
		// filter
		// Only apply filter if not "All"
		var tmpFolderItems = _.filter(projects, { ...filter });

		// Sort
		tmpFolderItems = _.orderBy(tmpFolderItems, [sort.by], [sort.order]);
		setFolderItems(tmpFolderItems);
	}, [filter, sort]);

	// useEffect to update proejcts or folderID
	useEffect(() => {
		if (
			null === projects ||
			undefined === projects[folderID] ||
			undefined === projects[folderID].folderItemsResult
		)
			return;

		let tmpFolderItems = [];
		var idx = 0;
		// debugger;
		while (idx < projects[folderID].folderItemsResult.length) {
			const itemID = projects[folderID].folderItemsResult[idx];
			if (projects[itemID]) {
				tmpFolderItems.push(projects[itemID]);
			}
			idx++;
		}

		// tmpFolderItems.push({
		// 	dateCreated: 1660187445770,
		// 	mediaType: "VIDEO",
		// 	transcodeProgress: "PROGRESSING",
		// 	itemID: "841eedbe-3573-4173-9ec7-c2d69c6aee85",
		// 	ownerID: "ca91e04f-e8f0-4dbd-8be9-90bbbbf6e6e0",
		// 	sendInvites: {},
		// 	locked: false,
		// 	thumbnailURL: "1660187443553-thumbnail-00001.jpg",
		// 	dateUpdated: 1660187445771,
		// 	mediaURL: "1660187443553.m3u8",
		// 	shares: {
		// 		teams: {},
		// 		users: {},
		// 	},
		// 	title: "Video 3gp - small",
		// 	itemAnalyses: [],
		// 	availableLOBs: [],
		// 	initials: "JX",
		// 	name: "Jonny Xx2",
		// 	email: "xx2@dreamaker.io",
		// });

		tmpFolderItems = _.filter(tmpFolderItems, { ...filter });
		tmpFolderItems = _.orderBy(tmpFolderItems, [sort.by], [sort.order]);
		setFolderItems(tmpFolderItems);
	}, [projects]);

	//Create Folder
	const onCreateFolderClick = async () => {
		const res = await dispatch(createFolder({ folderID }));
		if (res.error) {
			notify.error(res.error);
		}
	};

	const uploadFile = async (acceptedFiles) => {
		const file = acceptedFiles[0];
		if (file === undefined || file === null) return;

		const res = await dispatch(uploadMediaItem({ folderID, file }));
		if (res.error) {
			notify.error(res.error);
		}
	};

	const onZoomImportClick = () => {
		if (undefined === planType || PLANID.FREE[planType])
			openModal(MODAL_TYPES.INFO_UPGRADE, {
				feature: "Zoom Cloud Imports",
			});
		else
			openModal(MODAL_TYPES.IMPORT_ZOOM, {
				folderID,
			});
	};

	const renderFolderItems = folderItems.map((item) => {
		const { transcodeProgress } = item;
		if (
			PROGRESS_STATUS.PROGRESSING === transcodeProgress ||
			PROGRESS_STATUS.UPLOADSTARTED === transcodeProgress
		)
			return <ThumbUploadingCard item={item} key={item.itemID} userID={userID} />;
		return <ThumbCard item={item} key={item.itemID} userID={userID} />;
	});

	return (
		<DndProvider backend={HTML5Backend}>
			<div>
				<Container fluid className="p-0 h-100">
					<ProjectsHeader
						disabled={loading}
						createFolder={onCreateFolderClick}
						filterFolderResults={({ filterKey, filterValue }) => {
							if (undefined !== filterKey)
								setFilter({
									folderID,
									[filterKey]: filterValue,
								});
							else
								setFilter({
									folderID,
								});
						}}
						sortFolderResults={(sort) =>
							setSort({
								...sort,
							})
						}
						onZoomImportClick={onZoomImportClick}
						uploadFile={uploadFile}
					/>
					<Row className="g-5 p-3 justify-content-start">
						<ProjectUploadImporter
							uploadFile={uploadFile}
							onZoomImportClick={onZoomImportClick}
						/>

						{loading ? <ThumbLoadingCard /> : null}
						{createStatus ? (
							<ThumbLoadingCard
								title={"creating" === createStatus ? "Creating..." : "Duplicating..."}
							/>
						) : null}
						{exportStatus ? (
							<ThumbLoadingCard
								title="Exporting..."
								subtitle="Exporting to downloads folder"
							/>
						) : null}
						{renderFolderItems}
					</Row>
				</Container>
			</div>
		</DndProvider>
	);
};

export default Projects;
