import React from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { AuthSubmitButton } from "components/buttons/";

import useAuth from "hooks/useAuth";
import { ROUTES } from "constants/";

function SignIn() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const { signIn } = useAuth();

	return (
		<Formik
			initialValues={
				"development" === process.env.NODE_ENV
					? {
							email: "xx1@dreamaker.io",
							password: "$321Rewq",
							submit: false,
					  }
					: {
							email: "",
							password: "",
							submit: false,
					  }
			}
			validationSchema={Yup.object().shape({
				email: Yup.string()
					.email("Must be a valid email")
					.max(255)
					.required("Email is required"),
				password: Yup.string().max(255).required("Password is required"),
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				await signIn(values.email, values.password)
					.then((res) => {
						// Send them back to the page they tried to visit when they were
						// redirected to the login page. Use { replace: true } so we don't create
						// another entry in the history stack for the login page.  This means that
						// when they get to the protected page and click the back button, they
						// won't end up back on the login page, which is also really nice for the
						// user experience.

						// set location to push user to on successful signin
						const redirect = searchParams.get("redirect");
						let to = redirect
							? redirect
							: "/" !== location.pathname
							? location.pathname
							: ROUTES.HOME;

						to = to + location.hash;

						// console.log("redirect: ", redirect);
						// console.log("location: ", location);
						// console.log("to: ", to);
						// console.log("---");
						navigate(to, { replace: true });
					})
					.catch((error) => {
						setStatus({ success: false });
						setErrors({ submit: error.message || "Something went wrong" });
						setSubmitting(false);
					});
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<div className="text-danger" style={{ minHeight: "2em" }}>
						{errors.submit ? errors.submit : " "}
					</div>

					<Form.Group className="mb-3">
						<Form.Label>Email</Form.Label>
						<Form.Control
							size="lg"
							type="email"
							name="email"
							placeholder="Enter your email"
							value={values.email}
							isInvalid={Boolean(touched.email && errors.email)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.email && (
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						)}
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control
							size="lg"
							type="password"
							name="password"
							placeholder="Enter your password"
							value={values.password}
							isInvalid={Boolean(touched.password && errors.password)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.password && (
							<Form.Control.Feedback type="invalid">
								{errors.password}
							</Form.Control.Feedback>
						)}
						<small>
							<Link to={ROUTES.FORGOT}>Forgot password?</Link>
						</small>
					</Form.Group>

					<div className="text-center mt-3">
						<AuthSubmitButton title="Sign in" disabled={isSubmitting} />
						<p>
							Don't have an account? <Link to={ROUTES.SIGNUP}>Sign Up</Link>
						</p>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default SignIn;
