import { Helmet } from "react-helmet-async";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import MarkersLists from "./MarkersLists";
import SelectedMarker from "./SelectedMarker";

import { Col, Container, Row } from "react-bootstrap";

const MarkersPage = () => {
	const { currentEntityID: curretnMarkerID } = useSelector(
		(state) => state.markers
	);

	return (
		<React.Fragment>
			<Helmet title="Markers" />
			<Container fluid className="p-0 h-100">
				<Row className="g-0 h-100 d-flex justify-content-center">
					<Col lg={3} xl={2} className="border-end">
						<MarkersLists className="h-100" />
					</Col>
					<Col lg={5} xl={7}>
						<Row className="pb-4 mx-0 overflow-auto" style={{ minHeight: "100px" }}>
							<Col className="h-100">
								{/* <h4 className="border-bottom py-2 ">Hello</h4> */}

								{curretnMarkerID ? (
									<SelectedMarker />
								) : (
									<h2 className="my-4 text-muted" style={{ textAlign: "center" }}>
										{"Select a marker".toLocaleUpperCase()}
									</h2>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default MarkersPage;
