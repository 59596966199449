import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Amplify from "aws-amplify";

import { AuthProvider } from "contexts/AuthContext";
import { LayoutProvider } from "contexts/LayoutContext";
import { ModalProvider } from "contexts/ModalContext";
import { ThemeProvider } from "contexts/ThemeContext";
import { SidebarProvider } from "contexts/SidebarContext";

import { amplify } from "./config";
import store from "features/store";
import "./i18n";
import routes from "./routes";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripe = loadStripe(process.env.STRIPE_API_KEY);

Amplify.configure({
	...amplify,
});

/**
 * NOTES:
 * Redux:
 * 	<Provider /> makes the redux store available to the app
 */

// console.log("process.env.INTERCOM_ID: ", process.env.INTERCOM_ID);

const titleTemplate = `%s |${
	"production" === process.env.ENV ? "" : ` ${process.env.REACT_APP_DMIO_CONF}`
} Dreamaker.io - Bravo!`;

const App = () => {
	const content = useRoutes(routes);

	return (
		<HelmetProvider>
			<Helmet titleTemplate={titleTemplate} defaultTitle="Dreamaker.io - Bravo!">
				<script>{`window.intercomSettings = {horizontal_padding: 10, vertical_padding: 10};`}</script>
				<script>
					{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.INTERCOM_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
				</script>
				<script>{`window.Intercom('boot',{app_id:'${process.env.INTERCOM_ID}'})`}</script>
			</Helmet>
			<Provider store={store}>
				<Elements stripe={stripe}>
					<ThemeProvider>
						<SidebarProvider>
							<ModalProvider>
								<LayoutProvider>
									{/* <ChartJsDefaults /> */}
									<AuthProvider>{content}</AuthProvider>
								</LayoutProvider>
							</ModalProvider>
						</SidebarProvider>
					</ThemeProvider>
				</Elements>
			</Provider>
		</HelmetProvider>
	);
};

export default App;
