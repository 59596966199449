/** DM SPECIFIC **/
/**
 * Converts dmError to AWS err Obj
 */
export function dmErrToAwsErr(str) {
	try {
		const obj = JSON.parse(
			str.substr(str.indexOf("{"), str.lastIndexOf("}") - str.indexOf("{") + 1)
		);
		return obj;
	} catch (e) {
		return "dmErr: Internal error bad format";
	}
}

export function ownerNameStr(str) {
	const spaceIdx = str.indexOf(" ");
	return `${str.substr(0, spaceIdx)}${str.substr(spaceIdx, 2)}`;
}
