import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/buttons";

import { ROUTES } from "constants/";

import {} from "components/icons/";

const NavbarUpgradeButton = () => {
	const navigate = useNavigate();
	return (
		<Button
			className="nav-item text-success fw-bolder"
			style={{ marginTop: "5px", marginBottom: "5px" }}
			// size="sm"
			variant="link"
			onClick={() => navigate(ROUTES.UPGRADE)}
		>
			{"Upgrade".toLocaleUpperCase()}
		</Button>
	);
};

export default NavbarUpgradeButton;
