import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import { ReactComponent as Logo } from "assets/img/logo.svg";

const AuthCardWrapper = ({ helmet, title, subTitle, children }) => (
	<React.Fragment>
		<Helmet title={helmet} />
		<Card>
			<Card.Body>
				<div className="m-sm-4">
					<div className="text-center">
						<Logo className="img-fluid" width="90" height="90" />
						<h1 className="mt-4">{title}</h1>
						{subTitle ? (
							<div className="text-center mt-2">
								<p className="lead">{subTitle}</p>
							</div>
						) : null}
					</div>
					{children}
				</div>
			</Card.Body>
		</Card>
	</React.Fragment>
);

export default AuthCardWrapper;
