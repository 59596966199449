import React, { useEffect, useState } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

import {
	deleteEventTag,
	updateEventTag,
} from "features/analysis/eventTags.actions";

import {
	Accordion,
	Button,
	Card,
	CustomToggle,
	Dropdown,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { formatLocalDate, formatVideoTime } from "utils/";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import Avatar from "../Avatar";
import ClickToEdit from "../ClickToEdit";

const EventTag = (props) => {
	const { eventTag } = props;
	const { currentProjectDashboardTags } = props;
	const { currentProjectMarkers } = props;

	const dispatch = useAppDispatch();

	const {
		entities: dashboardTags,
		error: tagError,
		loading: tagLoading,
		currentEntityID: currentTagID,
	} = useAppSelector((state) => state.dashboardTags);

	const {
		entities: markerEntities,
		error: markerError,
		loading: markerLoading,
		currentEntityID: currentMarkerID,
	} = useAppSelector((state) => state.userMarkers);

	const { closeModal, openModal } = useModalContext();

	const [showDeleteX, setShowDeleteX] = useState(false);
	const [eventTagReplies, setEventTagReplies] = useState();
	const [displayTagDropdown, setDisplayTagDropdown] = useState(false);
	const [displayMarkerDropdown, setDisplayMarkerDropdown] = useState({
		show: false,
		markerID: "",
	});

	// On initial load and whenever an ET's replies object updates, set eventTagReplies to an array of ClickToEdit <li>s.
	useEffect(() => {
		if (eventTag.replies !== undefined) {
			let repliesArray = [];
			for (let reply in eventTag.replies) {
				repliesArray.push(eventTag.replies[reply]);
			}
			setEventTagReplies(
				repliesArray.map((reply) => {
					return (
						<li key={reply.eventTagReplyID}>
							<ClickToEdit
								eventTag={eventTag}
								formId="replyInput"
								initialRenamedField={reply.text}
								replyId={reply.eventTagReplyID}
							/>
						</li>
					);
				})
			);
		}
	}, [eventTag.replies]);

	// PLAYLIST
	const handlePlaylistOnClick = () => {
		let newEventTag = {
			...eventTag,
			playlist: !eventTag.playlist,
		};
		dispatch(updateEventTag(newEventTag))
			.then((originalPromiseResult) => {
				console.log("Update Project Success");
			})
			.catch((error) => {
				console.log("updateUserProject Error: ", error);
			});
	};

	// TAGS
	const eventTagTagsDropdown = (
		<Dropdown
			id="tagDropdown"
			// Need to get the onBlur working, but it interferes with the Dropdown.Item onClick function
			// onBlur={() => {
			// 	setDisplayTagDropdown(false);
			// }}
		>
			<Dropdown.Toggle variant="success">
				{eventTag.dashboardTagID === "root_comment"
					? "Comment"
					: dashboardTags[eventTag.dashboardTagID].label}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{currentProjectDashboardTags.map((dashboardTag, index) => {
					return (
						<Dropdown.Item
							as="button"
							key={index}
							onClick={() => {
								console.log("Hi hi hi ");
								handleUpdateEventTag(dashboardTag.dashboardTagID);
							}}
						>
							{dashboardTag.label}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);

	// MARKERS & OPTIONS
	const handleMarkerOnClick = (markerID) => {
		// Displays dropdown button for marker options
		if (
			markerID !== displayMarkerDropdown.markerID &&
			displayMarkerDropdown.markerID !== ""
		) {
			setDisplayMarkerDropdown({
				show: true,
				markerID: markerID,
			});
		} else {
			setDisplayMarkerDropdown({
				show: !displayMarkerDropdown.show,
				markerID: markerID,
			});
		}
	};

	// Makes the dropdown toggle the name of the selected option, or the name of the marker if no option is selected
	const generateMarkerDropdownToggle = () => {
		if (eventTag.markers[displayMarkerDropdown.markerID] !== undefined) {
			return markerEntities[displayMarkerDropdown.markerID].options[
				eventTag.markers[displayMarkerDropdown.markerID]
			];
		} else {
			if (displayMarkerDropdown.markerID !== "") {
				return markerEntities[displayMarkerDropdown.markerID].name;
			}
		}
	};

	// Maps the array of currentProjectMarkers
	const markerClickables = () => {
		if (currentProjectMarkers !== undefined) {
			return currentProjectMarkers.map((marker, index) => {
				return (
					<div
						key={index}
						onClick={() => {
							handleMarkerOnClick(marker.markerID);
						}}
					>
						{marker.name}
					</div>
				);
			});
		} else return null;
	};

	const generateMarkerOptionsDropdownItems = () => {
		let markerOptionsArray = [];
		if (markerEntities !== undefined && displayMarkerDropdown.markerID !== "") {
			for (let option in markerEntities[displayMarkerDropdown.markerID].options) {
				markerOptionsArray.push(
					markerEntities[displayMarkerDropdown.markerID].options[option]
				);
			}
		}
		return markerOptionsArray;
	};

	const optionDropdownList = generateMarkerOptionsDropdownItems().map(
		(option, index) => {
			return (
				<Dropdown.Item
					key={index}
					onClick={() => {
						console.log(`option: ${option}`);
					}}
				>
					{option}
				</Dropdown.Item>
			);
		}
	);

	const eventTagMarkerOptionsDropdown = (
		<Dropdown>
			<Dropdown.Toggle>{generateMarkerDropdownToggle()}</Dropdown.Toggle>
			<Dropdown.Menu>{optionDropdownList}</Dropdown.Menu>
		</Dropdown>
	);

	// UPDATE & DELETE EVENT TAG
	const handleUpdateEventTag = (dashboardTagID) => {
		setDisplayTagDropdown(false);
		let newEventTag = {
			...eventTag,
			dashboardTagID: dashboardTagID,
		};
		dispatch(updateEventTag(newEventTag))
			.then((originalPromiseResult) => {
				console.log("Update Project Success");
			})
			.catch((error) => {
				console.log("updateUserProject Error: ", error);
			});
	};

	const handleDeleteEventTagOnClick = (eventTagID) => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: "Are you sure you want to delete this Event Tag?",
			confirmBtnAction: () => handleDeleteEventTag(eventTagID),
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Delete Event Tag",
			title: "Delete " + eventTag.title,
		});
	};

	const handleDeleteEventTag = async (eventTagID) => {
		dispatch(deleteEventTag(eventTagID))
			.then((res) => {
				closeModal(false);
				console.log("deleteEventTag Success");
			})
			.catch((error) => {
				console.log("deleteEventTag Error: ", error);
			});
	};

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey, () =>
			console.log("totally custom!")
		);
		return (
			<button
				type="button"
				style={{ backgroundColor: "pink" }}
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	// return (
	// 	<Accordion defaultActiveKey="0">
	// 		<Card>
	// 			<Card.Header>
	// 				<CustomToggle eventKey="0">Click me!</CustomToggle>
	// 			</Card.Header>
	// 			<Accordion.Collapse eventKey="0">
	// 				<Card.Body>Hello! I'm the body</Card.Body>
	// 			</Accordion.Collapse>
	// 		</Card>
	// 		<Card>
	// 			<Card.Header>
	// 				<CustomToggle eventKey="1">Click me!</CustomToggle>
	// 			</Card.Header>
	// 			<Accordion.Collapse eventKey="1">
	// 				<Card.Body>Hello! I'm another body</Card.Body>
	// 			</Accordion.Collapse>
	// 		</Card>
	// 	</Accordion>
	// );

	return (
		<div
			className="w-100 p-2"
			style={{
				borderTop: "2px solid yellow",
			}}
			onMouseEnter={() => {
				setShowDeleteX(true);
			}}
			onMouseLeave={() => {
				setShowDeleteX(false);
			}}
		>
			<div id="eventTagTitle" className="d-flex justify-content-start">
				<Avatar size="md" initials="DK" />
				<div className="w-100">
					<div className="d-flex justify-content-between">
						<ClickToEdit
							eventTag={eventTag}
							formId="titleInput"
							initialRenamedField={eventTag.title}
						/>
						<div>
							<FontAwesomeIcon icon={regular("circle-play")} />{" "}
							{formatVideoTime(eventTag.sliceStart)}
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<p className="m-0">
							<b>Name </b>
							{formatLocalDate(eventTag.dateCreated)}
						</p>
						{eventTag.playlist === true ? (
							<FontAwesomeIcon
								icon={regular("circle-check")}
								color="yellow"
								onClick={() => {
									handlePlaylistOnClick();
								}}
							/>
						) : (
							<FontAwesomeIcon
								icon={regular("circle-check")}
								onClick={() => {
									handlePlaylistOnClick();
								}}
							/>
						)}
					</div>
				</div>
			</div>
			<div id="eventTagTagsNMarkers" className="d-flex">
				{eventTag.dashboardTagID === "root_comment" ? (
					<p
						onClick={() => {
							setDisplayTagDropdown(true);
						}}
					>
						Comment
					</p>
				) : (
					<p
						onClick={() => {
							setDisplayTagDropdown(true);
						}}
					>
						{dashboardTags[eventTag.dashboardTagID].label}
					</p>
				)}{" "}
				|| {markerClickables()}
			</div>
			<div id="dropdownArea">
				{displayTagDropdown === true ? eventTagTagsDropdown : null}
				{displayMarkerDropdown.show === true ? eventTagMarkerOptionsDropdown : null}
			</div>
			<div id="eventTagNote" className="w-50">
				{eventTag.comment === undefined ? (
					<ClickToEdit
						eventTag={eventTag}
						formId="blankCommentInput"
						initialRenamedField=""
						placeholder="Add a note"
					/>
				) : (
					<ClickToEdit
						eventTag={eventTag}
						formId="commentInput"
						initialRenamedField={eventTag.comment}
					/>
				)}
			</div>
			<div
				id="eventTagReply"
				className="w-75 d-flex justify-content-between overflow-scroll"
			>
				<ul>
					{eventTagReplies}
					<li>
						<ClickToEdit
							eventTag={eventTag}
							formId="blankReplyInput"
							initialRenamedField=""
							placeholder="Add a reply"
						/>
					</li>
				</ul>
				{showDeleteX === true ? (
					<Button
						variant="outline-dark"
						style={{ border: "none" }}
						onClick={() => {
							handleDeleteEventTagOnClick(eventTag.eventTagID);
						}}
					>
						X
					</Button>
				) : null}
			</div>
		</div>
	);
};

export default EventTag;
