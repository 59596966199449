import React, { useRef, useState } from "react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import { useDrag, useDrop } from "react-dnd";

// Move this to the appropriate style.js file
export const removeButtonStyle = {
	width: "5px",
	height: "5px",
	position: "relative",
	bottom: "15px",
	left: "5px",
};

export const PlaceholderTagBtn = (props) => {
	const {
		accept,
		addTagToDashboardOnHover,
		currentNumOfTags,
		dropFunction,
		id,
		index,
		label,
	} = props;

	const ref = useRef(null);

	const [{ handlerId }, drop] = useDrop({
		accept: accept,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex =
				item.index > currentNumOfTags - 1 ? currentNumOfTags - 1 : item.index;
			const hoverIndex = index;
			if (item.type === "searchTagBtn") {
				addTagToDashboardOnHover(item.id, dragIndex);
			}

			if (dragIndex === hoverIndex) {
				// Don't replace items with themselves
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle. The higher the denominator, the more sensitive the dnd.
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Get horizontal middle. The higher the denominator, the more sensitive the dnd.
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Get pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			// Not sure if any of this is needed?
			const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
			const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
			const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
			const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;
			if (upwards && (leftwards || rightwards)) {
				return;
			}
			if (downwards && (leftwards || rightwards)) {
				return;
			}
			item.index = hoverIndex;
		},
		drop(item, monitor) {
			if (item.type === "searchTagBtn" && currentNumOfTags < 8) {
				dropFunction(item.id);
			}
		},
	});

	drop(ref);

	return (
		<li
			className="btn btn-outline-secondary mx-2 mt-1"
			ref={ref}
			data-handler-id={handlerId}
			style={{ width: "150px" }}
			id={id}
		>
			{label}
		</li>
	);
};
