import "./dashboards.scss";
import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/buttons";
import { CreateIcon } from "components/icons";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { createUserDashboard } from "features/dashboards/dashboards.actions";

const DashboardCreateButton = () => {
	const dispatch = useDispatch();
	const { openModal, closeModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { entities: dashboards } = useSelector((state) => state.dashboards); //Bringing in state from global store

	const [maxDefaultSortOrder, setMaxDefaultSortOrder] = useState(0);

	// console.log("dashboards: ", dashboards);
	// console.log("maxDefaultSortOrder: ", maxDefaultSortOrder);

	// useEffect on [entities.dashboards] changing: sets defaultDashboards, otherDashboards and maxDefaultSortOrder
	useEffect(() => {
		// console.log("Running setDashboardsArrays useEffect");

		const orderedDashboards = _.orderBy(
			dashboards,
			["defaultSortOrder"],
			["desc"]
		);
		let maxDefaultSortOrder = 0;

		var cnt = 0,
			len = orderedDashboards.length;

		while (cnt < len) {
			const dashboard = orderedDashboards[cnt];
			if (true === dashboard.default) {
				if (maxDefaultSortOrder < dashboard.defaultSortOrder)
					maxDefaultSortOrder = dashboard.defaultSortOrder;
			}
			cnt++;
		}
		setMaxDefaultSortOrder(++maxDefaultSortOrder);
	}, [dashboards]);

	const onCreateClick = () => {
		openModal(MODAL_TYPES.CREATE, {
			title: "Create New Dashboard",
			confirmBtnAction: createDashboard,
			confirmBtnTitle: "Create Dashboard",
			createType: "Dashboard",
		});
	};

	const createDashboard = async ({ defaultChecked, note = "", title }) => {
		let newDashboard = {
			title,
			note: note,
			default: defaultChecked,
		};

		// only required if defaultChecked selected
		if (defaultChecked) {
			newDashboard.defaultSortOrder = maxDefaultSortOrder;
			setMaxDefaultSortOrder(maxDefaultSortOrder + 1);
		}

		const res = await dispatch(createUserDashboard(newDashboard));
		if (res.error) {
			notify.error(res.error);
			if (defaultChecked) {
				setMaxDefaultSortOrder(maxDefaultSortOrder - 1);
			}
			return;
		}
		notify.success("Dashboard successfully created!");
		closeModal();
	};

	return (
		// <div className="border-bottom py-2 d-flex flex-row-reverse">
		<div className="d-grid gap-2 me-2 pb-3">
			<Button variant="success" onClick={() => onCreateClick()} className="">
				<CreateIcon className="me-2" size="lg" />
				Create New Dashboard
			</Button>
		</div>
	);
};

export default DashboardCreateButton;
