import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { Button } from "components/buttons";

import useTheme from "hooks/useTheme";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import {
	updateBillingPlanPreview,
	billingUpdateSetupIntent,
} from "features/billing/billing.actions";

import StripeCheckoutForm from "components/stripe/StripeCheckoutForm.js";
import StripePlanChangePreview from "components/stripe/StripePlanChangePreview";

import { ucWords } from "utils/";
import { THEME } from "constants/";

function StripeCheckoutButton({
	className,
	disabled = false,
	buttonTitle = "Update your card",
	newPaymentPeriod,
	newPlan,
	variant,
}) {
	// const { theme } = useTheme();
	const { openModal } = useModalContext();
	const [clientSecret, setClientSecret] = useState(false); // use `false` to indicate Button disabled until !false
	const [loading, setLoading] = useState(false); // use `false` to indicate Button disabled until !false

	/** Setup payment intent on load for updating card only - do this here to speed up retrieving of intent later*/
	useEffect(() => {
		// Do not use effect when changeing to a newPlan
		if (undefined !== newPlan) return;
		const fetchData = async () => {
			const res = await billingUpdateSetupIntent();
			// console.log("res billingUpdateSetupIntent= ", res);
			if (res.setupIntent) {
				setClientSecret(res.setupIntent.client_secret);
			} else {
				//TODO: Handle
				console.log("setUpPaymentIntent Failed!");
			}
		};
		fetchData();
	}, []);

	// console.log("Reloaded - ");
	// console.log("previewChange: ", previewChange);
	// console.log("newPlan: ", newPlan);
	// console.log("newPaymentPeriod: ", newPaymentPeriod);

	const onClick = async () => {
		// newPlan === undefined: this is a card update, for which clientSecret is required
		// newPlan !== undefined: this is a new stripe customer, for which clientSecret not yet required
		if (undefined === newPlan && !clientSecret) return;

		setLoading(true);

		/** newPlan === undefined: go through to card update **/
		if (undefined === newPlan) {
			setLoading(false);
			return openModal(MODAL_TYPES.STRIPE_MODAL, {
				stripeFlow: "CC_UPDATE",
				data: {
					clientSecret,
				},
			});
		}

		/** newPlan !== undefined: get card for a change plan **/
		// set the prorationDate here to keep it constant through change-of-plan process
		const prorationDate = Math.floor(Date.now() / 1000);

		/** updateBillingPlanPreview returns either a setupIntent OR preview of charges */
		const res = await updateBillingPlanPreview({
			planID: newPlan.planID,
			paymentPeriod: newPaymentPeriod,
			prorationDate,
		});
		// console.log("res updateBillingPlanPreview = ", res);

		// IF res.setupintent exists - either new card needed or existing cards needs confirming - ask for card details
		if (res.setupIntent) {
			openModal(MODAL_TYPES.STRIPE_MODAL, {
				stripeFlow: "CHANGE_PLAN_CC_REQUIRED",
				data: {
					clientSecret: res.setupIntent.client_secret,
					newPaymentPeriod: newPaymentPeriod,
					newPlan: newPlan,
					prorationDate,
				},
			});
			setLoading(false);
		}
		// ELSE IF res.setupintent does not exist but 200 resp, authorized card existgs so go through to preview changes to be made
		else {
			openModal(MODAL_TYPES.STRIPE_MODAL, {
				stripeFlow: "CHANGE_PLAN_PREVIEW_CHANGES",
				data: {
					clientSecret,
					newPaymentPeriod: newPaymentPeriod,
					newPlan: newPlan,
					prorationDate,
					changePreview: { ...res },
				},
			});
			setLoading(false);
		}
		return;
	};

	return (
		<Button
			className={className}
			variant={variant}
			disabled={disabled || loading}
			loading={loading}
			onClick={() => onClick()}
		>
			{buttonTitle}
		</Button>
	);
}

export default StripeCheckoutButton;
