import React, { useEffect, useState } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { setCurrentDashboardID } from "features/dashboards/dashboards.slice";

import { Carousel, Form } from "react-bootstrap";

const DashboardsAnalysis = (props) => {
	const dispatch = useAppDispatch();

	const { currentProjectDashboards } = props;

	const {
		entities: dashboardEntities,
		error: dashboardError,
		loading: dashboardLoading,
		currentEntityID: currentDashboardID,
	} = useAppSelector((state) => state.dashboards);

	const {
		entities: dashboardTags,
		error: tagError,
		loading: tagLoading,
		currentEntityID: currentTagID,
	} = useAppSelector((state) => state.dashboardTags);

	const generateTagButtons = (tagIDs) => {
		let tagButtonArray = [];
		for (let i = 0; i < tagIDs.length; i++) {
			tagButtonArray.push(dashboardTags[tagIDs[i]]);
		}
		return tagButtonArray.map((tag) => {
			return (
				<li
					className={`btn btn-${tag.color} mx-2 mt-1`}
					style={{ width: "150px", minWidth: "150px" }}
					key={tag.dashboardTagID}
				>
					{tag.label}
				</li>
			);
		});
	};

	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
		dispatch(setCurrentDashboardID(allDashboards[selectedIndex].key));
	};

	const allDashboards = currentProjectDashboards.map((dashboard) => {
		return (
			<Carousel.Item key={dashboard.dashboardID}>
				<ul className="d-flex flex-wrap justify-content-center overflow-auto p-0">
					{generateTagButtons(dashboard.dashboardTags)}
				</ul>
			</Carousel.Item>
		);
	});

	useEffect(() => {
		dispatch(setCurrentDashboardID(allDashboards[index].key));
	}, []);

	return (
		<div>
			<div id="header" className="d-flex justify-content-between">
				<h6>
					{currentDashboardID !== undefined
						? dashboardEntities[currentDashboardID].title
						: null}
				</h6>
				<Form>
					<Form.Check type="checkbox" label="Pause on Tag"></Form.Check>
				</Form>
			</div>
			<div id="tagsArea">
				<Carousel
					indicators={false}
					interval={null}
					activeIndex={index}
					onSelect={handleSelect}
				>
					{allDashboards}
				</Carousel>
			</div>
			<div id="commentArea">
				<Form>
					<Form.Control
						as="textarea"
						rows={2}
						id="inviteMessageTextarea"
						name="inviteMessageTextarea"
						placeholder="Type message here"
						// onChange={handleInputChange}
						// value={formData.inviteMessageTextarea}
					/>
				</Form>
				<div className="d-flex justify-content-between">
					<p>
						<i>
							<kbd>
								<kbd>Command</kbd> + <kbd>Enter</kbd>
							</kbd>{" "}
							or{" "}
							<kbd>
								<kbd>Control</kbd> + <kbd>Enter</kbd>
							</kbd>{" "}
							to submit
						</i>
					</p>
					<Form>
						<Form.Check type="checkbox" label="Pause on Type"></Form.Check>
						<Form.Check type="checkbox" label="Resume on Submit"></Form.Check>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default DashboardsAnalysis;
