import "pages/dashboards/dashboards.scss";
import _ from "lodash";
import React, { useCallback, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders";

import NotifyContext from "contexts/NotifyContext";

import {
	fetchUserMarkers,
	updateUserMarker,
} from "features/markers/markers.actions";
import { setCurrentEntityID as setCurrentMarkerID } from "features/markers/markers.slice";

import MarkerCreateButton from "./MarkerCreateButton";
import { MarkersListItem } from "./MarkersListItem";

const MarkersLists = () => {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);

	const { currentEntityID: currentMarkerID, entities: markers } = useSelector(
		(state) => state.markers
	); //Bringing in state from global store

	const [loading, setLoading] = useState(true);
	const [defaultMarkers, setDefaultMarkers] = useState([]);
	const [otherMarkers, setOtherMarkers] = useState([]);
	const [maxDefaultSortOrder, setMaxDefaultSortOrder] = useState(0);

	// console.log("markers: ", markers);
	// console.log("defaultMarkers: ", defaultMarkers);
	// console.log("otherMarkers: ", otherMarkers);
	// console.log("maxDefaultSortOrder: ", maxDefaultSortOrder);

	useEffect(() => {
		async function fetchData() {
			await dispatch(fetchUserMarkers());
			setLoading(false);
		}
		null === markers ? fetchData() : setLoading(false);
	}, []);

	// useEffect on [entities.markers] changing: sets defaultMarkers, otherMarkers and maxDefaultSortOrder
	useEffect(() => {
		const orderedMarkers = _.orderBy(
			markers,
			["sortOrder", "dateUpdated"],
			["asc", "desc"]
		);
		let tmpDefaultMarkers = [];
		let tmpOtherMarkers = [];
		let maxDefaultSortOrder = 0;

		var cnt = 0,
			len = orderedMarkers.length;

		while (cnt < len) {
			// console.log("markerID: ", markerID);
			const marker = orderedMarkers[cnt];
			// console.log("marker: ", marker);
			if (true === marker.default) {
				if (maxDefaultSortOrder < marker.sortOrder)
					maxDefaultSortOrder = marker.sortOrder;
				tmpDefaultMarkers.push(marker);
			} else {
				tmpOtherMarkers.push(marker);
			}
			cnt++;
		}
		// tmpOtherMarkers.sort((a, b) => a.title.localeCompare(b.title));
		setOtherMarkers(tmpOtherMarkers);
		setDefaultMarkers(tmpDefaultMarkers);
		setMaxDefaultSortOrder(maxDefaultSortOrder);
	}, [markers]);

	const onMarkersReorderDrag = useCallback((dragIndex, hoverIndex) => {
		console.log("onMarkersReorderDrag() ");
		console.log("dragIndex ", dragIndex);
		console.log("hoverIndex ", hoverIndex);
		console.log("");
		setDefaultMarkers((prevMarkers) =>
			update(prevMarkers, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevMarkers[dragIndex]],
				],
			})
		);
	}, []);

	const onMarkersReorderDrop = useCallback(({ markerID, index }) => {
		const newDefaultSortOrder = index + 1;
		updateMarker(markerID, newDefaultSortOrder);

		const currentMarker = markers[markerID];

		console.log("sortOrder - ", currentMarker.sortOrder);
		console.log("newDefaultSortOrder - ", newDefaultSortOrder);
		console.log("moved marker - ", currentMarker);

		var cnt = newDefaultSortOrder;
		if (newDefaultSortOrder < currentMarker.sortOrder) {
			while (cnt < defaultMarkers.length) {
				const marker = defaultMarkers[cnt];
				if (markerID === marker.markerID) {
					continue;
				}
				updateMarker(marker.markerID, marker.sortOrder + 1);
				console.log("change marker - ", marker);
				console.log("... new deafultSortOrder - ", marker.sortOrder + 1);
				cnt++;
			}
		} else {
			while (cnt > 0) {
				cnt--;
				const marker = defaultMarkers[cnt];
				if (markerID === marker.markerID) {
					continue;
				}
				updateMarker(marker.markerID, marker.sortOrder - 1);
				console.log("change marker - ", marker);
				console.log("... new deafultSortOrder - ", marker.sortOrder - 1);
			}
		}
	});

	const updateMarker = (markerID, sortOrder) => {
		const res = dispatch(
			updateUserMarker({
				markerID,
				sortOrder,
			})
		);
		if (res.error) {
			console.log("updateUserMarker Error: ", res.error);
			notify.error(res.error);
		}
	};

	const defaultMarkersList = defaultMarkers.map(({ markerID, name }, index) => {
		return (
			<MarkersListItem
				key={markerID}
				active={markerID === currentMarkerID}
				markerID={markerID}
				index={index}
				onMarkersReorderDrag={onMarkersReorderDrag}
				onMarkersReorderDrop={onMarkersReorderDrop}
				onClick={() => dispatch(setCurrentMarkerID(markerID))}
				title={name}
			/>
		);
	});

	const otherMarkersList = otherMarkers.map(({ markerID, name }) => (
		<ListGroupItem
			action
			active={markerID === currentMarkerID}
			className="ps-1 py-2 d-flex justify-content-between align-items-start dashboards-list-item"
			key={markerID}
			onClick={() => dispatch(setCurrentMarkerID(markerID))}
		>
			<div className="ms-3 me-auto">{name}</div>
		</ListGroupItem>
	));

	return (
		<>
			<MarkerCreateButton />
			<div className="d-flex-column">
				<h4 className="ps-1 me-2 py-2 border-bottom">Default Markers</h4>
				<div className="dashboards-list p-2 dashboards-list-container">
					{loading ? (
						<LoadingSpinner />
					) : 0 >= defaultMarkersList.length ? (
						<p>No default markers</p>
					) : (
						<DndProvider backend={HTML5Backend}>
							<ListGroup variant="flush">{defaultMarkersList}</ListGroup>
						</DndProvider>
					)}
				</div>
				<h4 className="ps-1 me-2 pt-4 pb-2 border-bottom ">Other Markers</h4>
				<div className="p-2 dashboards-list-container">
					{loading ? (
						<LoadingSpinner />
					) : 0 >= otherMarkersList.length ? (
						<p>No other markers</p>
					) : (
						<ListGroup variant="flush">{otherMarkersList}</ListGroup>
					)}
				</div>
			</div>
		</>
	);
};

export default MarkersLists;
