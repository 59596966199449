/**
 * NOTE: Placing the regex expresison between // creates a Regex, so no need to use Regex(//)
 * NOTE: RegExp Creation
 * RegExp Test: https://www.regextester.com/15 or https://regex101.com/
 * Email RegExp:
 *      https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 *      http://emailregex.com/
 */
export const REGEXP = {
	EMAIL_VALID:
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
	// EMAIL_VALID: RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", 'i'),  // Old - validated email with no domain (example@nownow)

	// Allowed uploads: FileAssets & Images
	FILEASSET_WHITELIST:
		/csv|doc|docx|epub|key|mp3|m4a|numbers|ods|pages|pdf|ppt|rtf|tsv|txt|xls|xlsx|xps/, // extensions accesptable as file assets
	IMAGE_WHITELIST: /bmp|gif|jpg|jpeg|png|svg/, // extensions accesptable as file assets & images

	// Allowed uploads: Video & Audio
	MEDIA_BLACKLIST: /m3u/, // extensions which are interepretted as audio/video but are not transcodable
	MEDIA_WHITELIST: /mod|mts|ogv|mxf|3gp/, // extensions which are not interepretted as audio/video but are transcodable

	// WHITE_SPACE: RegExp("\s+", 'g')

	PASSWORD:
		/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
};
