/** Utility functions related to intercom
 * README:
 * 		https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 * 		https://developers.intercom.com/installing-intercom/docs/basic-javascript
 */

// import { getQueueSize, getUploadSpeed } from "utils/bandwidth";

import { formatStorage } from "utils/";

const INTERCOM_ID = process.env.INTERCOM_ID;

export function bootIntercom(user) {
	// console.log("intercome user: ", user);
	window.Intercom("update", {
		app_id: INTERCOM_ID,
		custom_launcher_selector: "#chatnow",

		// User data
		accountID: user.accountID,
		created_at: Math.round(user.dateCreated / 1000),
		email: user.email,
		name: user.lastName
			? `${user.firstName} ${user.lastName}`
			: `${user.firstName}`,
		user_hash: user.intercomIdentityVerificationHash,
		user_id: user.userID,
	});
}

export function updateIntercom({ user, account }) {
	// console.log("intercome user: ", user);
	// console.log("intercome account: ", account);
	var accountObject = {};
	if (undefined !== account) {
		const { organizationID, organizations, planData, usageData } = account;
		accountObject = {
			// company: organizationID
			// 	? {
			// 			company_id: organizationID,
			// 			name: organizations[organizationID].organizationAtDomain,
			// 	  }
			// 	: {},
			plan: planData.title,
			planID: planData.planID,
			videoCount: usageData.videoCount,
			audioCount: usageData.audioCount,
			collaboratorCount: usageData.collaboratorCount,
			shareCount: usageData.shareCount,
			storageCount: formatStorage(usageData.storageCount),
			dmioRelease: process.env.VERSION,
			// uploadQueueSize: getQueueSize(),
			// uploadSpeed: getUploadSpeed(),
		};
	}

	var userObject = {};
	if (undefined !== user) {
		userObject = {
			// User data
			accountID: user.accountID,
			created_at: Math.round(user.dateCreated / 1000),
			email: user.email,

			name: user.lastName
				? `${user.firstName} ${user.lastName}`
				: `${user.firstName}`,

			user_hash: user.intercomIdentityVerificationHash,
			user_id: user.userID,
		};
	}
	window.Intercom("update", {
		...accountObject,
		...userObject,
		dmioRelease: process.env.VERSION,
	});
}

export function shutdownIntercom() {
	window.Intercom("shutdown");
	window.Intercom("boot", { app_id: INTERCOM_ID });
}
