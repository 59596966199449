export const SIDEBAR_POSITION = {
	LEFT: "left",
	RIGHT: "right",
};

export const SIDEBAR_BEHAVIOR = {
	STICKY: "sticky",
	FIXED: "fixed",
	COMPACT: "compact",
};

export const LAYOUT = {
	FLUID: "fluid",
	BOXED: "boxed",
};

export const THEME = {
	DEFAULT: "default",
	COLORED: "colored",
	DARK: "dark",
	LIGHT: "light",
};

export const THEME_COLORS = {
	primary: "#1997C6",
	secondary: "#DEE6ED",
	success: "#1BC98E",
	info: "#9F86FF",
	warning: "#fbbf30",
	danger: "#E64759",
	cyan: "#1F9BCF",
	lime: "#E3EF45",
	plum: "#a25397",
	tangerine: "#FB9800",
	teal: "#5BC0dE",

	muted: "#9498a1",
};

export const THEME_COLOR_NAMES = {
	primary: "Bot blue",
	secondary: "Not quite white",
	success: "Just green",
	info: "Purple turtle",
	warning: "Limoncello",
	danger: "Cheeky red",
	// cyan: "#1F9BCF",
	lime: "Lovely lime",
	plum: "Yum plum",
	tangerine: "Tangerine",
	// teal: "#5BC0dE",

	// muted: "#9498a1",
};

// export const THE_COLOR_NAMES = {
// 	"Bot blue": THEME_COLORS.primary,
// 	"Not quite white": THEME_COLORS.secondary,
// 	"Just green": THEME_COLORS.success,
// 	"Purple turtle": THEME_COLORS.info,
// 	Limoncello: THEME_COLORS.warning,
// 	"Cheeky red": THEME_COLORS.danger,
// 	"Luvly Lime": THEME_COLORS.lime,
// 	Tangerine: THEME_COLORS.tangerine,
// 	"Yum Plum": THEME_COLORS.plum,
// };

// export const THEME_COLOR_NAMES = {
// 	"Bot blue": "primary",
// 	"Cheeky red": "danger",
// 	"Just green": "success",
// 	Limoncello: "warning",
// 	"Luvly Lime": "lime",
// 	"Not quite white": "secondary",
// 	"Purple turtle": "info",
// 	Tangerine: "tangerine",
// 	"Yum Plum": "plum",
// };

export const THEME_PALETTE_LIGHT = {
	primary: THEME_COLORS.primary,
	secondary: THEME_COLORS.secondary,
	success: THEME_COLORS.success,
	info: THEME_COLORS.info,
	warning: THEME_COLORS.warning,
	danger: THEME_COLORS.danger,
	teal: THEME_COLORS.teal,
	cyan: THEME_COLORS.cyan,
	lime: THEME_COLORS.cyan,
	plum: THEME_COLORS.plum,
	tangerine: THEME_COLORS.tangerine,
	"primary-dark": "#1659c7",
	"primary-light": "#84aef2",
	"gray-100": "#f4f7f9",
	"gray-200": "#e2e8ee",
	"gray-300": "#dee6ed",
	"gray-400": "#ced4da",
	"gray-500": "#adb5bd",
	"gray-600": "#6c757d",
	"gray-700": "#495057",
	"gray-800": "#020202",
	"gray-900": "#212529",
	black: "#000",
	white: "#fff",
};

export const THEME_PALETTE_DARK = {
	...THEME_PALETTE_LIGHT,
	"primary-dark": "#84aef2",
	"primary-light": "#1659c7",
	"gray-100": "#3e4555",
	"gray-200": "#545968",
	"gray-300": "#696e7b",
	"gray-400": "#7f838e",
	"gray-500": "#9498a1",
	"gray-600": "#a9acb3",
	"gray-700": "#bfc1c6",
	"gray-800": "#d4d6d9",
	"gray-900": "#eaeaec",
	black: "#fff",
	white: "#293042",
};
