import "./modal.scss";

import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { Form, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { Button } from "components/buttons/";
import { OpenIcon } from "components/icons";
import { LoadingSpinner } from "components/loaders";

import { fetchUserMarkers } from "features/markers/markers.actions";
import {
	fetchItemAnalysis,
	updateItemAnalysis,
} from "features/itemAnalyses/itemAnalyses.actions";

import { ROUTES } from "constants/";

export const ProjectMarkersModal = ({ title, itemAnalysisID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { closeModal, showModal } = useModalContext();

	const { entities: markers } = useSelector((state) => state.markers);
	const itemAnalysis = useSelector(
		(state) => state.itemAnalyses.entities?.[itemAnalysisID]
	);

	const [loading, setLoading] = useState(false);
	const [allMarkers, setAllMarkers] = useState([]);
	const [projectMarkers, setProjectMarkers] = useState([]);

	// console.log("==ProjectMarkersModal==");
	// console.log("title - ", title);
	// console.log("itemAnalysisID - ", itemAnalysisID);
	// console.log("itemAnalysis - ", itemAnalysis);
	// console.log("markers - ", markers);
	// console.log("allMarkers - ", allMarkers);
	// console.log("projectMarkers - ", projectMarkers);
	// console.log("");

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (null === markers) await dispatch(fetchUserMarkers());
			if (undefined === itemAnalysis)
				await dispatch(fetchItemAnalysis(itemAnalysisID));
			setLoading(false);
		}
		if (null === markers || undefined === itemAnalysis) fetchData();
	}, []);

	// Makes an array of all markers so they can be mapped, as well as an object displaying if each marker is in the current project's list (projectMarkers)
	// ex. {
	// 		markerID_1: false,
	// 		markerID_2: true,
	// 	}
	useEffect(() => {
		const orderedMarkers = _.orderBy(markers, ["name"], ["asc"]);
		setAllMarkers(orderedMarkers);
	}, [markers]);

	useEffect(() => {
		if (undefined !== itemAnalysis) setProjectMarkers(itemAnalysis.markers);
	}, [itemAnalysis]);

	const onCheckboxClick = (markerID) => {
		var tmpProjectMarkers = [...projectMarkers];
		const idx = projectMarkers.indexOf(markerID);
		// is not checked - add to projectMarkers
		if (-1 === idx) {
			tmpProjectMarkers.push(markerID);
		}
		// is checked - remove from projectMarkers
		else {
			tmpProjectMarkers.splice(idx, 1);
		}
		setProjectMarkers(tmpProjectMarkers);
	};

	const onEditClick = () => {
		navigate(ROUTES.MARKERS);
		closeModal();
	};

	const onSaveClick = async () => {
		const updatedItemAnalysis = {
			itemAnalysisID: itemAnalysis.itemAnalysisID,
			markers: [...projectMarkers],
		};

		const res = await dispatch(updateItemAnalysis(updatedItemAnalysis));
		if (res.error) {
			notify.error(res.error);
			return;
		}
		closeModal();
	};

	const renderMarkers = allMarkers.map(({ markerID, name }) => (
		<ListGroupItem
			action
			className="modal-list-item d-flex justify-content-between"
			key={markerID}
			onClick={() => onCheckboxClick(markerID)}
		>
			{/* <label>{name}</label> */}
			<Form.Check
				label={name}
				type="checkbox"
				checked={projectMarkers.includes(markerID)}
				onChange={() => onCheckboxClick(markerID)}
			/>
		</ListGroupItem>
	));

	return (
		<Modal show={showModal} onHide={() => closeModal()}>
			<Modal.Header>
				<Modal.Title className="d-flex justify-content-between">
					<>Project Markers</>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ maxHeight: "20rem", overflow: "scroll" }}>
				<ListGroup variant="flush">
					{loading ? <LoadingSpinner /> : renderMarkers}
				</ListGroup>
			</Modal.Body>
			<Modal.Footer className="d-flext justify-content-between">
				<Button variant="link" onClick={() => onEditClick()}>
					<OpenIcon className="px-2" />
					Edit markers
				</Button>
				<span>
					<Button
						variant="outline-danger"
						className="mx-2"
						onClick={() => closeModal()}
					>
						Cancel
					</Button>
					<Button variant="success" onClick={() => onSaveClick()}>
						Save
					</Button>
				</span>
			</Modal.Footer>
		</Modal>
	);
};
