import { createSlice } from "@reduxjs/toolkit";

import {
	createUserDashboard,
	deleteUserDashboard,
	fetchUserDashboards,
	updateUserDashboard,
} from "./dashboards.actions";

/**
 * Redux Learning NOTES
 * =====================\
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: null,
	currentEntityID: undefined,
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
};

const dashboardsSlice = createSlice({
	name: "dashboards",
	initialState,

	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		setCurrentDashboardID: (state, action) => {
			state.currentEntityID = action.payload; // payload is dashboardID
		},
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		/** fetchUserDashboards */
		[fetchUserDashboards.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchUserDashboards.fulfilled]: (state, action) => {
			state.loading = "idle";
			state.entities = action.payload; // payload contains object of user dashboards
		},
		[fetchUserDashboards.rejected]: (state, action) => {
			state.loading = "idle";
			// state.error = action.error;
		},

		/** createUserDashboard */
		[createUserDashboard.pending]: (state, action) => {
			state.loading = "pending";
		},
		[createUserDashboard.fulfilled]: (state, action) => {
			state.loading = "idle";
			state.entities = {
				...state.entities,
				...action.payload, // payload is dashboardObject {"abcID": {dashboardID: "abcID", ...}}
			};
			state.currentEntityID = Object.keys(action.payload)[0];
		},
		[createUserDashboard.rejected]: (state, action) => {
			state.loading = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** deleteUserDashboard */
		[deleteUserDashboard.pending]: (state, action) => {
			state.currentRequestId = action.meta.requestId;
		},
		[deleteUserDashboard.fulfilled]: (state, action) => {
			state.loading = "idle";
			// payload is dashboardID
			if (state.currentEntityID === action.payload)
				state.currentEntityID = undefined;
			delete state.entities[action.payload];
		},
		[deleteUserDashboard.rejected]: (state, action) => {
			state.loading = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** updateUserDashboard */
		[updateUserDashboard.pending]: (state, action) => {
			state.loading = "pending";
		},
		[updateUserDashboard.fulfilled]: (state, action) => {
			state.loading = "idle";
			state.entities[action.payload.dashboardID] = {
				...state.entities[action.payload.dashboardID],
				...action.payload,
			}; // payload is updated dashboard object (only changes and dashboardID)
		},
		[updateUserDashboard.rejected]: (state, action) => {
			state.loading = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
			state.currentRequestId = undefined;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentDashboardID } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;
