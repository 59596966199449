import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/**
 * Fetches all current user's dashboardTags
 */
export const fetchUserDashboardTags = createAsyncThunk(
	"dashboardTags/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(APIPATH.dashboardTagsGet);
			return res;
		} catch (err) {
			// console.log("fetchAccount FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** 
 * creates a dasboardtag, saving to cloud if indicated 
 * tag object shape is
* 	{
		"dashboardTagID": "722a7bfc-e3ba-4844-9446-fba78edfb03f",
* 		"metadata": {},
* 		"color": "default | waring | danger | success",
* 		"label": "ZComment",
* 		"type": "COMMENT" | "BUTTON"
* 	},
 * */
export const createUserDashboardTag = createAsyncThunk(
	"dashboardTag/create",
	async (dashboardTag, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const dashboardTagID = uuidV4();
			let newTag = {
				dashboardTagID,
				type: "BUTTON",
				...dashboardTag,
			};
			const res = await apigateway.post(APIPATH.dashboardTagCreate, newTag);
			return { ...res };
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

// /** deletes a user tag */
export const deleteUserDashboardTag = createAsyncThunk(
	"dashboardTag/delete",
	async (
		{ dashboardTagID },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			await apigateway.delete(`${APIPATH.dashboardTagDelete}${dashboardTagID}`);
			return dashboardTagID;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** 
 * updates a dashboardTag 
 * tab Object shape:
 * 	{
		"dashboardTagID": "722a7bfc-e3ba-4844-9446-fba78edfb03f",
 * 		"metadata": {},
 * 		"color": "default | waring | danger | success",
 * 		"label": "ZComment",
 * 		"type": "COMMENT" | "BUTTON"
 * 	}, 
 */
export const updateUserDashboardTag = createAsyncThunk(
	"dashboardTag/update",
	async (dashboardTag, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			// dashboardTag an object with dashboardTagID and any fields requiring updates
			await apigateway.post(APIPATH.dashboardTagUpdate, dashboardTag);
			return { ...dashboardTag };
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
