import React from "react";

import { ReactComponent as MoodleImage } from "assets/img/brands/moodle-logo.svg";
import { ReactComponent as ZoomImage } from "assets/img/brands/zoom-logo.svg";

export const MoodleLogo = () => (
	<MoodleImage
		className="img-fluid"
		style={{ backgroundColor: "white", padding: "5px", borderRadius: "4px" }}
	/>
);

export const ZoomLogo = () => <ZoomImage className="img-fluid" />;
