/**
 * Thunks for projects - folders, audio, video files
 * NOTE: in DynamoDB, folders, videos and autos are in the item table.
 *  NAMING CONVENTIONS IN DMIO
 * 	folder: is an item of mediaType FOLDER
 *  project: is an item of !mediaType FOLDER - ie, VIDEO, AUDIO
 * 	item: refers to any mediaType - ie, any item in the DynamoDB table item
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import _ from "lodash";
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchesProjects from API 
 * Response onject shape:
 * {
 * 		dateCreated: 1539300300802,
 * 		childItemIDList: [
 * 			"ecf10863-b9f4-49bd-9fc5-0ecac8f5b829",
 * 			...
 * 		],
 * 		folderID: "0",
 * 		ownerID: "714385a5-146e-40ff-89d9-c0e098e4fe2f",
 * 		dateUpdated: 1539300300802,
 * 		initials: "XN",
 * 		name: "XX1 Nofee",
 * 		email: "xx1@dreamaker.io"
}
*/
export const fetchFolder = createAsyncThunk(
	"projects/fetchFolder",
	async (folderID, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(`${APIPATH.folderGet}${folderID || 0}`);
			return {
				...getState().projects.entities,
				[folderID]: { ...res },
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchFolderChildren = createAsyncThunk(
	"projects/fetchFolderChildren",
	async (folderID, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(
				`${APIPATH.folderGetChildren}${folderID || 0}`
			);

			// REtrieve and delete childItems object
			const item = {
				...res,
			};
			const childItems = {
				...item.childItems,
			};

			delete item.childItems;

			return {
				...getState().projects.entities, // existing entities
				// updated/retrieved item
				[folderID]: {
					...getState().projects.entities[folderID],
					...item,
				},
				...childItems, // item's children, if any
			};
		} catch (err) {
			// console.log("fetchFolder FETCHING PROJECTS FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(err.response.data);
		}
	}
);

/** fetchesProjects from API 
 * Response onject shape:
 * {
 * 		dateCreated: 1539300300802,
 * 		childItemIDList: [
 * 			"ecf10863-b9f4-49bd-9fc5-0ecac8f5b829",
 * 			...
 * 		],
 * 		folderID: "0",
 * 		ownerID: "714385a5-146e-40ff-89d9-c0e098e4fe2f",
 * 		dateUpdated: 1539300300802,
 * 		initials: "XN",
 * 		name: "XX1 Nofee",
 * 		email: "xx1@dreamaker.io"
}
*/
export const fetchProject = createAsyncThunk(
	"projects/fetchProject",
	async (projectID, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(`${APIPATH.projectGet}${projectID}`);
			return {
				...getState().projects.entities,
				[projectID]: { ...res },
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(err.response.data);
		}
	}
);

/** creates a folder, audio, video inside folederID, If no folderID present, defaults to 0 */
export const createFolder = createAsyncThunk(
	"projects/createFolder",
	async ({ folderID }, { dispatch, getState, requestId, rejectWithValue }) => {
		const itemID = uuidV4();
		var newItem = {
			folderID,
			// folderID: "fweah", // invoke 400
			itemID,
			title: "New folder",
		};
		try {
			const res = await apigateway.post(APIPATH.folderCreate, newItem);

			/** NOTE: res contains usageData{} and new item{}:
			 * {
			 *    usageData: {...},
			 *    "abcID": {itemID: "abcID", ...}}
			 * */

			// Dispatch update to usage data & delete object
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			delete res.usageData;

			// Update parent folder
			const parentFolder = getState().projects.entities[folderID];

			const newParentFolder = {
				...parentFolder,
				folderItemsResult: [...parentFolder.folderItemsResult, itemID],
			};

			return {
				entities: {
					...getState().projects.entities,
					[folderID]: { ...newParentFolder },
					...res,
				},
				newItemID: itemID,
			};
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(err.response.data);
		}
	}
);

/** creates an audio or video inside folederID, If no folderID present, defaults to 0 */
export const createProject = createAsyncThunk(
	"projects/create",
	async (newProject, { dispatch, getState, requestId, rejectWithValue }) => {
		const { folderID, itemID } = newProject;
		try {
			const res = await apigateway.post(APIPATH.projectCreate, newProject);

			/** NOTE: res contains usageData{} and new item{}:
			 * {
			 *    usageData: {...},
			 *    "abcID": {itemID: "abcID", ...}}
			 * */

			// Dispatch update to usage data & delete object
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			delete res.usageData;

			// Update parent folder
			const parentFolder = getState().projects.entities[folderID];

			const newParentFolder = {
				...parentFolder,
				folderItemsResult: [...parentFolder.folderItemsResult, itemID],
			};

			return {
				entities: {
					...getState().projects.entities,
					[folderID]: { ...newParentFolder },
					...res,
				},
				newItemID: itemID,
			};
		} catch (err) {
			console.log(err);
			if (!err.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(err.response.data);
		}
	}
);

/** deletes a user project */
export const deleteItem = createAsyncThunk(
	"projects/deleteItem",
	async ({ itemID }, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.delete(
				`${APIPATH.itemDelete}${itemID}&deleteDependencies=true`
			);
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			return itemID;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

export const copyProject = createAsyncThunk(
	"projects/copy",
	async (itemID, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const { title, itemAnalysisIDs, folderID } =
				getState().projects.entities[itemID];
			const res = await apigateway.post(APIPATH.projectCopy, {
				itemID,
				title: `${title} - copy`,
				itemAnalysisIDs: [...itemAnalysisIDs],
			});
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			delete res.usageData;

			const newItemID = Object.keys(res)[0];

			// Update parent folder
			const parentFolder = getState().projects.entities[folderID];

			const newParentFolder = {
				...parentFolder,
				folderItemsResult: [...parentFolder.folderItemsResult, newItemID],
			};

			return {
				entities: {
					...getState().projects.entities,
					[folderID]: { ...newParentFolder },
					...res,
				},
				newItemID: newItemID,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
		//
	}
);

export const sendProject = createAsyncThunk(
	"projects/send",
	async (
		{ itemID, title, emails, itemAnalysisID },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			await apigateway.post(APIPATH.projectSend, {
				itemID,
				title: getState().projects.entities[itemID].title,
				emails,
				itemAnalysisIDs: [itemAnalysisID],
			});
			return;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
		//
	}
);

/** updates a folder, video, audio.
 * item contained an itemID and those key:value pairs which have been updated */
export const updateFolder = createAsyncThunk(
	"projects/updateFolder",
	async (item, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			await apigateway.post(APIPATH.folderUpdate, item);
			const updatedItem = {
				...getState().projects.entities[item.itemID],
				...item,
			};
			return updatedItem;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

/** updates a folder, video, audio.
 * item contained an itemID and those key:value pairs which have been updated */
export const updateProject = createAsyncThunk(
	"projects/update",
	async (item, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			await apigateway.post(APIPATH.projectUpdate, item);
			const updatedItem = {
				...getState().projects.entities[item.itemID],
				...item,
			};
			return updatedItem;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);
