import _ from "lodash";

import React, { useState } from "react";
import { connect } from "react-redux";
import {
	BigPlayButton,
	ClosedCaptionButton,
	ControlBar,
	CurrentTimeDisplay,
	// ForwardControl,
	LoadingSpinner,
	PlaybackRateMenuButton,
	Player,
	// ReplayControl,
	TimeDivider,
	VolumeMenuButton,
} from "video-react";

// import { URL_TRANSOUT, URL_TRANSOUT_SIGNED } from "config";

import { MediaSource } from "./MediaSource";
import { ScanControl } from "./ScanControl";

// import {
// 	mediaPlayerErrorHandler,
// 	resetSeekStartEnd,
// 	setPlayer,
// } from "../actions/mediaplayer";

import "video-react/dist/video-react.css";

// import {
// 	LOB_KEYS,
// 	PROGRESS_STATUS,
// 	TRANSCRIBE_LANGUAGES,
// } from "Constants/app.js";

const MediaPlayer = (props) => {
	// const {
	// 	mediaPlayerErrorHandler,
	// 	preferences: { scanBwd, scanFwd },
	// } = props;
	// let { startTime } = this.props.playerState;

	// pause = () => {
	// 	this.refs.player.pause();
	// 	// props received from analysis, we are telling the analysis state the player has paused/
	// 	this.props.pause();
	// };

	// play = () => {
	// 	this.refs.player.play();
	// 	// props received from analysis, we are telling the analysis state the player is currently playing.
	// 	this.props.play();
	// };

	// seek = seconds => {
	// 	seconds > 0 ? this.refs.player.seek(seconds) : '';
	// 	this.play();
	// };

	const [playerPaused, setPlayerPaused] = useState(true);

	const mediaSrc =
		"https://archive.org/download/ElephantsDream/ed_1024_512kb.mp4";

	return (
		<Player
			// crossOrigin={
			// 	"development" === process.env.NODE_ENV &&
			// 	"localhost" === window.location.hostname
			// 		? null
			// 		: "use-credentials"
			// }
			fluid={false}
			width="100%"
			height="100%"
			// style={{ minHeight: "150px" }}
			// playsInline={true}
			// ref="player"
			// preload="auto"
			// startTime={startTime}
			src={mediaSrc}
			// use-credentials={true}
			// style={{ minHeight: "150px" }}
		>
			<BigPlayButton position="center" />
			{/* <MediaSource
				// isVideoChild
				src={mediaSrc}
				// mediaPlayerErrorHandler={mediaPlayerErrorHandler}
			/> */}
			{/* {this.vttArr.length == 0
				? null
				: this.vttArr.map((vttFileName, i) => (
						<track
							default
							key={i}
							kind="captions"
							label={
								TRANSCRIBE_LANGUAGES[_.split(_.trimEnd(vttFileName, ".vtt"), "_")[1]]
							}
							src={this.baseURL + vttFileName}
							srcLang={_.split(_.trimEnd(vttFileName, ".vtt"), "_")[1]}
						/>
				  ))} */}
			<LoadingSpinner />
			<ControlBar autoHide={false}>
				<ScanControl
					order={1.1}
					// seconds={scanBwd}
					seconds={5}
					replay={true}
				/>
				<ScanControl
					order={1.2}
					// seconds={scanFwd}
					seconds={5}
				/>
				<CurrentTimeDisplay order={4.1} />
				<TimeDivider order={4.2} />
				<PlaybackRateMenuButton rates={[0.25, 0.5, 1, 1.5, 3, 5]} order={7.1} />
				<VolumeMenuButton vertical={true} />
				<ClosedCaptionButton order={7} />
			</ControlBar>
		</Player>
	);
};

// function mapStateToProps(state) {
// 	return {
// 		playerState: state.playerState,
// 		preferences: state.user.data.preferences,
// 		item: state.folderItems.selectedItem,
// 		vtts: state.folderItems.selectedVTTs,
// 	};
// }

// const mapDispatchToProps = {
// 	mediaPlayerErrorHandler,
// 	resetSeekStartEnd,
// 	setPlayer,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(MediaPlayer);

export default MediaPlayer;
