import React from "react";
import { Card, Table } from "react-bootstrap";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";
import OrganizationRow from "./OrganizationRow";

import useAppSelector from "hooks/useAppSelector";

import { INVITE_STATUS, UNLIMITED } from "constants/";
const { ACCEPTED, INVITED, LEFT } = INVITE_STATUS;

function OrganizationsInfo() {
	const {
		isOrganization,
		organizationID,
		organizations = {},
	} = useAppSelector((state) => state.account.entity);

	// Calculated variables
	let organizationsList = [];
	let nonDefaultOrganizations = [];

	Object.keys(organizations).some((orgID) => {
		const organization = organizations[orgID];
		const status = organization.status;
		const isDefaultOrganization = organizationID === orgID;

		/**
		 * Only show LEFT orgs if dateLeft within last 5 mins (300000	milliseconds)
		 */
		if (
			ACCEPTED === status ||
			INVITED === status ||
			(LEFT === status && new Date().getTime() - 300000 <= organization.dateLeft)
		) {
			let activeList = isDefaultOrganization
				? organizationsList
				: nonDefaultOrganizations;

			activeList.push(
				<OrganizationRow
					key={organizationID}
					isDefaultOrganization={isDefaultOrganization}
					isOrganization={isOrganization}
					organizationID={organizationID}
					organization={organization}
				/>
			);
		}
	});

	return (
		<Card>
			<Card.Header>
				<Card.Title className="mb-0">Your Organizations</Card.Title>
			</Card.Header>
			<Card.Body>
				{undefined === organizations ? (
					<PlaceholderGlow>
						<PlaceholderPrimary />
					</PlaceholderGlow>
				) : 0 === Object.keys(organizations).length ? (
					"You do not have any organizations."
				) : (
					<Table className="mb-0" responsive>
						<tbody>
							{organizationsList}
							{nonDefaultOrganizations}
						</tbody>
					</Table>
				)}
			</Card.Body>
		</Card>
	);
}

export default OrganizationsInfo;
