import AuthCardWrapper from "./AuthCardWrapper";
import SignIn from "./SignInForm";

const SignInPage = () => (
	<AuthCardWrapper
		helmet="Sign In"
		title="Welcome"
		subTitle="Sign in to get started."
	>
		<SignIn />
	</AuthCardWrapper>
);

export default SignInPage;
