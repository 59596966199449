import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Row } from "react-bootstrap";
import ChatNow from "components/ChatNow";
import ContactUs from "components/ContactUs";
import { PageLoader } from "components/loaders/";
import PlanCard from "./PlanCard";

import { fetchPlans } from "features/_/app.actions";
import { fetchBilling } from "features/billing/billing.actions";

const Plans = () => {
	const dispatch = useDispatch();
	const [billingPeriod, setBillingPeriod] = useState("MONTHLY"); // true is monthly

	const { paymentPeriod, planData, planType } = useSelector(
		(state) => state.account.entity
	);

	const { entity: userBilling } = useSelector((state) => state.billing.entity);
	const { plans } = useSelector((state) => state._);

	/** Fetch Plans */
	useEffect(() => {
		async function fetchData() {
			// fetchAccount called on app initialization
			if (!userBilling) await dispatch(fetchBilling());
			if (0 >= plans.length) await dispatch(fetchPlans());
		}
		fetchData();
	}, []);

	if (0 === plans.length || !planType) return <PageLoader />;

	// USER'S CURRENT PLAN
	const userPlan = {
		planID: planType,
		title: planData.title,
		paymentPeriod: paymentPeriod,
	};

	const selectedPeriodIsCurrentPeriod = userPlan.paymentPeriod === billingPeriod;

	// USER'S UPCOMING PLAN DETAILS
	const selectedPeriodIsUpcomingPeriod =
		userBilling?.upcomingInvoice?.paymentPeriod === billingPeriod;

	// undefined === upcomingInvoice.cancel_at_period_end when there is no upcomingInvoice or plan has been changed
	const hasUpcomingInvoice =
		true === userBilling?.upcomingInvoice?.cancel_at_period_end ||
		undefined === userBilling?.upcomingInvoice?.cancel_at_period_end
			? false
			: true;

	const upgradeCardMap = plans.map((plan, idx) => (
		<PlanCard
			idx={idx}
			key={idx}
			billingPeriod={billingPeriod}
			plan={plan}
			userPlan={userPlan}
			userBilling={userBilling}
			hasUpcomingInvoice={hasUpcomingInvoice}
			selectedPeriodIsCurrentPeriod={selectedPeriodIsCurrentPeriod}
			selectedPeriodIsUpcomingPeriod={selectedPeriodIsUpcomingPeriod}
		/>
	));

	const frequencyToggler = (
		<Form className="d-inline mb-3">
			<Form.Label className="d-inline me-2">Monthly </Form.Label>
			<Form.Check
				type="switch"
				id="frequencyToggler"
				className="d-inline"
				onChange={() => {
					setBillingPeriod("MONTHLY" === billingPeriod ? "YEARLY" : "MONTHLY");
				}}
			/>
			<Form.Label className="d-inline ms-2"> Yearly (save 10%)</Form.Label>
		</Form>
	);

	return (
		<Container>
			<Row className="text-center">
				<h1 className="mb-3">A Plan for Every Need</h1>
				<h4 className="mb-4">
					Level-up with the collaboration, storage and other awesome features!
				</h4>
				{frequencyToggler}
			</Row>
			<Row>{upgradeCardMap}</Row>
			<Row>
				<p>
					Interested in our <a>Enterprise Plans</a> or looking for something
					different?{" "}
					<ContactUs
						email="hello@dreamaker.io"
						subject="We're Upgrading and Looking for Something More"
					>
						Drop us an email
					</ContactUs>{" "}
					or <ChatNow />.
				</p>
				<p>
					Check out our <a>Frequently Asked Questions</a> for more information on all
					features.
				</p>
				<p>
					<em>
						<a>GET THE STUDENT DEAL </a>
					</em>{" "}
					Students save 30% on any plan, pay as little as $8.40 a month.{" "}
					<i>
						<ContactUs
							email="hello@dreamaker.io"
							subject="Sign me up for the 840 Student Deal!"
						>
							Email
						</ContactUs>
					</i>{" "}
					or{" "}
					<i>
						<ChatNow />
					</i>{" "}
					to get started!
				</p>
			</Row>
		</Container>
	);
};

export default Plans;
