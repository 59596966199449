import { Spinner } from "react-bootstrap";
import { CircleNotchSpinningIcon, ButtonLoadingIcon } from "components/icons";

export const LoadingSpinner = ({
	children,
	className,
	variant = "primary",
}) => (
	<>
		{/* <Spinner animation="border" size={size} role="status" variant={variant} /> */}
		<CircleNotchSpinningIcon variant={variant} className={className} size="2x" />
		<br />
		{children}
	</>
);
