import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Card, Placeholder, Table } from "react-bootstrap";
import { Button } from "components/buttons";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";

import { formatLocalDate, ucWords } from "utils/";
import { PLANID, ROUTES } from "constants/";

function PlanInfo() {
	const navigate = useNavigate();

	const { paymentPeriod, planData, planType, upgradeMe } = useSelector(
		(state) => state.account.entity
	);

	const {
		entity: { upcomingInvoice },
	} = useSelector((state) => state.billing);

	// if (!planData) return <PlaceholderGlow>{infoCard()}</PlaceholderGlow>;

	var invoiceDate, invoicePeriod;
	var title = planData?.title
		? `You're on the ${planData.title} Plan`
		: `Your Plan...`;

	if (undefined !== upcomingInvoice) {
		invoiceDate = formatLocalDate(upcomingInvoice.date * 1000, true);
		invoicePeriod = paymentPeriod;
	}

	return (
		<Card>
			<Card.Header>
				<Card.Title className="mb-0">{title}</Card.Title>
			</Card.Header>
			<Card.Body>
				{PLANID.TRIAL === planType ? (
					<p>Your trial will end on {invoiceDate}.</p>
				) : (
					<Table responsive>
						<tbody>
							{/* {tableRow("Plan", `${title} Plan`)} */}
							{tableRow("Payment Period", ucWords(invoicePeriod))}
							{tableRow("Next Payment Date", invoiceDate)}
						</tbody>
					</Table>
				)}
				<div className="d-flex justify-content-between">
					<Button onClick={() => navigate(ROUTES.UPGRADE)}>
						{upgradeMe ? "Upgrade Plan" : "Change Plan"}
					</Button>
					<Button
						variant="muted"
						onClick={() => navigate(`${ROUTES.ACCOUNT}/${ROUTES.BILLING}`)}
					>
						Cancel Plan
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
}

const tableRow = (title, value) => (
	<tr>
		<td className="w-50">{title}</td>
		<td>{value || <PlaceholderPrimary />}</td>
	</tr>
);

export default PlanInfo;
