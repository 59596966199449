import "./dashboards.scss";

import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

export const DashboardTagBtn = ({
	accept,
	addTagToDashboardOnHover,
	color,
	currentNumOfTags,
	dropFunction,
	id,
	index,
	label,
	moveTag,
	onClick,
	onDeleteClickHandler = false,
	type,
}) => {
	const [hover, setHover] = useState(false);

	const ref = useRef(null);

	const [{ handlerId }, drop] = useDrop({
		accept: accept,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (currentNumOfTags >= 8 && item.type === "searchTagBtn") {
				return;
			}
			if (!ref.current) {
				return;
			}
			const dragIndex =
				item.index > currentNumOfTags - 1 ? currentNumOfTags - 1 : item.index;
			const hoverIndex = index;
			if (item.type === "searchTagBtn") {
				addTagToDashboardOnHover(item.id, dragIndex);
			}

			if (dragIndex === hoverIndex) {
				// Don't replace items with themselves
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle. The higher the denominator, the more sensitive the dnd.
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Get horizontal middle. The higher the denominator, the more sensitive the dnd.
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Get pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			// Not sure if any of this is needed?
			const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
			const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
			const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
			const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;
			if (upwards && (leftwards || rightwards)) {
				return;
			}
			if (downwards && (leftwards || rightwards)) {
				return;
			}
			moveTag(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		drop(item, monitor) {
			if (item.type === "searchTagBtn" && currentNumOfTags < 8) {
				dropFunction(item.id);
			}
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type,
		item: () => {
			return { id, index, type };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging && type === "dashboardTagBtn" ? 0 : 1;

	drag(drop(ref));

	// const removeTagStyle = {
	// 	// only want to display if they are in the upper dashboardTags content, AND it's being hovered over AND it's not an empty tag, so it can be dragged.
	// 	display:
	// 		dndProps.tagLocation === "dashboardTags" &&
	// 		this.state.hover &&
	// 		dndProps.canBeDragged
	// 			? ""
	// 			: "none",
	// 	position: "absolute",
	// 	top: "-2px",
	// 	right: "4px",
	// 	fontSize: ".8rem",
	// };

	return (
		<li
			className={`btn btn-${color} m-2 dasboards-tag`}
			onClick={onClick}
			ref={ref}
			data-handler-id={handlerId}
			style={{ opacity, width: "150px" }}
			id={id}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{label}
			{onDeleteClickHandler ? (
				<span
					onClick={onDeleteClickHandler}
					style={{ visibility: hover ? "visible" : "hidden" }}
					className="dashboards-tag-remove"
					title="Remove from dashboard"
				>
					{"x"}
				</span>
			) : null}
		</li>
	);
};
