/**
 * Thunks for user
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import _ from "lodash";
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchesUser from API */
export const fetchUser = createAsyncThunk(
	"user/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			var res = await apigateway.get(APIPATH.userGet, { withCredentials: true });

			// set default preferences
			let preferences = {
				leadTime: 2,
				lagTime: 2,
				pauseOnTag: true,
				pauseOnType: true,
				scanFwd: 5,
				scanBwd: 5,
			};

			if (undefined === res.preferences) {
				res = { ...res, preferences };
			} else {
				res = {
					...res,
					preferences: {
						...preferences,
						...res.preferences,
					},
				};
			}
			return res;
		} catch (err) {
			// console.log("fetchUser FETCHING USER FAILED - ", err);
			if (!err.response) {
				return rejectWithValue(err);
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates a user project */
export const updateUser = createAsyncThunk(
	"user/update",
	async (user, { dispatch, getState, requestId, rejectWithValue }) => {
		delete user["submit"];
		// console.log("updateUser to ", user);
		try {
			await apigateway.post(APIPATH.userUpdate, user);
			return {
				...getState().user.entity,
				...user,
			};
		} catch (err) {
			if (!err.response) {
				rejectWithValue(err);
			}
			return rejectWithValue(err.response.data);
		}
	}
);
