import { Button } from "react-bootstrap";
import { ButtonLoadingIcon } from "components/icons";

export const SubmitButton = ({
	className,
	disabled,
	size = "",
	title,
	variant = "primary",
}) => (
	<Button
		type="submit"
		variant={variant}
		size={size}
		disabled={disabled}
		className={className}
	>
		{title}
		{disabled ? <ButtonLoadingIcon /> : null}
	</Button>
);
