import { MONTH, MONTHS_3AB, UNLIMITED } from "constants/";

/**
 * Format seconds as a time string, 11hrs 7min 30s
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} seconds Number of seconds to be turned into a string
 * @return {String} time formatted as 1hrs 1min 0s or 1min 33s
 * @function formatReadableTime
 */
export function formatReadableTime(seconds = 0) {
	//}, guide = seconds) {
	let s = Math.floor(seconds % 60);
	let m = Math.floor((seconds / 60) % 60);
	let h = Math.floor(seconds / 3600);

	// handle invalid times
	if (isNaN(seconds) || seconds === Infinity) {
		// '-' is false for all relational operators (e.g. <, >=) so this setting
		// will add the minimum number of fields specified by the guide
		h = m = s = "-";
	}

	// Hrs - only show if present
	h = h > 0 ? `${h}hr ` : "";

	return h + `${m}min ${s}s`;
}

/**
 * Format seconds as a time string, H:MM:SS or M:SS
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} seconds Number of seconds to be turned into a string
 * @param  {Number} guide   Number (in seconds) to model the string after
 * @param  {Boolean} addSuffix whether to add 'sec', 'mins', or 'hrs' to return
 * @return {String}         Time formatted as H:MM:SS or M:SS
 * @private
 * @function formatTime
 */
export function formatVideoTime(
	seconds = 0,
	guide = seconds,
	addSuffix = false
) {
	let s = Math.floor(seconds % 60);
	let m = Math.floor((seconds / 60) % 60);
	let h = Math.floor(seconds / 3600);
	const gm = Math.floor((guide / 60) % 60);
	const gh = Math.floor(guide / 3600);

	var suffix = seconds > 59 ? " min" : " sec";

	// handle invalid times
	if (isNaN(seconds) || seconds === Infinity) {
		// '-' is false for all relational operators (e.g. <, >=) so this setting
		// will add the minimum number of fields specified by the guide
		h = m = s = "-";
	}

	// Check if we need to show hours
	//   h = h > 0 || gh > 0 ? `${h}:` : "";
	if (h > 0 || gh > 0) {
		h = `${h}:`;
		suffix = " hrs";
	} else {
		h = "";
	}

	// If hours are showing, we may need to add a leading zero.
	// Always show at least one digit of minutes.
	m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

	// Check if leading zero is need for seconds
	s = s < 10 ? `0${s}` : s;

	return h + m + s + (addSuffix ? suffix : "");
}

/**
 * Formats a unix timestamp date to local time. if showYear is true, will also show the year.
 * @param {String} unixTs Unix timestamp
 * @param {Boolean} showYear If true returns four digit year
 */
export function formatLocalDate(unixTs = 0, showYear = false) {
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	const d = new Date(unixTs);
	var yr = d.getFullYear();
	const months = showYear ? MONTH : MONTHS_3AB;

	if (yr !== new Date().getFullYear() || showYear) {
		showYear ? (yr = `, ${yr}`) : (yr = `, '${yr.toString().substr(2, 4)}`);
	} else yr = ", " + d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2); //slice takes rightmost two chars so 012 = 12 but 03 = 03.

	return `${days[d.getDay()]} ${d.getDate()} ${months[d.getMonth()]}${yr}`;
}
