import AuthCardWrapper from "./AuthCardWrapper";
import SignUp from "./SignUpForm";

const SignUpPage = () => (
	<AuthCardWrapper helmet="Sign Up" title="Sign up to get started">
		<SignUp />
	</AuthCardWrapper>
);

export default SignUpPage;
