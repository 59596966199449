import "./projects.scss";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDrag, useDrop } from "react-dnd";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext } from "contexts/ModalContext";

import {
	Badge,
	ProgressBar,
	Card,
	Col,
	Form,
	ListGroup,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import ProjectSettingsDropdown from "./ProjectSettingsDropdown";
import {
	LockIcon,
	ProjectsIcon,
	ProjectsAudioIcon,
	ShieldCheckIcon,
} from "components/icons";

import { ownerNameStr } from "utils/";
import { ROUTES } from "constants/";
import { URL } from "config/";

import Avatar from "components/Avatar";

import { updateFolder } from "features/projects/projects.actions";
import {
	removeProjectChild,
	setCurrentEntityID as setCurrentFolderID,
} from "features/projects/projects.slice";

import { formatLocalDate, formatVideoTime } from "utils/";

import videoPlaceholderImg from "assets/img/placeholders/video-thumbnail.jpg";

const ZoomProjectThumb = ({ item, userID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const ref = useRef(null);

	const { currentEntityID: currentItemID } = useSelector(
		(state) => state.projects
	);

	const [hover, setHover] = useState(false);
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const [isFolder, setIsFolder] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [projectTitle, setProjectTitle] = useState();
	const [projectThumbnail, setProjectThumbnail] = useState();

	// const isClickable =
	// 	"COMPLETED" === transcodeProgress || "FOLDER" === mediaType; //Boolean value based on being a folder or transcoding
	const isClickable = true; //Replace this with the above once transcoding is implemented

	// console.log("==Project Card==");
	// console.log(`${item.title} - `, item.itemID);
	// // console.log("isEditingTitle - ", isEditingTitle);
	// // console.log("isOwner - ", isOwner);
	// // console.log("userID - ", userID);
	// // console.log("ownerID - ", item.ownerID);
	// console.log("isOwner - ", isOwner);
	// console.log("isFolder - ", isFolder);
	// console.log("");

	// useEffect set onMount
	useEffect(() => {
		const {
			itemID,
			mediaType,
			ownerID,
			thumbnailURL,
			thumbnailSignedURL,
			title,
		} = item;

		setIsSelected(currentItemID === itemID ? true : false);

		// TODO: set isAnalysisOwner

		setIsOwner(userID === ownerID);

		// MediaType & Thumbnail
		switch (mediaType) {
			case "FOLDER":
				setIsFolder(true);
				setProjectThumbnail(
					<div className="card-img-top project-card-thumbnail text-center align-self-middle">
						<ProjectsIcon fixedWidth={true} />
					</div>
				);
				break;
			case "AUDIO":
				setProjectThumbnail(
					<div className="card-img-top project-card-thumbnail text-center align-self-middle">
						<ProjectsAudioIcon fixedWidth={true} />
					</div>
				);
				break;
			default:
				setIsVideo(true);
				const src =
					"development" === process.env.NODE_ENV
						? videoPlaceholderImg
						: thumbnailSignedURL
						? thumbnailSignedURL
						: `${URL.TRANSOUT}${ownerID}/${itemID}/${thumbnailURL}`;
				setProjectThumbnail(
					<Card.Img
						variant="top"
						src={src}
						alt={title}
						className="project-card-thumbnail"
					/>
				);
		}
		setProjectTitle(title);
	}, []);

	useEffect(() => {
		setIsSelected(currentItemID === item.itemID ? true : false);
	}, [currentItemID]);

	useEffect(() => {
		setIsOwner(userID === item.ownerID);
	}, [userID, item.ownerID]);

	useEffect(() => {
		if (document.getElementById("titleInput")) {
			document.getElementById("titleInput").focus();
		}
	}, [isEditingTitle]);

	const openProject = () => {
		const { itemID, mediaType, itemAnalyses } = item;
		// console.log(`${mediaType} itemID: ${itemID} - `, itemAnalyses);
		if (mediaType === "FOLDER") {
			navigate(`${ROUTES.PROJECTS}/${itemID}`);
		} else {
			navigate(`${ROUTES.ANALYSIS}/${itemAnalyses[0].itemAnalysisID}`);
		}
	};

	const updateFolderTitle = async () => {
		setIsEditingTitle(false);
		const { itemID, title } = item;

		if ("" === projectTitle.trim()) {
			setProjectTitle(title);
			return;
		}
		if (projectTitle !== title) {
			const res = await projectUpdate(itemID, "title", projectTitle);
			if (res.error) {
				console.log("updateUserProject Error: ", res.error);
				notify.error(res.error);
				return;
			}
		}
	};

	const updateFolderBoolean = async (key) => {
		const value = item[key] || false;
		const res = await projectUpdate(item.itemID, key, !value);
		if (res.error) {
			notify.error(res.error);
			return;
		}
	};

	const projectUpdate = async (itemID, key, value) => {
		const updatedItem = {
			itemID,
			[key]: value,
		};
		return await dispatch(updateFolder(updatedItem));
	};

	const isDragging = false;
	const isHovering = false;

	return (
		<Col className="project-card-col" style={{ opacity: isDragging ? 0 : 1 }}>
			<Card
				onClick={() => dispatch(setCurrentFolderID(item.itemID))}
				onDoubleClick={(e) => openProject(item.itemID, item.mediaType)}
				className="project-card"
				border={isHovering ? "success" : isSelected ? "primary" : "muted"}
			>
				<div className="project-card-dropdown">
					<ProjectSettingsDropdown
						isFolder={isFolder}
						isOwner={isOwner}
						item={item}
						onBooleanClick={(key) => updateFolderBoolean(key)}
						onRenameClick={() => setIsEditingTitle(true)}
						onOpenClick={() => openProject()}
					/>
				</div>

				{projectThumbnail}

				<div className="project-card-overlay">
					{!isFolder ? (
						<div className="duration">{formatVideoTime(item.durationSeconds)}</div>
					) : null}

					{item.locked ? <LockIcon className="icons" variant="primary" /> : null}

					{item.confidential ? (
						<ShieldCheckIcon className="icons" variant="primary" />
					) : null}
				</div>

				<Card.Header className="px-3 pt-2 project-card-header">
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={<Tooltip id="button-tooltip">{projectTitle}</Tooltip>}
					>
						<Card.Title
							className="fs-truncate"
							style={{ width: "200px" }}
							onClick={isClickable ? () => setIsEditingTitle(true) : null}
						>
							{isEditingTitle === false ? (
								projectTitle
							) : (
								<Form.Control
									type="text"
									id="titleInput"
									defaultValue={projectTitle}
									onChange={(e) => setProjectTitle(e.target.value)}
									onBlur={() => updateFolderTitle()}
									onKeyDown={(e) =>
										e.code === "Enter" || e.code === "NumpadEnter"
											? updateFolderTitle(item)
											: null
									}
								></Form.Control>
							)}
						</Card.Title>
					</OverlayTrigger>
					<Card.Subtitle className="text-muted">
						{/* <Avatar size="md" initials={item.initials} /> */}
						{/* {ownerNameStr(item.name)} */}
						<span className="px-2">&#8226;</span>
						{formatLocalDate(item.dateCreated)}
					</Card.Subtitle>
				</Card.Header>
			</Card>
		</Col>
	);
};

export default ZoomProjectThumb;
