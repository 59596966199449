import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import { fetchBilling } from "features/billing/billing.actions";
import { fetchPlans } from "features/_/app.actions";

import UpcomingPayments from "./billing/UpcomingPayments";
import CreditCardInfo from "./billing/CreditCardInfo";

export function Billing() {
	const dispatch = useDispatch();

	const { card } = useSelector((state) => state.billing.entity);
	const { plans } = useSelector((state) => state._);

	useEffect(() => {
		async function fetchData() {
			// fetchBilling()) called in Account.js
			if (!0 >= plans.length) await dispatch(fetchPlans());
		}
		fetchData();
	}, []);

	return (
		<React.Fragment>
			<Container fluid className="p-0">
				<Row>
					<Col xl="6">
						<CreditCardInfo />
					</Col>
					<Col xl="5">
						<UpcomingPayments />
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}
