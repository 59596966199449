import React from "react";
import { Helmet } from "react-helmet-async";
import Plans from "./Plans";

const Upgrade = () => (
	<React.Fragment>
		<Helmet title="Upgrade" />
		<Plans />
	</React.Fragment>
);

export default Upgrade;
