import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";

import useAppSelector from "hooks/useAppSelector";

import { INVITE_STATUS, UNLIMITED } from "constants/";
const { ACCEPTED, INVITED, LEFT } = INVITE_STATUS;

function OrganizationRow({
	isDefaultOrganization,
	isOrganization,
	organization,
	organizationID,
}) {
	const [loading, setLoading] = useState(false);
	const [organizationMessage, setOrganizationMessage] = useState(false);

	const { organizationAtDomain, status } = organization;

	// Calculated variables
	const organisationRole =
		isOrganization && isDefaultOrganization ? "owner" : "a member";

	switch (status) {
		case INVITED:
			var statusString = (
				<p>
					You've been invited to join <b>{organizationAtDomain}</b> organization
				</p>
			);
			break;
		case LEFT:
			var statusString = (
				<p>
					You have left the <b>{organizationAtDomain}</b> organization
				</p>
			);
			break;
		default: {
			var statusString = (
				<p>
					You're {organisationRole} of the <b>{organizationAtDomain}</b> organization
					{isDefaultOrganization ? (
						<i
							className="fas fa-check-double text-primary"
							style={{ paddingLeft: ".5rem" }}
						/>
					) : null}
				</p>
			);
			break;
		}
	}

	return (
		<tr>
			<td className="w-90">{statusString}</td>
			<td className="align-top">
				{" "}
				{LEFT !== status && !isOrganization ? (
					<span>Drop</span>
				) : // <OrganizationSettingsDropdown
				// 	isDefaultOrganization={isDefaultOrganization}
				// 	organizationID={organizationID}
				// 	status={status}
				// />
				null}
			</td>
		</tr>
	);
}

export default OrganizationRow;
