import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Collapse, Form } from "react-bootstrap";
import { AuthSubmitButton } from "components/buttons/";

import useAuth from "hooks/useAuth";
import NotifyContext from "contexts/NotifyContext";
import { ROUTES } from "constants/";

function ForgotPassword() {
	const navigate = useNavigate();
	const { forgotPassword, forgotPasswordSubmit, resendVerificationCode } =
		useAuth();
	const [showAllFields, setShowAllFields] = useState(false);
	const [successfulMessage, setSuccessfulMessage] = useState(false);

	let initialValues = {
		email: "",
		submit: false,
	};

	let validationSchema = {
		email: Yup.string()
			.email("Must be a valid email")
			.max(255)
			.required("Email is required"),
	};

	if (showAllFields) {
		initialValues = {
			email: "",
			verificationCode: "",
			password: "",
			confirmPassword: "",
			submit: false,
		};

		validationSchema = {
			email: Yup.string()
				.email("Must be a valid email")
				.max(255)
				.required("Email is required"),
			verificationCode: Yup.string()
				.max(255)
				.required("Oops, verification code required."),
			password: Yup.string()
				.min(8, "Must be at least 8 characters")
				.max(255)
				.required("Required"),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.required("Required"),
		};
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape(validationSchema)}
			onSubmit={async (
				values,
				{ resetForm, setErrors, setStatus, setSubmitting }
			) => {
				if (false === showAllFields) {
					await forgotPassword(values.email)
						.then((res) => {
							setShowAllFields(true);
							setSuccessfulMessage(
								`A verification code has been sent to ${values.email}.`
							);
							setSubmitting(false);
						})
						.catch((error) => {
							const message = error.message || "Something went wrong";
							setStatus({ success: false });
							setErrors({ submit: message });
							setSubmitting(false);
						});
				} else {
					await forgotPasswordSubmit(values)
						.then((res) => {
							setSuccessfulMessage(
								<span>
									Password successfully updated, <Link to={ROUTES.SIGNIN}>sign in!</Link>
								</span>
							);
							setShowAllFields(false);
							resetForm();
						})
						.catch((error) => {
							setSuccessfulMessage(false);
							const message = error.message || "Something went wrong";
							setStatus({ success: false });
							setErrors({ submit: message });
							setSubmitting(false);
						});
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<div
						className={successfulMessage ? "text-success" : "text-danger"}
						style={{ minHheight: "2em" }}
					>
						{errors.submit ? errors.submit : successfulMessage}
					</div>

					<Form.Group className="mb-3">
						<Form.Label>Email address</Form.Label>
						<Form.Control
							disabled={showAllFields}
							type="email"
							name="email"
							placeholder="Email address"
							value={values.email}
							isInvalid={Boolean(touched.email && errors.email)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.email && (
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<Collapse in={showAllFields} className="mb-0">
						<div className="hello">
							<Form.Group className="mb-3">
								<Form.Label>Verification Code</Form.Label>
								<Form.Control
									type="text"
									name="verificationCode"
									placeholder="Verification Code"
									value={values.verificationCode}
									isInvalid={Boolean(
										touched.verificationCode && errors.verificationCode
									)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.verificationCode && (
									<Form.Control.Feedback type="invalid">
										{errors.verificationCode}
									</Form.Control.Feedback>
								)}
								<small>
									<a
										href=""
										onClick={(e) => {
											e.preventDefault();
											setShowAllFields(false);
											setSuccessfulMessage(false);
										}}
									>
										Resend code
									</a>
								</small>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									name="password"
									placeholder="New Password"
									value={values.password}
									isInvalid={Boolean(touched.password && errors.password)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.password && (
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								)}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									name="confirmPassword"
									placeholder="Confirm New Password"
									value={values.confirmPassword}
									isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.confirmPassword && (
									<Form.Control.Feedback type="invalid">
										{errors.confirmPassword}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</div>
					</Collapse>

					<div className="text-center mt-3">
						<AuthSubmitButton title="Reset Password" disabled={isSubmitting} />
						<p>
							Here by mistake? <Link to={ROUTES.SIGNIN}>Sign In.</Link>
						</p>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default ForgotPassword;
