import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

// Handles Redirects & server side searchPrams variables
function Redirect({ children }) {
	const [searchParams] = useSearchParams();

	const redirect = searchParams.get("redirect"); // redirect user
	// const organizationID = searchParams.get("organizationID"); // TODO:
	// const ltiResultIdentifier = searchParams.get("ltiResultIdentifier"); // TODO:

	// Handle redirect parameter if present
	const to = redirect || false;

	// console.log("redirect: ", redirect);
	// console.log("organizationID: ", organizationID);
	// console.log("ltiResultIdentifier: ", ltiResultIdentifier);
	// console.log("location: ", location);
	// console.log("to: ", to);
	// console.log("---");

	/**
	 * Sets LTI variables.
	 * ltiResultIdentifier
	 * ltiConsumerID - moodle.nownow.co.za
	 * ltiConsumerName
	 * ltiUserID
	 * ltiUserEmail
	 * ltiCourseID
	 * ltiCourseTitle
	 * ltiAssignmentID
	 * ltiAssignmentTitle
	 */
	const setLTIVariables = (values) => {
		// TODO: Differentiate a student from a teach
		const ltiMap = {
			ltiAssignmentID: values.ltiAssignmentID,
			ltiAssignmentTitle: values.ltiAssignmentTitle,
			ltiConsumerID: values.ltiConsumerID,
			ltiCourseID: values.ltiCourseID,
			ltiCourseTitle: values.ltiCourseTitle,
			ltiConsumerName: values.ltiConsumerName,
			ltiConsumerProduct: values.ltiConsumerProduct,
			ltiResultIdentifier: values.ltiResultIdentifier,
			ltiRoles: JSON.parse(values.ltiRoles),
			ltiUserFullname: values.ltiUserFullname,
			ltiUserEmail: values.ltiUserEmail,
			ltiUserID: values.ltiUserID,
		};
		// this.props.setUnderscoreVar( 'lti', ltiMap);
	};

	if (to) return <Navigate to={to} />;

	return <React.Fragment>{children}</React.Fragment>;
}

export default Redirect;
