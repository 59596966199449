import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropzone from "react-dropzone";

import { Card, Col } from "react-bootstrap";
import { UploadIcon, ZoomVideoIcon } from "components/icons";

const ProjectUploadImporter = ({ onZoomImportClick, uploadFile }) => {
	const renderCard = (isUpload, rootProps, input) => {
		return (
			<Card
				className="text-center project-card upload"
				border="muted"
				{...rootProps}
			>
				{input}
				<Card.Body className="py-2">
					<div className="project-card-upload">
						{isUpload ? (
							<UploadIcon fixedWidth={true} />
						) : (
							<ZoomVideoIcon fixedWidth={true} />
						)}
					</div>

					<Card.Text className="pt-1">
						{isUpload ? "Upload: drop or select file" : "Import from Zoom"}
					</Card.Text>
				</Card.Body>
			</Card>
		);
	};

	return (
		<Col className="project-card-col">
			<Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
				{({
					getRootProps,
					getInputProps,
					isDragActive,
					isDragAccept,
					isDragReject,
				}) => {
					const dragClass = isDragAccept ? "dragover" : isDragReject ? "" : "";
					return renderCard(
						true,
						{
							...getRootProps({
								className: `text-center project-card upload ${dragClass}`,
							}),
						},
						<input {...getInputProps()} />
					);
				}}
			</Dropzone>
			<span onClick={onZoomImportClick}>{renderCard(false)}</span>
		</Col>
	);
};

export default ProjectUploadImporter;
