/**
 * Thunks for account LTI consumer integration
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/**
 * Adds an ltiConsumer to an organization. Returns Promise,
 * @param {String} ltiConsumerID ltiConsumerID to add
 */
export const ltiAddConsumer = createAsyncThunk(
	"account/ltiAddConsumer",
	async (ltiConsumerID, { getState, dispatch, requestId, rejectWithValue }) => {
		try {
			var res = await apigateway.post(APIPATH.ltiConsumerAdd, { ltiConsumerID });
			const newAccountObj = getState().account.entity;
			return {
				...newAccountObj,
				ltiOrganizationConsumers: {
					...newAccountObj.ltiOrganizationConsumers,
					[ltiConsumerID]: {
						ltiConsumerSecret: res.ltiConsumerSecret,
					},
				},
			};
		} catch (err) {
			if (!err.response) {
				// throw err;
				return rejectWithValue(err);
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const ltiResetConsumerKey = createAsyncThunk(
	"account/ltiResetConsumerKey",
	async (
		{ ltiConsumerID, rollConsumerSecret = true },
		{ getState, dispatch, requestId, rejectWithValue }
	) => {
		// console.log("ltiConsumerID - ", ltiConsumerID);
		// console.log("rollConsumerSecret - ", rollConsumerSecret);
		try {
			var res = await apigateway.post(APIPATH.ltiConsumerReset, {
				ltiConsumerID,
				rollConsumerSecret,
			});
			const newAccountObj = getState().account.entity;
			return {
				...newAccountObj,
				ltiOrganizationConsumers: {
					...newAccountObj.ltiOrganizationConsumers,
					[ltiConsumerID]: {
						...newAccountObj.ltiOrganizationConsumers[ltiConsumerID],
						ltiConsumerSecret: res.ltiConsumerSecret,
					},
				},
			};
		} catch (err) {
			if (!err.response) {
				// throw err;
				return rejectWithValue(err);
			}
			return rejectWithValue(err.response.data);
		}
	}
);

// export function updateLTIConsumer(ltiConsumerID, rollConsumerSecret = true) {
// 	return function (dispatch, getState) {
// 		const requestObj = {
// 			method: "POST",
// 			url: `${apiConfig.ltiConsumerUpdate}`,
// 			data: {
// 				ltiConsumerID,
// 				rollConsumerSecret,
// 			},
// 		};

// 		return request(requestObj, LTI_ERROR)
// 			.then((resp) => {
// 				const account = getState().account;
// 				dispatch({
// 					type: ACCOUNT_REHYDRATE,
// 					payload: {
// 						...account,
// 						ltiOrganizationConsumers: {
// 							...account.ltiOrganizationConsumers,
// 							[ltiConsumerID]: {
// 								...account.ltiOrganizationConsumers[ltiConsumerID],
// 								ltiConsumerSecret: resp.ltiConsumerSecret,
// 							},
// 						},
// 					},
// 				});
// 				return Promise.resolve();
// 			})
// 			.catch((err) => {
// 				return Promise.reject();
// 			});
// 	};
// }
