import { v4 as uuidV4 } from "uuid";
import React, { useState, useEffect } from "react";

import { ListGroup, ListGroupItem, Form } from "react-bootstrap";
import { Button } from "components/buttons";

export const MarkerOptions = ({ onChange, options = {} }) => {
	const [currentOptions, setCurrentOptions] = useState({});
	const [newOptionName, setNewOptionName] = useState("");

	// console.log(`options: `, options);
	// console.log(`currentOptions: `, currentOptions);
	// console.log(`newOptionName: `, newOptionName);

	// var optionsList;

	useEffect(() => {
		setCurrentOptions(options);
	}, []);

	/** Markers: options change again */
	const onOptionChange = (action, optionID, optionName) => {
		optionName = optionName.trim();

		// ignore blank option names
		if (action !== "remove" && ("" === optionName || undefined === optionName)) {
			optionID ? setCurrentOptions(...currentOptions) : setNewOptionName("");

			return;
		}

		switch (action) {
			case "add":
				var newOptions = {
					...currentOptions,
					[uuidV4()]: optionName,
				};
				setNewOptionName("");
				break;

			case "update":
				var newOptions = {
					...currentOptions,
					[optionID]: optionName,
				};
				break;

			//action === "remove"
			default:
				var newOptions = {
					...currentOptions,
				};
				delete newOptions[optionID];
				break;
		}

		setCurrentOptions(newOptions);
		// debugger;
		onChange(newOptions);
	};

	const optionsList = Object.keys(currentOptions).map((optionID) => (
		<ListGroupItem key={optionID} style={{ backgroundColor: "transparent" }}>
			<Form.Control
				type="text"
				defaultValue={currentOptions[optionID]}
				maxLength="22"
				className="w-25 d-inline"
				onBlur={(e) => onOptionChange("update", optionID, e.target.value)}
				onKeyDown={(e) =>
					e.code === "Enter" || e.code === "NumpadEnter"
						? onOptionChange("update", optionID, e.target.value)
						: null
				}
			/>
			<Button
				variant="link"
				className="d-inline text-danger"
				onClick={() => onOptionChange("remove", optionID)}
			>
				X
			</Button>
		</ListGroupItem>
	));

	return (
		<>
			<h4 className="pt-3 ps-3">Options</h4>
			<ListGroup variant="flush">
				{optionsList}
				<ListGroupItem
					className="d-inline"
					style={{ backgroundColor: "transparent" }}
				>
					<Form.Control
						type="text"
						id="addOptionInput"
						placeholder="New Option"
						value={newOptionName}
						maxLength="22"
						className="w-25 d-inline"
						onChange={(e) => setNewOptionName(e.target.value)}
						onBlur={(e) => onOptionChange("add", null, e.target.value)}
						onKeyDown={(e) =>
							e.code === "Enter" || e.code === "NumpadEnter"
								? onOptionChange("add", null, e.target.value)
								: null
						}
					/>
				</ListGroupItem>
				<ListGroupItem style={{ backgroundColor: "transparent" }}>
					<Button onClick={() => onOptionChange("add")}>Add Option</Button>
				</ListGroupItem>
			</ListGroup>
		</>
	);
};
