import { v4 as uuidV4 } from "uuid";
import update from "immutability-helper";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Col, Form, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders";
import { DashboardTagBtn } from "./DashboardTagBtn";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import {
	createUserDashboardTag,
	fetchUserDashboardTags,
} from "features/dashboardTags/dashboardTags.actions";
import { setCurrentDashboardTagID } from "features/dashboardTags/dashboardTags.slice";

const TagsBank = () => {
	const dispatch = useDispatch();
	const { closeModal, openModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { entities: dashboardEntities, currentEntityID: currentDashboardID } =
		useSelector((state) => state.dashboards);

	const { entities: dashboardTags, currentEntityID: currentTagID } = useSelector(
		(state) => state.dashboardTags
	);

	const [loading, setLoading] = useState(true);
	const [currentSearch, setCurrentSearch] = useState("");
	const [tagsMatchingSearch, setTagsMatchingSearch] = useState([]); //Array of actual tag objects

	// useEffect to load dashbaordTags if not alreayd loaded
	useEffect(() => {
		async function fetchData() {
			await dispatch(fetchUserDashboardTags());
			setLoading(false);
		}
		null === dashboardTags ? fetchData() : setLoading(false);
	}, []);

	const handleTagOnClick = async (tagID) => {
		await dispatch(setCurrentDashboardTagID(tagID));
	};

	//Updates list of tags matching search input whenever search input changes or any tags are updated
	useEffect(() => {
		// console.log("Running setTagsMatchingSearch useEffect");
		let tagStagingArr = [];
		let stringLength = currentSearch.length;
		for (let tag in dashboardTags) {
			if (
				currentSearch ===
				dashboardTags[tag].label.toLowerCase().slice(0, stringLength)
			) {
				tagStagingArr.push(dashboardTags[tag]);
			}
		}
		tagStagingArr.sort((a, b) => a.label.localeCompare(b.label));
		setTagsMatchingSearch(tagStagingArr);
	}, [dashboardTags, currentSearch]);

	const handleSearchOnChange = (e) => {
		setCurrentSearch(e.target.value.toLowerCase());
	};

	const moveTag = useCallback((dragIndex, hoverIndex, item) => {
		console.log("Shouldn't be here just yet...");
		setTagsMatchingSearch((prevTags) =>
			update(prevTags, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevTags[dragIndex]],
				],
			})
		);
	}, []);

	const tagsMatchingSearchButtons = tagsMatchingSearch.map((tag, index) => (
		<DashboardTagBtn
			accept="none"
			key={tag.dashboardTagID}
			id={tag.dashboardTagID}
			moveTag={moveTag}
			index={index}
			color={tag.color}
			label={tag.label}
			onClick={() => handleTagOnClick(tag.dashboardTagID)}
			type="searchTagBtn"
		/>
	));

	const onCreateTagClick = () => {
		openModal(MODAL_TYPES.CREATE, {
			confirmBtnAction: async ({ label, color }) => {
				let newDashboardTag = {
					color,
					label,
				};
				const res = await dispatch(createUserDashboardTag(newDashboardTag));
				if (res.error) {
					notify.error(res.error);
					return;
				}
				notify.success("Dashboard successfully created!");
				closeModal();
			},
			confirmBtnClasses: "btn-success",
			confirmBtnTitle: "Create Tag",
			title: "Create New Tag",
			createType: "Tag",
		});
	};

	return (
		<React.Fragment>
			<Row>
				<Col>
					<h4 className="py-2 ">Available Dashboard Tags</h4>
				</Col>
				<Col>
					<Form.Control
						type="text"
						className="m-0"
						placeholder="Search tags..."
						onChange={handleSearchOnChange}
					/>
				</Col>
			</Row>
			<Row
				className="d-inline-flex flex-wrap justify-content-center align-items-start"
				onClick={() => {
					// dispatch(setCurrentDashboardTagID(undefined));
				}}
			>
				{loading ? (
					<LoadingSpinner />
				) : currentDashboardID === undefined ||
				  dashboardEntities[currentDashboardID] === undefined ? (
					<div className="d-inline-flex flex-wrap justify-content-center align-items-start">
						<ul className="mb-0 overflow-auto">
							<li
								className="btn mx-2 mt-1 border"
								onClick={onCreateTagClick}
								style={{ width: "150px", color: "white" }}
							>
								CREATE NEW TAG
							</li>
							<DndProvider backend={HTML5Backend}>
								{tagsMatchingSearchButtons}
							</DndProvider>
						</ul>
					</div>
				) : (
					<ul className="mb-0 overflow-auto">
						<li
							className="btn mx-2 mt-1 border"
							onClick={onCreateTagClick}
							style={{ width: "150px", color: "white" }}
						>
							CREATE NEW TAG
						</li>
						<DndProvider backend={HTML5Backend}>
							{tagsMatchingSearchButtons}
						</DndProvider>
					</ul>
				)}
			</Row>
		</React.Fragment>
	);
};

export default TagsBank;
