export const eventTagsData = {
	"5067ec75-51a8-47c4-b991-0beb17f73998": {
		dateCreated: 1649110821190,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "9bf842d7-3fbb-4b85-9dff-74ff9bde5ba3",
		mediaTimestamp: 14.016059,
		eventTagID: "5067ec75-51a8-47c4-b991-0beb17f73998",
		sliceEnd: 16.016059,
		sliceStart: 12.016059,
		playlist: true,
		dateUpdated: 1650034538133,
		markers: {
			"a6ac5b3c-d674-4ba2-83f5-349eb4ef65cb":
				"c0aaffbf-6c7e-4491-b972-6fa936dede60",
		},
		title: "ET Obi Wan",
	},
	"f7d2ccab-d7aa-4127-8ec1-8157b6029f4e": {
		dateCreated: 1649110638482,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "53ad8e97-2128-4556-bc6f-6d1896eeb4d1",
		mediaTimestamp: 0,
		eventTagID: "f7d2ccab-d7aa-4127-8ec1-8157b6029f4e",
		sliceEnd: 2,
		sliceStart: 0.001,
		comment: "Closet comment",
		playlist: true,
		dateUpdated: 1650034455544,
		markers: {},
		title: "ET Vader",
	},
	"3f0bc39e-dff6-4e28-8a95-1b2447e0b3e9": {
		dateCreated: 1649110818891,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "ce680126-6695-4302-bcc0-48b25f509b35",
		mediaTimestamp: 11.500223,
		eventTagID: "3f0bc39e-dff6-4e28-8a95-1b2447e0b3e9",
		sliceEnd: 13.500223,
		sliceStart: 9.500223,
		playlist: false,
		dateUpdated: 1650034483067,
		markers: {
			"c638afff-7482-4197-ab9f-5a41ba26f6d1":
				"0dbee609-d383-40db-80fb-6ac21191648b",
		},
		title: "ET Chewy",
	},
	"d4c14b06-242c-4589-bf3e-94188dce768c": {
		dateCreated: 1649110764323,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "3aeb6015-557a-4d5a-a545-98794df4009b",
		mediaTimestamp: 10.516211,
		eventTagID: "d4c14b06-242c-4589-bf3e-94188dce768c",
		sliceEnd: 12.516211,
		sliceStart: 8.516211,
		comment: "Living room comment",
		playlist: true,
		dateUpdated: 1650034472631,
		markers: {
			"a6ac5b3c-d674-4ba2-83f5-349eb4ef65cb":
				"ce8d64e8-a829-4371-80e7-965a17eecbbe",
		},
		title: "ET Leia",
		replies: {
			"9b801b90-604d-4915-8a3e-85796c79f102": {
				eventTagID: "d4c14b06-242c-4589-bf3e-94188dce768c",
				dateCreated: 1650034389992,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "Couch reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034389992,
				eventTagReplyID: "9b801b90-604d-4915-8a3e-85796c79f102",
			},
			"82465777-5cec-4d82-bc97-9ff389e6f6f9": {
				eventTagID: "d4c14b06-242c-4589-bf3e-94188dce768c",
				dateCreated: 1650034397797,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "Recliner reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034397797,
				eventTagReplyID: "82465777-5cec-4d82-bc97-9ff389e6f6f9",
			},
			"8ed54eb2-a5fb-45b5-adf8-729b7429e455": {
				eventTagID: "d4c14b06-242c-4589-bf3e-94188dce768c",
				dateCreated: 1650034393614,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "Television reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034393614,
				eventTagReplyID: "8ed54eb2-a5fb-45b5-adf8-729b7429e455",
			},
		},
	},
	"53ed4f07-ad67-4194-8e0d-232fa177b29e": {
		dateCreated: 1649110762295,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "702cbb5c-3d7a-45dc-9114-7ffd0be2ba42",
		mediaTimestamp: 10.516211,
		eventTagID: "53ed4f07-ad67-4194-8e0d-232fa177b29e",
		sliceEnd: 12.516211,
		sliceStart: 8.516211,
		playlist: true,
		dateUpdated: 1650034464352,
		markers: {},
		title: "ET Skywalker",
	},
	"aa8af4ae-7ac4-4ef7-b6ae-f2c9d043f632": {
		dateCreated: 1649110664317,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "root_comment",
		mediaTimestamp: 0,
		eventTagID: "aa8af4ae-7ac4-4ef7-b6ae-f2c9d043f632",
		sliceEnd: 2,
		sliceStart: 0.001,
		comment: "kitchen comment",
		playlist: false,
		dateUpdated: 1650034458803,
		markers: {},
		title: "ET Yoda",
		replies: {
			"4bc6a0c2-0bbe-4dfe-a855-37f68bd98fd5": {
				eventTagID: "aa8af4ae-7ac4-4ef7-b6ae-f2c9d043f632",
				dateCreated: 1649975778301,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "plate reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034370439,
				eventTagReplyID: "4bc6a0c2-0bbe-4dfe-a855-37f68bd98fd5",
			},
			"403a91e6-a7ce-4f45-9704-b4c5ae4453e6": {
				eventTagID: "aa8af4ae-7ac4-4ef7-b6ae-f2c9d043f632",
				dateCreated: 1649975779979,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "spoon reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034374180,
				eventTagReplyID: "403a91e6-a7ce-4f45-9704-b4c5ae4453e6",
			},
			"f50972d6-aefd-4cff-a710-d62b7baef0ad": {
				eventTagID: "aa8af4ae-7ac4-4ef7-b6ae-f2c9d043f632",
				dateCreated: 1650034380002,
				itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
				text: "blender reply",
				ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
				dateUpdated: 1650034380002,
				eventTagReplyID: "f50972d6-aefd-4cff-a710-d62b7baef0ad",
			},
		},
	},
	"80a0927d-808b-4fcf-ab15-40baed187d6f": {
		dateCreated: 1649110824274,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "702cbb5c-3d7a-45dc-9114-7ffd0be2ba42",
		mediaTimestamp: 14.060779,
		eventTagID: "80a0927d-808b-4fcf-ab15-40baed187d6f",
		sliceEnd: 16.060779,
		sliceStart: 12.060779,
		playlist: false,
		dateUpdated: 1650034496104,
		markers: {},
		title: "ET Palpatine",
	},
	"0a038b9b-c65d-40f7-a78d-4a47118af758": {
		dateCreated: 1649529919098,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "root_comment",
		mediaTimestamp: 15.580872,
		eventTagID: "0a038b9b-c65d-40f7-a78d-4a47118af758",
		sliceEnd: 17.580872,
		sliceStart: 13.580872,
		comment: "Den comment",
		playlist: false,
		dateUpdated: 1650034529668,
		markers: {
			"a6ac5b3c-d674-4ba2-83f5-349eb4ef65cb":
				"56be320f-eb48-4a90-b40b-77b7e906f082",
		},
		title: "ET Snoke",
	},
	"9e261164-e51a-411c-99b9-edd90dd8c3ac": {
		dateCreated: 1649110787474,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "5a94df84-d920-4574-bdef-68dc88e5bb5d",
		mediaTimestamp: 10.516211,
		eventTagID: "9e261164-e51a-411c-99b9-edd90dd8c3ac",
		sliceEnd: 12.516211,
		sliceStart: 8.516211,
		playlist: false,
		dateUpdated: 1650034478129,
		markers: {},
		title: "ET Han Solo",
	},
	"d901a58c-192b-4c51-a659-bc4c7a8072bb": {
		dateCreated: 1649529895653,
		itemAnalysisID: "487303bb-b1fc-4b3a-b1d1-976dd77c1746",
		ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
		dashboardTagID: "03585464-b61a-4814-a0c3-bcce123ac92c",
		mediaTimestamp: 15.580872,
		eventTagID: "d901a58c-192b-4c51-a659-bc4c7a8072bb",
		sliceEnd: 17.580872,
		sliceStart: 13.580872,
		playlist: true,
		dateUpdated: 1650034535212,
		markers: {
			"c638afff-7482-4197-ab9f-5a41ba26f6d1":
				"0dbee609-d383-40db-80fb-6ac21191648b",
		},
		title: "ET Windu",
	},
};
