import _ from "lodash";
import { UNLIMITED } from "constants/";
/**
 * Returns object with FA icon and icon color for given fileName's extension.
 * {icon}: is only is only the file extension part (eg -word for for fa-file-word).
 * {color}: is css color relevant to text- declarations. (eg -primary for text-primary)
 * @param (String) fileName including extension
 * @returns (Object) {icon: '-csv', color: 'danger'}
 * @example getExtension('MyHappyLife.doc') returns {icon: '-word', color: 'primary'}
 * FA File Icons
 * Powerpoint:	f1c4 || <i class="fas fa-file-powerpoint"></i>
 * Excel:		f1c3 || <i class="fas fa-file-excel"></i>
 * Word: 		f1c2 || <i class="fas fa-file-word"></i>
 * PDF: 		f1c1 || <i class="fas fa-file-pdf"></i>
 * CSV: 		f6dd || <i class="fas fa-file-csv"></i>
 * File: 		f15c || <i class="fas fa-file-alt"></i>
 * Image: 		f1c5 || <i class="fas fa-file-image"></i>
 *
 * keynote
 */
export const getExtensionIcon = (fileName) => {
	switch (getExtension(fileName)) {
		case "csv":
			return { icon: "-csv", color: "info" };

		case "doc":
		case "pages":
		case "docx":
			return { icon: "-word", color: "primary" };

		case "xls":
		case "numbers":
		case "xlsx":
			return { icon: "-excel", color: "success" };

		case "pdf":
			return { icon: "-pdf", color: "danger" };

		case "ppt":
		case "key":
		case "pptx":
			return { icon: "-powerpoint", color: "danger" };

		case "bmp":
		case "gif":
		case "jpg":
		case "jpeg":
		case "svg":
		case "png":
			return { icon: "-image", color: "plum" };

		case "3gp":
		case "mp4":
		case "mov":
		case "mod":
		case "mts":
		case "ogv":
		case "mxf":
			return { icon: "-video", color: "primary" };

		case "mp3":
		case "m4a":
			return { icon: "-audio", color: "success" };

		default:
			return { icon: "", color: "none" };
	}
};
/**
 * Returns the extension of fileName, if one exists
 * @param {String} fileName
 */
export const getExtension = (fileName) => {
	return _.toLower(
		fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)
	);
};

export function getFileData(file) {
	// postion of . otherwise str length

	var dotPos = file.name.lastIndexOf(".");
	switch (dotPos) {
		// No extension
		case -1:
			var fileNameBase = _.trim(file.name);
			var fileNameExtension = "";
			break;

		// No file name, just an extension
		case 0:
			var fileNameBase = "";
			var fileNameExtension = _.trim(
				_.toLower(file.name.substring(0, file.name.length))
			);
			break;

		default:
			var fileNameBase = _.trim(file.name.substring(0, dotPos));
			var fileNameExtension = _.trim(
				_.toLower(file.name.substring(dotPos + 1, file.name.length))
			);
	}

	const fileType = _.toUpper(_.split(file.type, "/")[0]) || "UNKNOWN";
	const sizeMB = file.size / 1000000; // Convert file size to MBs

	return {
		fileNameBase,
		fileNameExtension,
		fileType,
		sizeMB,
	};
}

/**
 * Returns storage in storage as either GB or MB, based in bytesUnit
 * @param {Number} storage in megabytes or bytes
 * @param {String} bytesUnit 'MEGA' | 'KILO' | BYTE. Default is 'MEGA'
 */
export function formatStorage(storage = 0, bytesUnit = "MEGA") {
	// if( UNLIMITED.value === storage ) return UNLIMITED.htmlCode
	if (UNLIMITED.value === storage) return UNLIMITED.word;
	if (isNaN(storage) || storage === Infinity) return storage;
	if (bytesUnit === "BYTE") storage = storage / 1000000;
	// convert bytes to megabytes
	else if (bytesUnit === "KILO") storage = storage / 1000; // convert kilobytes to mega bytes
	if (storage >= 1000) return `${(storage / 1000).toFixed(2)} GB`;
	if (storage < 1) return `${(storage * 1000).toFixed(0)} KB`;
	else return `${storage.toFixed(2)} MB`;
}
