import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "./wrapper/Wrapper";
import Sidebar from "./sidebar/Sidebar";
import Main from "./main/Main";
import Navbar from "./navbar/Navbar";
import Content from "./content/Content";
// import Footer from "footer/Footer";
// import Settings from "./settings/Settings";

import accountItems from "./sidebar/accountItems";
import analysisToolsItems from "./sidebar/analysisToolsItems";
import projectItems from "./sidebar/projectItems";

const AppLayout = ({ children }) => (
	<React.Fragment>
		<Wrapper>
			<Sidebar items={[projectItems, analysisToolsItems, accountItems]} />
			<Main>
				<Navbar />
				<Content>
					{children}
					<Outlet />
				</Content>
				{/* <Footer /> */}
			</Main>
		</Wrapper>
		{/* <Settings /> */}
	</React.Fragment>
);

export default AppLayout;
