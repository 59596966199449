/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for uploads
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */

import { createSlice } from "@reduxjs/toolkit";
import {
	initializeUpload,
	pollProgress,
	resumeUpload,
	updateProgress,
	uploadMediaItem,
} from "./uploader.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: {}, // current uploads
	entityList: [], // list of itemIDs of current uploads
	status: "idle",
};

const uploadSlice = createSlice({
	name: "uploader",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// setCurrentEntityID: (state, action) => {
		// 	// state.currentEntityID = action.payload; // payload is itemID
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,

		/** initializeUpload  */
		[initializeUpload.pending]: (state, action) => {
			state.status = "pending";
		},
		[initializeUpload.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// payload contains { entities:{...}, entityList: []}
			state.entities = action.payload.entities;
			state.entityList = action.payload.entityList;
		},
		[initializeUpload.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** resumeUpload */
		[resumeUpload.pending]: (state, action) => {
			state.status = "pending";
		},
		[resumeUpload.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// payload contains { entities:{...}, entityList: []}
			state.entities = action.payload.entities;
			state.entityList = action.payload.entityList;
		},
		[resumeUpload.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** pollProgress */
		[pollProgress.pending]: (state, action) => {
			state.status = "pending";
		},
		[pollProgress.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// TAKE NO ACTION
			// state.entities = action.payload; // payload contains members object
		},
		[pollProgress.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** updateProgress */
		[updateProgress.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateProgress.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// payload contains update entities object
			state.entities = action.payload;
		},
		[updateProgress.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** uploadMedia */
		[uploadMediaItem.pending]: (state, action) => {
			state.status = "pending";
		},
		[uploadMediaItem.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// TAKE NO ACTION
			// state.entities = action.payload; // payload contains members object
		},
		[uploadMediaItem.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// Action creators are generated for each case reducer function
// export const { initializeUpload } = uploadSlice.actions;
export default uploadSlice.reducer;
