import React, { useState, useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { copyElementToClipboard } from "utils/";

const ClickToCopy = ({ id, text, tip = "Click to copy" }) => {
	const [show, setShow] = useState(false);
	const [tooltip, setToolTip] = useState(tip);
	const target = useRef(null);

	return (
		<React.Fragment>
			<code
				id={id}
				className="cursor-pointer p-1"
				ref={target}
				// onMouseOver={() => setShow(true)}
				onMouseEnter={() => setShow(true)}
				onMouseOut={() => {
					// prevents seeing change of tooltip text onMouseExit
					setTimeout(() => {
						setToolTip(tip);
					}, 500);
					setShow(false);
				}}
				onClick={() => {
					copyElementToClipboard(id);
					setToolTip("Coppied!");
					setTimeout(() => {
						setToolTip(tip);
					}, 3000);
				}}
			>
				{text}
			</code>
			<Overlay target={target.current} show={show} placement="right">
				{(props) => (
					<Tooltip id="overlay-example" {...props}>
						{tooltip}
					</Tooltip>
				)}
			</Overlay>
		</React.Fragment>
	);
};
export default ClickToCopy;
