/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import { createSlice } from "@reduxjs/toolkit";
import {
	fetchAccount,
	updateAccount,
	updateOrganization,
} from "./account.actions";
import { ltiAddConsumer, ltiResetConsumerKey } from "./lticonsumer.actions";

import { PLANID } from "constants/";

/**
 * Initial state value of the slice
 */
const initialState = {
	entity: {},
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
};

const accountSlice = createSlice({
	name: "account",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// rehydrateAccount: (state, action) => {
		// 	state.entity = action.payload; // payload is account object
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,

		// called from listenerMiddleware
		setUpgradeMe: (state, action) => {
			const planType = action.payload || "BASIC-2017-01-13"; // payload is planID aka planType
			state.entity.upgradeMe = PLANID.FREE[planType] || PLANID.TRIAL === planType; // upgradeMe === true if plan is BASIC_x or TRIAL_x
		},

		"account/rehydrate": (state, action) => {
			state.entity = { ...action.payload };
		},

		"account/rehydrateUsage": (state, action) => {
			state.entity.usageData = { ...action.payload };
		},

		"account/rehydrateCollaborators": (state, action) => {
			state.entity.collaborators = { ...action.payload };
		},

		/** fetchAccount */
		[fetchAccount.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchAccount.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// state.entity = action.payload; // payload contains account object
			const planType = action.payload.planType || "BASIC-2017-01-13";
			state.status = "succeeded";
			state.entity = action.payload; // payload is account object
		},
		[fetchAccount.rejected]: (state, action) => {
			state.status = "failed";
			// state.error = action.error;
		},

		/** updateAccount */
		[updateAccount.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateAccount.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload is updated account object
		},
		[updateAccount.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** account updateOrganization */
		[updateOrganization.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateOrganization.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload updated account object
		},
		[updateOrganization.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** Adds LTI consumer to account */
		[ltiAddConsumer.pending]: (state, action) => {
			state.status = "pending";
		},
		[ltiAddConsumer.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload updated account object
		},
		[ltiAddConsumer.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** Rests LTI consumer secret key */
		[ltiResetConsumerKey.pending]: (state, action) => {
			state.status = "pending";
		},
		[ltiResetConsumerKey.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload updated account object
		},
		[ltiResetConsumerKey.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// Action creators are generated for each case reducer function
// export const { rehydrateAccount } = accountSlice.actions;
export default accountSlice.reducer;
