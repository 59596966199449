import _ from "lodash";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import useAppDispatch from "hooks/useAppDispatch";
import NotifyContext from "contexts/NotifyContext";

import { Dropdown } from "components/dropdowns/";
import { Dropdown as BSDropdown, ListGroup, Row, Col } from "react-bootstrap";
import {
	AudioIcon,
	ButtonLoadingIcon,
	CheckIcon,
	CircleNotchSpinningIcon,
	DownloadIcon,
	VideoIcon,
} from "components/icons";
import { LoadingSpinner } from "components/loaders";

import { formatStorage } from "utils/";

const recordingsInitialState = {
	chatDownloadUrl: null,
	chatRecordingOffsetSeconds: 0,
	transcriptDownloadUrl: null,
	vttDownloadUrl: null,
};

const ImportZoomDropdown = ({
	disabled,
	folderID,
	files,
	importRecording,
	topic,
	start_time,
}) => {
	const [loading, setLoading] = useState(false);
	const [imported, setImported] = useState(false);

	// console.log("== ImportZoomDropdown ==");
	// console.log("disabled - ", disabled);
	// console.log("files - ", files);
	// console.log("topic - ", topic);
	// console.log("start_time - ", start_time);
	// console.log("loading - ", loading);
	// console.log("");

	// unmount useEffect
	useEffect(() => {
		// return () => {
		// 	// put unmount code here
		// };
	});

	/** Triggers importing of file */
	const onImportClick = async (file) => {
		if (disabled || "completed" !== file.status) return;

		setLoading({ [file.id]: true });

		// importRecording( { ...file, importChat: this.state.importChat }, topic)
		await importRecording(file);
		setImported({ [file.id]: true });
		setLoading({ [file.id]: false });
	};

	// Defines buttons will be rendered only if the dropdown show state is true.
	const renderDropdownItems = () => {
		let importBtns = null;

		if (0 < files.length) {
			let recordingsCnt = 0,
				recordingStart = null,
				recordings = {};

			files = _.orderBy(files, "recording_type", "asc");
			// debugger;

			importBtns = files.map((file, idx) => {
				// file_size in bytes
				const {
					download_url,
					file_extension,
					file_size,
					file_type,
					id,
					recording_start,
					recording_type,
					status,
				} = file;
				if (
					"completed" !== status ||
					!["CC", "CHAT", "M4A", "MP4", "TRANSCRIPT"].includes(file_type)
				)
					return;

				// New set of recordingsCnt, re/set vars
				if (recording_start !== recordingStart) {
					recordingStart = recording_start;
					recordingsCnt = recordingsCnt + 1;
					recordings[recording_start] = { ...recordingsInitialState };
				}

				/* Zoom Chat: If file_type is the zoom Chat - set ChatDownloadURl & chatRecordingOffsetSeconds */
				if ("TXT" === file_extension) {
					recordings[recording_start].chatDownloadUrl = download_url;
					recordings[recording_start].chatRecordingOffsetSeconds =
						(new Date(recording_start).getTime() - new Date(start_time).getTime()) /
						1000;
					return;
				}

				/* Closed Captions: If file_type is Closed Caption - set VvttDoanloadURl */
				if ("VTT" === file_extension) {
					recordings[recording_start].vttDownloadUrl = download_url;
					return;
				}

				/* Transcript: If file_type is Closed Caption - set VvttDoanloadURl */
				if ("audio_transcript" === recording_type) {
					recordings[recording_start].transcriptDownloadUrl = download_url;
					return;
				}

				const isFileLoading = loading[id] || false;
				const isFileImported = imported[id] || false;

				const icon = isFileLoading ? (
					<span className="pe-2">
						<CircleNotchSpinningIcon />
					</span>
				) : isFileImported ? (
					<CheckIcon menu />
				) : "MP4" === file_type ? (
					<VideoIcon menu />
				) : (
					<AudioIcon menu />
				);
				// const iconClasses = classNames({
				// 	"spinner fa-spin": isFileLoading,
				// 	"video text-primary": !isFileLoading && "MP4" === file_type,
				// 	"microphone-stand text-success": !isFileLoading && "M4A" === file_type,
				// });

				const recordingName = `${_.upperFirst(
					recording_type.replace(/_/g, " ")
				)} - recording ${recordingsCnt}`;

				return (
					<BSDropdown.Item
						key={idx}
						disabled={isFileLoading}
						onClick={() => {
							onImportClick({
								...file,
								...recordings[recording_start],
								topic: `${topic} - ${recordingName}`,
							});
						}}
					>
						<div className="d-flex justify-content-between">
							<span>
								{icon}
								{`${recordingName}`}
							</span>
							<span className="ps-3 text-muted">{`${formatStorage(
								file_size,
								"BYTE"
							)}`}</span>
						</div>
					</BSDropdown.Item>
				);
			});
		}

		// renders all the options in a dropdown menu
		return importBtns;
	};

	/** Revokes/Invites membership access for members & invitees */

	return (
		<Dropdown
			heading="Import"
			icon={<DownloadIcon variant="primary" disabled={disabled} />}
			autoClose="outside"
		>
			{renderDropdownItems()}
		</Dropdown>
	);
};

export default ImportZoomDropdown;
