import React from "react";
import { LoadingLips } from "./LoadingLips";

export const PageLoader = () => {
	return (
		<div
			className="d-flex align-items-center align-middle"
			style={{ height: "100vh" }}
		>
			<LoadingLips />
		</div>
	);
};
