import React from "react";
import { Dropdown } from "react-bootstrap";

const DropdownItem = ({ color, disabled, icon, onClick, title }) => {
	return (
		<Dropdown.Item onClick={onClick} disabled={disabled}>
			{icon}
			{title}
		</Dropdown.Item>
	);
};

export default DropdownItem;
