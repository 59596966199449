/**
 * Contains all actions for this feature
 * TODO: Needs actions for eventTag.options create and delete
 */
import _ from "lodash";
import { v4 as uuidV4 } from "uuid";
import axios from "axios";
import { eventTagsData } from "./eventTags.mock";
import { createAsyncThunk } from "@reduxjs/toolkit";

/***
 * THUNK NOTES
 * createAsyncThunk accepts three parameters:
 *    a string action type value,
 *    a payloadCreator callback, and
 *    an options object.
 *
 * type: argument of 'users/requestStatus' will generate these action types
 *    pending: 'users/requestStatus/pending'
 *    fulfilled: 'users/requestStatus/fulfilled'
 *    rejected: 'users/requestStatus/rejected'
 *
 * payloadCreator: A callback function that should return a promise containing the result of some asynchronous logic
 */

/** fetchesEventTags from API */
export const fetchEventTags = createAsyncThunk(
	"eventTags/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const { currentRequestId, loading } = getState().eventTags;
			// console.log("==> currentRequestId: ", currentRequestId);
			// console.log("==> loading: ", loading);
			if (loading !== "pending" || requestId !== currentRequestId) {
				return;
			}
			// const response = await mockApi.fetchById(userId);
			// const response = await mockApi.get("/folderItems/Dashboards");
			// console.log(response);
			return eventTagsData;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

/** creates a eventTag, saving to cloud if indicated */
export const createEventTag = createAsyncThunk(
	"eventTags/create",
	async (eventTag, { dispatch, getState, requestId, rejectWithValue }) => {
		// A title & an option is required
		if ("" === eventTag.title) {
			return rejectWithValue("Title required.");
		}
		try {
			// const res = await EventTagDataService.update(id, data);
			// return res.data
			// Delete save as its no longer needed
			return eventTag;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

// /** deletes a user eventTag */
export const deleteEventTag = createAsyncThunk(
	"eventTags/delete",
	async (eventTagID, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			// await EventTagDataService.remove(eventTagID);
			return eventTagID;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

// /** updates a user eventTag */
export const updateEventTag = createAsyncThunk(
	"eventTags/update",
	async (eventTag, { dispatch, getState, requestId, rejectWithValue }) => {
		console.log("updateEventTag");
		try {
			// const res = await EventTagDataService.update(id, data);
			// return res.data;
			return eventTag;
		} catch (err) {
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);
