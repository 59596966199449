import React from "react";
import { Card, Table } from "react-bootstrap";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";

import useAppSelector from "hooks/useAppSelector";

import { formatStorage } from "utils/";
import { UNLIMITED } from "constants/";

function UsageInfo() {
	const {
		// organizations = {},
		organizationID,
		planData,
		usageData,
	} = useAppSelector((state) => state.account.entity);

	const {
		maxCollaborators,
		maxStorage,
		maxDashboards,
		maxTranscribe,
		mergeWithOrgMemberPlan,
	} = planData || {};
	const {
		storageCount,
		collaboratorCount,
		dashboardCount,
		transcriptionSeconds,
	} = usageData || {};

	// Calculated variables
	var collaborationTitle = "Collaborators";

	// if (0 < Object.keys(organizations).length) {
	if (organizationID) {
		collaborationTitle = (
			<React.Fragment>
				External Collaborators
				<br />
				<i className="text-primary">Unlimited organization collaboration</i>
			</React.Fragment>
		);
	}
	return (
		<Card>
			<Card.Header>
				<Card.Title className="mb-0">Your Usage</Card.Title>
			</Card.Header>
			<Card.Body>
				<Table className="mb-0" responsive>
					<tbody>
						{tableRow(
							"Storage",
							undefined === storageCount ? storageCount : formatStorage(storageCount),
							formatStorage(maxStorage)
						)}
						{tableRow(collaborationTitle, collaboratorCount, maxCollaborators)}
						{tableRow("Dashboards", dashboardCount, maxDashboards)}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
}

const tableRow = (title, value, maxValue) => (
	<tr>
		<td className="w-50">{title}</td>
		<td className="align-top">
			{undefined === value ? (
				<PlaceholderGlow>
					<PlaceholderPrimary />
				</PlaceholderGlow>
			) : (
				<React.Fragment>
					{value}
					<span className="ps-1 pe-1">/</span>
					{UNLIMITED.value === maxValue ? UNLIMITED.word : maxValue}
				</React.Fragment>
			)}
		</td>
	</tr>
);

// const renderUsageLimit = (title, usage, limit, color) => {
// 	return (
// 		<React.Fragment>
// 			<span className={color}>{usage}</span> /{" "}
// 			{UNLIMITED.value === limit ? UNLIMITED.word : limit}
// 		</React.Fragment>
// 	);
// };

export default UsageInfo;
