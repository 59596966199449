import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "./wrapper/Wrapper";
import Main from "./main/Main";
import NavbarAnalysis from "./navbar/NavbarAnalysis";
import Content from "./content/Content";
// import Settings from "./settings/Settings";

const AnalysisLayout = ({ children }) => (
	<React.Fragment>
		<Wrapper>
			<Main>
				<NavbarAnalysis />
				<Content className="p-1 m-0">
					{children}
					<Outlet />
				</Content>
			</Main>
		</Wrapper>
		{/* <Settings /> */}
	</React.Fragment>
);

export default AnalysisLayout;
