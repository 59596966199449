/** STRIPE TEST CARDS - https://stripe.com/docs/testing#international-cards
	 
	 * 4242424242424242: No authentication required
	 
	 SCA Cards
	 * 4000002500003155: Requires authentication for one-time payments. No further authentication needed. 
	 * 4000002760003184: Requires authentication on all transactions, regardless of how the card is set up.
	 * 4000008260003178: Requires authentication for one-time payments. All payments will be declined with an insufficient_funds failure code even after being successfully authenticated or previously set up. 
	 
	 3D Secure Cards
	 * 4000000000003220: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000000000003063: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000008400001629: 3D Secure required but payments will be declined with a card_declined failure code after authentication. By default, your Radar rules will request 3D Secure authentication for this card. 
	 * 378282246310005: 3D Secure is not supported on this card and cannot be invoked. The PaymentIntent will proceed without performing authentication.
	 */

import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import { COUNTRY_CODES } from "constants/";

// renders the stripe country codes
const renderCountries = () => {
	return COUNTRY_CODES.map((item) => {
		return (
			<option value={item.code} key={item.name}>
				{item.name}
			</option>
		);
	});
};
const StripeAddressForm = ({
	formData,
	setFormData,
	errors,
	touched,
	setTouched,
}) => {
	const handleOnChange = async (e) => {
		// e.preventDefault();
		const { name, value } = e.target;
		// console.log(`onChange called with for ${name} called with `, value);
		setTouched({
			...touched,
			[name]: true,
		});
		setFormData({
			...formData,
			[name]: value,
		});
	};

	return (
		<React.Fragment>
			<Form id="addressForm">
				<Form.Group className="mb-3">
					<Form.Control
						key="name"
						name="name"
						type="text"
						placeholder={"Name on card"}
						// onBlur={(e) => handleOnBlur(e)}
						onChange={(e) => handleOnChange(e)}
						defaultValue={formData.line1}
						style={{ border: errors.name ? "1px solid red" : "inherit" }}
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Control
						key="line1"
						name="line1"
						type="text"
						placeholder={"Addresss line 1"}
						// onBlur={(e) => handleOnBlur(e)}
						onChange={(e) => handleOnChange(e)}
						defaultValue={formData.line1}
						style={{ border: errors.line1 ? "1px solid red" : "inherit" }}
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Control
						key="line2"
						name="line2"
						type="text"
						placeholder={"Address line 2"}
						// onBlur={(e) => handleOnBlur(e)}
						onChange={(e) => handleOnChange(e)}
						defaultValue={formData.line2}
						style={{ border: errors.line2 ? "1px solid red" : "inherit" }}
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Row>
						<Col>
							<Form.Control
								key="city"
								name="city"
								type="text"
								placeholder={"City"}
								// onBlur={(e) => handleOnBlur(e)}
								onChange={(e) => handleOnChange(e)}
								defaultValue={formData.city}
								style={{ border: errors.city ? "1px solid red" : "inherit" }}
							/>
						</Col>
						<Col>
							{" "}
							<Form.Control
								key="state"
								name="state"
								type="text"
								placeholder={"State / County"}
								// onBlur={(e) => handleOnBlur(e)}
								onChange={(e) => handleOnChange(e)}
								defaultValue={formData.state}
								style={{ border: errors.state ? "1px solid red" : "inherit" }}
							/>
						</Col>
						<Col>
							<Form.Select
								key="country"
								name="country"
								aria-label="Country"
								// onBlur={(e) => handleOnBlur(e)}
								onChange={(e) => handleOnChange(e)}
								style={{ border: errors.country ? "1px solid red" : "inherit" }}
							>
								<option>Country</option>
								{renderCountries()}
							</Form.Select>
						</Col>
					</Row>
				</Form.Group>
			</Form>
		</React.Fragment>
	);
};

export default StripeAddressForm;
