import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { Container, Row } from "react-bootstrap";

import { fetchImportedZoomRecordings } from "features/zoom/zoom.actions";

import ProjectsHeader from "./ProjectsHeader";
import ZoomProjectThumb from "./ZoomProjectThumb";
import ThumbLoadingCard from "./ThumbLoadingCard";
import ThumbUploadingCard from "./ThumbUploadingCard";

import { PLANID, PROGRESS_STATUS, ROUTES } from "constants/";

const ZoomProjects = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { openModal } = useModalContext();

	const { oauthUserID: paramsOauthuserID } = useParams(); //dfc12a6e-dad8-4b3a-b5ab-ac0c191b47fc
	const { oauthUserID: storeOauthUserID, userID } = useSelector(
		(state) => state.user.entity
	);
	const [oauthUserID, setOauthUserID] = useState(paramsOauthuserID);

	const { exportStatus } = useSelector((state) => state.itemAnalyses);
	const { planType } = useSelector((state) => state.account.entity);

	const {
		entities: projects,
		createStatus,
		currentEntityID, // indicates currently selected entity, whether folder or item/project (NOT of the current folder contents being viewed)
	} = useSelector((state) => state.projects);

	const [filter, setFilter] = useState({
		filterKey: "",
		filterValue: "All",
	});
	const [folderItems, setFolderItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState({
		by: "dateCreated",
		order: "desc",
	});

	console.log("== ZoomProjects ==");
	console.log("projects.zoom - ", projects?.zoom || undefined);
	console.log("folderItems - ", folderItems);
	// console.log("paramsOauthuserID - ", paramsOauthuserID);
	// console.log("storeOauthUserID - ", storeOauthUserID);
	// console.log("oauthUserID - ", oauthUserID);
	console.log("sort - ", sort);
	console.log("filter - ", filter);
	console.log("");

	const fetchData = async () => {
		// console.log("fetchData for ", oauthUserID);
		setLoading(true);
		const res = await dispatch(fetchImportedZoomRecordings({ oauthUserID }));
		if (res.error) {
			console.log("error - ", res.error);
			// todo:navigate(ROUTES.HOME);
		}
		setLoading(false);
	};

	/**
	 * useEffect on params changing
	 * Note: Params is loaded after onMount - hence including folderID here will initiate fetch on first load too
	 */
	useEffect(() => {
		setOauthUserID(paramsOauthuserID ? paramsOauthuserID : storeOauthUserID);
		// if (null === projects || undefined === projects.zoom) fetchData();
		fetchData();

		// setFilter({ ...filter });
	}, [paramsOauthuserID]);

	// useEffect on filter and sort change
	useEffect(() => {
		if (null === projects || undefined === projects.zoom) return;

		// Sort
		var tmpFolderItems = _.orderBy(projects.zoom, [sort.by], [sort.order]);

		// filter - Only apply filter if not "All"
		if ("" !== filter.filterKey)
			tmpFolderItems = _.filter(tmpFolderItems, { ...filter });

		setFolderItems(tmpFolderItems);
	}, [filter, sort]);

	// useEffect to update proejcts or folderID
	useEffect(() => {
		if (null === projects || undefined === projects.zoom) return;
		const folderItems = Object.keys(projects.zoom);
		let tmpFolderItems = [];
		var idx = 0;

		while (idx < folderItems.length) {
			const itemID = folderItems[idx];

			if ("oauthUser" !== itemID) tmpFolderItems.push(projects.zoom[itemID]);
			// debugger;
			idx++;
		}

		if ("" !== filter.filterKey)
			tmpFolderItems = _.filter(tmpFolderItems, { ...filter });
		tmpFolderItems = _.orderBy(tmpFolderItems, [sort.by], [sort.order]);
		setFolderItems(tmpFolderItems);
		// debugger;
	}, [projects]);

	// unmount
	useEffect(() => {});

	const onZoomImportClick = () => {
		if (undefined === planType || PLANID.FREE[planType])
			openModal(MODAL_TYPES.INFO_UPGRADE, {
				feature: "Zoom Cloud Imports",
			});
		else
			openModal(MODAL_TYPES.IMPORT_ZOOM, {
				folderID: 0,
			});
	};

	const renderFolderItems = folderItems.map((item) => {
		console.log("hello");
		const { transcodeProgress } = item;
		if (
			PROGRESS_STATUS.PROGRESSING === transcodeProgress ||
			PROGRESS_STATUS.UPLOADSTARTED === transcodeProgress
		)
			return <ThumbUploadingCard item={item} key={item.itemID} userID={userID} />;
		return (
			<ZoomProjectThumb
				item={item}
				key={item.itemID}
				userID={userID}
				dnd={false}
			/>
		);
	});

	return (
		<div>
			<Container fluid className="p-0 h-100">
				<ProjectsHeader
					disabled={loading}
					isZoom={true}
					filterFolderResults={({ filterKey, filterValue }) => {
						if (undefined !== filterKey)
							setFilter({
								[filterKey]: filterValue,
							});
						else
							setFilter({
								filterKey: "",
								filterValue: "All",
							});
					}}
					sortFolderResults={(sort) =>
						setSort({
							...sort,
						})
					}
					onZoomImportClick={onZoomImportClick}
				/>
				<Row className="g-5 p-3 justify-content-start">
					{loading ? <ThumbLoadingCard /> : null}
					{createStatus ? (
						<ThumbLoadingCard
							title={"creating" === createStatus ? "Creating..." : "Duplicating..."}
						/>
					) : null}
					{exportStatus ? (
						<ThumbLoadingCard
							title="Exporting..."
							subtitle="Exporting to downloads folder"
						/>
					) : null}
					{renderFolderItems}
				</Row>
			</Container>
		</div>
	);
};

export default ZoomProjects;
