import React, { useEffect, useState } from "react";

import { v4 as uuidV4 } from "uuid";

import useAppDispatch from "hooks/useAppDispatch";
import { updateEventTag } from "features/analysis/eventTags.actions";

import { Button, Form } from "react-bootstrap";

// import { formatLocalDate, formatVideoTime } from "utils/";

const ClickToEdit = (props) => {
	const dispatch = useAppDispatch();

	const [isEditing, setIsEditing] = useState(false);
	const [renamedField, setRenamedField] = useState(props.initialRenamedField);
	const [showDeleteX, setShowDeleteX] = useState(false);

	useEffect(() => {
		if (document.getElementById(props.formId)) {
			document.getElementById(props.formId).focus();
		}
	}, [isEditing]);

	const handleOnClick = () => {
		setIsEditing(true);
	};

	const handleOnChange = (e) => {
		setRenamedField(e.target.value);
	};

	// Get a better way to refactor this!
	const handleSave = (eventTag) => {
		//Error handling for empty fields - if updating something that exists, either revert to initial value or provide an error message to the user. If the empty field is for a new reply/field (and not mandatory), allow user to leave it blank
		// if (renamedField.length < 1) {
		// 	console.log("Field must contain at least one character");
		// 	return;
		// }
		setIsEditing(false);
		if (props.formId === "titleInput") {
			if (renamedField !== eventTag.title) {
				eventTag = {
					...eventTag,
					title: renamedField,
				};
				dispatch(updateEventTag(eventTag))
					.then((originalPromiseResult) => {
						console.log("Update Project Success");
					})
					.catch((error) => {
						console.log("updateUserProject Error: ", error);
					});
			}
		} else if (props.formId === "commentInput") {
			if (renamedField !== eventTag.comment) {
				eventTag = {
					...eventTag,
					comment: renamedField,
				};
				dispatch(updateEventTag(eventTag))
					.then((originalPromiseResult) => {
						console.log("Update Project Success");
					})
					.catch((error) => {
						console.log("updateUserProject Error: ", error);
					});
			}
		} else if (props.formId === "replyInput") {
			if (renamedField !== eventTag.replies[props.replyId].text) {
				eventTag = {
					...eventTag,
					replies: {
						...eventTag.replies,
						[props.replyId]: {
							...eventTag.replies[props.replyId],
							text: renamedField,
						},
					},
				};
				dispatch(updateEventTag(eventTag))
					.then((originalPromiseResult) => {
						console.log("Update Project Success");
					})
					.catch((error) => {
						console.log("updateUserProject Error: ", error);
					});
			}
		} else if (props.formId === "blankCommentInput") {
			if (renamedField.length > 0) {
				eventTag = {
					...eventTag,
					comment: renamedField,
				};
				dispatch(updateEventTag(eventTag))
					.then((originalPromiseResult) => {
						console.log("Update Project Success");
					})
					.catch((error) => {
						console.log("updateUserProject Error: ", error);
					});
			}
		} else if (props.formId === "blankReplyInput") {
			if (renamedField.length > 0 && renamedField !== props.initialRenamedField) {
				console.log(`renamedField: ${renamedField}`);
				const newReplyId = uuidV4();
				eventTag = {
					...eventTag,
					replies: {
						...eventTag.replies,
						[newReplyId]: {
							eventTagID: eventTag.eventTagID,
							dateCreated: Date.now(),
							itemAnalysisID: eventTag.itemAnalysisID,
							text: renamedField,
							// ownerID: "8448c9f2-6ae0-4dc1-99e3-db552080accc",
							// dateUpdated: 1650034389992,
							eventTagReplyID: newReplyId,
						},
					},
				};
				console.log(eventTag.replies[newReplyId].dateCreated);
				setRenamedField("");
				dispatch(updateEventTag(eventTag))
					.then((originalPromiseResult) => {
						console.log("Update Project Success");
					})
					.catch((error) => {
						console.log("updateUserProject Error: ", error);
					});
			}
		} else {
			console.log("Unrecognized formId");
			return;
		}
	};

	const handleDelete = (elementId) => {
		let replies = {};
		for (let reply in props.eventTag.replies) {
			if (reply !== elementId) {
				replies[reply] = props.eventTag.replies[reply];
			}
		}
		let eventTag = {
			...props.eventTag,
			replies: replies,
		};
		dispatch(updateEventTag(eventTag))
			.then((originalPromiseResult) => {
				console.log("Update Project Success");
			})
			.catch((error) => {
				console.log("updateUserProject Error: ", error);
			});
	};

	const editForm = (
		<Form>
			<Form.Control
				type="text"
				id={props.formId}
				value={renamedField}
				onChange={(e) => {
					handleOnChange(e);
				}}
				onBlur={() => handleSave(props.eventTag)}
				onKeyDown={(e) =>
					e.code === "Enter" || e.code === "NumpadEnter"
						? handleSave(props.eventTag)
						: null
				}
			></Form.Control>
		</Form>
	);

	const newForm = (
		<Form>
			<Form.Control
				type="text"
				id={props.formId}
				placeholder={props.placeholder}
				value={renamedField}
				onChange={(e) => {
					handleOnChange(e);
				}}
				onBlur={() => handleSave(props.eventTag)}
				onKeyDown={(e) =>
					e.code === "Enter" || e.code === "NumpadEnter"
						? handleSave(props.eventTag)
						: null
				}
			></Form.Control>
		</Form>
	);

	const deleteButton = (
		<Button
			onClick={() => {
				handleDelete(props.replyId);
			}}
		>
			X
		</Button>
	);

	if (
		props.formId === "blankCommentInput" ||
		props.formId === "blankReplyInput"
	) {
		// Return a clickable component with a newForm input and placeholder text
		return (
			<div
				onClick={() => {
					handleOnClick();
				}}
			>
				{isEditing === true ? newForm : props.placeholder}
			</div>
		);
	} else {
		// Return a clickable component with an editable form
		return (
			<div
				onClick={() => {
					handleOnClick();
				}}
				onMouseEnter={() => {
					if (props.formId === "replyInput") {
						setShowDeleteX(true);
					}
				}}
				onMouseLeave={() => {
					if (props.formId === "replyInput") {
						setShowDeleteX(false);
					}
				}}
			>
				{isEditing === true ? editForm : props.initialRenamedField}
				{showDeleteX === true ? deleteButton : null}
			</div>
		);
	}
};

export default ClickToEdit;
