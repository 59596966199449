module.exports = {
	// app
	fetchPlans: "plan", // gets all live Dmio plans
	// appGetVersion: "version",
	// appFetchTemplates: "template", // PATCH. Fetches all live templates.

	// user account & plan
	accountGet: "account",

	// billing
	billingGet: "payment/billing", // used to get billing details
	billingUpdate: "payment/billing/update", // used to update billing details
	billingUpdateSetupIntent: "payment/billing/beforeupdate", // for stripe SCA, used to create stripe setupIntent
	billingChangePlan: "payment/changeplan", // used for updating payment plan and create stripe setupIntent

	// dashboards
	dashboardsGet: "folderitem/dashboard", // POST gets all user Dashboards if no body, else specific dashboards
	dashboardCreate: "folderitem/dashboard/create", // POST Creates a dashboard
	dashboardUpdate: "folderitem/dashboard/update", // POST Updates a dashboard
	dashboardDelete: "folderitem/dashboard?dashboardID=", // DLELETEs dashboard if deleteDashboardTags=true

	// dashboard Tags
	dashboardTagsGet: "folderitem/dashboard/tag", // GET gets all user Dashboards if no body, else specific dashboards
	dashboardTagCreate: "folderitem/dashboard/tag/create", // POST Creates a dashboard
	dashboardTagUpdate: "folderitem/dashboard/tag/update", // POST Updates a dashboard
	dashboardTagDelete: "folderitem/dashboard/tag?dashboardTagID=", // DLELETEs dashboard if deleteDashboardTags=true

	// folders
	folderGet: "folderview/withitems?folderID=", // NEW 'folder?folderID=0'; GETS folder details with a list of child itemIDs.
	folderGetChildren: "folderview/withitems?folderID=", // UPDATE "/folder/children?folderID=" GETS child items of folder, returning an Object with key-value
	folderCreate: "folderitem/create", // UPDATE item/create; POST Creates a folder or project item
	folderUpdate: "folderitem/update", // UPDATE item/update; POST Updates a folder or project item
	folderDelete: "folderitem?itemID=", // UPDATE item/delete?itemID=; DEL a folder or project item params itemID & deleteDependencies

	// lti
	ltiAssignmentDetach: "lti/assignment/detach", // POST { itemAnalysisID: "97b16d18-1638-4de3-933c-8aa9977f46cf" }
	ltiAssignmentAttach: "lti/assignment/attach", // POST { 'itemAnalysisID', 'ltiConsumerID', 'ltiAssignmentID', 'ltiAssignmentTitle'}
	ltiConsumerAdd: "lti/consumer/add", // POST { ltiConsumerID: "moodle.nownow.co.za" }
	ltiConsumerReset: "lti/consumer/update", // POST { ltiConsumerID: "moodle.nownow.co.za", rollConsumerSecret: true }
	ltiGradeGet: "lti/gradeget?itemAnalysisID=", // GET e467ab06-f477-416c-9039-f6f70a509079"
	ltiGradePassback: "lti/gradepassback", // POST {resultScore": 0.01, itemAnalysisID: <itemAnalysisID> }

	//markers
	markerFetch: "folderitem/eventtag/marker",
	markerUpdate: "folderitem/eventtag/marker/update",
	markerCreate: "folderitem/eventtag/marker/create",
	markerDelete: "folderitem/eventtag/marker/delete",

	// reports
	ltiAttachReport: "report/ltiassignmentattachment", // GET

	// Organization & Membersembers
	memberInvite: "organization/invite?", // Invite member to organization - email or userID
	memberRevoke: "organization/revoke?", // Revoke member from organization - email or userID
	memberAccept: "organization/accept?organizationID=", // Accept invitation to organizationID
	memberDecline: "organization/decline?organizationID=", // Decline invitation to organizationID
	memberLeave: "organization/leave?organizationID=", // Leave invitation to organizationID
	membersFetch: "organization", // Fetches all members in organization
	organizationActivate: "organization/changeactiveorganization?organizationID=", //
	organizationUpdate: "organization/update", // POST with {autoMemberAtSignup Boolean true | false}

	// user
	userGet: "user",
	userGetCookies: "user/cookies",
	userGetSharedCookies: "user/cookies?itemID=",
	userUpdate: "user",
	userEmailContains: "user?emailContains=", // Fetches all users where email contains entered string

	// Zoom
	zoomImportRecording: "zoom/recordings/import", // POST {downloadUrl: "https://zoom.us/rec/download/7sckIu2srz43HtGctgSDA_B-W9S4KK2s1HIf8vtcyxrmB3cLY1Sjb-MTNrSeXZSliHfLSBisID-l_i2o","itemID": "05e7e6a5-76c7-4ca6-8bfe-989f44b03fe8"}
	zoomInitiateAuthorization: "oauth/zoom/initiateauthorization?folderID=", // GET
	zoomFetchRecordings: "zoom/recordings", // GET
	zoomRetainData: "oauth/zoom/userdata/retain?oauthUserID=", //POST retains data imported from zoom (gives permission)
	zoomRevokeAuthorization: "oauth/zoom/revokeauthorization", // GET
	zoomFetchImportedItems: "oauth/zoom/userdata", // GET {oauthUserID: option, pendingDelete: true|false options}
	zoomDeleteImportedItems: "oauth/zoom/userdata?oauthUserID=", // DELETE {oauthUserID: option, pendingDelete: true|false options}

	// items
	// itemCreate: "folderitem/create",
	// itemDelete: "folderitem?itemID=",
	// itemGet: "folderitem?itemID=", // Gets a single items
	// itemUpdate: "folderitem/update",
	// itemSend: "folderitem/send", // POST
	// itemSignUrl: "folderitem/signurl?", // currently not in use
	// itemTranlate: "folderitem/translate",
	// itemTranscribe: "folderitem/transcribe",                                                            // GET. Gets all live templates with makrers, dashboards and dashboardTags

	// // anaylsis - event tags
	// eventTagsFetch: "folderitem/eventtag?itemAnalysisID=",
	// eventTagUpdate: "folderitem/eventtag/update",
	// eventTagCreate: "folderitem/eventtag/create",
	// eventTagDelete: "folderitem/eventtag",

	// // analysis - replies
	// eventTagReplyCreate: "folderitem/eventtag/reply/create",
	// eventTagReplyUpdate: "folderitem/eventtag/reply/update",
	// eventTagReplyDelete: "folderitem/eventtag/reply?eventTagReplyID=",

	// // analysis - itemAnalysis
	// analysisGet: "folderitem/itemanalysis?itemAnalysisID=", //&lobsList=transcription_en,transcription_fr,notes
	// analysisCreate: "folderitem/itemanalysis/create",
	// analysisUpdate: "folderitem/itemanalysis/update",
	// analysisDelete: "folderitem/itemanalysis/delete",
	// analysisExport: "folderitem/itemanalysis/export?itemAnalysisID=",
	// analysisVisibility: "folderitem/itemanalysis/visibility", // POST { itemAnalysisID: 'abc-xde', mode: 'PUBLIC' || 'PRIVATE'}

	// charts
	//chartFetch
	// chartCreate: "folderitem/itemanalysis/chart/create",
	// chartUpdate: "folderitem/itemanalysis/chart/update", // Handles updating chart metadata as well as child datasets (incl. delete)
	// chartDatasetCreate: "folderitem/itemanalysis/chart/dataset/create",

	// // dashboard tags + markers
	// dashboardFetch: "folderitem/dashboard",
	// dashboardCreate: "folderitem/dashboard/create",
	// dashboardDelete: "folderitem/dashboard",
	// allTagsFetch: "folderitem/dashboard/tag",
	// tagDelete: "folderitem/dashboard/tag",
	// tagCreate: "folderitem/dashboard/tag/create",
	// tagUpdate: "folderitem/dashboard/tag/update",
	// dashboardUpdate: "folderitem/dashboard/update",

	// FILE ASSETS
	// fileAssetCreate: "folderitem/itemanalysis/file/create", // POST {itemAnalysisID, title, fileType, locked}
	// fileAssetGetLink: "folderitem/itemanalysis/file/download?fileID=", // GET
	// fileAssetUpdate: "folderitem/itemanalysis/file/update", // POST {fileID, title, locked, confidential, deleted}

	// // Share & Collaborate
	// userInvite: "folderitem/collaboration/invite", // Bulk share & collaborator invite, post
	// userRevoke: "folderitem/collaboration/revoke?", // Revoke user access; (itemAnalysisID= OR itemID=)  AND &userID=
	// userLeave: "folderitem/collaboration/leave?", // User chooses to leave a share or collaborate; itemAnalysisID= OR itemID=
};
