import "./projects.scss";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDrag, useDrop } from "react-dnd";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext } from "contexts/ModalContext";

import {
	Badge,
	ProgressBar,
	Card,
	Col,
	Form,
	ListGroup,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import ProjectSettingsDropdown from "./ProjectSettingsDropdown";
import {
	LockIcon,
	ProjectsIcon,
	ProjectsAudioIcon,
	ShieldCheckIcon,
} from "components/icons";

import { ownerNameStr } from "utils/";
import { ROUTES } from "constants/";
import { URL } from "config/";

import Avatar from "components/Avatar";

import { updateFolder } from "features/projects/projects.actions";
import {
	removeProjectChild,
	setCurrentEntityID as setCurrentFolderID,
} from "features/projects/projects.slice";

import { formatLocalDate, formatVideoTime } from "utils/";

import videoPlaceholderImg from "assets/img/placeholders/video-thumbnail.jpg";

const ThumbCard = ({ item, userID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const ref = useRef(null);

	const { currentEntityID: currentItemID } = useSelector(
		(state) => state.projects
	);

	const [hover, setHover] = useState(false);
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const [isFolder, setIsFolder] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [projectTitle, setProjectTitle] = useState();
	const [projectThumbnail, setProjectThumbnail] = useState();

	// const isClickable =
	// 	"COMPLETED" === transcodeProgress || "FOLDER" === mediaType; //Boolean value based on being a folder or transcoding
	const isClickable = true; //Replace this with the above once transcoding is implemented

	// console.log("==Project Card==");
	// console.log(`${item.title} - `, item.itemID);
	// // console.log("isEditingTitle - ", isEditingTitle);
	// // console.log("isOwner - ", isOwner);
	// // console.log("userID - ", userID);
	// // console.log("ownerID - ", item.ownerID);
	// console.log("isOwner - ", isOwner);
	// console.log("isFolder - ", isFolder);
	// console.log("");

	// useEffect set onMount
	useEffect(() => {
		const {
			itemID,
			mediaType,
			ownerID,
			thumbnailURL,
			thumbnailSignedURL,
			title,
		} = item;

		setIsSelected(currentItemID === itemID ? true : false);

		// TODO: set isAnalysisOwner

		setIsOwner(userID === ownerID);

		// MediaType & Thumbnail
		switch (mediaType) {
			case "FOLDER":
				setIsFolder(true);
				setProjectThumbnail(
					<div className="card-img-top project-card-thumbnail text-center align-self-middle">
						<ProjectsIcon fixedWidth={true} />
					</div>
				);
				break;
			case "AUDIO":
				setProjectThumbnail(
					<div className="card-img-top project-card-thumbnail text-center align-self-middle">
						<ProjectsAudioIcon fixedWidth={true} />
					</div>
				);
				break;
			default:
				setIsVideo(true);
				const src =
					"development" === process.env.NODE_ENV
						? videoPlaceholderImg
						: thumbnailSignedURL
						? thumbnailSignedURL
						: `${URL.TRANSOUT}${ownerID}/${itemID}/${thumbnailURL}`;
				setProjectThumbnail(
					<Card.Img
						variant="top"
						src={src}
						alt={title}
						className="project-card-thumbnail"
					/>
				);
		}
		setProjectTitle(title);
	}, []);

	useEffect(() => {
		setIsSelected(currentItemID === item.itemID ? true : false);
	}, [currentItemID]);

	useEffect(() => {
		setIsOwner(userID === item.ownerID);
	}, [userID, item.ownerID]);

	useEffect(() => {
		if (document.getElementById("titleInput")) {
			document.getElementById("titleInput").focus();
		}
	}, [isEditingTitle]);

	const openProject = () => {
		const { itemID, mediaType, itemAnalyses } = item;
		// console.log(`${mediaType} itemID: ${itemID} - `, itemAnalyses);
		if (mediaType === "FOLDER") {
			navigate(`${ROUTES.PROJECTS}/${itemID}`);
		} else {
			navigate(`${ROUTES.ANALYSIS}/${itemAnalyses[0].itemAnalysisID}`);
		}
	};

	const updateFolderTitle = async () => {
		setIsEditingTitle(false);
		const { itemID, title } = item;

		if ("" === projectTitle.trim()) {
			setProjectTitle(title);
			return;
		}
		if (projectTitle !== title) {
			const res = await projectUpdate(itemID, "title", projectTitle);
			if (res.error) {
				console.log("updateUserProject Error: ", res.error);
				notify.error(res.error);
				return;
			}
		}
	};

	const updateFolderBoolean = async (key) => {
		const value = item[key] || false;
		const res = await projectUpdate(item.itemID, key, !value);
		if (res.error) {
			notify.error(res.error);
			return;
		}
	};

	const projectUpdate = async (itemID, key, value) => {
		const updatedItem = {
			itemID,
			[key]: value,
		};
		return await dispatch(updateFolder(updatedItem));
	};

	const moveItem = (itemID, folderID, newFolderID) => {
		console.log("=== moveItemCalled. item ===");
		console.log("moving ", itemID);
		console.log("from ", folderID);
		console.log("to ", newFolderID);
		dispatch(removeProjectChild({ folderID: folderID, childItemID: itemID }));
		projectUpdate(itemID, "folderID", newFolderID);
	};

	const [{ isDragging }, drag] = useDrag({
		type: "project", // type of dnd items accepted
		item: () => {
			// whatis passed "drop" method in function below
			return { itemID: item.itemID, folderID: item.folderID };
		},
		// end: (item, monitor) => {
		// 	// const dropResult = monitor.getDropResult();
		// 	// console.log("useDrag end item - ", item);
		// 	// console.log("useDrag end dropResult - ", dropResult);
		// 	// if (item && dropResult) {
		// 	// 	moveItem(item.item, dropResult.item);
		// 	// }
		// },
		collect: (monitor) => {
			// console.log("useDrag collect");
			return {
				isDragging: monitor.isDragging(),
				// isDragging: !!monitor.isDragging(),
				// handlerId: monitor.getHandlerId(),
			};
		},
	});

	const [{ canDrop, getDrop, isHovering }, drop] = useDrop(
		() => ({
			accept: "project",
			drop: (droppedItem, monitor) => {
				if (!isFolder) return;
				// const dropResult = monitor.getDropResult();
				// console.log("dropped item - ", droppedItem);
				// console.log("dropped into  - ", item.itemID);
				// console.log("dropped getDrop - ", getDrop);

				// if (item && dropResult) {
				// 	moveItem(item.item, dropResult.item);
				// }
				// console.log(`Dropped. canDrop is ${canDrop} item - `, item);
				// if (item && dropResult) {
				moveItem(droppedItem.itemID, droppedItem.folderID, item.itemID);
				// }
				return { newfolderID: item.itemID };
			},
			collect: (monitor) => {
				// console.log(" useDrop collected - ", monitor.canDrop());
				return {
					// isOver: !!monitor.isOver()
					isHovering: isFolder && monitor.isOver(),
					canDrop: isFolder,
					getDrop: monitor.getDropResult(),
				};
			},
		}),
		[isFolder]
	);

	drag(drop(ref));

	return (
		<Col
			// ref={drag}
			ref={ref}
			// ref={isFolder ? drop : drag}
			className="project-card-col"
			style={{ opacity: isDragging ? 0 : 1 }}
		>
			<Card
				onClick={() => dispatch(setCurrentFolderID(item.itemID))}
				onDoubleClick={(e) => openProject(item.itemID, item.mediaType)}
				className="project-card"
				border={isHovering ? "success" : isSelected ? "primary" : "muted"}
			>
				<div className="project-card-dropdown">
					<ProjectSettingsDropdown
						isFolder={isFolder}
						isOwner={isOwner}
						item={item}
						onBooleanClick={(key) => updateFolderBoolean(key)}
						onRenameClick={() => setIsEditingTitle(true)}
						onOpenClick={() => openProject()}
					/>
				</div>

				{projectThumbnail}

				<div className="project-card-overlay">
					{!isFolder ? (
						<div className="duration">{formatVideoTime(item.durationSeconds)}</div>
					) : null}

					{item.locked ? <LockIcon className="icons" variant="primary" /> : null}

					{item.confidential ? (
						<ShieldCheckIcon className="icons" variant="primary" />
					) : null}
				</div>

				<Card.Header className="px-3 pt-2 project-card-header">
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={<Tooltip id="button-tooltip">{projectTitle}</Tooltip>}
					>
						<Card.Title
							className="fs-truncate"
							style={{ width: "200px" }}
							onClick={isClickable ? () => setIsEditingTitle(true) : null}
						>
							{isEditingTitle === false ? (
								projectTitle
							) : (
								<Form.Control
									type="text"
									id="titleInput"
									defaultValue={projectTitle}
									onChange={(e) => setProjectTitle(e.target.value)}
									onBlur={() => updateFolderTitle()}
									onKeyDown={(e) =>
										e.code === "Enter" || e.code === "NumpadEnter"
											? updateFolderTitle(item)
											: null
									}
								></Form.Control>
							)}
						</Card.Title>
					</OverlayTrigger>
					<Card.Subtitle className="text-muted">
						{/* <Avatar size="md" initials={item.initials} /> */}
						{ownerNameStr(item.name)}
						<span className="px-2">&#8226;</span>
						{formatLocalDate(item.dateCreated)}
					</Card.Subtitle>
					{/* <Badge className="my-2" bg="primary">
						In progress
					</Badge> */}
				</Card.Header>
				{/* <Card.Body className="px-4 pt-2">
					<Card.Subtitle className="mb-1">
						{item.name}
						{formatLocalDate(item.dateCreated)}
						{"    "}
					</Card.Subtitle>
				</Card.Body> */}
				{/* <ListGroup variant="flush">
					<ListGroup.Item className="px-4 pb-4">
						<p className="mb-2 fw-bold">
							Progress<span className="float-end">25%</span>
						</p>
						<ProgressBar className="progress-sm" now={25} />
					</ListGroup.Item>
				</ListGroup> */}
			</Card>
		</Col>
	);
};

export default ThumbCard;
