import { COUNTRY_CODES } from "constants/";

export function getCountryName(countryCode) {
	const country = COUNTRY_CODES.find((country) => {
		return country.code === countryCode;
	});
	if (country.name === undefined) return "";
	return country.name;
}

/**
 * Converts a string to currency format with 2 decimal places and comma separating
 * Example 1: formatCurrency(1020) returns 10.20
 * Example 2: formatCurrency(109920) returns 1,099.20
 * @param {String} amount of numbers without decimal points
 * @param {String} currency default is $
 */
export function formatCurrency(amount, currency = "$") {
	return `${currency}${(amount / 100)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}`;
}
