import { API } from "aws-amplify";

const apiName = "APIGateway";
const apiInit = {
	response: true, // REQUIRED (returns the entire Axios response object instead of only response.data - Dmio API has no response.data object)
};

const apigateway = {
	get: async (path, params = {}) => {
		return API.get(apiName, path, { ...apiInit, ...params })
			.then((response) => {
				console.log(`apigateway.get ${path} SUCCESS: `, response);
				console.log("apigateway.get PARAMS: ", { ...apiInit, ...params });
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				// console.log("apigateway.get FAILED: ", error);
				return Promise.reject(error);
			});
	},
	post: async (path, body, params = {}) => {
		return API.post(apiName, path, {
			...apiInit,
			...params,
			body: { ...body },
		})
			.then((response) => {
				// console.log("apigateway.post SUCCESS: ", response);
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				// console.log("apigateway.post FAILED: ", error);
				return Promise.reject(error);
			});
	},
	delete: async (path) => {
		return API.del(apiName, path, { ...apiInit })
			.then((response) => {
				// console.log("apigateway.delete SUCCESS: ", response);
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				// console.log("apigateway.delete FAILED: ", error);
				return Promise.reject(error);
			});
	},
};

export default apigateway;
