import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userTagsData } from "./dashboardTags.mock";
import axios from "axios";
import {
	createUserDashboardTag,
	deleteUserDashboardTag,
	fetchUserDashboardTags,
	updateUserDashboardTag,
} from "./dashboardTags.actions";

const initialState = {
	entities: null,
	currentEntityID: undefined,
	status: "idle",
};

const dashboardTagsSlice = createSlice({
	name: "dashboardTags",
	initialState,

	reducers: {
		setCurrentDashboardTagID: (state, action) => {
			state.currentEntityID = action.payload;
		},
	},

	extraReducers: {
		reset: () => initialState,

		/** fetch usertags */
		[fetchUserDashboardTags.pending]: (state, action) => {
			if (state.status === "idle") {
				state.status = "pending";
			}
		},
		[fetchUserDashboardTags.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = action.payload;
		},
		[fetchUserDashboardTags.rejected]: (state, action) => {
			state.status = "idle";
		},

		/** createUserDashboard */
		[createUserDashboardTag.pending]: (state, action) => {
			if (state.status === "idle") {
				state.status = "pending";
			}
		},
		[createUserDashboardTag.fulfilled]: (state, action) => {
			state.status = "idle";
			// state.entities[action.payload.dashboardTagID] = action.payload;
			state.entities = {
				...state.entities,
				[action.payload.dashboardTagID]: action.payload,
			};
			state.currentEntityID = action.payload.dashboardTagID;
		},
		[createUserDashboardTag.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** deleteUserDashboard */
		[deleteUserDashboardTag.pending]: (state, action) => {
			if (state.status === "idle") {
				state.status = "pending";
			}
		},
		[deleteUserDashboardTag.fulfilled]: (state, action) => {
			state.status = "idle";
			if (state.currentEntityID === action.payload)
				state.currentEntityID = undefined;
			delete state.entities[action.payload]; // payload is dashboardTagID
		},

		[deleteUserDashboardTag.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** updateUserDashboard */
		[updateUserDashboardTag.pending]: (state, action) => {
			if (state.status === "idle") {
				state.status = "pending";
			}
		},
		[updateUserDashboardTag.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities[action.payload.dashboardTagID] = action.payload; // payload is updated tag object
		},
		[updateUserDashboardTag.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentDashboardTagID } = dashboardTagsSlice.actions;
export default dashboardTagsSlice.reducer;
