import React from "react";
import { Card, Col, Row, Tab } from "react-bootstrap";

import useAppSelector from "hooks/useAppSelector";

import { Billing } from "./Billing";
import { Account as AccountInfo } from "./Account";
import { Integrations } from "./Integrations";
import Members from "./Members";
import { Preferences } from "./Preferences";
import Profile from "./Profile";
import Security from "./Security";

const TabPaneCard = ({ children, size = 12 }) => (
	<Row>
		<Col xl={size}>
			<Card>
				<Card.Body>{children}</Card.Body>
			</Card>
		</Col>
	</Row>
);

const PageTabPanes = () => {
	const { isOrganization } = useAppSelector((state) => state.account.entity);

	return (
		<Tab.Content>
			<Tab.Pane eventKey="#profile">
				<h1>Your Profile</h1>
				<TabPaneCard size={10}>
					<Profile />
				</TabPaneCard>
			</Tab.Pane>
			<Tab.Pane eventKey="#account">
				<h1>Your Plan & Usage</h1>
				<AccountInfo />
			</Tab.Pane>
			<Tab.Pane eventKey="#preferences">
				<h1>Your Preferences</h1>
				<Preferences />
			</Tab.Pane>
			<Tab.Pane eventKey="#integrations">
				<h1>Your Integrations</h1>
				<Integrations />
			</Tab.Pane>
			{isOrganization ? (
				<React.Fragment>
					<Tab.Pane eventKey="#members">
						<h1>Manage Members</h1>
						<TabPaneCard>
							<Members />
						</TabPaneCard>
					</Tab.Pane>
					<Tab.Pane eventKey="#reports">
						<h1>Reports</h1>
					</Tab.Pane>
				</React.Fragment>
			) : null}
			<Tab.Pane eventKey="#billing">
				<h1>Billing</h1>
				<Billing />
			</Tab.Pane>
			<Tab.Pane eventKey="#security">
				<h1>Security</h1>
				<TabPaneCard>
					<Security />
				</TabPaneCard>
			</Tab.Pane>
		</Tab.Content>
	);
};

export default PageTabPanes;
