import "./modal.scss";

import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { Form, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { Button } from "components/buttons/";
import { OpenIcon } from "components/icons";
import { LoadingSpinner } from "components/loaders";

import { fetchUserDashboards } from "features/dashboards/dashboards.actions";
import {
	fetchItemAnalysis,
	updateItemAnalysis,
} from "features/itemAnalyses/itemAnalyses.actions";

import { ROUTES } from "constants/";

export const ProjectDashboardsModal = ({ title, itemAnalysisID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notify = useContext(NotifyContext);
	const { closeModal, showModal } = useModalContext();

	const { entities: dashboards } = useSelector((state) => state.dashboards);
	const itemAnalysis = useSelector(
		(state) => state.itemAnalyses.entities?.[itemAnalysisID]
	);

	const [loading, setLoading] = useState(false);
	const [allDashboards, setAllDashboards] = useState([]);
	const [projectDashboards, setProjectDashboards] = useState([]);

	// console.log("==ProjectDashboardsModal==");
	// console.log("title - ", title);
	// console.log("itemAnalysisID - ", itemAnalysisID);
	// console.log("itemAnalysis - ", itemAnalysis);
	// console.log("dashboards - ", dashboards);
	// console.log("allDashboards - ", allDashboards);
	// console.log("projectDashboards - ", projectDashboards);
	// console.log("");

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (null === dashboards) await dispatch(fetchUserDashboards());
			if (undefined === itemAnalysis)
				await dispatch(fetchItemAnalysis(itemAnalysisID));
			setLoading(false);
		}
		if (null === dashboards || undefined === itemAnalysis) fetchData();
	}, []);

	// Makes an array of all dashboards so they can be mapped, as well as an object displaying if each dashboard is in the current project's list (projectDashboards)
	// ex. {
	// 		dashboardID_1: false,
	// 		dashboardID_2: true,
	// 	}
	useEffect(() => {
		const orderedDashboards = _.orderBy(dashboards, ["title"], ["asc"]);
		setAllDashboards(orderedDashboards);
	}, [dashboards]);

	useEffect(() => {
		if (undefined !== itemAnalysis) setProjectDashboards(itemAnalysis.dashboards);
	}, [itemAnalysis]);

	const onCheckboxClick = (dashboardID) => {
		var tmpProjectDashboards = [...projectDashboards];
		const idx = projectDashboards.indexOf(dashboardID);
		// is not checked - add to projectDashboards
		if (-1 === idx) {
			tmpProjectDashboards.push(dashboardID);
		}
		// is checked - remove from projectDashboards
		else {
			tmpProjectDashboards.splice(idx, 1);
		}
		setProjectDashboards(tmpProjectDashboards);
	};

	const onEditClick = () => {
		navigate(ROUTES.DASHBOARDS);
		closeModal();
	};

	const onSaveClick = async () => {
		const updatedItemAnalysis = {
			itemAnalysisID: itemAnalysis.itemAnalysisID,
			dashboards: [...projectDashboards],
		};

		const res = await dispatch(updateItemAnalysis(updatedItemAnalysis));
		if (res.error) {
			notify.error(res.error);
			return;
		}
		closeModal();
	};

	const renderDashboards = allDashboards.map(({ dashboardID, title }) => (
		<ListGroupItem
			action
			className="modal-list-item d-flex justify-content-between"
			key={dashboardID}
			onClick={() => onCheckboxClick(dashboardID)}
		>
			{/* <label>{title}</label> */}
			<Form.Check
				label={title}
				type="checkbox"
				checked={projectDashboards.includes(dashboardID)}
				onChange={() => onCheckboxClick(dashboardID)}
			/>
		</ListGroupItem>
	));

	return (
		<Modal show={showModal} onHide={() => closeModal()}>
			<Modal.Header>
				<Modal.Title className="d-flex justify-content-between">
					<>Project Dashboards</>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ maxHeight: "20rem", overflow: "scroll" }}>
				<ListGroup variant="flush">
					{loading ? <LoadingSpinner /> : renderDashboards}
				</ListGroup>
			</Modal.Body>
			<Modal.Footer className="d-flext justify-content-between">
				<Button variant="link" onClick={() => onEditClick()}>
					<OpenIcon className="px-2" />
					Edit dashboards
				</Button>
				<span>
					<Button
						variant="outline-danger"
						className="mx-2"
						onClick={() => closeModal()}
					>
						Cancel
					</Button>
					<Button variant="success" onClick={() => onSaveClick()}>
						Save
					</Button>
				</span>
			</Modal.Footer>
		</Modal>
	);
};
