import { Button as BSButton } from "react-bootstrap";

import { ButtonLoadingIcon } from "components/icons/";

export const Button = ({
	children,
	// className,
	className = "",
	disabled = false,
	id,
	loading,
	onClick,
	size = "",
	style,
	title,
	variant = "primary",
}) => (
	<BSButton
		disabled={disabled}
		id={id}
		variant={variant}
		size={size}
		className={`rounded px-3  ` + className}
		style={style}
		onClick={onClick}
	>
		{children}
		{!loading || <ButtonLoadingIcon />}
	</BSButton>
);
