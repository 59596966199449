import React from "react";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { AuthSubmitButton } from "components/buttons/";

import useAuth from "hooks/useAuth";
import { ROUTES } from "constants/";

function SignUp() {
	const navigate = useNavigate();
	const { signUp } = useAuth();

	return (
		<Formik
			initialValues={
				"development" === process.env.NODE_ENV
					? {
							name: "xx",
							email: "xx1@dreamaker.io",
							password: "$321Rewq",
							confirmPassword: "$321Rewq",
							submit: false,
					  }
					: {
							name: "",
							email: "",
							password: "",
							confirmPassword: "",
							submit: false,
					  }
			}
			validationSchema={Yup.object().shape({
				name: Yup.string().max(255).required("First name is required"),
				email: Yup.string()
					.email("Must be a valid email")
					.max(255)
					.required("Email is required"),
				password: Yup.string()
					.min(8, "Must be at least 8 characters")
					.max(255)
					.required("Required"),
				confirmPassword: Yup.string().oneOf(
					[Yup.ref("password"), null],
					"Passwords must match"
				),
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				// console.log("values: ", values);
				try {
					await signUp(values);
					navigate(ROUTES.VERIFY);
				} catch (error) {
					setStatus({ success: false });
					setErrors({ submit: error.message || "Something went wrong" });
					setSubmitting(false);
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<div className="text-danger" style={{ minHeight: "2em" }}>
						{errors.submit ? errors.submit : " "}
					</div>

					<Form.Group className="mb-3">
						<Form.Label>First name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							placeholder="First name"
							value={values.name}
							isInvalid={Boolean(touched.name && errors.name)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.name && (
							<Form.Control.Feedback type="invalid">
								{errors.name}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Email address</Form.Label>
						<Form.Control
							type="email"
							name="email"
							placeholder="Email address"
							value={values.email}
							isInvalid={Boolean(touched.email && errors.email)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.email && (
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							name="password"
							placeholder="Password"
							value={values.password}
							isInvalid={Boolean(touched.password && errors.password)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.password && (
							<Form.Control.Feedback type="invalid">
								{errors.password}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type="password"
							name="confirmPassword"
							placeholder="Password"
							value={values.confirmPassword}
							isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{!!touched.confirmPassword && (
							<Form.Control.Feedback type="invalid">
								{errors.confirmPassword}
							</Form.Control.Feedback>
						)}
					</Form.Group>
					<div className="text-center mt-3">
						<AuthSubmitButton title="Sign up" disabled={isSubmitting} />
						<p>
							Already have an account? <Link to={ROUTES.SIGNIN}>Sign In</Link>
						</p>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default SignUp;
