import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Button } from "react-bootstrap";

const TranscriptionAnalysis = () => {
	return (
		<div className="w-100 px-2">
			<div className="d-flex justify-content-between mb-2">
				<h6 className="m-0 py-2">Transcriptions</h6>
				<Button variant="outline-dark" style={{ border: "none" }}>
					Manage Transcriptions <FontAwesomeIcon icon={regular("gear")} />
				</Button>
			</div>
			<Button>
				<FontAwesomeIcon icon={solid("bullhorn")} /> Manage Transcriptions
			</Button>
		</div>
	);
};

export default TranscriptionAnalysis;
