import "./projects.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ProgressBar, Card, Col } from "react-bootstrap";

import { ownerNameStr } from "utils/";

import { formatLocalDate, formatReadableTime } from "utils/";

import { PROGRESS_STATUS } from "constants/";
const { CALCULATING, PROGRESSING, UPLOADSTARTED } = PROGRESS_STATUS;

const ThumbUploadingCard = ({ item, userID }) => {
	// console.log("item - ", item);

	const itemStats = useSelector((state) => state.uploader.entities[item.itemID]);

	const [percentage, setPercentage] = useState(false);
	const [status, setStatus] = useState(PROGRESSING);
	const [statusStr, setStatusStr] = useState();

	// console.log("==UploadingCard==");
	// console.log("itemStats - ", itemStats);
	// console.log("percentage - ", percentage);
	// console.log("statusStr - ", statusStr);
	// console.log("");

	useEffect(() => {
		itemStats
			? setStatus(itemStats.progressStatus)
			: setStatus(item.transcodeProgress);
	}, []);

	useEffect(() => {
		if (itemStats) {
			setPercentage((itemStats.percentage * 100).toFixed(1));
		}
		// setPercentage(25);
	}, [itemStats?.percentage]);

	useEffect(() => {
		const { mediaSource } = item;
		// console.log("transcodeProgress - ", transcodeProgress);
		// console.log("mediaSource - ", mediaSource);
		// console.log("progressStatus - ", itemStats.progressStatus);

		const useStatus = itemStats
			? itemStats.progressStatus
			: item.transcodeProgress;

		setStatus(useStatus);

		switch (useStatus) {
			// switch (transcodeProgress) {
			case UPLOADSTARTED:
				setStatusStr(
					["zoom"].includes(mediaSource)
						? `Importing from ${mediaSource}`
						: "Uploading file"
				);
				break;

			case PROGRESSING:
				setStatusStr("Preparing project");
				break;

			case CALCULATING:
				setStatusStr(`Calculating`);
				break;

			default:
				break;
		}
	}, [item, itemStats?.progressStatus]);

	// useEffect set onMount
	useEffect(() => {}, []);

	return (
		<Col className="project-card-col">
			<Card className="project-card" border="muted">
				<div className="card-img-top  project-card-thumbnail text-center">
					<h5 className="mt-5 mb-2 upload-progress text-primary">{statusStr}</h5>
					{UPLOADSTARTED === status && !["zoom"].includes(item.mediaSource) ? (
						<>
							<ProgressBar
								animated={UPLOADSTARTED === status ? true : false}
								variant="primary"
								className="mx-2 progress"
								now={percentage}
								label={`${percentage}%`}
							/>
							<p className="pt-1">
								{formatReadableTime(itemStats.stats.secondsLeft)} left
							</p>
						</>
					) : (
						<p>Your project is being prepared. We'll email you when its ready.</p>
					)}
				</div>

				<Card.Header className="px-3 pt-2 project-card-header">
					<Card.Title>{item.title}</Card.Title>
					<Card.Subtitle className="text-muted">
						{ownerNameStr(item.name)}
						<span className="px-2">&#8226;</span>
						{formatLocalDate(item.dateCreated)}
					</Card.Subtitle>
				</Card.Header>
			</Card>
		</Col>
	);
};

export default ThumbUploadingCard;
