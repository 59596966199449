module.exports = {
	// app
	fetchPlans: "plan", // gets all live Dmio plans
	// appGetVersion: "version",
	// appFetchTemplates: "template", // PATCH. Fetches all live templates.

	// user account & plan
	accountGet: "account",

	// billing
	billingGet: "payment/billing", // GET billing details
	billingUpdate: "payment/billing/update", // after calling payment/billing/beforeupdate, used to finalize update of billing card
	billingUpdateSetupIntent: "payment/billing/beforeupdate", // for stripe SCA, used to create stripe setupIntent to update the billing card
	billingChangePlan: "payment/changeplan", // used for updating payment plan

	// Share & Collaborate
	collaborationInvite: "folderitem/collaboration/invite", // Bulk share & collaborator invite, post
	collaborationLeave: "folderitem/collaboration/leave?", // User chooses to leave a share or collaborate; itemAnalysisID= OR itemID=
	collaborationRevoke: "folderitem/collaboration/revoke?userID=", // Revoke user access; (itemAnalysisID= OR itemID=)  AND &userID=

	// dashboards
	dashboardsGet: "dashboard", // POST gets all user Dashboards if no body, else specific dashboards
	dashboardCreate: "dashboard/create", // POST Creates a dashboard
	dashboardUpdate: "dashboard/update", // POST Updates a dashboard
	dashboardDelete: "dashboard?dashboardID=", // DLELETEs dashboard if deleteDashboardTags=true

	// dashboard Tags
	dashboardTagsGet: "dashboard/tag", // GET gets all user Dashboards if no body, else specific dashboards
	dashboardTagCreate: "dashboard/tag/create", // POST Creates a dashboard
	dashboardTagUpdate: "dashboard/tag/update", // POST Updates a dashboard
	dashboardTagDelete: "dashboard/tag?dashboardTagID=", // DLELETEs dashboard if deleteDashboardTags=true

	// folders, projects, items
	folderGet: "folder?folderID=", // NEW 'folder?folderID=0'; GETS folder details with a list of child itemIDs.
	folderGetChildren: "folder/children?folderID=", // UPDATE "/folder/children?folderID=" GETS child items of folder, returning an Object with key-value
	folderCreate: "folder/create", // UPDATE item/create; POST Creates a folder or project item
	folderUpdate: "folder/update", // UPDATE item/update; POST Updates a folder or project item

	itemDelete: "item?itemID=", // UPDATE item/delete?itemID=; DEL a folder or project item params itemID & deleteDependencies

	// analysis - itemAnalysis
	itemAnalysisGet: "itemanalysis?itemAnalysisID=", //&lobsList=transcription_en,transcription_fr,notes
	// itemAnalysisCreate: "itemanalysis/create",
	itemAnalysisUpdate: "itemanalysis/update",
	// itemAnalysisDelete: "itemanalysis/delete",
	itemAnalysisExport: "itemanalysis/export?itemAnalysisID=",
	itemAnalysisVisibility: "itemanalysis/visibility", // POST { itemAnalysisID: 'abc-xde', mode: 'PUBLIC' || 'PRIVATE'}

	projectCreate: "item/create",
	projectUpdate: "item/update",
	projectGet: "item?itemID=", // Gets a single items
	projectSend: "item/send", // POST - send to person
	projectCopy: "item/copy", // POST - send to person
	// projectSignUrl: "item/signurl?", // currently not in use
	// projectTranlate: "item/translate",
	// projectTranscribe: "item/transcribe",

	// lti
	ltiAssignmentDetach: "lti/assignment/detach", // POST { itemAnalysisID: "97b16d18-1638-4de3-933c-8aa9977f46cf" }
	ltiAssignmentAttach: "lti/assignment/attach", // POST { 'itemAnalysisID', 'ltiConsumerID', 'ltiAssignmentID', 'ltiAssignmentTitle'}
	ltiConsumerAdd: "lti/consumer/add", // POST { ltiConsumerID: "moodle.nownow.co.za" }
	ltiConsumerReset: "lti/consumer/update", // POST { ltiConsumerID: "moodle.nownow.co.za", rollConsumerSecret: true }
	ltiGradeGet: "lti/gradeget?itemAnalysisID=", // GET e467ab06-f477-416c-9039-f6f70a509079"
	ltiGradePassback: "lti/gradepassback", // POST {resultScore": 0.01, itemAnalysisID: <itemAnalysisID> }

	// Reports
	ltiAttachReport: "report/ltiassignmentattachment", // GET

	//markers
	markersGet: "/marker", // POST
	markerUpdate: "marker/update", // POST
	markerCreate: "marker/create", // POST
	markerDelete: "marker?markerID=", //DELETE

	// Organization & Membersembers
	memberInvite: "organization/invite?", // Invite member to organization - email or userID
	memberRevoke: "organization/revoke?", // Revoke member from organization - email or userID
	memberAccept: "organization/accept?organizationID=", // Accept invitation to organizationID
	memberDecline: "organization/decline?organizationID=", // Decline invitation to organizationID
	memberLeave: "organization/leave?organizationID=", // Leave invitation to organizationID
	membersFetch: "organization", // Fetches all members in organization
	organizationActivate: "organization/changeactiveorganization?organizationID=", //
	organizationUpdate: "organization/update", // POST with {autoMemberAtSignup Boolean true | false}

	// user
	userGet: "user",
	userGetCookies: "user/cookies",
	userGetSharedCookies: "user/cookies?itemID=",
	userUpdate: "user",
	userEmailContains: "user?emailContains=", // Fetches all users where email contains entered string

	// Zoom
	zoomImportRecording: "zoom/recordings/import", // POST {downloadUrl: "https://zoom.us/rec/download/7sckIu2srz43HtGctgSDA_B-W9S4KK2s1HIf8vtcyxrmB3cLY1Sjb-MTNrSeXZSliHfLSBisID-l_i2o","itemID": "05e7e6a5-76c7-4ca6-8bfe-989f44b03fe8"}
	zoomInitiateAuthorization: "oauth/zoom/initiateauthorization?folderID=", // GET
	zoomFetchRecordings: "zoom/recordings", // GET
	zoomRetainData: "oauth/zoom/userdata/retain?oauthUserID=", //POST retains data imported from zoom (gives permission)
	zoomRevokeAuthorization: "oauth/zoom/revokeauthorization", // GET
	zoomFetchImportedItems: "oauth/zoom/userdata", // GET {oauthUserID: option, pendingDelete: true|false options}
	zoomDeleteImportedItems: "oauth/zoom/userdata?oauthUserID=", // DELETE {oauthUserID: option, pendingDelete: true|false options}                                                     // GET. Gets all live templates with makrers, dashboards and dashboardTags

	// // anaylsis - event tags
	// eventTagsFetch: "folderitem/eventtag?itemAnalysisID=",
	// eventTagUpdate: "folderitem/eventtag/update",
	// eventTagCreate: "folderitem/eventtag/create",
	// eventTagDelete: "folderitem/eventtag",

	// // analysis - replies
	// eventTagReplyCreate: "folderitem/eventtag/reply/create",
	// eventTagReplyUpdate: "folderitem/eventtag/reply/update",
	// eventTagReplyDelete: "folderitem/eventtag/reply?eventTagReplyID=",

	// charts
	//chartFetch
	// chartCreate: "itemanalysis/chart/create",
	// chartUpdate: "itemanalysis/chart/update", // Handles updating chart metadata as well as child datasets (incl. delete)
	// chartDatasetCreate: "itemanalysis/chart/dataset/create",

	// FILE ASSETS
	// fileAssetCreate: "itemanalysis/file/create", // POST {itemAnalysisID, title, fileType, locked}
	// fileAssetGetLink: "itemanalysis/file/download?fileID=", // GET
	// fileAssetUpdate: "itemanalysis/file/update", // POST {fileID, title, locked, confidential, deleted}

	// // Share & Collaborate
	// userInvite: "folderitem/collaboration/invite", // Bulk share & collaborator invite, post
	// userRevoke: "folderitem/collaboration/revoke?", // Revoke user access; (itemAnalysisID= OR itemID=)  AND &userID=
	// userLeave: "folderitem/collaboration/leave?", // User chooses to leave a share or collaborate; itemAnalysisID= OR itemID=
};
