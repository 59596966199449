/**
 * Custom video-react component which to replace video-react's
 * ReplayControl & ForwardControl buttons. This component allows
 * for user's preference of the number of seconds to scan forwards
 * and replay by.
 *
 * video-react's ReplayControl & ForwardControl only allow for
 * scans of 5, 10, and 30 increments.
 */
// import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

// const propTypes = {
// 	className: PropTypes.string,
// 	player: PropTypes.object,
// 	replay: PropTypes.bool,
// 	seconds: PropTypes.number,
// };

export const ScanControl = (props) => {
	const { actions, replay, seconds } = props;

	const handleClick = replay ? actions.replay : actions.forward;

	return (
		<button
			// ref={(c) => {
			// 	this.button = c;
			// }}
			className={classNames({
				"video-react-control": true,
				"video-react-button": true,
				"video-react-icon": true,
				"video-react-icon-forward-5": !replay,
				"video-react-icon-replay-5": replay,
				"video-react-forward-control": true,
			})}
			tabIndex="0"
			onClick={(e) => handleClick(seconds)}
		></button>
	);
};

// ScanControl.propTypes = propTypes;
