export const APP_OPTIONS = {
	LEAD_TIME: 2, // for tagged event, default lead time to save
	LAG_TIME: 2, // for tagged event, default lead time to save
};

/**
 * PlanID's used in app...
 * planID: true --> when planID in DynamoDB is `active`
 * LIVE: planID --> `live` planID in DynamoDB
 */
export const PLANID = {
	FREE: {
		"BASIC-2017-01-13": true,
		"BASIC-2017-09-07": true,
		"BASIC-2019-01-15": true,
		LIVE: "BASIC-2019-01-15",
	},
	TRIAL: "TRIAL-PROFESSIONAL-2018-09-21",
};

// import moodleLogo from 'assets/images/3rdparty/moodle-logo.png';
// import zoomLogo from 'assets/images/3rdparty/zoom-icon-circle.png'
// export const IMAGES = {
// 	ZOOM_LOGO: zoomLogo,
// 	MOODLE_LOGO: moodleLogo
// }

/**
 * ERRORS specific to invites.
 * See https://dreamakerio.atlassian.net/browse/DMIO-362
 **/
export const INVITE_ERRORS = {
	EXCEEDED: "EXCEEDED", // invite type limit exceeded
	NOTIFICATION: "NOTIFICATION", // failure notifying client (invite succeeded)
	OTHER: "OTHER", // other error
};

/** Different kinds of invites */
export const INVITE_TYPES = {
	COLLABORATE: "COLLABORATE",
	// SHARE: "SHARE",
	ORG_MEMBERS: "ORG_MEMBERS",
	SEND: "SEND",
};

/** Different kinds of invite statuses */
export const INVITE_STATUS = {
	/** Official Lambda/Dynamo */
	ACCEPTED: "ACCEPTED",
	DECLINED: "DECLINED",
	INVITED: "INVITED",
	LEFT: "LEFT",
	REVOKED: "REVOKED",

	/** APP SPECIFIC */
	LOADING: "LOADING",
	ERROR_INVITING: "ERROR INVITING", // used in Member invitations
	ERROR_DEACTIVATING: "ERROR DEACTIVATING", // used in Member invitations
};

export const PROGRESS_STATUS = {
	COMPLETED: "COMPLETED", // transcode, transcribe, file upload - successful completion
	CALCULATING: "CALCULATING", // transcode, file upload - upload initiated but not yet started
	ERROR: "ERROR", // transcode, transcribe, file upload - unsuccessful completion
	INPROGRESS: "IN_PROGRESS", // transcribe in progress
	PROGRESSING: "PROGRESSING", // transcode, file upload - transcode progressing; file uploaded - copy & transcode progressing
	WARNING: "WARNING", // transcode - successful completion with warning
	UPLOADSTARTED: "UPLOADSTARTED", // transcode, file upload - upload has started
};

/**
 * LOB constants used in temAnlaysis.GET and item.GET
 *
 * LOBS Formats:
 * =============
 * LOBS.CHART:  JSON.
 * LOBS.FILE: todo: ???
 * LOBS.NOTES:  JSON. See Deltas for quill.js
 * LOBS.TRANSCRIPTION: JSON
 * LOBS.TRANSCRIPTION_AWS: JSON
 * LOBS.TRANSLATION: JSON
 */
export const LOB_KEYS = {
	CHART: "chartObject", // followed by a single digit for version
	FILE: "file",
	NOTES: "notes", // for project notes
	TRANSCRIPTION: "transcription_", // followed by TRANSCRIBE_LANGUAGES[lang].code
	VTT: "subtitleVTT_", // followed by TRANSCRIBE_LANGUAGES[lang].code
	// TRANSCRIPTION_AWS: 'transcription_aws', // orginal transcrption
	// TRANSLATION: 'translation_',         // followed by TRANSLATE_LANGUAGES[x].code
};

export const ROLES = {
	AUTOTEACHER: "AUTO-TEACHER", // Collaborators: Specific to LTI interations with Moodle - collaborators who are teachers take on this role
};

export const SHARE_PERMISSIONS = {
	INVITE_COLLABORATOR: "INVITE-COLLABORATOR",
};

export const VISIBILITY = {
	COLLABORATORS: "COLLABORATORS",
	PRIVATE: "PRIVATE",
};

export const UNLIMITED = {
	value: 999999,
	word: "Unlimited",
	// htmlCode: '\u221E' // U+0221E //'&infin;'
	// getHtml: () => { return <span style={{content: '\u221E'}} /> }
};

// export const THUMBS = {
// 	FOLDER: folderImg,
// 	AUDIO: audioImg
// };

/**
 * Lannguages supported for transcription
 */
