import { GearIcon, RocketLaunchIcon } from "components/icons";
import { ROUTES } from "constants/";
const path = `${ROUTES.ACCOUNT}/`;
const accountSection = [
	{
		href: path + ROUTES.ACCOUNT,
		icon: GearIcon,
		title: "Account",
		children: [
			{
				href: path + ROUTES.PROFILE,
				title: "Profle",
			},
			{
				href: ROUTES.ACCOUNT + "/#account",
				title: "Plan & Usage",
			},
			{
				href: path + ROUTES.PREFERENCES,
				title: "Preferences",
			},
			{
				href: path + ROUTES.INTEGRATIONS,
				title: "Integrations",
			},
			{
				href: path + ROUTES.BILLING,
				title: "Billing",
			},
			{
				href: path + ROUTES.SECURITY,
				title: "Security",
			},
		],
	},
	{
		href: ROUTES.UPGRADE,
		icon: RocketLaunchIcon,
		title: "Upgrade",
	},
];

const navItems = [
	{
		title: "Account & Preferences",
		pages: accountSection,
	},
];

export default navItems;
