import React from "react";
import { Form } from "react-bootstrap";

export const PreferenceInput = (
	title,
	id,
	value,
	info,
	handleBlur,
	handleChange
) => (
	<tr>
		<td>{title}</td>
		<td>
			{typeof value == "boolean" ? (
				<Form.Check
					type="checkbox"
					name={id}
					id={id}
					className="pt-2"
					defaultChecked={value}
					onBlur={handleBlur}
					onChange={handleChange}
				/>
			) : (
				<Form.Control
					type="number"
					min="0"
					step="1"
					name={id}
					id={id}
					className="pt-2"
					defaultValue={value}
					onBlur={handleBlur}
					onChange={handleChange}
				/>
			)}
		</td>
		<td className="w-50">{info}</td>
	</tr>
);
