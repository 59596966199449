import { ROUTES } from "constants/";

import { ProjectsIcon } from "components/icons/featureIcons";

const projectsSection = [
	{
		href: ROUTES.PROJECTS,
		icon: ProjectsIcon,
		title: "Projects",
	},
];

const navItems = [
	{
		title: "Projects",
		pages: projectsSection,
	},
];

export default navItems;
