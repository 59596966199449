import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Card } from "react-bootstrap";
import { Button } from "components/buttons";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders";

import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { updateBillingPlan } from "features/billing/billing.actions";

import { formatLocalDate, formatCurrency, ucWords } from "utils/";
import { PLANID, ROUTES } from "constants/";

const cardHeader = (
	<Card.Header>
		<Card.Title className="mb-0">Upcoming Payments</Card.Title>
	</Card.Header>
);

function UpcomingPayments() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { openModal, closeModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { paymentPeriod, planData } = useSelector(
		(state) => state.account.entity
	);

	const {
		entity: { upcomingInvoice },
	} = useSelector((state) => state.billing);

	if (!planData || !upcomingInvoice) return <React.Fragment />;

	const renderUpcomingPaymentsInfo = () => {
		/**
		 * Get title of upcoming plan from active plans.
		 * NOTE: If active plans does not contain upcoming planID, upcomingPlanTitle is undefined
		 * (IE, inactive plan, custom plan, or organization plan)
		 **/
		var upcomingPlanTitle = upcomingInvoice.planTitle || "Code me custom plan";

		const upcomingPlan = upcomingInvoice.paymentPeriod ? (
			<b>
				{ucWords(upcomingInvoice.paymentPeriod)} {upcomingPlanTitle} Plan
			</b>
		) : (
			<b>{upcomingPlanTitle} Plan</b>
		);

		const currentPlan = (
			<p>
				You are currently on the{" "}
				<b>
					{ucWords(paymentPeriod)} {ucWords(planData.title)} Plan
				</b>
				.
			</p>
		);

		const dateDue = <b>{formatLocalDate(upcomingInvoice.date * 1000, true)}</b>;
		const amountDue = <b>{formatCurrency(upcomingInvoice.amount_due)}</b>;

		// Cancellation from a paid plan coming
		if (upcomingInvoice.cancel_at_period_end) {
			return (
				<React.Fragment>
					{currentPlan}
					<p>
						Your subscription will end on {dateDue} and your plan changed to the free{" "}
						{upcomingPlan}.
					</p>
				</React.Fragment>
			);
		}

		// upcomingInvoice account_balance shows a negative number if we owe the customer, therefore we need to divide by negative 100 in order to get the correct amount.
		// Ex: You are on the Starter plan with a Monthly subscription. You have a credit of $120 to apply to your next invoice. On Oct 25th, 18 you will be charged $0.
		if (upcomingInvoice.account_balance < 0) {
			return (
				<React.Fragment>
					{currentPlan}
					<p>
						Your account has a credit of{" "}
						{formatCurrency(-upcomingInvoice.account_balance)}. On {dateDue} you will
						be charged {amountDue} for your subscription.
					</p>
				</React.Fragment>
			);
		}

		// if the upcoming plan is not the same as the current plan
		// Ex: You're on the Starter Plan billed Yearly. On Oct 25, 2018 you will be changed to the Professional Plan and charged $15.
		if (upcomingPlanTitle !== planData.title) {
			return (
				<React.Fragment>
					{currentPlan}
					<p>
						On {dateDue} your plan will be changed to the {upcomingPlan} and you will
						be charged {amountDue}.
					</p>
				</React.Fragment>
			);
		}

		// This should only show up when the plans are the same, but the payment periods are different.
		// Ex: You're on the Starter Plan billed Yearly. On Oct 25th, 2018 your account will be changed to the monthly payment period and charged $12.
		if (
			upcomingPlanTitle === planData.title &&
			paymentPeriod !== upcomingInvoice.paymentPeriod
		) {
			return (
				<React.Fragment>
					{currentPlan}
					<p>
						On {dateDue} your plan will be changed to the {upcomingPlan} and you will
						be charged {amountDue}.
					</p>
				</React.Fragment>
			);
		}

		// Should be displayed if everything else fails. Generally this is for recurring subscriptions with no changes being made.
		// Ex: You are on the Professional Plan with a Yearly subscription. On Oct 25th, 2018 you will be charged $132.
		return (
			<React.Fragment>
				{currentPlan}
				<p>
					Your next payment of {amountDue} will be charged on {dateDue}.
				</p>
			</React.Fragment>
		);
	};

	const onCancelPlanClick = () => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			title: "Cancel Your Dreamaker.io Subscription",
			// size: "lg",
			cancelBtnTitle: "No, I'll keep my plan",
			confirmBtnTitle: "Yes, cancel my plan",
			confirmBtnAction: async () => {
				const res = await dispatch(
					updateBillingPlan({
						planID: PLANID.FREE.LIVE,
						paymentPeriod: "MONTHLY",
					})
				);

				if (res.error) {
					notify.error(res.error.message);
					return;
				}

				// console.log("SUCCESSS CANCELLING PLAN ", res);
				notify.success("Your plan has been successfully cancelled!");
				closeModal();
			},
			body: (
				<React.Fragment>
					<p>We're sorry to see you go!</p>
					<p>
						Cancelling will downgrade your subscription to the limited Free Plan at
						the end of your current billing cycle on{" "}
						<b>{formatLocalDate(upcomingInvoice.date * 1000, true)}</b>.
					</p>
					<p>
						The Free Plan has lower usage limits than your current plan - downgrading
						may require you to lower your account usage to within the Free Plan
						limits. View your usage in the Account section.
					</p>
					<p>
						Are you sure you'd like to cancel your subscription and downgrade to the
						Free Plan now?
					</p>
				</React.Fragment>
			),
		});
	};

	return (
		<Card>
			{cardHeader}
			<Card.Body>
				{renderUpcomingPaymentsInfo()}
				<hr />
				<div className="d-flex justify-content-between">
					<Button onClick={() => navigate(ROUTES.UPGRADE)}>Change Plan</Button>
					<Button variant="muted" onClick={() => onCancelPlanClick()}>
						Cancel Plan
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
}

export default UpcomingPayments;
