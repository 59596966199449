import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { ProfileForm } from "./profile/ProfileForm";

function Profile() {
	return (
		<React.Fragment>
			<ProfileForm />
		</React.Fragment>
	);
}

export default Profile;
