import React from "react";
import { Helmet } from "react-helmet-async";

import Projects from "./Projects";
import ZoomProjects from "./ZoomProjects";

const ProjectsPage = ({ zoom = false }) => (
	<React.Fragment>
		<Helmet title={zoom ? "Zoom Cloud Imports" : "Projects"} />
		{zoom ? <ZoomProjects /> : <Projects />}
	</React.Fragment>
);

export default ProjectsPage;
