import { SubmitButton } from "./SubmitButton";

export const AuthSubmitButton = ({ disabled, title }) => (
	<SubmitButton
		className="mb-3"
		disabled={disabled}
		size="lg"
		title={title}
		variant="warning"
	/>
);
