import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { ButtonLoadingIcon } from "components/icons";

import { useModalContext } from "contexts/ModalContext";

/**
 * Displays a confimration model
 * @param {*} props All props required (other than confirmBtnTitle,  cancelBtnAction)
 * @returns
 */
export const ConfirmationModal = (props) => {
	const { closeModal, showModal } = useModalContext();
	const [loadingOnConfirm, setLoadingOnConfirm] = useState(false);
	const [loadingOnCancel, setLoadingOnCancel] = useState(false);
	const {
		body,
		cancelBtnAction,
		cancelBtnClasses,
		cancelBtnTitle,
		confirmBtnAction,
		confirmBtnClasses,
		confirmBtnTitle,
		size = "",
		title,
		data,
	} = props;

	const onConfirmClick = async (e) => {
		// console.log("onConfirmClicked, data: ", data);
		setLoadingOnConfirm(true);
		if (data) {
			await confirmBtnAction({ ...data }).catch((err) => {
				setLoadingOnConfirm(false);
			});
		} else {
			await confirmBtnAction().catch((err) => {
				setLoadingOnConfirm(false);
			});
		}
	};

	const onCancelClick = (e) => {
		if (cancelBtnAction) {
			setLoadingOnCancel(true);
			cancelBtnAction(e);
		}
		closeModal();
	};

	return (
		<Modal
			size={size}
			show={showModal}
			onShow={() => {
				document.getElementById("cancelModalBtn").focus();
			}}
			onHide={onCancelClick}
		>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{body}</Modal.Body>
			<Modal.Footer>
				<Button
					id="cancelModalBtn"
					variant="outline-muted"
					size="sm"
					className={`rounded m-2 ` + cancelBtnClasses}
					onClick={onCancelClick}
				>
					{cancelBtnTitle || "Cancel"} {!loadingOnCancel || <ButtonLoadingIcon />}
				</Button>
				<Button
					autoFocus
					variant="success"
					size="sm"
					className={`rounded m-2 ` + confirmBtnClasses}
					onClick={onConfirmClick}
				>
					{confirmBtnTitle} {!loadingOnConfirm || <ButtonLoadingIcon />}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
