import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import { Container, Row } from "react-bootstrap";

import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

import { fetchEventTags } from "../analysis/eventTags.actions";
import { fetchFolder } from "../projects/projects.actions";
import { fetchUserDashboards } from "features/dashboards/dashboards.actions";
import { fetchUserMarkers } from "features/markers/markers.actions";
import { fetchUserDashboardTags } from "features/dashboardTags/dashboardTags.actions";
import { setCurrentEntityID as setCurrentProjectID } from "features/projects/projects.slice";

import { SIDEBAR_BEHAVIOR } from "constants/";
import useSidebar from "hooks/useSidebar";

import MediaPlayer from "components/Analysis/MediaPlayer";
import BottomBar from "components/Analysis/BottomBar";
import AnalysisPane from "components/Analysis/AnalysisPane";

const ItemAnalysis = () => {
	const dispatch = useAppDispatch();
	const initFetch = async () => {
		//Fetch all items, dashboards, and markers (dashboards and markers needed for modals). Also fetching all projects, but only actually need the one.
		const resultActionDashboards = await dispatch(fetchUserDashboards());
		if (fetchUserDashboards.fulfilled.match(resultActionDashboards)) {
			//...
		} else {
			console.log("Fetch failed: ", resultActionDashboards.error);
		}
		const resultActionMarkers = await dispatch(fetchUserMarkers());
		if (fetchUserMarkers.fulfilled.match(resultActionMarkers)) {
			//...
		} else {
			console.log("Fetch failed: ", resultActionMarkers.error);
		}
		const resultActionTags = await dispatch(fetchUserDashboardTags());
		if (fetchUserDashboardTags.fulfilled.match(resultActionTags)) {
			//...
		} else {
			console.log("Fetch failed: ", resultActionTags.error);
		}
		const resultActionEventTags = await dispatch(fetchEventTags());
		if (fetchEventTags.fulfilled.match(resultActionEventTags)) {
			//...
		} else {
			console.log("Fetch failed: ", resultActionEventTags.error);
		}
		const resultActionProjects = await dispatch(fetchFolder());
		if (fetchFolder.fulfilled.match(resultActionProjects)) {
			//...
		} else {
			console.log("Fetch failed: ", resultActionProjects.error);
		}
	};

	const { behavior, setBehavior } = useSidebar();

	// CURRENT PROJECT ID IS HARDCODED
	useEffect(() => {
		setBehavior(SIDEBAR_BEHAVIOR.COMPACT);
		initFetch();
		dispatch(setCurrentProjectID("e6e2d1de-e9f0-447f-a973-985c4fb4d94d"));
	}, []);

	const {
		entities: eventTagEntities,
		error: eventTagError,
		loading: eventTagLoading,
		currentEntityID: currentEventTagID,
	} = useAppSelector((state) => state.eventTags);

	const {
		entities: dashboardEntities,
		error: dashboardError,
		loading: dashboardLoading,
		currentEntityID: currentDashboardID,
	} = useAppSelector((state) => state.dashboards);

	const {
		entities: dashboardTags,
		error: tagError,
		loading: tagLoading,
		currentEntityID: currentTagID,
	} = useAppSelector((state) => state.dashboardTags);

	const {
		entities: projectEntities,
		error: projectError,
		loading: projectLoading,
		currentEntityID: currentProjectEntityID,
	} = useAppSelector((state) => state.projects);

	const {
		entities: markerEntities,
		error: markerError,
		loading: markerLoading,
		currentEntityID: currentMarkerID,
	} = useAppSelector((state) => state.userMarkers);

	const [currentProjectDashboards, setCurrentProjectDashboards] = useState([]);
	const [currentProjectDashboardTags, setCurrentProjectDashboardTags] = useState(
		[]
	);
	const [currentProjectMarkers, setCurrentProjectMarkers] = useState([]);
	const [currentProjectEventTags, setCurrentProjectEventTags] = useState([]);

	// On load, and whenever the project is updated, this useEffect collects all dashboards, tags, and markers in order to pass them as props to the EventTags and AnalysisFilters
	useEffect(() => {
		if (projectEntities.childItems !== undefined) {
			let dashboardArray = [];
			let tagsArray = [];
			let markersArray = [];

			// Collects dashboards and tags
			projectEntities.childItems[
				currentProjectEntityID
			].itemAnalyses[0].dashboards.forEach((dashboardId) => {
				dashboardArray.push(dashboardEntities[dashboardId]);
				dashboardEntities[dashboardId].dashboardTags.forEach((tagId) => {
					tagsArray.push(dashboardTags[tagId]);
				});
			});

			// Collects markers
			projectEntities.childItems[
				currentProjectEntityID
			].itemAnalyses[0].markers.forEach((markerId) => {
				markersArray.push(markerEntities[markerId]);
			});

			tagsArray = _.uniq(tagsArray);
			setCurrentProjectDashboardTags(tagsArray);
			setCurrentProjectDashboards(dashboardArray);
			setCurrentProjectMarkers(markersArray);
		}
	}, [projectEntities]);

	// This is separated from the above useEffect so that you don't need to reload dashboards, tags, and markers every time an eventTag is removed/added to/from playlist
	useEffect(() => {
		let eventTagsArray = [];
		// Collects eventTags
		for (let eventTag in eventTagEntities) {
			eventTagsArray.push(eventTagEntities[eventTag]);
		}
		setCurrentProjectEventTags(eventTagsArray);
	}, [eventTagEntities]);

	// Used for resizing
	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 768px)").matches
	);

	useEffect(() => {
		window.matchMedia("(min-width: 768px)").addEventListener("change", (e) => {
			setMatches(e.matches);
		});
	}, []);

	return (
		<Container fluid className="h-100 p-1 m-0">
			{matches === true ? (
				<ReflexContainer orientation="vertical">
					{/* Media Player & Analysis Tools */}
					<ReflexElement flex={5} minSize={300} id="mediaAndBottomBox">
						<ReflexContainer orientation="horizontal">
							{/* Media Player */}
							<ReflexElement
								flex={5}
								style={{
									overflow: "hidden",
									minHeight: "150px",
								}}
								id="mediaPlayer"
							>
								<Container fluid className="h-100 mx-auto">
									<MediaPlayer />
								</Container>
							</ReflexElement>

							<ReflexSplitter />

							{/* Bootombar - Analysis Tools */}
							<ReflexElement flex={2} id="bottomBar">
								<BottomBar
									currentProjectDashboards={currentProjectDashboards}
									currentProjectDashboardTags={currentProjectDashboardTags}
								/>
							</ReflexElement>
						</ReflexContainer>
					</ReflexElement>

					<ReflexSplitter />

					{/* Right-Sidebar */}
					<ReflexElement flex={2} id="rightBar">
						<AnalysisPane
							currentProjectDashboards={currentProjectDashboards}
							currentProjectDashboardTags={currentProjectDashboardTags}
							currentProjectMarkers={currentProjectMarkers}
							currentProjectEventTags={currentProjectEventTags}
							tabContentStyle={{ inset: "90px 0px 0px" }}
							tabContentClasses="position-absolute overflow-auto w-100"
						/>
					</ReflexElement>
				</ReflexContainer>
			) : (
				<div id="smallSizeContainer" className="h-100">
					<Row className="h-25">
						<MediaPlayer />
					</Row>
					<Row className="h-25">
						<BottomBar
							currentProjectDashboards={currentProjectDashboards}
							currentProjectDashboardTags={currentProjectDashboardTags}
						/>
					</Row>
					<Row className="h-50">
						<AnalysisPane
							currentProjectDashboards={currentProjectDashboards}
							currentProjectDashboardTags={currentProjectDashboardTags}
							currentProjectMarkers={currentProjectMarkers}
							currentProjectEventTags={currentProjectEventTags}
						/>
					</Row>
				</div>
			)}
		</Container>
	);
};

export default ItemAnalysis;
