import classNames from "classnames";
import _ from "lodash";
// import * as Yup from "yup";

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import useAppDispatch from "hooks/useAppDispatch";
import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { Form, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { Button } from "components/buttons";
import { ButtonLoadingIcon } from "components/icons";
import { LoadingSpinner } from "components/loaders";

import {
	fetchItemAnalysis,
	revokeCollaboration,
} from "features/itemAnalyses/itemAnalyses.actions";

import { INVITE_TYPES, REGEXP } from "constants/";

export const InviteModal = ({
	itemID,
	itemAnalysisID,
	confirmBtnAction,
	inviteType,
}) => {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const { closeModal, showModal } = useModalContext();

	const itemAnalysis = useSelector(
		(state) => state.itemAnalyses.entities?.[itemAnalysisID]
	);

	const [loading, setLoading] = useState(true);
	const [loadingOnConfirm, setLoadingOnConfirm] = useState(false);
	const [confirmBtnTitle, setConfirmBtnTitle] = useState("Invite");
	const [modalTitle, setModalTitle] = useState();
	const [formData, setFormData] = useState({
		emailAddresses: [],
		emailAddressInput: "",
		messageTextarea: "",
	});

	// console.log("==Invite Modal==");
	// console.log("formData - ", formData);
	// console.log("itemAnalysisID - ", itemAnalysisID);
	// console.log("itemID - ", itemID);
	// console.log("loading - ", loading);

	useEffect(() => {
		async function fetchData() {
			// setLoading(true);
			if (undefined === itemAnalysis)
				await dispatch(fetchItemAnalysis(itemAnalysisID));
			setLoading(false);
		}

		// only fetch itemAnalysis data if NOT INVITE_TYPES.ORG_MEMBERS
		if (INVITE_TYPES.ORG_MEMBERS !== inviteType && undefined === itemAnalysis)
			fetchData();
		else setLoading(false);

		// Set inviteType data
		switch (inviteType) {
			case INVITE_TYPES.ORG_MEMBERS:
				setModalTitle("Invite people to your organization");
				break;
			case INVITE_TYPES.COLLABORATE:
				setModalTitle(`Invite people to collaborate with you`);
				break;
			case INVITE_TYPES.SEND:
				setFormData({
					...formData,
					inviteCheckbox: false,
				});
				setModalTitle("Send media to others");
				setConfirmBtnTitle("Send");
				break;
			default:
				break;
		}
	}, []);

	/**
	 * Handles collaborating, sending or inviting members
	 */
	const onConfirmClick = async () => {
		let invitees = _.uniq(formData.emailAddresses); // dedupe
		if (21 < invitees.length) {
			notify.error("Sorry, only able to send to 20 people at a time");
			return;
		}

		for (var i = 0; i < invitees.length; i++) {
			if (false === REGEXP.EMAIL_VALID.test(invitees[i])) {
				notify.error(
					"Sorry, unable to understand email starting with '" + invitees[i] + "'"
				);
				return;
			}
		}

		setLoadingOnConfirm(true);
		await confirmBtnAction({
			itemID,
			itemAnalysisID,
			emails: invitees,
		});

		//TODO: error handle
		// const res = dispatch(await confirmBtnAction(formData));
		// if (!res) {
		// 	notify.error("Something went wrong ", res);
		// 	setLoadingOnConfirm(false);
		// 	return;
		// }

		setLoadingOnConfirm(false);
		closeModal();
	};

	//Autosizes email address text area
	const htmlElementResize = (elementID) => {
		let el = document.getElementById(elementID);
		setTimeout(function () {
			el.style.cssText = "height:auto;";
			el.style.cssText = "height:" + (8 + el.scrollHeight) + "px";
		}, 0);
	};

	/**
	 * Removes email from displayed email addresses
	 * @param {Integer} index of email address to remove
	 */
	const removeEmailAddressChip = (index) => {
		let tempArr = Array.from(formData.emailAddresses);
		tempArr.splice(index, 1);
		setFormData({
			...formData,
			emailAddresses: tempArr,
		});
		htmlElementResize("emailAddressDiv");
	};

	// Captures newline, comma and updates formData.emailAddreses
	const onEmailAdressInputKeyDown = (e) => {
		const { code, shiftKey } = e;
		if (
			(code === "Comma" && shiftKey === false) ||
			code === "Enter" ||
			code === "NumpadEnter"
		) {
			// Prevents using a comma or enter key as part of an email
			if (code === "Comma" || code === "Enter" || code === "NumpadEnter") {
				e.preventDefault();
			}
			var email = formData.emailAddressInput.replace(/\s+/g, ""); //remove any whitespace;
			updateEmailAddresses([email.toLowerCase()]);
		}
	};

	// Captures paste of email addresses, cleans and updates formData.emailAddreses
	const onEmailAddressInputPaste = (e) => {
		e.preventDefault();

		var emails = e.clipboardData.getData("text");

		// clean emails
		emails = emails.toLowerCase(); //lowercase
		emails = emails.replace(/(?:\r\n|\r|\n)/g, ","); //replace newline with `,`
		emails = emails.replace(/\s+/g, ""); //remove any whitespace
		emails = emails.split(","); // convert to array
		emails = _.uniq(emails); //ensure unique emails
		emails = emails.filter(String); //remove empties
		updateEmailAddresses(emails);
	};

	const onInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]:
				"checkbox" === e.target.type ? e.target.checked : e.target.value,
		});
	};

	/**
	 * Updates formData.emailAddreses
	 * @param {Array} emails
	 */
	const updateEmailAddresses = (emails) => {
		// remove empty email strings - happens onBlur & Enter if nothing typed
		_.remove(emails, (email) => {
			return "" === email;
		});
		if (emails.length > 0) {
			var tmpEmailAddresses = [...formData.emailAddresses, ...emails];
			// tmpEmailAddresses =_.uniq(tmpEmailAddresses); // insures updated emailAddress will be unique,
			// but visually could be confusing as new email address not added
			setFormData({
				...formData,
				emailAddresses: [...tmpEmailAddresses],
				emailAddressInput: "",
			});
		}
		htmlElementResize("emailAddressDiv");
	};

	/**
	 * Renders email address buttons
	 */
	const renderEmailAddressChips = formData.emailAddresses.map((email, index) => {
		const validClass =
			false === REGEXP.EMAIL_VALID.test(email) ? "danger" : "light";
		return (
			<div
				className="btn-group me-2 mb-2"
				role="group"
				aria-label="emailButton"
				key={index}
			>
				<Button
					variant={`outline-${validClass}`}
					size="sm"
					className="rounded-left"
					disabled
				>
					{email}
				</Button>
				<Button
					variant={`${validClass}`}
					size="sm"
					className="rounded-right"
					onClick={() => {
						removeEmailAddressChip(index);
					}}
				>
					X
				</Button>
			</div>
		);
	});

	/**
	 * Renders email address buttons
	 */
	function renderCurrentCollaborators() {
		let collaborators = Object.keys(itemAnalysis.collaborators.users)
			.filter(
				(userID) => "ACCEPTED" === itemAnalysis.collaborators.users[userID].status
			)
			.map((userID) => {
				const { name, status } = itemAnalysis.collaborators.users[userID];
				// if ( !== status) return;
				return (
					<ListGroupItem
						action
						className="d-flex justify-content-between"
						key={userID}
					>
						{name}
						<a
							href=""
							onClick={async (e) => {
								e.preventDefault();
								await dispatch(revokeCollaboration({ userID, itemAnalysisID }));
							}}
						>
							Remove
						</a>
					</ListGroupItem>
				);
			});
		return collaborators;
	}

	return (
		<Modal
			show={showModal}
			onShow={() => {
				document.getElementById("emailAddressInput").focus();
			}}
			onHide={() => closeModal()}
		>
			<Modal.Header>
				<Modal.Title>{modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group className="mb-3">
					<Form.Label className="me-1">People</Form.Label>
					<div
						className="form-control"
						id="emailAddressDiv"
						style={{ height: "62px" }}
					>
						{renderEmailAddressChips}
						<Form.Control
							as="input"
							id="emailAddressInput"
							name="emailAddressInput"
							className="m-0 p-0"
							placeholder={
								formData.emailAddresses.length < 1
									? "Enter email addresses. Separate with comma or a new line."
									: ""
							}
							onKeyDown={onEmailAdressInputKeyDown}
							onBlur={() =>
								updateEmailAddresses([formData.emailAddressInput.replace(/\s+/g, "")])
							}
							onChange={onInputChange}
							onPaste={onEmailAddressInputPaste}
							required
							value={formData.emailAddressInput}
						/>
					</div>
					{/* <Form.Label className="mt-3">Message (optional)</Form.Label>
					<Form.Control
						as="textarea"
						style={{ height: "62px" }}
						id="messageTextarea"
						name="messageTextarea"
						placeholder="Type message here"
						onKeyDown={() => htmlElementResize("messageTextarea")}
						onChange={onInputChange}
						value={formData.messageTextarea}
					/> */}
					{INVITE_TYPES.SEND === inviteType ? (
						<Form.Check
							type="checkbox"
							className="mt-3"
							name="inviteCheckbox"
							id="inviteCheckbox"
							label="Include analysis, project notes, attachments, and transcriptions"
							onChange={onInputChange}
							value={false}
						/>
					) : null}
				</Form.Group>

				{INVITE_TYPES.COLLABORATE === inviteType ? (
					!loading ? (
						<>
							<h5 className="pt-3">Current Collaborators</h5>
							<ListGroup variant="flush" className="">
								{renderCurrentCollaborators()}
							</ListGroup>
						</>
					) : (
						<LoadingSpinner />
					)
				) : null}
			</Modal.Body>
			<Modal.Footer>
				<Button
					id="cancelModalBtn"
					variant="primary"
					size="sm"
					className="rounded m-2"
					onClick={() => closeModal()}
				>
					Done
				</Button>
				{formData.emailAddresses.length > 0 ? (
					<Button
						disabled={formData.emailAddresses.length < 0 || loading}
						variant="success"
						size="sm"
						className="rounded m-2"
						onClick={onConfirmClick}
					>
						{confirmBtnTitle}
						{!loadingOnConfirm || <ButtonLoadingIcon />}
					</Button>
				) : null}
			</Modal.Footer>
		</Modal>
	);
};
