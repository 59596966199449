import React, { useState, createContext, useContext, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
	CreateModal,
	ConfirmationModal,
	InfoModal,
	InfoUpgradeModal,
	ImportZoomModal,
	InviteModal,
	ProjectDashboardsModal,
	ProjectMarkersModal,
	StripeModal,
} from "components/modals";

export const MODAL_TYPES = {
	CONFIRMATION: "CONFIRMATION",
	CREATE: "CREATE",
	INFO: "INFO",
	INFO_UPGRADE: "INFO_UPGRADE",
	INVITE: "INVITE",
	STRIPE_MODAL: "STRIPE_MODAL",
	UPDATE_DASHBOARDS: "UPDATE_DASHBOARDS",
	UPDATE_MARKERS: "UPDATE_MARKERS",
	IMPORT_ZOOM: "IMPORT_ZOOM",
};

const MODAL_COMPONENTS = {
	[MODAL_TYPES.CONFIRMATION]: ConfirmationModal,
	[MODAL_TYPES.CREATE]: CreateModal,
	[MODAL_TYPES.INFO]: InfoModal,
	[MODAL_TYPES.INFO_UPGRADE]: InfoUpgradeModal,
	[MODAL_TYPES.INVITE]: InviteModal,
	[MODAL_TYPES.STRIPE_MODAL]: StripeModal,
	[MODAL_TYPES.UPDATE_DASHBOARDS]: ProjectDashboardsModal,
	[MODAL_TYPES.UPDATE_MARKERS]: ProjectMarkersModal,
	[MODAL_TYPES.IMPORT_ZOOM]: ImportZoomModal,
};

const intitialState = {
	showModal: false,
	openModal: () => {},
	closeModal: () => {},
	store: {},
};

const ModalContext = createContext(intitialState);
export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const [showModal, setShowModal] = useState(false);
	const [store, setStore] = useState({});
	const { modalType, modalProps } = store || {};
	const { folderID = "0" } = useParams(); // folder/itemID of current folder contents being viewed

	// console.log("== ModalProvider ==");
	// console.log("children - ", children);
	// console.log("modalType - ", modalType);
	// console.log("modalProps - ", modalProps);
	// console.log("store - ", store);

	// useEffect to pick up URL searchParams that should trigger openModal
	useEffect(() => {
		const zoomAuthorization = searchParams.get("zoomAuthorization"); // zoom just authorized, open zoom modal

		// zoomAuthorization=success
		if ("success" === zoomAuthorization) {
			openModal(MODAL_TYPES.IMPORT_ZOOM, {
				folderID,
			});
			//TODO: Clear query string & redirect
		}

		// console.log(
		// 	"ModalProvider useEffect- zoomAuthorization: ",
		// 	zoomAuthorization
		// );
	}, []);

	const openModal = (modalType, modalProps = {}) => {
		// console.log("ModalProvider.openModal() called");
		// console.log("... with modalType - ", modalType);
		// console.log("... with modalProps - ", modalProps);
		// console.log("--");
		setStore({
			...store,
			modalType,
			modalProps,
		});
		setShowModal(true);
	};

	const closeModal = () => {
		// console.log("ModalProvider.closeModal() called");
		// console.log("--");
		setStore({});
		setShowModal(false);
	};

	const renderComponent = () => {
		// console.log("ModalProvider.renderComponent() called");
		const ModalComponent = MODAL_COMPONENTS[modalType];
		if (!modalType || !ModalComponent) {
			return null;
		}
		return <ModalComponent {...modalProps} />;
	};

	return (
		<ModalContext.Provider
			value={{ showModal, store, setShowModal, openModal, closeModal }}
		>
			{renderComponent()}
			{children}
		</ModalContext.Provider>
	);
};
