import { FaIcon } from "./FaIcon.js";
import { AudioIcon } from "./appIcons";
import { THEME_COLORS } from "constants/theme";

/** DMIO FEATURE ICONS */
export const CollaboratorsIcon = (props) => <FaIcon icon="users" {...props} />;

export const CollaborationLeaveIcon = (props) => (
	<FaIcon icon="person-walking-arrow-right" variant="danger" {...props} />
);

export const ColorIcon = (props) => (
	<FaIcon
		icon="circle"
		weight="fas"
		className="pe-3"
		color={props.color}
		{...props}
	/>
);

export const DashboardsIcon = (props) => (
	<FaIcon icon="bullseye-pointer" {...props} />
);

export const MarkersIcon = (props) => <FaIcon icon="bookmark" {...props} />;

export const ProjectsAudioIcon = (props) => (
	<AudioIcon variant="plum" {...props} />
);

export const ProjectsIcon = (props) => (
	<FaIcon icon="folder" variant="plum" {...props} />
);

export const ProjectsExportIcon = (props) => (
	<FaIcon icon="bookmark" {...props} />
);

export const ProjectOpenIcon = (props) => (
	<FaIcon icon="up-right-from-square" {...props} />
);

export const ProjectRenameIcon = (props) => (
	<FaIcon icon="file-pen" {...props} />
);

export const ProjectSendIcon = (props) => (
	<FaIcon icon="share-from-square" {...props} />
);

export const ThemeToggleIcon = (props) => (
	<FaIcon
		icon="toggle-on"
		size="lg"
		className="me-2 align-self-center"
		{...props}
	/>
);

export const ThemeToggleMoonIcon = (props) => (
	<FaIcon size={null} icon="moon" className={null} {...props} />
);

export const ThemeToggleSunIcon = (props) => (
	<FaIcon size={null} icon="sun-bright" className={null} {...props} />
);

export const SidebarToggleIcon = (props) => (
	<FaIcon icon="align-left" size="xl" className="align-self-center" {...props} />
);
