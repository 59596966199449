import "./projects.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Card, Col } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders";

const ThumbLoadingCard = ({ title = "Loading...", subtitle = "" }) => {
	return (
		<Col className="project-card-col">
			<Card
				className="project-card"
				border="plum"
				style={{ background: "transparent", border: "none" }}
			>
				<div
					className="card-img-top project-card-thumbnail loader text-center"
					style={{ background: "transparent" }}
				>
					<LoadingSpinner className="loader my-3" />
				</div>

				<Card.Header
					className="px-3 pt-2 project-card-header text-center"
					style={{ background: "transparent" }}
				>
					<Card.Title>{title}</Card.Title>
					<Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
				</Card.Header>
			</Card>
		</Col>
	);
};

export default ThumbLoadingCard;
