/**
 * API Paths
 * Uncomment as you use
 */

export let APIPATH = {};
export const setAPIPATH = (value) => {
	switch (value) {
		case "bravo":
			APIPATH = require("./apipath.bravo.js");
			break;
		case "bravold":
		case "devpaola":
		case "devnat":
			APIPATH = require("./apipath.old");
			break;
		// case "staging":
		// 	awsConfig = require("./config/config.staging");
		// 	break;
		// case "live":
		// 	awsConfig = require("./config/config.live");
		// 	break;
		default:
			APIPATH = require("./apipath.old");
	}
	// console.log("API ", value);
};

setAPIPATH(process.env.REACT_APP_DMIO_CONF);
