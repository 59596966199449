import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { ZoomLogo } from "components/images";

import { IntegrationsLoading } from "./IntegrationsLoading";
import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import {
	zoomDeauthorization,
	zoomAuthorization,
} from "features/user/oauth.actions";

import { ROUTES } from "constants";

function ZoomIntegration({ upgradeRequired }) {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const { closeModal, openModal } = useModalContext();

	const {
		entity: { oauth },
	} = useSelector((state) => state.user);

	const { isAuthorized, oauthUserID } = oauth?.zoom ?? { isAuthorized: false };

	let authorizeVars = ["Connect", "primary"];
	if (isAuthorized) {
		authorizeVars = ["Deauthorize", "danger"];
	}

	const onInitiateRevokeZoomAuthorizationClick = (action) => {
		if (upgradeRequired) {
			openModal(MODAL_TYPES.INFO_UPGRADE, {
				feature: "Zoom Cloud Imports",
			});
		} else {
			if ("Connect" === action) {
				dispatch(zoomAuthorization());
				return openModal(MODAL_TYPES.INFO, {
					title: "Redirecting to Zoom...",
					body: "You're being redirected to Zoom...",
				});
			}

			// if not Connect, assume Revoke
			openModal(MODAL_TYPES.CONFIRMATION, {
				title: "Deauthorize Zoom Integration",
				confirmBtnClasses: "danger",
				confirmBtnTitle: "Deauthorize Zoom Integration",
				confirmBtnAction: async () => {
					const res = await dispatch(zoomDeauthorization());
					if (!res.error) {
						notify.success("Successfully Deautherized");
						closeModal();
					} else {
						const message =
							res.payload.message || res.error.message || "Something went wrong";
						notify.error(message);
						return Promise.reject(false);
					}
				},
				body: (
					<div>
						<a
							href="https://zoom.us/marketplace_developer_agreement"
							target="_blank"
							rel="noreferrer"
						>{`Zoom's Marketplace Developer Agreement`}</a>
						{` requires Dreamaker.io to delete all user data shared with Dreamaker.io by Zoom if Zoom access is deauthorized. `}
						{`This data includes any audio and video files imported from Zoom, as well as all associated analysis.`}
						<br />
						<br />
						<Link
							to={`${ROUTES.ZOOM}/${oauthUserID}`}
						>{`View media files imported from Zoom`}</Link>{" "}
						<br />
						<br />
						{`After proceding with deauthorization, you will be given 9 days to give Dreamaker.io express permission to retain this data.`}
						<br />
						<br />
						{`Would you like to deauthorize Zoom access?`}
					</div>
				),
			});
		}
	};

	return (
		<tr>
			<td>
				<ZoomLogo />
			</td>
			<td>Upload your Zoom cloud directly from Zoom into Dreamaker.io.</td>
			<td>
				<Button
					type="submit"
					variant={authorizeVars[1]}
					onClick={() => onInitiateRevokeZoomAuthorizationClick(authorizeVars[0])}
				>
					{authorizeVars[0]}
				</Button>
			</td>
		</tr>
	);
}

export default ZoomIntegration;
