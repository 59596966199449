/** STRIPE TEST CARDS - https://stripe.com/docs/testing#international-cards
	 
	 * 4242424242424242: No authentication required
	 
	 SCA Cards
	 * 4000002500003155: Requires authentication for one-time payments. No further authentication needed. 
	 * 4000002760003184: Requires authentication on all transactions, regardless of how the card is set up.
	 * 4000008260003178: Requires authentication for one-time payments. All payments will be declined with an insufficient_funds failure code even after being successfully authenticated or previously set up. 
	 
	 3D Secure Cards
	 * 4000000000003220: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000000000003063: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000008400001629: 3D Secure required but payments will be declined with a card_declined failure code after authentication. By default, your Radar rules will request 3D Secure authentication for this card. 
	 * 378282246310005: 3D Secure is not supported on this card and cannot be invoked. The PaymentIntent will proceed without performing authentication.
	 */
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Form, Modal } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders/";
import { Button } from "components/buttons/";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";
import useTheme from "hooks/useTheme";

import StripeAddressForm from "./StripeAddressForm";

import {
	updateBillingPlanPreview,
	updateBillingCard,
} from "features/billing/billing.actions";

import { MODAL_TYPES } from "contexts/ModalContext";

import { THEME } from "constants/";

const formInitalState = {
	name: "",
	line1: "",
	line2: "",
	city: "",
	state: "",
	country: "",
};

const formValidationSchema = Yup.object({
	name: Yup.string().trim().max(255).required("name"),
	line1: Yup.string().trim().max(255).required("line1"),
	line2: Yup.string().trim().max(255).optional("line2"),
	city: Yup.string().trim().max(255).required("city"),
	state: Yup.string().trim().max(255).required("state"),
	country: Yup.string().trim().max(255).required("country"),
});

const StripeCheckout = ({
	clientSecret,
	newPaymentPeriod,
	newPlan,
	prorationDate,
}) => {
	const dispatch = useDispatch();
	const { closeModal, openModal } = useModalContext();
	const notify = useContext(NotifyContext);
	const stripe = useStripe();
	const elements = useElements();
	const { theme } = useTheme();

	const { backgroundColor, color } =
		THEME.DARK === theme
			? { color: "#bfc1c6", backgroundColor: "#333b52" }
			: { color: "#495057", backgroundColor: "#f3f6fb" };

	const cardElementOptions = {
		style: {
			base: {
				backgroundColor: backgroundColor,
				color: color,
				fontFamily:
					"Poppins, -apple-system, 'system-ui', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
				fontSize: "13.2px",
				fontWeight: "400",
				lineHeight: "2.5	",
				padding: "0.35rem 0.75rem",

				"::placeholder": {
					color: color,
					fontWeight: "400",
				},
			},
		},
	};

	// const [clientSecret, setClientSecret] = useState("");
	const [coupon, setCoupon] = useState("");
	const [errors, setErrors] = useState(formInitalState);
	const [formData, setFormData] = useState(formInitalState);
	const [loading, setLoading] = useState(false); // Form is being submitted
	const [ready, setReady] = useState(false); // Form, specifically <CardElement /> is ready
	const [setupIntent, setSetupIntent] = useState("");
	const [touched, setTouched] = useState(formInitalState);

	/** Retrieve payment intent on vars [stripe, clientSecret]
	 * var stripe changes while being loaded via stripe API via useStripe() hook
	 * var clientSecret is being loaded by parent component
	 */
	useEffect(() => {
		// console.log("-- elements: ", elements);
		// console.log("-- stripe: ", stripe);
		// console.log("-- clientSecret: ", clientSecret);

		if (!stripe || !clientSecret) {
			return;
		}
		const fetchData = async () => {
			const res = await stripe.retrieveSetupIntent(clientSecret);
			// console.log("res retrieveSetupIntent = ", res);
			if (res.setupIntent) {
				setSetupIntent(res.setupIntent);
			} else {
				//TODO: Handle
				console.log("retrieveSetupIntent Failed!");
			}
		};
		fetchData();
	}, [stripe, clientSecret]);

	// console.log("StripeCheckout - ");
	// console.log("newPlan: ", newPlan);
	// console.log("newPaymentPeriod: ", newPaymentPeriod);
	// console.log("prorationDate: ", prorationDate);
	// console.log("");
	// console.log("");

	// console.log("formData: ", formData);
	// console.log("coupon: ", coupon);
	// console.log("errors: ", errors);
	// console.log("touched: ", touched);
	// console.log("");
	// console.log("");
	// console.log("elements: ", elements);
	// console.log("stripe: ", stripe);
	// console.log("clientSecret: ", clientSecret);
	// console.log("setupIntent: ", setupIntent);

	const onSubmitClick = async (e) => {
		e.preventDefault();
		// console.log("handleSubit Called... ", e);

		if (!stripe || !elements || !ready) {
			// Stripe.js has not yet loaded & setUpIntent not yet retrieved
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setLoading(true);

		// console.log("formData: ", formData);

		/** VALIDATE ADDRESS VARS IN <AddressForm /> */
		var hasErrors = false;
		// var tmpErrors = formInitalState;
		await formValidationSchema
			.validate(formData, {
				abortEarly: false,
				stripUnknown: true,
			})
			.then((value) => {})
			.catch((err) => {
				// tmpErrors = true;
				err.errors.forEach((element) => {
					hasErrors = {
						...hasErrors,
						[element]: true,
					};
				});
				setErrors(hasErrors);
			});

		// console.log("hasErrors: ", hasErrors);
		// console.log("errors: ", errors);
		if (hasErrors) {
			setLoading(false);
			return;
		}

		// stripe.confirmCardPayment
		// stripe.confirmCardSetup => confirms card for future payments - replaces stripe.handleCardSetup
		// stripe.confirmPayment => confimrs the payemtn
		// stripe.confirmSetup
		// stripe.handleCardAction
		// stripe.createPaymentMethod

		/** CONFIRM CARD SETUP
		 * Used to both update billing for an existing customer, and add a card for a new customer
		 */
		const { name, line1, line2, city, state, country } = formData;
		const result = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: {
					name,
					address: {
						line1,
						line2,
						city,
						state,
						country,
					},
				},
			},
		});

		if (result.error) {
			notify.error(result.error.message);
			setLoading(false);
			return;
		}

		/** NO newPlan - THIS IS AN UPDATE BILLING CARD **/
		if (undefined === newPlan) {
			// console.log("Updating Billing Card");
			const res = await dispatch(
				updateBillingCard(result.setupIntent.payment_method)
			);
			if (res.error) {
				notify.error(res.error);
				setLoading(false);
			} else {
				notify.success("Card successfully updated!");
				closeModal();
			}
			return;
		}

		/** newPlan EXISTS - PROCEED WITH CHANGING PLAN FLOW */
		const stripePaymentMethodID = result.setupIntent.payment_method;
		// console.log("Changing Plan...");
		await updateBillingPlanPreview({
			coupon,
			planID: newPlan.planID,
			paymentPeriod: newPaymentPeriod,
			prorationDate,
			stripePaymentMethodID,
		})
			.then((res) => {
				// console.log("OK, Proceed with Preview Changes!! ");
				return openModal(MODAL_TYPES.STRIPE_MODAL, {
					stripeFlow: "CHANGE_PLAN_PREVIEW_CHANGES",
					data: {
						changePreview: { ...res },
						coupon,
						clientSecret,
						newPaymentPeriod,
						newPlan,
						prorationDate,
						stripePaymentMethodID,
					},
				});
			})
			.catch((err) => {
				notify.error(err.data?.message);
				setLoading(false);
				return;
			});
	};

	return (
		<React.Fragment>
			{!ready ? (
				<Modal.Body style={{ textAlign: "center" }}>
					<LoadingSpinner size="lg" className="py-3" />
				</Modal.Body>
			) : null}

			<Modal.Body
				className="mb-0 pb-0"
				style={{ display: ready ? "inline" : "none" }}
			>
				<StripeAddressForm
					formData={formData}
					setFormData={setFormData}
					errors={errors}
					setErrors={setErrors}
					touched={touched}
					setTouched={setTouched}
				/>
			</Modal.Body>
			<form id="payment-form" onSubmit={onSubmitClick}>
				<Modal.Body
					className="mt-0 pt-0"
					style={{ display: ready ? "block" : "none" }}
				>
					<CardElement options={cardElementOptions} onReady={() => setReady(true)} />
					{undefined !== newPlan ? ( // show coupone field
						<Form.Group className="mt-3">
							<Form.Control
								key="coupon"
								name="coupon"
								type="text"
								placeholder={"Coupon"}
								onChange={(e) => setCoupon(e.target.value)}
								defaultValue={coupon}
								style={{ border: errors.coupon ? "1px solid red" : "inherit" }}
							/>
						</Form.Group>
					) : null}
				</Modal.Body>
				<Modal.Footer>
					<Button
						id="cancelModalBtn"
						variant="outline-muted"
						size="sm"
						className={`rounded m-2`}
						onClick={() => closeModal()}
					>
						Cancel
					</Button>
					<Button
						disabled={loading || !ready}
						id="submit"
						onClick={onSubmitClick}
						loading={loading}
					>
						<span id="button-text">
							{undefined === newPlan ? "Update Card" : "Confirm Upgrade"}
						</span>
					</Button>
				</Modal.Footer>
			</form>
		</React.Fragment>
	);
};

export default StripeCheckout;
