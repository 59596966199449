/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import { createSlice } from "@reduxjs/toolkit";
import {
	exportAnalysis,
	fetchItemAnalysis,
	updateItemAnalysis,
	inviteCollaboration,
	leaveCollaboration,
	revokeCollaboration,
} from "./itemAnalyses.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: null, //{},
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
	exportStatus: undefined,
	// currentRequestId: undefined, //a unique string ID value automatically generated by the thunk callback, identifying this request sequence
	// error: null,
};

const membersSlice = createSlice({
	name: "members",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// setCurrentEntityID: (state, action) => {
		// 	// state.currentEntityID = action.payload; // payload is itemID
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		/** fetchItemAnalysis */
		[fetchItemAnalysis.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchItemAnalysis.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entities = action.payload; // updated state.entities object
		},
		[fetchItemAnalysis.rejected]: (state, action) => {
			state.status = "failed";
			// state.error = action.error;
		},

		/** exportAnalysis */
		[exportAnalysis.pending]: (state, action) => {
			state.status = "pending";
			state.exportStatus = "exporting";
		},
		[exportAnalysis.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.exportStatus = undefined;
			// Do nothing; no payload
		},
		[exportAnalysis.rejected]: (state, action) => {
			state.status = "failed";
			state.exportStatus = undefined;
		},

		/** updateItemAnalysis */
		[updateItemAnalysis.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateItemAnalysis.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entities[action.payload.itemAnalysisID] = action.payload; // payload is updated itemAnalysis object
		},
		[updateItemAnalysis.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** inviteCollaboration */
		[inviteCollaboration.pending]: (state, action) => {
			state.status = "pending";
		},
		[inviteCollaboration.fulfilled]: (state, action) => {
			state.status = "succeeded";
			// Payload empty, nothing done with payload
		},
		[inviteCollaboration.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** leaveCollaboration */
		[leaveCollaboration.pending]: (state, action) => {
			state.status = "pending";
		},
		[leaveCollaboration.fulfilled]: (state, action) => {
			state.status = "succeeded";
			if (state.entities && action.payload)
				// payload is itemAnalysisID
				delete state.entities[action.payload];
		},
		[leaveCollaboration.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** revokeCollaboration */
		[revokeCollaboration.pending]: (state, action) => {
			state.status = "pending";
		},
		[revokeCollaboration.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entities[action.payload.itemAnalysisID] = action.payload; // payload is updated itemAnalysis object
		},
		[revokeCollaboration.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// Action creators are generated for each case reducer function
// export const { setCurrentEntityID } = membersSlice.actions;
export default membersSlice.reducer;
