import React, { useCallback, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import NotifyContext from "contexts/NotifyContext";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import {
	deleteUserDashboard,
	updateUserDashboard,
} from "features/dashboards/dashboards.actions";

import { setCurrentDashboardTagID } from "features/dashboardTags/dashboardTags.slice";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Button } from "components/buttons";
import { DashboardTagBtn } from "./DashboardTagBtn";
import { PlaceholderTagBtn } from "./PlaceholderTagBtn";

const SelectedDashboard = () => {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const { closeModal, openModal } = useModalContext();

	const { entities: dashboards, currentEntityID: currentDashboardID } =
		useSelector((state) => state.dashboards);

	const { entities: dashboardTags, currentEntityID: currentTagID } = useSelector(
		(state) => state.dashboardTags
	);

	const acceptTypes = ["dashboardTagBtn", "searchTagBtn"];
	const [currentDashboardTagBtns, setCurrentDashboardTagBtns] = useState([]); // Array of Tag objects (full tag data)
	const [renamedDashboardTitle, setRenamedDashboardTitle] = useState("");

	const getDashboardTags = (tagIdList = []) => {
		// console.log("getDashboardTags");
		let associatedDashboardTags = [];
		for (let i = 0; i < tagIdList.length; i++) {
			if (tagIdList[i] in dashboardTags) {
				associatedDashboardTags.push(dashboardTags[tagIdList[i]]);
			} else {
				console.log("Error: Didn't find an expected tag in dashboardTags");
			}
		}
		return associatedDashboardTags;
	};

	// console.log("=== SelectedDashboard Component ===");
	// console.log("currentDashboardID: ", currentDashboardID);
	// console.log("dashboards: ", dashboards);
	// console.log("dashboardTags: ", dashboardTags);
	// console.log("");
	// console.log("");

	//Fires when a user switches dashboards, or when any tags are updated
	useEffect(() => {
		// console.log("Running setRenamedDashboardTitle useEffect");
		if (dashboards[currentDashboardID] !== undefined) {
			setRenamedDashboardTitle(dashboards[currentDashboardID].title);
			getDashboardTags(dashboards[currentDashboardID].dashboardTags);
		}
	}, [currentDashboardID, dashboardTags]);

	//Maps all tags associated with currentDashboard, and re-renders tags when one is updated
	useEffect(() => {
		// console.log("Running setCurrentDashboardTagBtns useEffect");
		if (
			dashboards[currentDashboardID] !== undefined &&
			currentDashboardID !== undefined
		) {
			setCurrentDashboardTagBtns(
				getDashboardTags(dashboards[currentDashboardID].dashboardTags)
			);
		}
	}, [dashboards[currentDashboardID], dashboardTags]);

	const handleTagOnClick = (tagID) => {
		dispatch(setCurrentDashboardTagID(tagID));
	};

	// Updates currentDashboardTagBtns with the new order
	const moveTag = useCallback((dragIndex, hoverIndex) => {
		setCurrentDashboardTagBtns((prevTags) =>
			update(prevTags, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevTags[dragIndex]],
				],
			})
		);
	}, []);

	// This function adds the tag to local state, but not update Redux just yet. Use useCallback here?
	const addTagToDashboardOnHover = (tagId, hoverIndex) => {
		if (!currentDashboardTagBtns.includes(dashboardTags[tagId])) {
			let tempArr = Array.from(currentDashboardTagBtns);
			tempArr.splice(hoverIndex, 0, dashboardTags[tagId]);
			setCurrentDashboardTagBtns(tempArr);
		}
	};

	// This is the function that actually updates Redux
	const dropFunction = (tagId) => {
		if (!dashboards[currentDashboardID].dashboardTags.includes(tagId)) {
			let tagIdsArray = [];
			for (let tag in currentDashboardTagBtns) {
				tagIdsArray.push(currentDashboardTagBtns[tag].dashboardTagID);
			}
			let dashboard = dashboards[currentDashboardID];
			dashboard = {
				...dashboard,
				dashboardTags: tagIdsArray,
			};
			dispatch(updateUserDashboard(dashboard))
				.then((originalPromiseResult) => {
					console.log("updateUserDashboardSuccess");
				})
				.catch((error) => {
					console.log("updateUserDashboard Error: ", error);
				});
		}
	};

	const currentDashboardTagBtnsMap = currentDashboardTagBtns.map(
		(tag, index) => {
			return (
				<DashboardTagBtn
					accept={acceptTypes}
					addTagToDashboardOnHover={addTagToDashboardOnHover}
					color={tag.color}
					currentNumOfTags={currentDashboardTagBtns.length}
					dropFunction={dropFunction}
					id={tag.dashboardTagID}
					index={index}
					key={tag.dashboardTagID}
					label={tag.label}
					moveTag={moveTag}
					onClick={() => handleTagOnClick(tag.dashboardTagID)}
					onDeleteClickHandler={() => onRemoveTagClick(tag.dashboardTagID)}
					type="dashboardTagBtn"
				/>
			);
		}
	);

	const generatePlaceholderTags = (numPlacholders) => {
		let placeholderArray = [];
		for (let i = 0; i < numPlacholders; i++) {
			placeholderArray.push(i);
		}
		return placeholderArray.map((button, index) => (
			<PlaceholderTagBtn
				accept="searchTagBtn"
				addTagToDashboardOnHover={addTagToDashboardOnHover}
				currentNumOfTags={currentDashboardTagBtns.length}
				dropFunction={dropFunction}
				id={button}
				index={index}
				key={button}
				label="DRAG TAG HERE"
			/>
		));
	};

	const renameDashboard = async (dashboard) => {
		if (renamedDashboardTitle !== dashboards[currentDashboardID].title) {
			const res = await dispatch(
				updateUserDashboard({
					dashboardID: dashboard.dashboardID,
					title: renamedDashboardTitle,
				})
			);
			if (res.error) {
				console.log("updateUserDashboard Error: ", res.error);
				notify.error(res.error);
			}
		}
	};

	// Toggle default
	const onDefaultChange = async (dashboard) => {
		const res = await dispatch(
			updateUserDashboard({
				dashboardID: dashboard.dashboardID,
				default: !dashboard.default,
				defaultSortOrder: dashboard.default ? 0 : 0,
			})
		);
		if (res.error) {
			console.log("updateUserDashboard Error: ", res.error);
			notify.error(res.error);
		}
	};

	// Delete dashboard
	const onDeleteClick = (dashboardID) => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: "Are you sure you want to delete this dashboard?",
			confirmBtnAction: async ({ dashboardID }) => {
				const res = await dispatch(deleteUserDashboard({ dashboardID }));
				if (!res.error) {
					notify.success("Dashboard susseccfully deleted!");
					closeModal();
				} else {
					notify.error(res.error);
					return Promise.reject(false);
				}
			},
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Delete Dashboard",
			title: "Delete Dashboard: " + dashboards[dashboardID].title,
			data: { dashboardID },
		});
	};

	// Remove button from currentDashboard
	const onRemoveTagClick = (tagId) => {
		let tagListCopy = Array.from(dashboards[currentDashboardID].dashboardTags);
		for (let i = 0; i < tagListCopy.length; i++) {
			if (tagId === tagListCopy[i]) {
				tagListCopy.splice(i, 1);
				break;
			}
		}
		let dashboard = {
			...dashboards[currentDashboardID],
			dashboardTags: tagListCopy,
		};
		dispatch(updateUserDashboard(dashboard))
			.then((originalPromiseResult) => {
				console.log("updateUserDashboardSuccess");
			})
			.catch((error) => {
				console.log("updateUserDashboard Error: ", error);
			});
	};

	return (
		<>
			<Row className="px-3">
				<Col className="d-flex justify-content-between">
					<Form.Group>
						<FloatingLabel label="Title" className="mb-3">
							<Form.Control
								id="selectedDashboardTitle"
								type="text"
								maxLength="34"
								placeholder="Title"
								style={{ width: "400px" }}
								value={renamedDashboardTitle}
								text-overflow="ellipsis"
								onChange={(e) => setRenamedDashboardTitle(e.target.value)}
								onBlur={() => renameDashboard(dashboards[currentDashboardID])}
								onKeyDown={(e) =>
									e.code === "Enter" || e.code === "NumpadEnter"
										? renameDashboard(dashboards[currentDashboardID])
										: null
								}
							/>
						</FloatingLabel>
					</Form.Group>
					<Button
						variant="outline-danger"
						style={{ height: "fit-content" }}
						onClick={() => onDeleteClick(currentDashboardID)}
					>
						Delete Dashboard
					</Button>
				</Col>
			</Row>
			<Row className="px-3">
				<Col>
					<Form.Check
						style={{ display: "inline-block" }}
						checked={dashboards[currentDashboardID].default}
						onChange={() => onDefaultChange(dashboards[currentDashboardID])}
						label="Add to all new projects"
					/>
				</Col>
			</Row>
			<Row
				id="SelectedDashboardTagsDnD"
				className="mt-3 d-inline-flex flex-wrap justify-content-center"
			>
				<Col lg={10} xl={10} className="mx-auto">
					<DndProvider backend={HTML5Backend}>
						<ul style={{ padding: 0 }}>
							{currentDashboardTagBtnsMap.length < 1
								? null
								: currentDashboardTagBtnsMap}
							{currentDashboardTagBtnsMap.length < 8
								? generatePlaceholderTags(8 - currentDashboardTagBtns.length)
								: null}
						</ul>
					</DndProvider>
				</Col>
			</Row>
		</>
	);
};

export default SelectedDashboard;
