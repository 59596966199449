import React from "react";
import { CreateFolderIcon, UploadIcon, ZoomVideoIcon } from "components/icons";
import DropdownItem from "./DropdownItem";

export const CreateFolderMenuBtn = (props) => (
	<DropdownItem
		icon={<CreateFolderIcon menu={true} />}
		title="Folder"
		{...props}
	/>
);

export const ImportZoomMenuBtn = (props) => (
	<DropdownItem
		icon={<ZoomVideoIcon menu={true} />}
		title="Import from Zoom"
		{...props}
	/>
);

export const UploadMenuBtn = (props) => (
	<DropdownItem icon={<UploadIcon menu={true} />} title="Upload" {...props} />
);
