import AWS from "aws-sdk/global";
import { Auth } from "aws-amplify";

import Evaporate from "./uploaders/dmio_evaporate.js";
import { awsCustomAuth } from "utils";

// import { _dmAppErrorHandler, _session } from "actions/index";
import { amplify, BUCKET } from "config";
import { DMIO_RELEASE } from "config";
import { updateProgress } from "features/uploader/uploader.actions";

import { PROGRESS_STATUS } from "constants/";

let client = null;
let _dispatch = false;
let _evaporate = false;
let _credentials = null;

const initialize = async function ({
	bucket = `${BUCKET.TRANSIN}`,
	dispatch,
	maxConcurrentParts = 5,
	partSize = 6 * 1024 * 1024,
	storeKey,
}) {
	_dispatch = dispatch;
	// const credentials = await Auth.currentCredentials();
	_credentials = { ...(await Auth.currentCredentials()) };
	// _credentials = await getCredentials();

	// console.log("_credentials - ", _credentials);

	const config = {
		aws_key: _credentials.getAccessKeyId,

		//NOTE: bucket required on creation
		bucket,

		awsRegion: amplify.s3.REGION,
		cloudfront: true,

		computeContentMd5: true,
		cryptoMd5Method: (data) => {
			return AWS.util.crypto.md5(data, "base64");
		},
		cryptoHexEncodedHash256: (data) => {
			return AWS.util.crypto.sha256(data, "hex");
		},

		logging: "development" === process.env.NODE_ENV ? false : false,

		s3Acceleration: true,
		signTimeout: 10,
		maxConcurrentParts,

		sendCanonicalRequestToSignerUrl: true,
		customAuthMethod: awsCustomAuth,

		signParams: { secretKey: _credentials.secretAccessKey },

		// NOTE: below as no impact on signHeaders us in customAuth
		// signHeaders: {
		// 	'x-amz-content-sha256': 'UNSIGNED-PAYLOAD',
		// 	'x-amz-paola': 'love'
		// 	},

		partSize,
		progressIntervalMS: 500, // milliseconds; default is 1000
		// s3FileCacheHoursAgo: 1,

		// NOTE: allowS3ExistenceOptimization requires CORS <AllowedMethod>HEAD</AllowedMethod> & BicketPolicy.Statement.Action: "s3:GetObject",
		allowS3ExistenceOptimization: true, // Default false; Checks for preexistence of file;
	};

	// client = Evaporate.create(config, AWS, storeKey);
	// Paola New: adding auth
	client = Evaporate.create(config, Auth, storeKey);

	return upload;
};

/**
 * Wrapper with default success/error actions
 */
const upload = async function ({
	type = "ITEM",
	bucket,
	file,
	fileID,
	name,
	userID,

	createCallback,
	deleteCallback,
	preparationProgressCallback,
	resumeCallback,
}) {
	const fileName = file.name;

	/**
	 * xAmzHeadersCommon - headers that should be added to all AWS requests other than the initiate request.
	 * xAmzHeadersAtUpload and xAmzHeadersAtComplete do not need to be specified if xAmzHeadersCommon satisfies the AWS header requirements.
	 */
	let xAmzHeadersCommon = {
		"x-amz-security-token": _credentials.sessionToken,
	};

	let xAmzHeadersAtInitiate = {
		...xAmzHeadersCommon,
		// "x-amz-meta-access-token": _session.getAccessToken().getJwtToken(), // Adds AWS S3 meta data
		"x-amz-meta-access-token": `${(await Auth.currentSession())
			.getAccessToken()
			.getJwtToken()}`,
	};
	// let xAmzHeadersAtComplete = {
	// 	...xAmzHeadersCommon,
	// 	// 'x-amz-meta-access-token': _session.getAccessToken().getJwtToken(),	// Adds AWS S3 meta data
	// }
	let resumedID = false;
	let resumedItemAnalysisID = false;

	var config = {
		name,
		file: file,
		contentType: file.type,
		xAmzHeadersCommon,
		xAmzHeadersAtInitiate,
		// xAmzHeadersAtUpload,
		// xAmzHeadersAtComplete,

		/**
		 * Evaporate Function Calls
		 * New Upload call sequence		: progress() > started() > uploadInitiated()
		 * Name changed call sequence	: progress() > started() > nameChanged() NOTE: called when restarting an upload
		 * Bad bucket call sequence 	: started() > warn() > ???
		 *
		 * Design notes:
		 * 	1: uploadInitiated() 	triggers the createCallback
		 *  2: nameChanged()		triggers the resumeCallback
		 *  3: started():			does NOT trigger UPLOAD_INITILIZING action (triggered from calling function)
		 */

		// Called when the file upload starts.
		// The file_key represents the internal identifier of the file whose upload is starting.
		started: (file_key) => {
			// console.log("upload::started() - ", file_key);
		},

		/**
		 * Called when the S3 upload is successfully initiated.
		 * @param (String) 3UploadId - the AWS S3 uploaded ID returned when the multipart upload is initiated
		 */
		uploadInitiated: (s3UploadId) => {
			// console.log("upload::uploadInitiated() - ", s3UploadId);
			_dispatch(createCallback());
		},

		// called when a successful cancel is called for an upload id.
		cancelled: (s3UploadId) => {
			// console.log("upload::cancelled() - ", s3UploadId);
		},

		// called on a potentially recoverable error, and will be retried (e.g. part upload)
		warn: (msg, step, reason) => {
			// console.log(
			// 	`upload::warn()\n>> msg: ${msg}\n>> step: ${step}\n>> reason: ${reason}`
			// );
			// let errMsg = `Upload warning in step: ${step}; reason: ${reason}`
			// TODO:
			// Rollbar.warning(errMsg);
		},

		paused: () => {
			// console.log("upload::pause()");
			// _dispatch({ type: EVAPORATE, payload: _evaporate })
		},

		// pausing: callback_methods.pausing,
		resumed: () => {
			// console.log("upload::resume()");
		},

		/**
		 * called when the requested AWS S3 object key changes either because
		 *  - the requested object was previously interrupted (and is being resumed),
		 *  - or because the entire object already exists on S3
		 */
		nameChanged: async (awsObjectKey) => {
			/**
			 * PART			: Value Media Item 	| Value fileAsset
			 * KeyParts[0]	: userID 			| userID
			 * KeyParts[1]	: itemID 			| itemID
			 * KeyParts[2]	: S3 object name 	| itemAnalysisID
			 * KeyParts[3]	: N/A				| S3 object name (`file_${fileAssetID}`)
			 *
			 * S3 object name: resumedName is keyParts[length - 1]
			 */
			var keyParts = awsObjectKey.split("/");
			// console.log(
			// 	`upload::nameChanged() to ${awsObjectKey} with keyParts `,
			// 	keyParts
			// );

			const length = keyParts.length;
			const resumerdUserID = keyParts[0],
				resumedItemID = keyParts[1];

			if (resumerdUserID !== userID) {
				return; //TODO: check this... security concern. this should not happen. but does it?
			}

			switch (type) {
				case "FILEASSET": {
					resumedItemAnalysisID = keyParts[2];
					resumedID = keyParts[3].split("_")[1];
					fileID = resumedID;
					await _dispatch(resumeCallback({ resumedID, resumedItemAnalysisID }));
					return;
				}

				case "ITEM": {
					if (keyParts.length === 3) {
						resumedID = resumedItemID;
						fileID = resumedID;
						// debugger;
						await _dispatch(resumeCallback({ resumedID }));
						// TODO: Perhaps rather change UPLOADSTATUS here to - RESUMED_RENAMED?
					}
					return;
				}

				default: {
					// TODO:
					// Rollbar.error("Evaporate Error: irregular call on nameChanged()", {
					// 	dmioRelease: DMIO_RELEASE,
					// 	awsObjectKey: awsObjectKey,
					// });
					return;
				}
			}
		},

		/**
		 * Called at a frequency determined by progressIntervalMS as the file uploads, where
		 * * p: is the fraction (between 0 and 1) of the file that is uploaded.
		 * * Note: that this number will increase or decrease depending on the status of uploading parts.
		 *
		 * * stats: an object that contains the unformatted and formatted transfer rate in bytes and a value approximating in how may seconds the upload should complete.
		 * {
		 *     speed: 70343222.003493043, // avgSpeedBytesPerSecond,
		 *     readableSpeed: "703 Kb",
		 *     loaded: 7034333, // Bytes loaded since the last call. it's for part upload.
		 *     totalUploaded: 10024457, // Total bytes uploaded
		 *     remainingSize: 20000000,
		 *     secondsLeft: 10, //when -1, it's unknown.
		 *     fileSize: 30024457
		 * }
		 */
		progress: async (p, stats) => {
			// console.log(`upload::progress() - ${p} - `, stats);
			await _dispatch(
				updateProgress({
					id: fileID,
					percentage: p,
					stats: { ...stats },
					progressStatus:
						1 === p ? PROGRESS_STATUS.PROGRESSING : PROGRESS_STATUS.UPLOADSTARTED,
				})
			);
		},

		/**
		 * Called when the file upload is complete.
		 * * awsObjectKey parameter to notify the client of the S3 object key that was used.
		 * * stats: refer to the progress() callback
		 */
		complete: async (xhr, awsObjectKey, stats) => {
			// console.log(`upload::complete() - ${awsObjectKey} stats `, stats);
			_dispatch(
				preparationProgressCallback({ resumedID: resumedID, resumedItemAnalysisID })
			);

			//NOTE: Setting to false only good for one file upload at a time
			// _dispatch({ type: EVAPORATE, payload: false })
		},

		// called on an irrecoverable error.
		error: (error) => {
			console.log(`upload::error() - `, error);
			// TODO:
			//_dispatch(_dmAppErrorHandler(UPLOAD_ERROR, error));
		},
	};

	var overrides = {
		bucket,
	};

	return add(config, overrides);
};

const add = function (config, overrides) {
	return client.then(function (evaporate) {
		// console.log("Evaporate Supported? ", evaporate.supported);
		_evaporate = evaporate;

		return evaporate.add(config, overrides);
		// Handle below by caller
		// .then(
		// 	function (awsObjectKey) {
		// 		// console.log('uploader.js success: ', awsObjectKey);
		// 	},
		// 	function (err) {
		// 		// console.log('uploader.js failure with reason ', err);
		// 		Rollbar.error(
		// 			`uploader.js error: `, err,
		// 			{
		// 				dmioRelease: DMIO_RELEASE,
		// 			})
		// 	}
		// )
	});
};

// const getCredentials = async () => {
// 	const credentials = await Auth.currentCredentials();
// 	return { ...credentials };
// };

export const Uploader = {
	initialize,
};

// export default Uploader;
