import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Card, Form, Placeholder, Row } from "react-bootstrap";
import {
	PlaceholderButton,
	PlaceholderPrimary,
	PlaceholderGlow,
} from "components/placeholders";

import useTheme from "hooks/useTheme";
import NotifyContext from "contexts/NotifyContext";
import { useModalContext } from "contexts/ModalContext";

import { getCountryName } from "utils/";
import { ROUTES } from "constants/";

import StripeCheckoutButton from "components/stripe/StripeCheckoutButton";

const cardHeader = (
	<Card.Header>
		<Card.Title className="mb-0">Your Payment Card</Card.Title>
	</Card.Header>
);

function CreditCardInfo() {
	const { theme } = useTheme();
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);
	const { closeModal, openModal } = useModalContext();

	const {
		entity: { card },
		status,
	} = useSelector((state) => state.billing);

	const paragraphStyle = { lineHeight: "190%" };

	return (
		<Card>
			{cardHeader}
			<Card.Body className="ms-2">
				{"pending" === status ? (
					<PlaceholderGlow>
						<PlaceholderPrimary />
						<PlaceholderPrimary />
					</PlaceholderGlow>
				) : undefined === card ? (
					<React.Fragment>
						No billing information to display. Want to see some?{" "}
						<Link to={ROUTES.UPGRADE}>Upgrade</Link> 😁
					</React.Fragment>
				) : (
					<React.Fragment>
						<Row className="ms-4 me-4">
							<Col md={6} className="">
								<h6 id="h6-small">Card</h6>
								<p style={paragraphStyle}>
									{card.name}
									<br />
									<code>{_.upperFirst(card.brand)}</code> card ending in{" "}
									<code>{card.last4}</code>
									<br />
									Expiry:{" "}
									<code>
										{card.exp_month}/{card.exp_year}
									</code>
								</p>
							</Col>
							<Col md={6} className="">
								<h6 id="h6-small">Card Address</h6>
								<p style={paragraphStyle}>
									{card.address_line1}
									<br />
									{card.address_line2 ? (
										<span>
											{card.address_line2}
											<br />
										</span>
									) : null}
									{card.address_city}, {card.address_state}, {card.address_zip}
									<br />
									{getCountryName(card.address_country)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<hr />
								<StripeCheckoutButton buttonTitle="Update Card" />
							</Col>
						</Row>
					</React.Fragment>
				)}
			</Card.Body>
		</Card>
	);
}

export default CreditCardInfo;
