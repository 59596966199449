import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import PlanInfo from "./account/PlanInfo";
import UsageInfo from "./account/UsageInfo";
import OrganizationsInfo from "./account/OrganizationsInfo";

import { fetchBilling } from "features/billing/billing.actions";

export function Account() {
	const dispatch = useDispatch();

	// Needed for <PlanInfo />
	const { upcomingInvoice } = useSelector((state) => state.billing.entity);

	useEffect(() => {
		async function fetchData() {
			if (!upcomingInvoice) await dispatch(fetchBilling());
		}
		fetchData();
	}, []);

	return (
		<React.Fragment>
			<Container fluid className="p-0">
				<Row>
					<Col xl="6">
						<UsageInfo />
						<OrganizationsInfo />
					</Col>
					<Col xl="5">
						<PlanInfo />
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}
