/**
 * Thunks for billing
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchBilling from API */
export const fetchBilling = createAsyncThunk(
	"billing/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(APIPATH.billingGet);
			return res;
		} catch (err) {
			// console.log("fetchBilling FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** creates a setupIntent for updating billing info, returning clientSecret & setupIntent
 * Note that the setupIntent is not used in the final instance for updating billing card. Rather,
 * stripe.retrieveSetupIntent(clientSecret) is used.
 */
export const billingUpdateSetupIntent = async () => {
	try {
		const res = await apigateway.post(APIPATH.billingUpdateSetupIntent);
		return res;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		throw err.response;
	}
};

/** updates a billing project */
export const updateBillingCard = createAsyncThunk(
	"billing/updateCard",
	async (
		stripePaymentMethodID,
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			await apigateway.post(
				`${APIPATH.billingUpdate}?stripePaymentMethodID=${stripePaymentMethodID}`
			);
			await dispatch(fetchBilling());
			return Promise.resolve();
		} catch (err) {
			if (!err.response) {
				// catches coding errors
				throw err;
			}
			return rejectWithValue(err.response);
		}
	}
);

/**
 * Returns an object with plan change preview, or a setupIntent if customer does not have an authorized card on file.
 * @param {Object} changePlanData
 */
export function updateBillingPlanPreview(changePlanData) {
	return _updateBillingPlan({ ...changePlanData, changePlan: false });
}

export const updateBillingPlan = createAsyncThunk(
	"billing/updatePlan",
	async (changePlanData, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			var res = await _updateBillingPlan({ ...changePlanData, changePlan: true });
			await dispatch({ type: "account/rehydrate", payload: { ...res } });
			dispatch(fetchBilling());
		} catch (err) {
			if (!err.response) {
				// catches coding errors
				throw err;
			}
			return rejectWithValue(err.response);
		}
	}
);

// export const billingChangePlanAuthorize = async (changePlanData) => {
// 	return _updateBillingPlan({ ...changePlanData }, true);
// };

/**
 * API call for account upgrade, both to preview upgrade and actually make upgrade.
 * @param {Object} changePlanData
 * @param {Boolean} changePlan true to upgrade account, false to preview account upgrade
 */
const _updateBillingPlan = async ({
	coupon, // optional. Only present when new card is being authorized
	paymentPeriod, // required
	planID, // required
	prorationDate, // required
	stripePaymentMethodID, // optional unless new card is being authorized
	changePlan = false, // required Boolean true or false
}) => {
	// POST with a string (posting object is broken)
	let url =
		`${APIPATH.billingChangePlan}?` +
		`changePlan=${changePlan}&` +
		`planType=${planID}&` +
		`paymentPeriod=${paymentPeriod.toUpperCase()}&` +
		`proration_date=${prorationDate}&`;

	// stripePaymentMethodID only present when setupIntent returned (IE, new stripe customer)
	url = stripePaymentMethodID
		? (url = url + `stripePaymentMethodID=${stripePaymentMethodID}`)
		: url;

	url = coupon ? url + `&coupon=${coupon}` : url;

	try {
		// if (changePlan) throw new Error("Whoops!");
		// if (changePlan) throw "success";
		const res = await apigateway.post(url);
		// console.log("SUCCESS res: ", res);
		return res;
	} catch (err) {
		if (!err.response) {
			// console.log("ERROR no err.response - ", err);
			throw err;
		}
		// console.log("ERROR res.response - ", err.response);
		throw err.response;
	}
};
