import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

import useAppSelector from "hooks/useAppSelector";
import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import useAuth from "hooks/useAuth";
import useTheme from "hooks/useTheme";

import avatar1 from "assets/img/avatars/avatar.jpg";

import { ROUTES } from "constants/";

const apiOptions = [
	{
		name: "Old",
	},
	{
		name: "Bravo",
	},
];

const NavbarUser = () => {
	const navigate = useNavigate();
	const { openModal } = useModalContext();
	const { signOut } = useAuth();
	const { entity: user } = useAppSelector((state) => state.user);
	const { firstName } = user;

	return (
		<Dropdown className="nav-item" align="end">
			<span className="d-inline-block d-sm-none">
				<Dropdown.Toggle as="a" className="nav-link">
					<FontAwesomeIcon icon={light("gear")} className="align-middle" />
				</Dropdown.Toggle>
			</span>
			<span className="d-none d-sm-inline-block">
				<Dropdown.Toggle as="a" className="nav-link">
					<img
						src={avatar1}
						className="avatar img-fluid rounded-circle me-1"
						alt={firstName}
					/>
					{/* <span className="text-dark me-1">{firstName}</span> */}
				</Dropdown.Toggle>
			</span>
			<Dropdown.Menu drop="end">
				<Dropdown.Item
					onClick={() => {
						navigate(ROUTES.ACCOUNT + ROUTES.PROFILE);
					}}
				>
					<FontAwesomeIcon icon={light("user")} className="align-middle me-2" />
					Profile
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item
					onClick={() => {
						window.open("https://dreamaker.io/privacy");
					}}
				>
					Privacy & Terms
				</Dropdown.Item>
				<Dropdown.Item
					onClick={() => {
						window.open("https://get.dreamaker.help/en/");
					}}
				>
					Help
				</Dropdown.Item>
				{/* <Dropdown.Divider /> */}
				<Dropdown.Item
					onClick={() => {
						openModal(MODAL_TYPES.INFO, {
							cancelBtnTitle: false, // dont show any close button
							backdrop: "static",
							body: <div className="p-2 text-primary fs-5">Signing out...</div>,
						});
						signOut();
					}}
				>
					Sign out
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.ItemText className="pt-0 pb-0 text-center">
					<small>DMIO v{process.env.VERSION}</small>
				</Dropdown.ItemText>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default NavbarUser;
