import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
import { LoadingSpinner } from "components/loaders/";
import { Button } from "components/buttons/";

import { useModalContext } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import { formatLocalDate, formatCurrency, ucWords } from "utils/";

import { updateBillingPlan } from "features/billing/billing.actions";

import { ROUTES } from "constants/";
import { ModelAttributeAuthAllow } from "@aws-amplify/datastore";

const StripePlanChangePreview = ({
	changePreview,
	coupon,
	newPlan,
	newPaymentPeriod,
	prorationDate,
	stripePaymentMethodID,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { closeModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const [loading, setLoading] = useState(false);

	const {
		entity: { planData, paymentPeriod },
	} = useSelector((state) => state.account);

	if (!changePreview)
		return (
			<React.Fragment>
				<Modal.Body style={{ textAlign: "center" }}>
					<LoadingSpinner size="lg" className="py-3" />
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</React.Fragment>
		);

	const onMakePaymentClick = async (e) => {
		// console.log("onMakePaymentClick Called... ");
		e.preventDefault();
		setLoading(true);

		const res = await dispatch(
			updateBillingPlan({
				planID: newPlan.planID,
				paymentPeriod: newPaymentPeriod,
				prorationDate,
				stripePaymentMethodID,
				coupon,
			})
		);
		if (res.error) {
			notify.error(res.error.message);
			setLoading(false);
			return;
		}

		notify.success("Congrats, change of plan successful!");
		navigate(ROUTES.HOME);
		closeModal();
	};

	// console.log("StripePlanChangePreview - ");
	// console.log("changePreview: ", changePreview);
	// console.log("newPlan: ", newPlan);
	// console.log("newPaymentPeriod: ", newPaymentPeriod);
	// console.log("planData: ", planData);
	// console.log("paymentPeriod: ", paymentPeriod);
	// console.log("stripePaymentMethodID: ", stripePaymentMethodID);
	// console.log("coupon: ", coupon);

	return (
		<React.Fragment>
			<Modal.Body>
				<p style={{ marginBottom: "1rem" }}>
					Changing to the{" "}
					<b>
						{ucWords(newPaymentPeriod)} {ucWords(newPlan?.title)} Plan
					</b>{" "}
					from the{" "}
					<b>
						{ucWords(paymentPeriod)} {ucWords(planData?.title)} Plan
					</b>{" "}
					will result in the below charges:
				</p>
				{changePreview.upcomingInvoice?.starting_balance ? (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							fontWeight: 400,
							borderBottom: "1px solid lightgrey",
							marginBottom: "5px",
						}}
					>
						<span>Account opening balance:</span>
						<span>
							{formatCurrency(-changePreview.upcomingInvoice.starting_balance)}
						</span>
					</div>
				) : null}
				<div className="mx-4">
					<span className="me-2">Charge due date:</span>
					<span>
						{formatLocalDate(changePreview.upcomingInvoice.date * 1000, true)}
					</span>
				</div>
				<div className="mx-4">
					<span className="me-2">Amount due:</span>
					<span>
						{formatCurrency(changePreview.upcomingInvoice.amount_due)}
						{changePreview.upcomingInvoice.ending_balance ? " *" : null}
					</span>
				</div>
				<div className="mx-4">
					<span className="me-2">Charge card:</span>
					<span>
						{_.upperFirst(changePreview.card.brand)}{" "}
						<code>{changePreview.card.last4}</code>
					</span>
				</div>
				{changePreview.card ? (
					<Button
						className="mx-2"
						disabled={loading}
						variant="link"
						onClick={() => {
							navigate(`${ROUTES.ACCOUNT}/${ROUTES.BILLING}`);
							closeModal();
						}}
					>
						Use a different card.
					</Button>
				) : null}
				{changePreview.upcomingInvoice.ending_balance ? (
					<div className={"mt-3"}>
						<i>
							* Your account will have{" "}
							{formatCurrency(-changePreview.upcomingInvoice.ending_balance)} credit
							after this charge. The credit is from the unused time on your current
							plan & will be applied to all upcoming invoices.
						</i>
					</div>
				) : null}
			</Modal.Body>
			<Modal.Footer>
				<Button
					id="cancelModalBtn"
					variant="outline-muted"
					size="sm"
					className={`rounded m-2`}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>
				<Button
					disabled={loading}
					id="submit"
					onClick={onMakePaymentClick}
					loading={loading}
				>
					<span id="button-text">Make payment</span>
				</Button>
			</Modal.Footer>
		</React.Fragment>
	);
};

export default StripePlanChangePreview;
