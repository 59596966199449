import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Col, Row, Form } from "react-bootstrap";

import NotifyContext from "contexts/NotifyContext";
import { updateUser } from "features/user/user.actions";

import {
	PlaceholderButton,
	PlaceholderGlow,
	PlaceholderPrimary,
} from "components/placeholders";
import { SubmitButton } from "components/buttons/";
import ChatNow from "components/ChatNow";

export function ProfileForm() {
	const dispatch = useDispatch();
	const notify = useContext(NotifyContext);

	const {
		entity: { firstName, lastName, email },
	} = useSelector((state) => state.user);

	let initialValues = {
		submit: false,
	};

	if (undefined === firstName) return <Loadholder />;

	initialValues = {
		firstName: firstName,
		lastName: lastName,
		email: email,
		submit: false,
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().trim().max(255).required("First name is required"),
				lastName: Yup.string().trim().max(255).required("Last name is required"),
				location: Yup.string().trim().max(255).optional(),
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				const res = await dispatch(updateUser(values));
				if (!res.error) notify.success("Successfully updated!");
				else {
					const message = res.error.message || "Something went wrong";
					notify.error(message);
					setStatus({ success: false });
					setErrors({ submit: message });
					setSubmitting(false);
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Row className="ps-2 pe-2">
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>First name</Form.Label>
								<Form.Control
									type="text"
									name="firstName"
									placeholder="First name"
									value={values.firstName}
									isInvalid={Boolean(touched.firstName && errors.firstName)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.firstName && (
									<Form.Control.Feedback type="invalid">
										{errors.firstName}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>Last name</Form.Label>
								<Form.Control
									type="text"
									name="lastName"
									placeholder="Last name"
									value={values.lastName}
									isInvalid={Boolean(touched.lastName && errors.lastName)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.lastName && (
									<Form.Control.Feedback type="invalid">
										{errors.lastName}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row className="ps-2 pe-2">
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>Email address</Form.Label>
								<Form.Control
									type="email"
									name="email"
									placeholder="Email address"
									value={values.email}
									disabled
								/>
								<Form.Text className="pt-3 ps-2 fs-sm text-primary">
									<ChatNow>Contact us to change your email address</ChatNow>
								</Form.Text>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Label>Location</Form.Label>
								<Form.Control
									type="text"
									name="location"
									placeholder="Location"
									value={values.location}
									isInvalid={Boolean(touched.location && errors.location)}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								{!!touched.location && (
									<Form.Control.Feedback type="invalid">
										{errors.location}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
					</Row>

					<SubmitButton title="Save Changes" disabled={isSubmitting} />
				</Form>
			)}
		</Formik>
	);
}

const Loadholder = () => {
	const col = (
		<Col>
			<PlaceholderPrimary className="w-100 placeholder-lg" />
		</Col>
	);
	const row = (
		<Row className="m-2">
			{col}
			{col}
		</Row>
	);
	return (
		<PlaceholderGlow>
			{row}
			{row}
			<PlaceholderButton />
		</PlaceholderGlow>
	);
};

// export  Profile;
