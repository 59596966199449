import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, FloatingLabel } from "react-bootstrap";
import { Button } from "components/buttons";
import { ColorDropdown } from "components/ColorDropdown";

import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";
import NotifyContext from "contexts/NotifyContext";

import {
	deleteUserDashboardTag,
	updateUserDashboardTag,
} from "features/dashboardTags/dashboardTags.actions";

const TagInfo = () => {
	const dispatch = useDispatch();
	const { closeModal, openModal } = useModalContext();
	const notify = useContext(NotifyContext);

	const { entities: dashboardTags, currentEntityID: currentDashboardTagID } =
		useSelector((state) => state.dashboardTags);

	const [dashboardTagLabel, setDashboardTagLabel] = useState("");
	const [dashboardTagColor, setDashboardTagColor] = useState("");

	//Updates "dashboardTagLabel" whenever a new tag is selected, so it can automatically populate the input field to rename a tag
	useEffect(() => {
		if (undefined === currentDashboardTagID) {
			setDashboardTagLabel("");
			setDashboardTagColor("");
		} else {
			setDashboardTagLabel(dashboardTags[currentDashboardTagID].label);
			setDashboardTagColor(dashboardTags[currentDashboardTagID].color);
		}
	}, [currentDashboardTagID]);

	// console.log("===TagInfo===");
	// console.log("currentDashboardTagID: ", currentDashboardTagID);
	// console.log("dashboardTagLabel: ", dashboardTagLabel);
	// console.log("dashboardTagColor: ", dashboardTagColor);
	// console.log("");
	// console.log("");

	const onColorUpdate = (color) => {
		setDashboardTagColor(color);
		updateDashboardTag("color", color);
	};

	const updateDashboardTag = async (key, value) => {
		if ("label" === key) {
			value = value.trim();
			if ("" === value) {
				notify.error("Dashboard tag label required.");
				return;
			}
		}
		var updatedTag = {
			...dashboardTags[currentDashboardTagID],
			[key]: value,
		};

		const res = await dispatch(updateUserDashboardTag(updatedTag));
		if (res.error) {
			notify.error(res.error);
			return;
		}
		// notify.success("Dashboard tag successfully updated!");
		// setDashboardTagLabel(tag.label);
	};

	const onDeleteClick = (dashboardTagID) => {
		openModal(MODAL_TYPES.CONFIRMATION, {
			body: "Are you sure you want to delete this tag?",
			confirmBtnAction: async ({ dashboardTagID }) => {
				const res = await dispatch(deleteUserDashboardTag({ dashboardTagID }));
				if (!res.error) {
					notify.success("Dashboard tag susseccfully deleted!");
					closeModal();
				} else {
					notify.error(res.error);
					return Promise.reject(false);
				}
			},
			confirmBtnClasses: "btn-danger",
			confirmBtnTitle: "Delete Tag",
			title: "Delete Tag: " + dashboardTags[currentDashboardTagID].label,
			data: { dashboardTagID },
		});
	};

	return (
		<>
			<div className="pb-4 ms-2">
				<h4 className="border-bottom py-2 ">Tag Info</h4>
				<div className="pt-2">
					<div>
						<FloatingLabel label="Tag label" className="mb-3">
							<Form.Control
								id="tagLabel"
								type="text"
								name="label"
								placeholder="palace"
								value={dashboardTagLabel}
								maxLength={16}
								onChange={(e) => setDashboardTagLabel(e.target.value)}
								onBlur={(e) => updateDashboardTag("label", e.target.value)}
								onKeyDown={(e) =>
									e.code === "Enter" || e.code === "NumpadEnter"
										? updateDashboardTag("label", e.target.value)
										: null
								}
								required
							/>
						</FloatingLabel>
					</div>
					<div>
						<ColorDropdown tagColor={dashboardTagColor} onChange={onColorUpdate} />

						<Button
							variant="outline-danger"
							onClick={() => onDeleteClick(currentDashboardTagID)}
							className="d-block mt-3 float-end"
						>
							Delete Tag
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TagInfo;
