import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { Button, Card, Form, Row, Col, Table } from "react-bootstrap";
import { ButtonLoadingIcon } from "components/icons";
import { PlaceholderTable } from "components/placeholders/";
import MemberDropdown from "./members/MemberDropdown";

import { fetchMembers, inviteMember } from "features/members/members.actions";
import { updateOrganization } from "features/account/account.actions";

import { capitalize, formatLocalDate } from "utils/";
import { INVITE_STATUS, INVITE_TYPES, UNLIMITED } from "constants/";

function Members() {
	const dispatch = useDispatch();
	const { closeModal, openModal } = useModalContext();
	const [inviting, setInviting] = useState(false);
	const [sort, setSort] = useState({
		key: "name",
		order: "asc",
	});

	const {
		autoMemberAtSignup = false,
		organizationID,
		organizations,
	} = useSelector((state) => state.account.entity);

	const {
		entities: members, // members structure: { memUserID-1: {}, memUserID-2: {} }
	} = useSelector((state) => state.members);

	useEffect(() => {
		if (null === members) dispatch(fetchMembers());
	}, []);

	const { userID } = useSelector((state) => state.user.entity);

	if (undefined === organizationID || null === members)
		return (
			<Card>
				<Card.Body>
					<PlaceholderTable header={true} rows={2} cols={5} />
				</Card.Body>
			</Card>
		);

	const { organizationAtDomain } = organizations[organizationID];

	// let sort = this.state.sort;
	let renderMembers = null;
	if (0 < _.keys(members).length) {
		let orderMembers = _.orderBy(members, [sort.key, "name"], [sort.order]);
		// console.log("member.datePendingRevoke - ", member.datePendingRevoke);
		renderMembers = orderMembers.map((member, i) => (
			<tr key={member.email}>
				<td>{member.name}</td>
				<td>{member.email}</td>
				<td>{renderBillingStatus(member, userID)}</td>
				<td>{member.lastLogin ? formatLocalDate(member.lastLogin) : null}</td>
				<td>
					{member.datePendingRevoke
						? formatLocalDate(member.datePendingRevoke)
						: "-"}
				</td>
				<td>
					{userID === member.userID ? null : <MemberDropdown member={member} />}
				</td>
			</tr>
		));
	}

	/** Renders <thead /> row */
	const renderTheadRow = () => {
		/** Return <th /> header with sort */
		const getTh = (key, heading) => {
			return (
				<th
					className={
						`sorting ` +
						(key === sort.key
							? `sorting_` + sort.order
							: `sorting_` + sort.order + `_disabled`)
					}
					onClick={() => onHeaderClick(key)}
				>
					{heading || capitalize(key)}
				</th>
			);
		};

		return (
			<tr>
				{getTh("name")}
				{getTh("email")}
				{getTh("status", "Billing Status")}
				{getTh("last Login", "Last Login")}
				{getTh("datePendingRevoke", "Deactivation Date")}
				<th></th>
			</tr>
		);
	};

	/** Sorts members table by key */
	const onHeaderClick = (key) => {
		setSort({ key, order: "asc" === sort.order ? "desc" : "asc" });
	};

	const onInviteMembersClick = () => {
		return openModal(MODAL_TYPES.INVITE, {
			confirmBtnAction: async ({ emails, inviteMessage }) => {
				await emails.map(async (email, i) => {
					dispatch(await inviteMember({ email }))
						.then((data) => {
							// return Promise.success();
						})
						.catch((err) => {
							console.log("failed -", err);
							// return Promise.reject();
						});
				});
				return Promise.resolve();
			},
			inviteType: INVITE_TYPES.ORG_MEMBERS,
		});
	};

	/** Toggles automemberSignup - organizationUpdate */
	const toggleAutoMemberSignup = (e) => {
		dispatch(updateOrganization(e.target.checked));
	};

	return (
		<Card>
			<Card.Header>
				{/* <Card.Title className="mb-0">Members</Card.Title> */}
				<Row>
					<Col
						md={{ span: 12 }}
						className="mb-0"
						style={{ display: "flex", justifyContent: "space-between" }}
					>
						<Form.Check
							type="checkbox"
							name="autoSignUp"
							id="checkbox" //rename to defaultCheckbox?
							className="pt-2"
							checked={autoMemberAtSignup}
							label={`Auto add people with ${organizationAtDomain} email addresses`}
							onChange={toggleAutoMemberSignup}
						/>
						<Button
							variant="primary"
							size=""
							disabled={inviting}
							className="mt-0"
							onClick={() => {
								onInviteMembersClick();
							}}
						>
							Invite People
							{inviting ? <ButtonLoadingIcon /> : null}
						</Button>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Table className="mb-0" responsive hover>
					<thead>{renderTheadRow()}</thead>
					<tbody>{renderMembers}</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
}

const renderBillingStatus = (member, userID) => {
	let date,
		className = "",
		title = null;
	const { status } = member;

	switch (status) {
		case INVITE_STATUS.ERROR_INVITING:
		case INVITE_STATUS.ERROR_DEACTIVATING: {
			title = <i>{capitalize(status)}</i>;
			className = "text-danger";
			date = "now";
			break;
		}
		case INVITE_STATUS.LOADING: {
			title = <i className="far fa-spinner fa-spin" />;
			className = "text-success";
			date = "now";
			break;
		}
		case INVITE_STATUS.INVITED: {
			title = <i>Invitation sent</i>;
			className = "text-teal";
			date = formatLocalDate(member.dateInvited);
			break;
		}
		case INVITE_STATUS.DECLINED:
			title = <i>Deactivated</i>;
			className = "text-gray";
			date = formatLocalDate(member.dateDeclined);
			break;
		case INVITE_STATUS.LEFT: {
			title = <i>Deactivated</i>;
			className = "text-gray";
			date = formatLocalDate(member.dateLeft);
			break;
		}
		case INVITE_STATUS.REVOKED: {
			title = <i>Deactivated</i>;
			className = "text-gray";
			date = formatLocalDate(member.dateRevoked);
			break;
		}
		case INVITE_STATUS.ACCEPTED:
		default: {
			title = userID === member.userID ? <b>Account Owner</b> : <b>Member</b>;
			className = "text-success";
			date =
				undefined === member.dateAccepted
					? ""
					: formatLocalDate(member.dateAccepted);
			break;
		}
	}
	return (
		<span>
			<span className={className}>
				{null !== title ? title : capitalize(status)}
			</span>
			<br />
			<small>{"" === date ? null : date}</small>
		</span>
	);
};

export default Members;
