/**
 * Thunks for organizations
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchesUser from API */
export const fetchOrganizations = createAsyncThunk(
	"organizations/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(APIPATH.organizationsGet);
			return res;
		} catch (err) {
			// console.log("fetchOrganizations FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates a organizations project */
export const updateOrganizations = createAsyncThunk(
	"organizations/update",
	async (organizations, { dispatch, getState, requestId, rejectWithValue }) => {
		// console.log("updateOrganizations to ", organizations);
		try {
			await apigateway.post(APIPATH.organizationsUpdate, organizations);
			return {
				...getState().organizations.entity,
				...organizations,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
