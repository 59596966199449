import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Col, Container, Nav, Row } from "react-bootstrap";

import DashboardsAnalysis from "./DashboardsAnalysis";
import NotesAnalysis from "./NotesAnalysis";
import AttachmentsAnalysis from "./AttachmentsAnalysis";
import TranscriptionAnalysis from "./TranscriptionAnalysis";

const BottomBar = (props) => {
	const { currentProjectDashboards } = props;
	const { currentProjectDashboardTags } = props;

	const [currentComponent, setCurrentComponent] = useState();

	const displayComponent = (componentName) => {
		switch (componentName) {
			case "dashboards":
				setCurrentComponent(
					<DashboardsAnalysis
						currentProjectDashboards={currentProjectDashboards}
						currentProjectDashboardTags={currentProjectDashboardTags}
					/>
				);
				break;
			case "notes":
				setCurrentComponent(<NotesAnalysis />);
				break;
			case "attachments":
				setCurrentComponent(<AttachmentsAnalysis />);
				break;
			case "transcriptions":
				setCurrentComponent(<TranscriptionAnalysis />);
				break;
			case "data":
				setCurrentComponent(
					<div>
						<h1>WIP</h1>
					</div>
				);
				break;
		}
	};

	return (
		<Container className="w-100 h-100">
			<Row className="w-100">
				<Col xs={1}>
					<Nav
						activeKey="dashboards"
						onSelect={(selectedKey) => displayComponent(selectedKey)}
						className="d-flex flex-column"
					>
						<Nav.Item>
							<Nav.Link eventKey="dashboards">
								<FontAwesomeIcon icon={solid("bullseye-pointer")} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="notes">
								<FontAwesomeIcon icon={solid("file-lines")} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="attachments">
								<FontAwesomeIcon icon={solid("paperclip")} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="transcriptions">
								<FontAwesomeIcon icon={solid("bullhorn")} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="data">
								<FontAwesomeIcon icon={solid("chart-line-up")} />
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Col>
				<Col xs={11}>
					<div className="p-2">{currentComponent}</div>
				</Col>
			</Row>
		</Container>
	);
};

export default BottomBar;
