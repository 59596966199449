import "./projects.scss";

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Dropdown as BSDropdown } from "react-bootstrap";
import { Dropdown } from "components/dropdowns/";
import { Button } from "components/buttons";
import {
	CreateFolderMenuBtn,
	ImportZoomMenuBtn,
	UploadMenuBtn,
} from "components/dropdowns";
import {
	CreateIcon,
	HomeIcon,
	SortIcon,
	SortAZIcon,
	SortZAIcon,
	Sort19Icon,
	Sort91Icon,
} from "components/icons";

import { ROUTES } from "constants/";

const ProjectsHeader = ({
	createFolder,
	disabled,
	filterFolderResults,
	isZoom = false,
	onZoomImportClick,
	sortFolderResults,
	uploadFile,
}) => {
	const navigate = useNavigate();
	const [sort, setSort] = useState({ by: "dateCreated", order: "desc" });
	const [filter, setFilter] = useState({
		filterKey: "",
		filterValue: "All",
	});

	// console.log("ProjectHeader isZoom - ", isZoom);

	const onSortClick = (by) => {
		const nextSort = {
			by,
			order: "asc" === sort.order ? "desc" : "asc",
		};
		setSort(nextSort);
		sortFolderResults(nextSort);
	};

	const onFilterClick = (newFilter) => {
		// console.log("onFilterClick");
		setFilter({ ...newFilter });
		filterFolderResults({ ...newFilter });
	};

	const onUploadFileClick = () => {
		document.getElementById("uploadFileDialog").click();
	};

	// const FilterButton = ({ filterKey = "", filterValue = "All", title }) => (
	const FilterButton = ({ filterKey, filterValue = "all", title }) => {
		// console.log("title - ", title);
		// console.log("filterKey - ", filterKey);
		// console.log("filterValue - ", filterValue);
		// console.log(
		// 	".... - ",
		// 	title.toLowerCase().includes(filter.filterValue?.toLowerCase())
		// );
		// console.log("");
		return (
			<Button
				disabled={disabled}
				variant="link"
				onClick={() => onFilterClick({ filterKey, filterValue })}
				className="project-header-filter"
				style={{
					textDecoration: title
						.toLowerCase()
						.includes(filter.filterValue?.toLowerCase())
						? "underline"
						: "none",
				}}
			>
				{title}
			</Button>
		);
	};

	return (
		<>
			<input
				style={{ visibility: "hidden" }}
				type="file"
				id="uploadFileDialog"
				onChange={(e) => uploadFile(e.target.files)}
			/>
			<header className="d-flex justify-content-between">
				<h1>{isZoom ? "Media Imported from Zoom" : "Projects"}</h1>
				<div className="d-flex align-items-end align-self-baseline">
					{/* HOME */}
					<Button variant="a" onClick={() => navigate(ROUTES.PROJECTS)}>
						<HomeIcon variant="primary" disabled={disabled} />
					</Button>

					{/* SORT */}
					<Dropdown
						heading="Sort"
						icon={<SortIcon variant="primary" disabled={disabled} />}
					>
						{/* Title */}
						<BSDropdown.Item
							disabled={disabled}
							onClick={() => {
								onSortClick("title");
							}}
						>
							{"desc" === sort.order ? (
								<SortAZIcon variant={"title" === sort.by ? "primary" : "muted"} />
							) : (
								<SortZAIcon variant={"title" === sort.by ? "primary" : "muted"} />
							)}
							Title{" "}
						</BSDropdown.Item>

						{/* Date Created */}
						<BSDropdown.Item
							disabled={disabled}
							onClick={() => {
								onSortClick("dateCreated");
							}}
						>
							{"desc" === sort.order ? (
								<Sort19Icon variant={"dateCreated" === sort.by ? "primary" : "muted"} />
							) : (
								<Sort91Icon variant={"dateCreated" === sort.by ? "primary" : "muted"} />
							)}
							Date Created
						</BSDropdown.Item>

						{/* Date Updated */}
						<BSDropdown.Item
							disabled={disabled}
							onClick={() => {
								onSortClick("dateUpdated");
							}}
						>
							{"desc" === sort.order ? (
								<Sort19Icon variant={"dateUpdated" === sort.by ? "primary" : "muted"} />
							) : (
								<Sort91Icon variant={"dateUpdated" === sort.by ? "primary" : "muted"} />
							)}
							Date Updated
						</BSDropdown.Item>
					</Dropdown>

					{/* CREATE */}
					{!isZoom && (
						<Dropdown heading="Add new" icon={<CreateIcon variant="primary" />}>
							<CreateFolderMenuBtn disabled={disabled} onClick={createFolder} />
							<UploadMenuBtn disabled={disabled} onClick={onUploadFileClick} />
							<ImportZoomMenuBtn disabled={disabled} onClick={onZoomImportClick} />
						</Dropdown>
					)}
				</div>
			</header>
			<header>
				<FilterButton title="All" />
				<FilterButton filterKey="mediaType" filterValue="VIDEO" title="Video" />
				<FilterButton filterKey="mediaType" filterValue="AUDIO" title="Audio" />
				{!isZoom && (
					<FilterButton filterKey="mediaType" filterValue="FOLDER" title="Folders" />
				)}
				{!isZoom && (
					<FilterButton
						filterKey="mediaSource"
						filterValue="zoom"
						title="Zoom Imports"
					/>
				)}
			</header>
		</>
	);
};

export default ProjectsHeader;
