/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import { createSlice } from "@reduxjs/toolkit";
import { fetchUser, updateUser } from "./user.actions";
import { zoomDeauthorization, zoomAuthorization } from "./oauth.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entity: {},
	status: "idle", // idle | pending |  succeeded | failed -- if state is "loaded", don't call initFetch
};

const userSlice = createSlice({
	name: "user",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		// setCurrentEntityID: (state, action) => {
		// 	// state.currentEntityID = action.payload; // payload is itemID
		// },
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		// reset: () => {
		// 	console.log("reset called on user");
		// 	state = initialState;
		// },
		/** fetchUser */
		[fetchUser.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchUser.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload contains user object
		},
		[fetchUser.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** updateUser */
		[updateUser.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateUser.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = action.payload; // payload is updated project object
		},
		[updateUser.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** zoomAuthorization */
		[zoomAuthorization.pending]: (state, action) => {
			state.status = "pending";
		},
		[zoomAuthorization.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.entity = { ...state.entity, oauth: { ...action.payload } }; // payload is updated user.oauth object
		},
		[zoomAuthorization.rejected]: (state, action) => {
			state.status = "failed";
		},

		/** zoomDeauthorization */
		[zoomDeauthorization.pending]: (state, action) => {
			state.status = "pending";
		},
		[zoomDeauthorization.fulfilled]: (state, action) => {
			state.status = "succeeded";
			delete state.entity.oauth.zoom; // payload is empty
		},
		[zoomDeauthorization.rejected]: (state, action) => {
			state.status = "failed";
		},
	},
});

// Action creators are generated for each case reducer function
// export const { reset } = userSlice.actions;
export default userSlice.reducer;
