import React from "react";
import {
	CollaboratorsIcon,
	CollaborationLeaveIcon,
	FileCopyIcon,
	DashboardsIcon,
	TrashCanIcon,
	ProjectsExportIcon,
	LockIcon,
	MarkersIcon,
	ProjectOpenIcon,
	ProjectRenameIcon,
	ProjectSendIcon,
	ShieldCheckIcon,
} from "components/icons";
import DropdownItem from "./DropdownItem";

export const CollaborateMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<CollaboratorsIcon menu="true" />}
		title="Invite collaborators"
	/>
);

export const CollaborationLeaveMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<CollaborationLeaveIcon menu="true" />}
		title="Leave Collaboration"
	/>
);

export const DashboardsMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<DashboardsIcon menu="true" />}
		title="Update dashboards"
	/>
);

export const MarkersMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<MarkersIcon menu="true" />}
		title="Update markers"
	/>
);

export const ProjectConfidentialMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<ShieldCheckIcon menu="true" />}
		title="Mark confidential"
	/>
);

export const ProjectCopyMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<FileCopyIcon menu="true" />}
		title="Duplicate..."
	/>
);

export const ProjectDeleteMenuBtn = (props) => (
	<DropdownItem {...props} icon={<TrashCanIcon menu="true" />} title="Delete" />
);

export const ProjectExportMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<ProjectsExportIcon menu="true" />}
		title="Export analysis"
	/>
);

export const ProjectLockMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<LockIcon menu="true" />}
		title="Lock project"
	/>
);

export const ProjectOpenMenuBtn = (props) => (
	<DropdownItem {...props} icon={<ProjectOpenIcon menu="true" />} title="Open" />
);

export const ProjectSendMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<ProjectSendIcon menu="true" />}
		title="Send to..."
	/>
);

export const ProjectRenameMenuBtn = (props) => (
	<DropdownItem
		{...props}
		icon={<ProjectRenameIcon menu="true" />}
		title="Rename"
	/>
);
