import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

import { ROUTES } from "constants/";

/**
 * Displays a information model
 * @param {*} props All props required (other than confirmBtnTitle,  cancelBtnAction)
 * @returns
 */
export const InfoModal = (props) => {
	const { closeModal, showModal } = useModalContext();
	const [loadingOnCancel, setLoadingOnCancel] = useState(false);
	const {
		children,
		body,
		cancelBtnAction,
		cancelBtnClasses,
		cancelBtnTitle = "Close", // if boolean false, no butoon shown
		backdrop = true,
		size = "sm",
		title,
	} = props;

	const onCancelClick = (e) => {
		if (cancelBtnAction) {
			setLoadingOnCancel(true);
			cancelBtnAction(e);
		}
		closeModal();
	};

	return (
		<Modal
			size={size}
			backdrop={backdrop}
			show={showModal}
			onShow={() => {
				if (cancelBtnTitle) document.getElementById("cancelModalBtn").focus();
			}}
			onHide={onCancelClick}
		>
			{title && (
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{body || children}</Modal.Body>

			{cancelBtnTitle && (
				<Modal.Footer>
					<Button
						id="cancelModalBtn"
						variant="primary"
						size="sm"
						className={`rounded m-2 ` + cancelBtnClasses}
						onClick={onCancelClick}
					>
						{cancelBtnTitle}{" "}
						{!loadingOnCancel || (
							<FontAwesomeIcon icon={solid("circle-notch")} className="fa-spin ms-2" />
						)}
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export const InfoUpgradeModal = ({ feature = "this feature" }) => {
	return (
		<InfoModal cancelBtnTitle="Ok">
			<p>To use {feature}, please upgrade your account.</p>
			<p>
				<Link to={ROUTES.UPGRADE}>View plans here.</Link>
			</p>
		</InfoModal>
	);
};
