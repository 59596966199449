import React from "react";
import useTheme from "hooks/useTheme";
import { Button } from "react-bootstrap";
import { NavButton } from "components/buttons";
import {
	ThemeToggleIcon,
	ThemeToggleMoonIcon,
	ThemeToggleSunIcon,
} from "components/icons/";

import { THEME, THEME_COLORS } from "constants/";

const NavbarColorTheme = () => {
	const { theme, setTheme } = useTheme();

	const colors =
		THEME.DARK === theme
			? {
					moon: "primary",
					sun: "",
					toggle: "",
			  }
			: {
					moon: "",
					sun: "primary",
					toggle: "",
			  };

	// debugger;
	return (
		<React.Fragment>
			<NavButton
				onClick={(e) => {
					e.stopPropagation();
					setTheme(THEME.DARK);
				}}
				className="pe-0"
				icon={<ThemeToggleMoonIcon variant={colors.moon} />}
			/>
			<NavButton
				onClick={(e) => {
					e.stopPropagation();
					setTheme(THEME.LIGHT);
				}}
				className="ps-0 me-2"
				icon={<ThemeToggleSunIcon variant={colors.sun} />}
			/>
		</React.Fragment>
	);
};

export default NavbarColorTheme;
