import React from "react";
import { useSelector } from "react-redux";

import { Card, Col, Container, Row, Table } from "react-bootstrap";

import { IntegrationsLoading } from "./integrations/IntegrationsLoading";
import ZoomIntegration from "./integrations/ZoomIntegration";
import MoodleIntegration from "./integrations/MoodleIntegration";
import MoodleIntegrationsList from "./integrations/MoodleIntegrationsList";

import { PLANID } from "constants/";

export function Integrations() {
	const {
		entity: { accountID, planType },
	} = useSelector((state) => state.account);
	const {
		entity: { userID },
	} = useSelector((state) => state.user);

	if (undefined === accountID || undefined === userID)
		return <IntegrationsLoading />;

	const upgradeRequired = PLANID.FREE[planType];

	return (
		<React.Fragment>
			<Container fluid className="p-0">
				<Row>
					<Col xl={9}>
						<Card>
							<Card.Body>
								<Table responsive borderless>
									<thead>
										<tr>
											<td width="120px"></td>
											<td></td>
											<td></td>
										</tr>
									</thead>
									<tbody>
										<ZoomIntegration upgradeRequired={upgradeRequired} />
										<MoodleIntegration />
										<MoodleIntegrationsList />
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}
