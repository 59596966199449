import React from "react";

import NotesEditor from "./NotesEditor";

const NotesAnalysis = () => {
	return (
		<div className="w-100 px-2">
			<h2>NotesAnalysis</h2>
			<NotesEditor />
		</div>
	);
};

export default NotesAnalysis;
