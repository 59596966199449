import { createSlice } from "@reduxjs/toolkit";

import {
	createEventTag,
	deleteEventTag,
	fetchEventTags,
	updateEventTag,
} from "./eventTags.actions";

/**
 * Redux Learning NOTES
 * =====================\
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: {},
	currentEntityID: undefined,
	loading: "idle", // idle | pending | loaded -- if state is "loaded", don't call initFetch
	currentRequestId: undefined,
	error: null,
};

const eventTagsSlice = createSlice({
	name: "eventTags",
	initialState,

	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		setCurrentEntityID: (state, action) => {
			state.currentEntityID = action.payload; // payload is dashboardID
		},
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,
		/** fetchEventTags */
		[fetchEventTags.pending]: (state, action) => {
			console.log("fetchEventTags.pending");
			if (state.loading === "idle") {
				state.loading = "pending";
				state.currentRequestId = action.meta.requestId;
			}
		},
		[fetchEventTags.fulfilled]: (state, action) => {
			console.log("fetchEventTags.fulfilled");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				state.entities = action.payload; // payload contains object of eventTags
				state.currentRequestId = undefined;
			}
		},
		[fetchEventTags.rejected]: (state, action) => {
			console.log("fetchEventTags.rejected");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				state.error = action.error;
				state.currentRequestId = undefined;
			}
		},

		// /** createEventTag */
		[createEventTag.pending]: (state, action) => {
			console.log("createEventTags.pending");
			if (state.loading === "idle") {
				state.loading = "pending";
				state.currentRequestId = action.meta.requestId;
			}
		},
		[createEventTag.fulfilled]: (state, action) => {
			console.log("createEventTags.fulfilled");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				state.entities[action.payload.eventTagID] = action.payload; // the new dashboard object
				// state.currentEntityID = action.payload.eventTagID;
				state.currentRequestId = undefined;
			}
		},
		[createEventTag.rejected]: (state, action) => {
			console.log("createEventTags.rejected");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				if (action.payload) {
					// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
					state.error = action.payload.errorMessage;
				} else {
					state.error = action.error;
				}
				state.currentRequestId = undefined;
			}
		},

		// /** deleteEventTag */
		[deleteEventTag.pending]: (state, action) => {
			console.log("deleteEventTag.pending");
			if (state.loading === "idle") {
				state.loading = "pending";
				state.currentRequestId = action.meta.requestId;
			}
		},
		[deleteEventTag.fulfilled]: (state, action) => {
			console.log("deleteEventTag.fulfilled");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				delete state.entities[action.payload]; // payload is eventTagID
				state.currentRequestId = undefined;
			}
		},
		[deleteEventTag.rejected]: (state, action) => {
			console.log("deleteEventTag.rejected");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				if (action.payload) {
					// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
					state.error = action.payload.errorMessage;
				} else {
					state.error = action.error;
				}
				state.currentRequestId = undefined;
			}
		},

		// /** updateEventTag */
		[updateEventTag.pending]: (state, action) => {
			console.log("updateEventTag.pending");
			if (state.loading === "idle") {
				state.loading = "pending";
				state.currentRequestId = action.meta.requestId;
			}
		},
		[updateEventTag.fulfilled]: (state, action) => {
			console.log("updateEventTag.fulfilled");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				state.entities[action.payload.eventTagID] = action.payload; // payload is updated dashboard object
				state.currentRequestId = undefined;
			}
		},
		[updateEventTag.rejected]: (state, action) => {
			console.log("updateEventTag.rejected");
			const { requestId } = action.meta;
			if (state.loading === "pending" && state.currentRequestId === requestId) {
				state.loading = "idle";
				if (action.payload) {
					// Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
					state.error = action.payload.errorMessage;
				} else {
					state.error = action.error;
				}
				state.currentRequestId = undefined;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { setCurrentEntityID } = eventTagsSlice.actions;
export default eventTagsSlice.reducer;
