/**
 * Thunks for members
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH, INVITE_STATUS } from "constants/";

/** fetch organization members from API */
export const fetchMembers = createAsyncThunk(
	"members/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(APIPATH.membersFetch);
			return res;
		} catch (err) {
			// console.log("fetchMembers FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates members */
export const updateMember = createAsyncThunk(
	"members/update",
	async (members, { dispatch, getState, requestId, rejectWithValue }) => {
		console.log("updateMember to - ", members);
		try {
			await apigateway.post(APIPATH.updateMember, members);
			return {
				...getState().members.entities,
				...members,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const inviteMember = createAsyncThunk(
	"members/invite",
	async (member, { dispatch, getState, requestId, rejectWithValue }) => {
		let { key, value } = _getValidMemberKeyValuePair(member);
		try {
			await apigateway.get(`${APIPATH.memberInvite}${key}=${value}`);
			const updatedMember = _getUpdatedMember(member, key, INVITE_STATUS.INVITED);
			return {
				...getState().members.entities,
				...updatedMember,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

export const revokeMember = createAsyncThunk(
	"members/revoke",
	async (member, { dispatch, getState, requestId, rejectWithValue }) => {
		let { key, value } = _getValidMemberKeyValuePair(member);
		try {
			await apigateway.get(`${APIPATH.memberRevoke}${key}=${value}`);
			const updatedMember = _getUpdatedMember(member, key, INVITE_STATUS.REVOKED);
			return {
				...getState().members.entities,
				...updatedMember,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Adds member to state's members.organizationInvites {}, replacing exiting member if it present
 * @param {Object} member member object being added/updated
 * @param {String} key key of member object being added/updated
 * @param {String} status one of INVITE_STATUS
 */
function _getUpdatedMember(member, key, status) {
	let dateKey;

	switch (status) {
		case INVITE_STATUS.ERROR_INVITING:
		case INVITE_STATUS.ERROR_DEACTIVATING:
		case INVITE_STATUS.LOADING: {
			dateKey = "now";
			break;
		}
		case INVITE_STATUS.ACCEPTED: {
			dateKey = "dateAccepted";
			break;
		}
		case INVITE_STATUS.LEFT: {
			dateKey = "dateLeft";
			break;
		}
		case INVITE_STATUS.REVOKED: {
			dateKey = "dateRevoked";
			break;
		}
		case INVITE_STATUS.INVITED:
		default: {
			dateKey = "dateInvited";
			break;
		}
	}

	return {
		[member[key]]: {
			...member,
			[dateKey]: new Date().getTime(),
			status,
		},
	};
}

/**
 * used in the case that a member has not yet signed up to Dreamaaker, checks member and returns the
 * valid key-value pair to use in invites & revokes.
 * Options are "email" and [email] OR "userID" and [userID]
 * @param {Object} member
 * @returns {Object} key-value pair as {key: [key], value: [value]}
 */
function _getValidMemberKeyValuePair(member) {
	let key = member.userID ? "userID" : "email";
	let value = member.userID ? member.userID : member.email;
	return { key, value };
}
