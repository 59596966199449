/**
 * Handles app wide notifications
 */
import React from "react";
import { Notyf } from "notyf";

import { THEME_COLORS } from "constants/";

export default React.createContext(
	new Notyf({
		duration: 5000,
		dismissible: true,
		ripple: false,
		position: {
			x: "center",
			y: "top",
		},
		types: [
			{
				type: "default",
				backgroundColor: THEME_COLORS.primary,
				icon: false,
			},
			{
				type: "success",
				backgroundColor: THEME_COLORS.success,
				icon: false,
			},
			{
				type: "warning",
				backgroundColor: THEME_COLORS.warning,
				icon: false,
			},
			{
				type: "error",
				backgroundColor: THEME_COLORS.danger,
				duration: 100000,
				icon: false,
			},
		],
	})
);
