import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Dropdown, Navbar, Nav } from "react-bootstrap";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, light } from "@fortawesome/fontawesome-svg-core/import.macro";

import { SIDEBAR_BEHAVIOR } from "constants/";
import useAppSelector from "hooks/useAppSelector";
import useSidebar from "hooks/useSidebar";

import { useModalContext, MODAL_TYPES } from "contexts/ModalContext";

// import NavbarUser from "./NavbarUser";
import {
	CollaborateMenuBtn,
	DashboardsMenuBtn,
	ProjectDeleteMenuBtn,
	MarkersMenuBtn,
	ProjectOpenMenuBtn,
	ProjectRenameMenuBtn,
	ProjectSendMenuBtn,
	ExportAnalysisMenuBtn,
} from "components/dropdowns/";

import ProjectSettingsDropdown from "pages/projects/ProjectSettingsDropdown.js";

import { ROUTES } from "constants/";

const NavbarAnalysisComponent = () => {
	const navigate = useNavigate();
	const location = useLocation();
	let from = location.state?.from?.pathname || ROUTES.PROJECTS;

	const { behavior, setBehavior } = useSidebar();
	const { closeModal, openModal } = useModalContext();
	const settingsIcon = () => <FontAwesomeIcon icon={solid("gear")} />;

	const handleOpenDashboardModalOnClick = () => {
		return openModal(MODAL_TYPES.UPDATE_DASHBOARDS);
	};

	const handleOpenMarkerModalOnClick = () => {
		return openModal(MODAL_TYPES.UPDATE_MARKERS);
	};

	const handleOpenInviteModalOnClick = (inviteType) => {
		return openModal(MODAL_TYPES.INVITE, {
			confirmBtnAction: (formData) => {
				handleSendInvites();
			},
			confirmBtnClasses: "btn-success",
			confirmBtnTitle: "Send",
			title: "Send Media to Others",
			inviteType: inviteType,
			cancelBtnTitle: "Cancel",
			cancelBtnClasses: "btn-danger",
			cancelBtnAction: () => closeModal(false),
		});
	};

	const handleSendInvites = () => {
		console.log("Invite(s) sent!");
		closeModal(false);
	};

	return (
		<Navbar variant="light" expand className="navbar-bg-other p-1">
			<span
				className="nav-link nav-icon d-flex"
				onClick={() => {
					navigate(from);
				}}
			>
				<FontAwesomeIcon icon={solid("angle-left")} />
			</span>
			<span className="navbar-text">Media Title</span>
			<Navbar.Collapse>
				<Nav className="navbar-align">
					<ProjectSettingsDropdown />
					{/* <Dropdown className="nav-item" align="end">
						<span className="d-none d-sm-inline-block">
							<Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
								<FontAwesomeIcon icon={light("gear")} className="align-middle" />
							</Dropdown.Toggle>
						</span>
						<Dropdown.Menu drop="end">
							<DashboardsMenuBtn
								// isDisabled={isLocked}
								onClick={() => {
									handleOpenDashboardModalOnClick();
								}}
							/>
							<MarkersMenuBtn
								// isDisabled={isLocked}
								onClick={() => {
									handleOpenMarkerModalOnClick();
								}}
							/>
							<CollaborateMenuBtn
								onClick={() => {
									handleOpenInviteModalOnClick("collaborate");
								}}
							/>
							<ProjectSendMenuBtn
								onClick={() => {
									handleOpenInviteModalOnClick("send");
								}}
							/>
							<Dropdown.Divider />
							<ExportAnalysisMenuBtn />
						</Dropdown.Menu>
					</Dropdown> */}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavbarAnalysisComponent;
