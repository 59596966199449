/**
 * Thunks for account
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchesUser from API */
export const fetchAccount = createAsyncThunk(
	"account/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.get(APIPATH.accountGet);
			return res;
		} catch (err) {
			// console.log("fetchAccount FETCHING FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			// When do we need this?
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates a account project */
export const updateAccount = createAsyncThunk(
	"account/update",
	async (account, { dispatch, getState, requestId, rejectWithValue }) => {
		// console.log("updateAccount to ", account);
		try {
			await apigateway.post(APIPATH.accountUpdate, account);
			return {
				...getState().account.entity,
				...account,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/**
 * Updates autoMemberAtSignup only - pass in Boolean true or fals
 */
export const updateOrganization = createAsyncThunk(
	"account/updateOrganization",
	async (
		autoMemberAtSignup,
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			await apigateway.post(APIPATH.organizationUpdate, {
				autoMemberAtSignup: autoMemberAtSignup,
			});
			return {
				...getState().account.entity,
				autoMemberAtSignup: autoMemberAtSignup,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
