import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

import { SidebarToggleIcon, MagnifyingGlassIcon } from "components/icons/";

import useSidebar from "hooks/useSidebar";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarLanguages from "./NavbarLanguages";
import NavbarThemeToggle from "./NavbarThemeToggle";
import NavbarUpgradeButton from "./NavbarUpgradeButton";
import NavbarUser from "./NavbarUser";

import { SIDEBAR_BEHAVIOR } from "constants/";

import avatar1 from "assets/img/avatars/avatar.jpg";
import avatar3 from "assets/img/avatars/avatar-3.jpg";
import avatar4 from "assets/img/avatars/avatar-4.jpg";
import avatar5 from "assets/img/avatars/avatar-5.jpg";

const notifications = [
	{
		type: "important",
		title: "Update completed",
		description: "Restart server 12 to complete the update.",
		time: "2h ago",
	},
	{
		type: "default",
		title: "Lorem ipsum",
		description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
		time: "6h ago",
	},
	{
		type: "login",
		title: "Login from 192.186.1.1",
		description: "",
		time: "6h ago",
	},
	{
		type: "request",
		title: "New connection",
		description: "Anna accepted your request.",
		time: "12h ago",
	},
];

const messages = [
	{
		name: "Ashley Briggs",
		avatar: avatar5,
		description: "Nam pretium turpis et arcu. Duis arcu tortor.",
		time: "15m ago",
	},
	{
		name: "Chris Wood",
		avatar: avatar1,
		description: "Curabitur ligula sapien euismod vitae.",
		time: "2h ago",
	},
	{
		name: "Stacie Hall",
		avatar: avatar4,
		description: "Pellentesque auctor neque nec urna.",
		time: "4h ago",
	},
	{
		name: "Bertha Martin",
		avatar: avatar3,
		description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
		time: "5h ago",
	},
];

const NavbarComponent = () => {
	const { t } = useTranslation();
	const { behavior, setBehavior } = useSidebar();
	const { upgradeMe } = useSelector((state) => state.account.entity || true);

	// const bellSlashIcon = () => <FontAwesomeIcon icon={light("bell-slash")} />;
	const messageIcon = () => <FontAwesomeIcon icon={light("comment")} />;
	return (
		<Navbar variant="light" expand className="navbar-bg">
			{/* Toggle Sidebar Behavior */}
			<span
				className="sidebar-toggle d-flex"
				onClick={() => {
					setBehavior(
						SIDEBAR_BEHAVIOR.STICKY !== behavior
							? SIDEBAR_BEHAVIOR.STICKY
							: SIDEBAR_BEHAVIOR.COMPACT
					);
				}}
			>
				<SidebarToggleIcon />
			</span>

			{/* Search */}
			<Form inline="true" className="d-none d-sm-inline-block">
				<InputGroup className="input-group-navbar">
					<Form.Control placeholder={t("Search")} aria-label="Search" />
					<Button variant="">
						<MagnifyingGlassIcon />
					</Button>
				</InputGroup>
			</Form>

			<Navbar.Collapse>
				<Nav className="navbar-align">
					{/* Messages Dropdown */}
					{/* <NavbarDropdown
						header="New Messages"
						footer="Show all messages"
						icon={messageIcon}
						count={messages.length}
						showBadge
					>
						{messages.map((item, key) => {
							return (
								<NavbarDropdownItem
									key={key}
									icon={
										<img
											className="avatar img-fluid rounded-circle"
											src={item.avatar}
											alt={item.name}
										/>
									}
									title={item.name}
									description={item.description}
									time={item.time}
									spacing
								/>
							);
						})}
					</NavbarDropdown> */}
					{/* Notifications dropdown */}
					{/* <NavbarDropdown
						header="New Notifys"
						footer="Show all notifications"
						icon={bellSlashIcon}
						count={notifications.length}
					>
						{notifications.map((item, key) => {
							let icon = (
								<FontAwesomeIcon icon={light("bell")} className="text-warning" />
							);

							if (item.type === "important") {
								icon = (
									<FontAwesomeIcon
										icon={light("circle-exclamation")}
										className="text-danger"
									/>
								);
							}

							if (item.type === "login") {
								icon = (
									<FontAwesomeIcon icon={light("house")} className="text-primary" />
								);
							}

							if (item.type === "request") {
								icon = (
									<FontAwesomeIcon icon={light("user-plus")} className="text-success" />
								);
							}

							return (
								<NavbarDropdownItem
									key={key}
									icon={icon}
									title={item.title}
									description={item.description}
									time={item.time}
								/>
							);
						})}
					</NavbarDropdown> */}
					{/* Language Dropdown */}
					{/* <NavbarLanguages /> */}
					{upgradeMe ? <NavbarUpgradeButton /> : null}
					<NavbarThemeToggle />
					<NavbarUser />
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavbarComponent;
