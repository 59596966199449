/**
 * Thunks for members
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
import _ from "lodash";
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { fetchAccount } from "features/account/account.actions";
import {
	createProject,
	fetchProject,
	deleteItem,
} from "features/projects/projects.actions";
import { rehydrateProject } from "features/projects/projects.slice";

import {
	APIPATH,
	PLANID,
	PROGRESS_STATUS,
	REGEXP,
	UNLIMITED,
} from "constants/";
import { BUCKET, LOCAL_STORAGE_PREFIX } from "config/";

import { getFileData, Uploader } from "utils/";
// import Uploader from "utils/uploader.js";
// export var upload = null;

export const initializeUpload = createAsyncThunk(
	"uploader/initialize",
	async (
		{ id, name, progressStatus },
		{ getState, requestId, rejectWithValue }
	) => {
		try {
			// Add to or create array of uploads in progress
			const { entities, entityList } = getState().uploader;
			let uploadsList = [...entityList];
			if (!uploadsList.includes(id)) uploadsList.push(id);

			return {
				entityList: [...uploadsList],
				entities: {
					...entities,
					[id]: {
						name,
						percentage: 0,
						stats: {},
						progressStatus,
					},
				},
			};
		} catch (err) {
			console.log(err);
			let error = err; // cast the error for access
			if (!error.response) {
				throw err;
			}
			// We got validation errors, let's return those so we can reference in our component and set form errors
			return rejectWithValue(error.response.data);
		}
	}
);

/** resumes a previously started, then aborted, upload */
export const resumeUpload = createAsyncThunk(
	"uploader/resume",
	async (
		{ oldID, resumedID },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			const { entities, entityList } = getState().uploader;
			let uploads = { ...entities };
			let uploadList = [...entityList];

			// debugger;

			// var newUploadInfo = state[oldID]
			// Get upload info
			let newUploadInfo = { ...uploads[oldID] };

			// delete oldID from uploads and uploadsList
			// delete uploads[oldID];
			// _.remove(uploadList, (remove) => {
			// 	return remove === oldID;
			// });

			// add resume ID
			uploads = {
				...uploads,
				[resumedID]: {
					...newUploadInfo,
					id: resumedID,
				},
			};
			uploadList.push(resumedID);

			return {
				entities: { ...uploads },
				entityList: { ...uploadList },
			};
		} catch (err) {
			console.log("resumeUpload FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** fetch organization members from API */
export const uploadMediaItem = createAsyncThunk(
	"uploader/upload",
	async (
		{ folderID, file, attach },
		{ dispatch, getState, requestId, rejectWithValue }
	) => {
		try {
			// Get needed variables
			const { userID, usageData, planData, dateCreated } =
				getState().account.entity;

			//Get media file data
			let { fileNameBase, fileNameExtension, fileType, sizeMB } =
				getFileData(file);

			const fileName = `${fileNameBase}.${fileNameExtension}`;

			// ERROR CHECKS - set hasError to error string on error
			let hasError = false;

			// Check for valid file extensions
			if ("" === fileNameExtension || "" === fileNameBase)
				hasError = `Files must include a name and extension. Eg MyVideo.mp4`;
			// Check MEDIA_BLACKLIST first - files that are interpretted as video/audio but not accepted
			else if (REGEXP.MEDIA_BLACKLIST.test(fileNameExtension))
				hasError = `Files type of '${fileNameExtension}' not accepted.`;
			else if ("VIDEO" !== fileType && "AUDIO" !== fileType) {
				// Check MEDIA_WHITELIST - valid accepted video files which dont default to video type
				if (REGEXP.MEDIA_WHITELIST.test(fileNameExtension)) fileType = "VIDEO";
				else hasError = "Only audio or video files accepted.";
			}

			// Check if free plan - limit to 1 analysis projects if signup after 12AM GMT 24 October  (1508803200000)
			if (
				hasError === false &&
				UNLIMITED.value !== planData.maxStorage &&
				PLANID.FREE[planData.planID] &&
				dateCreated > 1508803200000 &&
				1 <= usageData.videoCount + usageData.audioCount
			)
				hasError =
					"Unfortuantely you'll need to upgrade to upload further analysis projects.";

			// Check usageData.storageLimit (We're giving them an allowance of 2 MBs)
			if (
				hasError === false &&
				usageData.storageCount + sizeMB > planData.maxStorage + 2
			)
				hasError =
					"Unfortunately you don't have enough storage space for this file.";

			// Throw error if present
			if (hasError) {
				return Promise.reject(hasError);
			}

			// INITIALIZE VARIABLES
			const partSize = 1024 * 1024 * 5; // This is the default, cannot be smaller
			const itemID = uuidV4();
			const s3FileName = new Date().getTime().toString();
			const path = `${userID}/${itemID}`;
			let thumbnailURL = `urlforaudiothumbs`;
			if ("VIDEO" === fileType) {
				sizeMB >= 40 // Thumbnails retrieved at 60 sec intervals in live - 13 second video was 2.8MB;
					? (thumbnailURL = `${s3FileName}-thumbnail-00002.jpg`)
					: (thumbnailURL = `${s3FileName}-thumbnail-00001.jpg`);
			}

			// Create item now - to ensure item is created by the time Lambda picks it up
			let newItem = {
				itemID,
				folderID,
				mediaType: fileType,
				title: fileNameBase,
				thumbnailURL,
				mediaURL: `${s3FileName}.m3u8`,
				transcodeProgress: PROGRESS_STATUS.UPLOADSTARTED,
			};

			const mediaCount = fileType.toLowerCase() + "Count";
			const updatedUsageData = {
				usageData: {
					storageCount: usageData.storageCount + sizeMB,
					[mediaCount]: usageData[mediaCount] + 1,
				},
			};

			dispatch(
				initializeUpload({
					id: itemID,
					name: fileName,
					progressStatus: PROGRESS_STATUS.CALCULATING,
				})
			);

			let upload = await Uploader.initialize({
				dispatch,
				bucket: BUCKET.TRANSIN,
				// maxConcurrentParts: getState().app.queueSize, // TODO:
				partSize: partSize,
				sizeMB,
				storeKey: `${LOCAL_STORAGE_PREFIX}.${userID}.uploads`,
			});

			// console.log("Uploader Initialized, upload is ", upload);

			upload({
				bucket: BUCKET.TRANSIN,
				// bucket: 'bad_bucket_test',
				file,
				fileID: itemID,
				name: `${path}/${s3FileName}.${fileNameExtension}`,
				userID,

				// Creates new item
				createCallback: function (overrides = {}) {
					const { newItem, updatedUsageData } = this;
					return function (dispatch) {
						dispatch(createProject({ ...newItem }))
							// Creation successful, update usage stats
							.then((resp) => {
								dispatch({
									type: "account/rehydrateUsage",
									payload: { updatedUsageData },
								});
							});
					};
				}.bind({ newItem, updatedUsageData }),

				/// deleted item - currently not in use - impacts resumability by deleting bd record
				// deleteCallback: function (overrides = {}) {
				// 	const { itemID } = this;
				// 	const { resumedID } = overrides
				// 	return function (dispatch) {
				// 		console.log('CALLED deleteCallback ')
				// 		dispatch(deleteItem(resumedID ? resumedID : itemID))
				// 	}
				// }.bind({ itemID }),

				/**
				 * checks progress of copying & transcoding after upload
				 * NOTE: Although fileID is updated, need to bind this for future proofing against other uploader calls needing it
				 */
				preparationProgressCallback: function (overrides = {}) {
					// console.log("preparationProgressCallback called ");
					const { itemID } = this;
					const { resumedID } = overrides;
					return function (dispatch) {
						dispatch(pollProgress(resumedID ? resumedID : itemID));
					};
				}.bind({ itemID }),

				// resumes an alreadu initiated upload
				resumeCallback: function (overrides = {}) {
					const { itemID } = this;
					const { resumedID } = overrides;
					return async function (dispatch) {
						// dispatch({
						// 	type: UPLOAD_NAME_CHANGE,
						// 	payload: { oldID: itemID, resumedID },
						// });

						await dispatch(resumeUpload({ oldID: itemID, resumedID }));
						dispatch({
							type: "account/rehydrateUsage",
							payload: { updatedUsageData },
						});
						await dispatch(fetchProject(resumedID));
					};
				}.bind({ itemID }),
			}).then(
				function (awsObjectKey) {
					// console.log('Upload success: ', awsObjectKey);
					// dispatch(setShow('uploadsInProgress', true))
				},
				function (err) {
					console.log("upload.js error: ", err);
					updateProgress({
						id: itemID,
						progressStatus: PROGRESS_STATUS.ERROR,
					});
				}
			);
		} catch (err) {
			console.log("upload FAILED - ", err);
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** Updates progress of an upload */
export const updateProgress = createAsyncThunk(
	"uploader/updateProgress",
	async (progress, { getState, requestId, rejectWithValue }) => {
		const uploads = getState().uploader.entities;
		const { id } = progress;
		// create new uploads.entities object
		return {
			...uploads,
			[id]: {
				...uploads[id],
				...progress,
			},
		};
	}
);

// transcodeProgress = STARTED | PROGRESSING | COMPLETED | ERROR | WARNING
export const pollProgress = createAsyncThunk(
	"uploader/pollProgress",
	async (itemID, { dispatch, getState, requestId, rejectWithValue }) => {
		// Run this evey 10 seconds to check on transcodeProgress
		var checkProgress = setInterval(function () {
			apigateway
				.get(`${APIPATH.projectGet}${itemID}`)
				.then((resp) => {
					const progressStatus = resp.transcodeProgress;
					dispatch(
						updateProgress({
							id: itemID,
							progressStatus: progressStatus,
						})
					);
					switch (progressStatus) {
						case PROGRESS_STATUS.COMPLETED:
							clearInterval(checkProgress);

							dispatch(rehydrateProject({ ...resp }));
							dispatch(fetchAccount());
							break;

						case PROGRESS_STATUS.PROGRESSING:
							dispatch(rehydrateProject({ ...resp }));
							break;

						case PROGRESS_STATUS.WARNING:
						case PROGRESS_STATUS.ERROR:
							// Error shown in uploadsInProgress
							clearInterval(checkProgress);
							// Server side error - delete dynamoDB entries
							// Handles Reydration
							dispatch(deleteItem({ itemID }));
							break;
						default:
							break;
					}
					return;
				})
				.catch((error) => {
					clearInterval(checkProgress);
					// dispatch(_dmAppErrorHandler(UPLOAD_ERROR, error));
					throw error;
				});
		}, 5000); // 5sec
	}
);
