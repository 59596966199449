import { Col, Row } from "react-bootstrap";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";

export const PlaceholderTable = ({ header = false, rows, cols }) => {
	// console.log("header ", header);
	// console.log("rows ", rows);
	// console.log("cols ", cols);

	const col = (
		<Col md={12}>
			<PlaceholderPrimary className="w-100 placeholder-lg" />
		</Col>
	);

	const renderRows = () => {
		var tableRows = [];
		for (let x = 0; x < rows; x++) {
			tableRows[x] = (
				<Row key={x} className="m-2">
					{col}
				</Row>
			);
		}

		return tableRows;
	};

	const renderHeader = () => {
		if (!header) return;

		var theads = [];
		for (let x = 0; x < cols; x++) {
			theads[x] = <PlaceholderPrimary key={x} className="m-1" />;
		}

		return (
			<Row className="m-1">
				<Col md="12" style={{ display: "flex", justifyContent: "space-between" }}>
					{theads}
				</Col>
			</Row>
		);
	};

	return (
		<PlaceholderGlow>
			{renderHeader()}
			{renderRows()}
		</PlaceholderGlow>
	);
};
