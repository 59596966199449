import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import useAuth from "hooks/useAuth";

import { Security as SecurityForm } from "./security/SecurityForm";

function Security() {
	const { signOut } = useAuth();
	return (
		<React.Fragment>
			<Row className="mb-4">
				<Col md={12} className="">
					<h6>Global Signout</h6>
					<span className="text-danger ms-3 me-3">
						If you are concerned the security of your account has been compromised,
						contact us and{" "}
						<a
							className="text-danger"
							onClick={(e) => {
								e.preventDefault();
								signOut(true);
							}}
						>
							signout of all devices
						</a>
						, including this device, immediately.
					</span>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h6>Update your Password</h6>
					<SecurityForm />
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default Security;
