import _ from "lodash";

export const capitalize = (str) => {
	return _.startCase(str?.toLowerCase());
};

export const ucWords = (str) => {
	return capitalize(str);
};

export function copyElementToClipboard(element, and) {
	var inp = document.createElement("input");
	document.body.appendChild(inp);
	inp.value = document.getElementById(element).textContent;
	inp.select();
	document.execCommand("copy", false);
	inp.remove();
}
