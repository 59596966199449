import AWS from "aws-sdk/global";
const { createHmac } = AWS.util.crypto.lib;
// import jwtDecode from "jwt-decode";
// import { verify, sign } from "jsonwebtoken";
// import axios from "./axios";

// const isValidToken = (accessToken) => {
// 	if (!accessToken) {
// 		return false;
// 	}
// 	const decoded = jwtDecode(accessToken);
// 	const currentTime = Date.now() / 1000;

// 	return decoded.exp > currentTime;
// };

// //  const handleTokenExpired = (exp) => {
// //   let expiredTimer;

// //   window.clearTimeout(expiredTimer);
// //   const currentTime = Date.now();
// //   const timeLeft = exp * 1000 - currentTime;
// //   console.log(timeLeft);
// //   expiredTimer = window.setTimeout(() => {
// //     console.log('expired');
// //   }, timeLeft);
// // };

// const setSession = (accessToken) => {
// 	if (accessToken) {
// 		localStorage.setItem("accessToken", accessToken);
// 		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
// 		// This function below will handle when token is expired
// 		// const { exp } = jwtDecode(accessToken);
// 		// handleTokenExpired(exp);
// 	} else {
// 		localStorage.removeItem("accessToken");
// 		delete axios.defaults.headers.common.Authorization;
// 	}
// };

// export { verify, sign, isValidToken, setSession };

const hmacSha256 = (signingKey, stringToSign, encoding) => {
	return createHmac("sha256", signingKey).update(stringToSign).digest(encoding);
};

/**
 * Custom authorization method specifically for Evaporate.js
 * See https://docs.aws.amazon.com/general/latest/gr/signature-version-4.html
 * See https://docs.amazonaws.cn/en_us/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html
 * @param {Object} signParams Object containing secretKey for signing
 * @param {Object} signHeaders unused
 * @param {String} stringToSign encoded string to sign strong to sign
 * @param {String} signatureDateTime Amz formatted date time sting
 * @param {String} canonicalRequest unused
 */
export const awsCustomAuth = (
	signParams,
	signHeaders,
	stringToSign,
	signatureDateTime,
	canonicalRequest
) => {
	const stringToSignDecoded = decodeURIComponent(stringToSign);
	const requestScope = stringToSignDecoded.split("\n")[2];
	const [date, region, service, signatureType] = requestScope.split("/");

	// dm_print( '=== Dmio.awsCustomAuth() start === ')
	// dm_print( ' - signParams :', signParams )
	// dm_print( ' - signHeaders :', signHeaders)
	// dm_print( ' - signatureDateTime :', signatureDateTime)
	// dm_print( ' - canonicalRequest :', canonicalRequest)
	// dm_print( ' - stringToSign :', stringToSign)
	// dm_print( ' - stringToSignDecoded :', stringToSignDecoded)
	// dm_print( ' - signParams.secretKey :', signParams.secretKey )
	// dm_print( ' - requestScope :', requestScope )
	// dm_print( ' - date :', date)
	// dm_print( ' - region :', region)
	// dm_print( ' - service :', service)
	// dm_print( ' - signatureType :', signatureType)
	// dm_print( '=== Dmio.awsCustomAuth() end === ')

	return new Promise(function (resolve, reject) {
		const DATE = hmacSha256(`AWS4${signParams.secretKey}`, date);
		const REGION = hmacSha256(DATE, region);
		const SERVICE = hmacSha256(REGION, service);
		const SIGNING = hmacSha256(SERVICE, signatureType);
		const FINAL = hmacSha256(SIGNING, stringToSignDecoded, "hex");
		resolve(FINAL);
	});
};

/**
 * JWTtoken decode
 * @param {Object} Valid JWTtoken
 */
export const parseJwt = (token) => {
	const base64Url = token.split(".")[1];
	var base64 = base64Url.replace("-", "+").replace("_", "/");
	return JSON.parse(window.atob(base64));
};
