import React from "react";
import { Modal } from "react-bootstrap";

import { useModalContext } from "contexts/ModalContext";
import useTheme from "hooks/useTheme";

import StripeCheckoutForm from "components/stripe/StripeCheckoutForm";
import StripePlanChangePreview from "components/stripe/StripePlanChangePreview";

import { ucWords } from "utils/";
import { THEME } from "constants/";

/**
 * Displays a confimration model
 * @param {*} props All props required (other than confirmBtnTitle,  cancelBtnAction)
 * @returns
 */
export const StripeModal = ({ stripeFlow, data }) => {
	const { closeModal, showModal } = useModalContext();

	// console.log("StripeModal - ");
	// console.log("stripeFlow: ", stripeFlow);
	// console.log("data", data);

	const {
		changePreview,
		newPaymentPeriod,
		clientSecret,
		coupon,
		newPlan,
		prorationDate,
		stripePaymentMethodID,
	} = data;

	// console.log("changePreview: ", changePreview);
	// console.log("newPlan: ", newPlan);
	// console.log("newPaymentPeriod: ", newPaymentPeriod);
	// console.log("prorationDate: ", prorationDate);
	// console.log("stripePaymentMethodID: ", stripePaymentMethodID);
	// console.log("coupon: ", coupon);
	// console.log("");
	// console.log("");

	var bodyFooter, title;

	switch (stripeFlow) {
		case "CHANGE_PLAN_CC_REQUIRED":
			title = "Dreamaker.io Plan Upgrade";
			bodyFooter = (
				<React.Fragment>
					<Modal.Body>
						The{" "}
						<strong>
							{ucWords(newPaymentPeriod)} {ucWords(newPlan.title)}
						</strong>{" "}
						Plan, great choice! Your card will be charged{" "}
						<strong>
							$
							{"YEARLY" === newPaymentPeriod
								? `${newPlan.costYearly} yearly`
								: `${newPlan.costMonthly} monthly`}
						</strong>
						.
					</Modal.Body>
					<StripeCheckoutForm
						clientSecret={clientSecret}
						newPaymentPeriod={newPaymentPeriod}
						newPlan={newPlan}
						prorationDate={prorationDate}
					/>
				</React.Fragment>
			);
			break;

		case "CHANGE_PLAN_PREVIEW_CHANGES":
			title = "You're Changing Your Plan!";
			bodyFooter = (
				<StripePlanChangePreview
					changePreview={changePreview}
					clientSecret={clientSecret}
					coupon={coupon}
					newPaymentPeriod={newPaymentPeriod}
					newPlan={newPlan}
					prorationDate={prorationDate}
					stripePaymentMethodID={stripePaymentMethodID}
				/>
			);
			break;

		default:
		case "CC_UPDATE":
			title = "Update Card";
			bodyFooter = <StripeCheckoutForm clientSecret={clientSecret} />;
			break;
	}
	return (
		<Modal show={showModal} onHide={() => closeModal()}>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			{bodyFooter}
		</Modal>
	);
};
