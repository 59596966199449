import React, { useState, useEffect } from "react";

import { Dropdown, Form } from "react-bootstrap";
import { ColorIcon } from "components/icons";
import { THEME_COLOR_NAMES, THEME_COLORS } from "constants/";

export const ColorDropdown = ({ onChange, tagColor = "primary" }) => {
	const [selectedColor, setSelectedColor] = useState("");
	// console.log(
	// 	`selectedColor: ${selectedColor} is ${THEME_COLORS[selectedColor]}`
	// );

	useEffect(() => {
		setSelectedColor(tagColor);
	}, [tagColor]);

	const onColorSelect = (color) => {
		setSelectedColor(color);
		onChange(color);
	};

	let colorDropdown = [];
	let colors = Object.keys(THEME_COLOR_NAMES);
	colorDropdown = colors.map((color, x) => {
		// console.log(`${color} is ${THEME_COLOR_NAMES[color]}`);
		return (
			<Dropdown.Item
				key={color}
				onClick={() => {
					onColorSelect(color);
				}}
			>
				<ColorIcon color={THEME_COLORS[color]} />
				{THEME_COLOR_NAMES[color]}
			</Dropdown.Item>
		);
	});

	return (
		<React.Fragment>
			<Form.Label className="mx-2">Color:</Form.Label>
			<Dropdown className="d-inline">
				<Dropdown.Toggle
					variant="muted"
					id="dropdown-basic"
					name="color"
					value={selectedColor}
				>
					<ColorIcon color={THEME_COLORS[selectedColor]} />
				</Dropdown.Toggle>
				<Dropdown.Menu>{colorDropdown}</Dropdown.Menu>
			</Dropdown>
		</React.Fragment>
	);
};
