import React from "react";
import { Card, Placeholder, Table } from "react-bootstrap";
import { PlaceholderGlow, PlaceholderPrimary } from "components/placeholders/";

const tableData = (
	<td>
		<PlaceholderPrimary />
	</td>
);
const tableRow = (
	<tr>
		{tableData}
		{tableData}
		{tableData}
	</tr>
);

export const PreferencesLoading = ({ children }) => (
	<PlaceholderGlow>
		<Card>
			{children}
			<Card.Body>
				<Table responsive>
					<tbody>
						{tableRow}
						{tableRow}
					</tbody>
				</Table>
				<Placeholder.Button variant="primary" xs={2} size="sm" className="mt-3" />
			</Card.Body>
		</Card>
	</PlaceholderGlow>
);
