import React from "react";

import { Nav, Tab } from "react-bootstrap";

import EventTag from "./EventTag";
import AnalysisFilters from "./AnalysisFilters";

const AnalysisPane = (props) => {
	const {
		currentProjectDashboards,
		currentProjectDashboardTags,
		currentProjectMarkers,
		currentProjectEventTags,
		tabContentStyle,
		tabContentClasses
	} = props;

	const allEventTags = currentProjectEventTags.map((eventTag) => {
		return (
			<EventTag
				key={eventTag.eventTagID}
				eventTag={eventTag}
				currentProjectDashboardTags={currentProjectDashboardTags}
				currentProjectMarkers={currentProjectMarkers}
			/>
		);
	});

	const playlistEventTags = currentProjectEventTags
		.filter((eventTag) => eventTag.playlist === true)
		.map((eventTag) => {
			return (
				<EventTag
					key={eventTag.eventTagID}
					eventTag={eventTag}
					currentProjectDashboardTags={currentProjectDashboardTags}
					currentProjectMarkers={currentProjectMarkers}
				/>
			);
		});

	return (
		<div className="h-100">
			<Tab.Container id="analysis-tabs" defaultActiveKey="analysis">
				<Nav variant="tabs">
					<Nav.Item>
						<Nav.Link eventKey="analysis">Analysis</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="shortlist">Shortlist</Nav.Link>
					</Nav.Item>
				</Nav>
				<AnalysisFilters
					currentProjectDashboards={currentProjectDashboards}
					currentProjectMarkers={currentProjectMarkers}
				/>

				<div className="w-100">
					<Tab.Content
						className={tabContentClasses || ''}
						style={tabContentStyle || {}}
					>
						<Tab.Pane eventKey="analysis">
							<div id="eventTagList">{allEventTags}</div>
						</Tab.Pane>
						<Tab.Pane eventKey="shortlist">
							<div id="eventTagPlaylistList">{playlistEventTags}</div>
						</Tab.Pane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</div>
	);
};

export default AnalysisPane;
