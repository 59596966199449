export const ROUTES = {
	// ROOT
	// INDEX: '/',
	// SIGNOUT: '/',
	SIGNIN: "/",
	SIGNUP: "/signup",
	// APP: '/',

	FORGOT: "/forgotpass",
	PAYMENT: "/payment",
	// PRICING: '/pricing',

	UPGRADE: "/upgrade",
	VERIFY: "/verify",

	// ACCOUNT PAGES
	ACCOUNT: "/account",
	BILLING: "#billing",
	INTEGRATIONS: "#integrations",
	MEMBERS: "#members",
	PROFILE: "#profile",
	PREFERENCES: "#preferences",
	REPORTS: "#reports",
	SECURITY: "#security",

	// ANALYSIS
	ANALYSIS: "/analysis",
	ANALYSIS_RT: "/analysis/:itemAnalysisID",

	// DASHBOARDS + MARKERS
	DASHBOARDS: "/dashboards",
	MARKERS: "/markers",

	// ZOOM LIST
	ZOOM_RT: "/zprojects/:oauthUserID",
	ZOOM: "/zprojects",

	// PROJECT
	PROJECTS_RT: "/projects/:folderID",
	PROJECTS: "/projects",
	HOME: "/projects",

	// ERRORS
	404: "404",
};
