/**
 * Contains all actions for this feature
 */
import { v4 as uuidV4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apigateway from "utils/apigateway";

import { APIPATH } from "constants/";

/** fetchesUserMarkers from API */
export const fetchUserMarkers = createAsyncThunk(
	"markers/fetch",
	async (unused, { getState, requestId, rejectWithValue }) => {
		try {
			const res = await apigateway.post(APIPATH.markersGet);
			return res;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** creates a userMarker, saving to cloud if indicated */
export const createUserMarker = createAsyncThunk(
	"markers/create",
	async (marker, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			const markerID = uuidV4();
			let res = await apigateway.post(APIPATH.markerCreate, {
				markerID,
				...marker,
			});
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			delete res.usageData;
			return { ...res };
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** deletes a user marker */
export const deleteUserMarker = createAsyncThunk(
	"markers/delete",
	async ({ markerID }, { dispatch, getState, requestId, rejectWithValue }) => {
		// debugger;
		try {
			const res = await apigateway.delete(`${APIPATH.markerDelete}${markerID}`);
			await dispatch({
				type: "account/rehydrateUsage",
				payload: { ...res.usageData },
			});
			return markerID;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

/** updates a user marker */
export const updateUserMarker = createAsyncThunk(
	"markers/update",
	// marker an object with markerID and any fields requiring updates
	async (marker, { dispatch, getState, requestId, rejectWithValue }) => {
		try {
			await apigateway.post(APIPATH.markerUpdate, {
				...marker,
			});
			return {
				...getState().markers.entities[marker.markerID],
				...marker,
			};
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
