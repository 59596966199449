import React, { useContext } from "react";
import * as Yup from "yup";
import { Formik, yupToFormErrors } from "formik";
import { Card, Col, Form, Placeholder, Row, Table } from "react-bootstrap";
import { PreferencesLoading } from "./PreferencesLoading";

import { SubmitButton } from "components/buttons/";
import { PreferenceInput } from "./PreferenceInput";

import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

import NotifyContext from "contexts/NotifyContext";
import { updateUser } from "features/user/user.actions";

const cardHeader = (
	<Card.Header>
		<Card.Title className="mb-0">Analysis Preferences</Card.Title>
	</Card.Header>
);

function AnalysisPrefs() {
	const dispatch = useAppDispatch();
	const notify = useContext(NotifyContext);

	const {
		entity: { preferences },
	} = useAppSelector((state) => state.user);

	if (undefined === preferences)
		return <PreferencesLoading>{cardHeader}</PreferencesLoading>;

	const { leadTime, lagTime, pauseOnType, pauseOnTag } = preferences;

	let initialValues = {
		leadTime,
		lagTime,
		pauseOnType,
		pauseOnTag,
		submit: false,
	};

	let numValid = Yup.number().min(0).max(1000).optional();
	let boolValid = Yup.boolean().optional();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				leadTime: numValid,
				lagTime: numValid,
				pauseOnType: boolValid,
				pauseOnTag: boolValid,
			})}
			onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
				const res = await dispatch(
					updateUser({ preferences: { ...preferences, ...values } })
				);
				if (!res.error) notify.success("Successfully updated!");
				else {
					const message = res.error.message || "Something went wrong";
					notify.error(message);
					setStatus({ success: false });
					setErrors({ submit: message });
					setSubmitting(false);
				}
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Card>
						{cardHeader}
						<Card.Body>
							<Table responsive>
								<tbody>
									{PreferenceInput(
										"Pause on tag",
										"pauseOnTag",
										values.pauseOnTag,
										"Pause media playback when tagging media",
										handleBlur,
										handleChange
									)}
									{PreferenceInput(
										"Pause on type",
										"pauseOnType",
										values.pauseOnType,
										"Pause media playback when typing in the dashboard comments area",
										handleBlur,
										handleChange
									)}
									{PreferenceInput(
										"Lead play time",
										"leadTime",
										values.leadTime,
										"When viewing a tagged event, lead play time is the number of seconds to start play before timestamp.",
										handleBlur,
										handleChange
									)}
								</tbody>
							</Table>
							<SubmitButton title="Save Changes" disabled={isSubmitting} />
						</Card.Body>
					</Card>
				</Form>
			)}
		</Formik>
	);
}

export default AnalysisPrefs;
