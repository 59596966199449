/**
 * Redux Toolkit QuickStart: https://redux-toolkit.js.org/tutorials/quick-start
 *
 * Slice for projects / folders
 * See: https://redux-toolkit.js.org/api/createAsyncThunk
 */
/**
 * Redux Learning NOTES
 * =====================
 * A slice is equivalent to a reducer.
 *
 * Creating a slice requires
 * 	a string name to identify the slice,
 *  an initial state value, and
 *  one or more reducer functions to define how the state can be updated.
 *
 * Once a slice is created, we can export the generated Redux
 *   action creators and
 *   the reducer functions .
 *
 * createSlice and createReducer APIs use Immer inside to allow us to write "mutating" update
 * logic that becomes correct immutable updates.
 */
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import {
	copyProject,
	createFolder,
	createProject,
	deleteItem,
	fetchFolderChildren,
	fetchFolder,
	fetchProject,
	sendProject,
	updateFolder,
	updateProject,
} from "./projects.actions";

/**
 * Initial state value of the slice
 */
const initialState = {
	entities: null, // after load, entities is an object
	currentEntityID: undefined, //currently selected entity, whether folder or project
	// currentFolderID: undefined, //new - indicates the current folder we're in, if any.
	status: "idle", // idle | pending | loaded -- if state is "loaded", don't call initFetch
	createStatus: undefined,
};

const projectsSlice = createSlice({
	name: "projects",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions - ie, functions
	// The actions define how the state of this store can be updated
	// The actions are accessible via slice.actions
	reducers: {
		setCurrentEntityID: (state, action) => {
			state.currentEntityID = action.payload; // payload is itemID
		},
		rehydrateProject: (state, action) => {
			state.entities[action.payload.itemID] = action.payload; // payload is project
		},
		removeProject: (state, action) => {
			delete state.entities[action.payload];
		},
		removeProjectChild: (state, action) => {
			const { folderID, childItemID } = action.payload;
			// Immer prevents delete on embedded child so extrapolate, delete child, re-assign
			let tmpFolderItemsResult = [...state.entities[folderID].folderItemsResult];
			delete tmpFolderItemsResult[childItemID];
			state.entities[folderID].folderItemsResult = tmpFolderItemsResult;
		},
	},

	// The `extraReducers` field lets the slice handle actions defined elsewhere - eg, thunks,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: {
		reset: () => initialState,

		// places zoom imports in projects folder
		"zoom/fetchImportedRecordings/fulfilled": (state, action) => {
			state.entities = {
				...state.entities,
				zoom: {
					...action.payload,
				},
			};
		},

		/** fetchFolder */
		[fetchFolder.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchFolder.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = action.payload; // payload contains updated entities object
		},
		[fetchFolder.rejected]: (state, action) => {
			state.status = "idle";
			state.error = action.error;
		},

		/** fetchFolderChildren */
		[fetchFolderChildren.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchFolderChildren.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = action.payload; // payload contains object of user projects
		},
		[fetchFolderChildren.rejected]: (state, action) => {
			state.status = "idle";
			state.error = action.error;
		},

		/** fetchProject */
		[fetchProject.pending]: (state, action) => {
			state.status = "pending";
		},
		[fetchProject.fulfilled]: (state, action) => {
			state.status = "idle";
			state.entities = action.payload; // payload contains updated entities object
		},
		[fetchProject.rejected]: (state, action) => {
			state.status = "idle";
			state.error = action.error;
		},

		/** copyProject */
		[copyProject.pending]: (state, action) => {
			state.status = "pending";
			state.createStatus = "copying";
		},
		[copyProject.fulfilled]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			state.entities = action.payload.entities; // payload is (entities: {updatated entities}, newItemID: itemID)
			state.currentEntityID = action.payload.newItemID;
		},
		[copyProject.rejected]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			if (action.payload) {
				state.error = action.payload.message;
			} else {
				state.error = action.error;
			}
		},

		/** createFolder */
		[createFolder.pending]: (state, action) => {
			state.status = "pending";
			state.createStatus = "creating";
		},
		[createFolder.fulfilled]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			state.entities = action.payload.entities; // payload is (entities: {updatated entities}, newItemID: itemID)
			state.currentEntityID = action.payload.newItemID;
		},
		[createFolder.rejected]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			if (action.payload) {
				state.error = action.payload.message;
			} else {
				state.error = action.error;
			}
		},

		/** createProject */
		[createProject.pending]: (state, action) => {
			state.status = "pendiing";
			state.createStatus = "creating";
		},
		[createProject.fulfilled]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			state.entities = action.payload.entities; // payload is (entities: {updatated entities}, newItemID: itemID)
			state.currentEntityID = action.payload.newItemID;
		},
		[createProject.rejected]: (state, action) => {
			state.status = "idle";
			state.createStatus = undefined;
			if (action.payload) {
				state.error = action.payload.message;
			} else {
				state.error = action.error;
			}
		},

		/** deleteItem */
		[deleteItem.pending]: (state, action) => {
			state.status = "pending";
		},
		[deleteItem.fulfilled]: (state, action) => {
			// payload is itemID
			const itemID = action.payload;
			state.status = "idle";
			if (state.currentEntityID === itemID) state.currentEntityID = undefined;

			// Delete item from parent folder
			const { folderID } = state.entities[itemID];
			// const indexOf = _.indexOf(state.entities.folderItemsResult, itemID);
			// delete state.entities[folderID].folderItemsResult[indexOf]; // delete item from parent folder
			_.pull(state.entities[folderID].folderItemsResult, itemID);
			// Delete Item
			delete state.entities[itemID]; //delete item
		},
		[deleteItem.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** sendProject */
		[sendProject.pending]: (state, action) => {
			state.status = "pending";
		},
		[sendProject.fulfilled]: (state, action) => {
			state.status = "idle";
			// Do nothing; paylaod empty
		},
		[sendProject.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				state.error = action.payload.message;
			} else {
				state.error = action.error;
			}
		},

		/** updateFolder */
		[updateFolder.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateFolder.fulfilled]: (state, action) => {
			state.status = "idle";
			//payload is updated item with itemID embedded
			state.entities[action.payload.itemID] = action.payload; // payload is updated project object
		},
		[updateFolder.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},

		/** updateProject */
		[updateProject.pending]: (state, action) => {
			state.status = "pending";
		},
		[updateProject.fulfilled]: (state, action) => {
			state.status = "idle";
			//payload is updated item with itemID embedded
			state.entities[action.payload.itemID] = action.payload; // payload is updated project object
		},
		[updateProject.rejected]: (state, action) => {
			state.status = "idle";
			if (action.payload) {
				state.error = action.payload.errorMessage;
			} else {
				state.error = action.error;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	rehydrateProject,
	removeProject,
	removeProjectChild,
	setCurrentEntityID,
} = projectsSlice.actions;
export default projectsSlice.reducer;
